import React, { useState, ReactNode } from 'react';
import WContainer from '../WContainer/WContainer';

/* The 2 lists that this component can toggle between using the view/setView */

interface SocietiesArtistsProps {
  societiesComponent: ReactNode;
  artistsComponent: ReactNode;
}

// Define the SocietiesArtists component. It accepts societies and artists as props.
export const SocietiesArtistsListBox: React.FC<SocietiesArtistsProps> = ({
  societiesComponent,
  artistsComponent,
}) => {
  const [view, setView] = useState('artists');

  return (
    <WContainer extraClasses="tw-w-1/4">
      {/* Society - Artists search toggle to return in phase II */}
      {/* <div className="tw-flex tw-justify-between tw-mb-6 tw-rounded-[19px] tw-overflow-hidden tw-bg-surfaceBlack80 tw-py-1">
                <button
                    className={`tw-w-1/2 tw-text-center tw-py-1 tw-rounded-[19px] ${view === 'societies' ? 'tw-bg-textLime tw-text-textBlack80' : 'tw-bg-surfaceBlack80 tw-text-textWhite'}`}
                    onClick={() => setView('societies')}
                >
                    Societies
                </button>
                <button
                    className={`tw-w-1/2 tw-text-center tw-py-1 tw-rounded-[19px] ${view === 'artists' ? 'tw-bg-textLime tw-text-textBlack80' : 'tw-bg-surfaceBlack80 tw-text-textWhite'}`}
                    onClick={() => setView('artists')}
                >
                    Artists
                </button>
            </div> */}
      {view === 'societies' && societiesComponent}
      {view === 'artists' && artistsComponent}
    </WContainer>
  );
};
