/* tslint:disable */
/* eslint-disable */
/**
 * Westbury Music API Admin
 * API for Westbury Music admin users
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ClientUserUserSettings } from './ClientUserUserSettings';
import {
    ClientUserUserSettingsFromJSON,
    ClientUserUserSettingsFromJSONTyped,
    ClientUserUserSettingsToJSON,
} from './ClientUserUserSettings';

/**
 * 
 * @export
 * @interface ClientUser
 */
export interface ClientUser {
    /**
     * 
     * @type {number}
     * @memberof ClientUser
     */
    readonly id?: number;
    /**
     * Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only.
     * @type {string}
     * @memberof ClientUser
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof ClientUser
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientUser
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientUser
     */
    lastName?: string;
    /**
     * Designates whether this user should be treated as active. Unselect this instead of deleting accounts.
     * @type {boolean}
     * @memberof ClientUser
     */
    isActive?: boolean;
    /**
     * Designates whether the user can log into this admin site.
     * @type {boolean}
     * @memberof ClientUser
     */
    isStaff?: boolean;
    /**
     * Designates that this user has all permissions without explicitly assigning them.
     * @type {boolean}
     * @memberof ClientUser
     */
    isSuperuser?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof ClientUser
     */
    dateJoined?: Date;
    /**
     * 
     * @type {ClientUserUserSettings}
     * @memberof ClientUser
     */
    userSettings: ClientUserUserSettings;
}

/**
 * Check if a given object implements the ClientUser interface.
 */
export function instanceOfClientUser(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "username" in value;
    isInstance = isInstance && "userSettings" in value;

    return isInstance;
}

export function ClientUserFromJSON(json: any): ClientUser {
    return ClientUserFromJSONTyped(json, false);
}

export function ClientUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClientUser {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'username': json['username'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'firstName': !exists(json, 'first_name') ? undefined : json['first_name'],
        'lastName': !exists(json, 'last_name') ? undefined : json['last_name'],
        'isActive': !exists(json, 'is_active') ? undefined : json['is_active'],
        'isStaff': !exists(json, 'is_staff') ? undefined : json['is_staff'],
        'isSuperuser': !exists(json, 'is_superuser') ? undefined : json['is_superuser'],
        'dateJoined': !exists(json, 'date_joined') ? undefined : (new Date(json['date_joined'])),
        'userSettings': ClientUserUserSettingsFromJSON(json['user_settings']),
    };
}

export function ClientUserToJSON(value?: ClientUser | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'username': value.username,
        'email': value.email,
        'first_name': value.firstName,
        'last_name': value.lastName,
        'is_active': value.isActive,
        'is_staff': value.isStaff,
        'is_superuser': value.isSuperuser,
        'date_joined': value.dateJoined === undefined ? undefined : (value.dateJoined.toISOString()),
        'user_settings': ClientUserUserSettingsToJSON(value.userSettings),
    };
}

