export class WError extends Error {
  public title?: string;

  constructor(message: string, error?: Error, title?: string) {
    super(message);
    
    this.name = 'WError';
    this.title = title;

    // If an error object is provided, use its stack
    if (error) {
      this.stack = error.stack;
      // TODO: Log the error to an error reporting service
    }
  }
}

// In the future, we can add more error types here, e.g. WNetworkError, WHttpError, etc.
// Then in the catch block, we can do something like:
// catch (error) {
//   if (error instanceof WNetworkError) {
//     // Handle network error
//   } else if (error instanceof WHttpError) {
//     // Handle http error
//   } else {
//     // Handle other errors
