/* tslint:disable */
/* eslint-disable */
/**
 * Westbury Music API Admin
 * API for Westbury Music admin users
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ListSecondaryDiscographies
 */
export interface ListSecondaryDiscographies {
    /**
     * 
     * @type {string}
     * @memberof ListSecondaryDiscographies
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ListSecondaryDiscographies
     */
    societyName: string;
    /**
     * 
     * @type {string}
     * @memberof ListSecondaryDiscographies
     */
    status: string;
    /**
     * 
     * @type {string}
     * @memberof ListSecondaryDiscographies
     */
    s3Url: string;
    /**
     * 
     * @type {Date}
     * @memberof ListSecondaryDiscographies
     */
    dateCreated: Date;
    /**
     * 
     * @type {Date}
     * @memberof ListSecondaryDiscographies
     */
    dateSentToSociety: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ListSecondaryDiscographies
     */
    country: string;
}

/**
 * Check if a given object implements the ListSecondaryDiscographies interface.
 */
export function instanceOfListSecondaryDiscographies(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "societyName" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "s3Url" in value;
    isInstance = isInstance && "dateCreated" in value;
    isInstance = isInstance && "dateSentToSociety" in value;
    isInstance = isInstance && "country" in value;

    return isInstance;
}

export function ListSecondaryDiscographiesFromJSON(json: any): ListSecondaryDiscographies {
    return ListSecondaryDiscographiesFromJSONTyped(json, false);
}

export function ListSecondaryDiscographiesFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListSecondaryDiscographies {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'societyName': json['society_name'],
        'status': json['status'],
        's3Url': json['s3_url'],
        'dateCreated': (new Date(json['date_created'])),
        'dateSentToSociety': (json['date_sent_to_society'] === null ? null : new Date(json['date_sent_to_society'])),
        'country': json['country'],
    };
}

export function ListSecondaryDiscographiesToJSON(value?: ListSecondaryDiscographies | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'society_name': value.societyName,
        'status': value.status,
        's3_url': value.s3Url,
        'date_created': (value.dateCreated.toISOString()),
        'date_sent_to_society': (value.dateSentToSociety === null ? null : value.dateSentToSociety.toISOString()),
        'country': value.country,
    };
}

