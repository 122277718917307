/* tslint:disable */
/* eslint-disable */
/**
 * Westbury Music API Admin
 * API for Westbury Music admin users
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TermsOfAgreementConfirmationPandadocDocumentIdsInner
 */
export interface TermsOfAgreementConfirmationPandadocDocumentIdsInner {
    /**
     * 
     * @type {string}
     * @memberof TermsOfAgreementConfirmationPandadocDocumentIdsInner
     */
    documentName: string;
    /**
     * 
     * @type {string}
     * @memberof TermsOfAgreementConfirmationPandadocDocumentIdsInner
     */
    documentId: string;
}

/**
 * Check if a given object implements the TermsOfAgreementConfirmationPandadocDocumentIdsInner interface.
 */
export function instanceOfTermsOfAgreementConfirmationPandadocDocumentIdsInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "documentName" in value;
    isInstance = isInstance && "documentId" in value;

    return isInstance;
}

export function TermsOfAgreementConfirmationPandadocDocumentIdsInnerFromJSON(json: any): TermsOfAgreementConfirmationPandadocDocumentIdsInner {
    return TermsOfAgreementConfirmationPandadocDocumentIdsInnerFromJSONTyped(json, false);
}

export function TermsOfAgreementConfirmationPandadocDocumentIdsInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): TermsOfAgreementConfirmationPandadocDocumentIdsInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'documentName': json['document_name'],
        'documentId': json['document_id'],
    };
}

export function TermsOfAgreementConfirmationPandadocDocumentIdsInnerToJSON(value?: TermsOfAgreementConfirmationPandadocDocumentIdsInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'document_name': value.documentName,
        'document_id': value.documentId,
    };
}

