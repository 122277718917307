/* tslint:disable */
/* eslint-disable */
/**
 * Westbury Music API Admin
 * API for Westbury Music admin users
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StatementsJsonMetadata
 */
export interface StatementsJsonMetadata {
    /**
     * 
     * @type {string}
     * @memberof StatementsJsonMetadata
     */
    clientName: string;
    /**
     * 
     * @type {string}
     * @memberof StatementsJsonMetadata
     */
    commission: string;
    /**
     * 
     * @type {string}
     * @memberof StatementsJsonMetadata
     */
    paymentDate: string;
    /**
     * 
     * @type {string}
     * @memberof StatementsJsonMetadata
     */
    remittanceMethod: StatementsJsonMetadataRemittanceMethodEnum;
    /**
     * 
     * @type {string}
     * @memberof StatementsJsonMetadata
     */
    status: StatementsJsonMetadataStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof StatementsJsonMetadata
     */
    totalAmountReceived: string;
    /**
     * 
     * @type {string}
     * @memberof StatementsJsonMetadata
     */
    totalPayable: string;
}


/**
 * @export
 */
export const StatementsJsonMetadataRemittanceMethodEnum = {
    BankTransfer: 'bank_transfer',
    Cheque: 'cheque'
} as const;
export type StatementsJsonMetadataRemittanceMethodEnum = typeof StatementsJsonMetadataRemittanceMethodEnum[keyof typeof StatementsJsonMetadataRemittanceMethodEnum];

/**
 * @export
 */
export const StatementsJsonMetadataStatusEnum = {
    Held: 'held',
    ToBeTransferred: 'to_be_transferred',
    WaitingChequeClear: 'waiting_cheque_clear',
    Cleared: 'cleared'
} as const;
export type StatementsJsonMetadataStatusEnum = typeof StatementsJsonMetadataStatusEnum[keyof typeof StatementsJsonMetadataStatusEnum];


/**
 * Check if a given object implements the StatementsJsonMetadata interface.
 */
export function instanceOfStatementsJsonMetadata(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "clientName" in value;
    isInstance = isInstance && "commission" in value;
    isInstance = isInstance && "paymentDate" in value;
    isInstance = isInstance && "remittanceMethod" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "totalAmountReceived" in value;
    isInstance = isInstance && "totalPayable" in value;

    return isInstance;
}

export function StatementsJsonMetadataFromJSON(json: any): StatementsJsonMetadata {
    return StatementsJsonMetadataFromJSONTyped(json, false);
}

export function StatementsJsonMetadataFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatementsJsonMetadata {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'clientName': json['client_name'],
        'commission': json['commission'],
        'paymentDate': json['payment_date'],
        'remittanceMethod': json['remittance_method'],
        'status': json['status'],
        'totalAmountReceived': json['total_amount_received'],
        'totalPayable': json['total_payable'],
    };
}

export function StatementsJsonMetadataToJSON(value?: StatementsJsonMetadata | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'client_name': value.clientName,
        'commission': value.commission,
        'payment_date': value.paymentDate,
        'remittance_method': value.remittanceMethod,
        'status': value.status,
        'total_amount_received': value.totalAmountReceived,
        'total_payable': value.totalPayable,
    };
}

