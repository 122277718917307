import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useApi } from 'shared_components/context';
import { Row, Col, Form } from 'react-bootstrap';
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from 'react-circular-progressbar';
import { useClientId } from 'shared_components/context/client';
import DistributionChart from './Components/DistributionChart';
import UploadDistribution from './Components/Distribution/UploadDistribution';
import UploadCredit from './Components/Distribution/UploadCredit';
import CashChequeCard from './Components/Distribution/CashChequeCard';
import XeroFileReady from './Components/Distribution/XeroFileReady';
import { AdminTaskClient } from 'shared_components/generated/admin/models';
import { WTable } from 'shared_components/WTable/WTable';

import { CircleChart } from 'shared_components/components/CircleChart';
import ClientsChart from './Components/ClientsChart';
import Button from 'shared_components/components/Button';
import WContainer from 'shared_components/components/WContainer/WContainer';
import WButton from 'shared_components/components/WForms/WButton/WButton';
import { WProgressBar } from 'shared_components/components/WProgressBar/WProgressBar';
function DistributionDetails() {
  const { adminApi: api } = useApi();
  const [registerUsers, setRegisterUsers] = useState(75);
  const [clientTasks, setClientTasks] = useState<AdminTaskClient[]>([]);
  const [taskId, setTaskId] = useState('');
  const [continueButtonDisabled, setContinueButtonDisabled] = useState(true);
  const [eoiReviewID, setEoiReviewID] = useState<string>('');
  const [DiscographyID, setDiscographyID] = useState<string>();
  const [showEOIcard, setShowEOIcard] = useState(false);
  const [showCashChequeCard, setShowCashChequeCard] = useState(false);
  const [showUploadDistribution, setshowUploadDistribution] = useState(false);
  const [showUploadCredit, setShowUploadCredit] = useState(false);
  const [statementView, setStatementView] = useState(false);
  const things_to_do_status = new Map([
    ['verify_passport_and_send_forms', 'Cash cheque'],
    ['expression_of_interest_review', 'Upload credit advice'],
    ['submit_discography_id', 'Post cheque to client'],
  ]);
  const { clientId, setClientId, setClientEmail } = useClientId();

  const fetchData = () => {
    api
      .listAdminTaskClients()
      .then((adminClientTasks) => {
        setClientTasks(adminClientTasks);
      })
      .catch(() => {});
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleThingsToDoStatusClick = (clientTask: AdminTaskClient) => {
    const client_task_status = clientTask.clientAdminTasks[0].status;
    if (client_task_status == 'verify_passport_and_send_forms') {
      setShowCashChequeCard(true);
    } else if (client_task_status == 'expression_of_interest_review') {
      setShowCashChequeCard(true);
    } else if (client_task_status == 'submit_discography_id') {
      setShowCashChequeCard(true);
    }
  };

  const showCard = () => {
    document.body.classList.add('hide-scroll');
  };

  const hideCard = () => {
    document.body.classList.remove('hide-scroll');
    setShowEOIcard(false);
    setShowCashChequeCard(false);
  };
  const showDistributionUpload = () => {
    setshowUploadDistribution(true);
    if (showUploadDistribution == true) {
      setshowUploadDistribution(false);
    }
  };
  const showDistributionCredit = () => {
    setShowUploadCredit(true);
    if (showUploadCredit == true) {
      setShowUploadCredit(false);
    }
  };
  const handleShow = () => setStatementView(!statementView);

  let tableData = clientTasks.map((clientTask, i) => {
    const [firstThingToDo] = clientTask?.clientAdminTasks ?? [];
    let dateCreated = firstThingToDo?.dateCreated;
    return {
      artist: clientTask.fullName ?? clientTask.username,
      action: (
        <div
          role="button"
          tabIndex={0}
          onClick={() => handleThingsToDoStatusClick(clientTask)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              handleThingsToDoStatusClick(clientTask);
            }
          }}
        >
          {things_to_do_status.get(firstThingToDo?.status ?? '')}
        </div>
      ),
      dateCreated: <p>{dateCreated?.toLocaleDateString().toString() || '-'}</p>,
      status: <WProgressBar percentage={75} label="75% complete" />,
    };
  });

  return (
    <>
      {/* MODALS */}
      <CashChequeCard isOpen={showCashChequeCard} cardEvent={hideCard} />

      <UploadDistribution
        show={showUploadDistribution}
        onCloseBtn={showDistributionUpload}
      />
      {statementView ? (
        <XeroFileReady
          show={statementView}
          modalClose={handleShow}
          statementPdf={'test'}
        />
      ) : (
        ''
      )}
      <div
        className={`EOICardWrap ${showUploadCredit ? 'active' : 'hide-card'}`}
      >
        <UploadCredit
          show={showUploadCredit}
          onCloseBtn={showDistributionCredit}
        />
      </div>

      {/* END MODALS */}
      <h2 className="tw-mb-6">Distribution dashboard</h2>
      <div
        className="tw-flex tw-w-full tw-space-x-6 tw-max-w-[1500px]"
        style={{ maxWidth: '1584px', width: '100%' }}
      >
        <WContainer
          extraClasses="tw-flex tw-w-[340px] tw-flex-col tw-bg-surfaceBlack90 tw-text-white tw-p-6 tw-rounded-3 tw-rounded-xl tw-mt-0"
          // style={{ minWidth: '302px' }}
          disabled
        >
          <p className="tw-uppercase tw-text-sm">Income paid out</p>
          <CircleChart
            value={registerUsers}
            text="of income cleared"
          ></CircleChart>
          <div className="tw-text-right">
            <Link to="/" className="viewLink">
              View
            </Link>
          </div>
        </WContainer>
        <WContainer
          disabled
          extraClasses="tw-flex tw-flex-col tw-align-bottom tw-bg-surfaceBlack90 tw-text-white tw-p-4 tw-rounded-xl tw-flex-grow tw-overflow-scroll tw-mt-0"
        >
          <span className="tw-uppercase tw-text-sm tw-pb-2">WMC Income</span>
          <div
            className="tw-overflow-x-scroll tw-flex-grow tw-w-full"
            style={{}}
          >
            <ClientsChart />
          </div>
        </WContainer>
      </div>

      <p className="tw-uppercase tw-text-2xl tw-text-white tw-mt-8 tw-mb-8 tw-uppercase">
        Tasks
      </p>
      <WContainer disabled>
        <WTable
          columns={[
            {
              title: 'Artist',
              dataKey: 'artist',
            },
            {
              title: 'Action',
              dataKey: 'action',
            },
            {
              title: 'Date Added',
              dataKey: 'dateCreated',
            },
            {
              title: 'Status',
              dataKey: 'status',
            },
          ]}
          data={tableData}
        />
      </WContainer>
    </>
  );
}

export default DistributionDetails;
