import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';
import { ClientSocietyFormTaskStatusEnum } from 'shared_components/generated/admin';
import { useApi } from 'shared_components/context';
import {
  AdminSociety,
  TermsOfAgreementSlidingScaleBasedOnEnum,
  UserSettings,
  TermsOfAgreementBankPaymentMethodEnum,
  TermsOfAgreement,
} from 'shared_components/generated/admin';
import ArtistDetails from './Components/ArtistDetails';
import TermsOfAgreementSent from './Components/termsOfAgreement/TermsOfAgreementSent';
import TermsOfAgreementForm from './Components/termsOfAgreement/TermsOfAgreementForm';
import AgreementModal from 'client_portal/src/view/Client/PageLayout/AgreementModal';
import PageLayout from './PageLayout';

import { WFormProvider } from 'shared_components/components/WForms/WFormProvider';
import { WFormFooter } from 'shared_components/components/WForms/WFormFooter';
import WButton from 'shared_components/components/WForms/WButton/WButton';
export interface AdminSocietyWithCheckbox extends AdminSociety {
  isChecked: boolean;
}

import { WError } from 'shared_components/components/WError/WError';
import { useWError } from 'shared_components/components/WError/WErrorProvider';
import WModal from 'shared_components/components/WModal';
import ThankyouBlock from 'shared_components/components/ThankYouBlock/ThankyouBlock';

function TermsOfAgreement() {
  const { throwError } = useWError();
  const [societyList, setSocietyList] = useState<
    Array<AdminSocietyWithCheckbox>
  >([]);
  const { adminApi: api } = useApi();
  const [selectAllSocieties, setSelectAllSocieties] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [userSettings, setUserSettings] = useState<UserSettings | undefined>(
    undefined
  );
  const [formSentToClient, setFormSentToClient] = useState(false);
  const [showPage, setShowPage] = useState(true);
  const { userId } = useParams<{ userId: string }>();
  const navigate = useNavigate();
  const [termsOfAgreement, setTermsOfAgreement] = useState<TermsOfAgreement>({
    user: userId as unknown as number,
    standardAgreement: true,
    commissionRate: '',
    minimumPayment: '',
    universalSocietyCommissionRate: true,
    societyCommissionRates: [
      {
        commissionRate: '',
        society: '',
      },
    ],
    performerOrProducer: true,
    masterRights: false,
    universalMandate: true,
    slidingScaleRequired: false,
    slidingScaleBasedOn: TermsOfAgreementSlidingScaleBasedOnEnum.NetIncome,
    slidingScales: [
      {
        threshold: '',
        commissionRate: '',
      },
    ],
    bankPaymentMethod: TermsOfAgreementBankPaymentMethodEnum.BankTransfer,
    representativeFeeRequired: false,
    representativeFee: {
      percentage: '',
      takenFrom: undefined,
      expirationDate: new Date(),
    },
    clientRequiresAdvance: false,
    advanceAmount: '',
    mandatedSocieties: [],
  });
  const data = useLocation();
  const taskId: string | undefined = data.state?.taskId
    ? data.state.taskId
    : undefined;

  interface documentIds {
    documentId: string;
    documentName: string;
  }
  const [pandadocDocumentIds, setPandadocDocumentIds] = useState<documentIds[]>(
    []
  );
  const [isLoading, setIsLoading] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const societiesFetch = async () => {
    try {
      let response = await api.listAdminSocieties();
      let societyListWithCheckbox = response.map((society) => ({
        ...society,
        isChecked: false,
      }));
      setSocietyList(societyListWithCheckbox);
    } catch (err) {
      throwError(new WError('Failed to fetch societies'));
    }
  };
  const userFetch = async (userId: string) => {
    if (userId === undefined) {
      return;
    }
    try {
      let userSettings = await api.retrieveUserSettings({ userId: userId });
      setUserSettings(userSettings);
    } catch (err) {
      throwError(new WError('Error fetching user data'));
    }
  };

  const saveUserSettings = async (updatedSettings: {
    fullName: string | undefined | null;
    emailAddress: string | undefined | null;
    firstName: string | undefined | null;
    lastName: string | undefined | null;
    middleName: string | undefined | null;
  }) => {
    let combinedName = generateFullName(
      updatedSettings.firstName,
      updatedSettings.middleName,
      updatedSettings.lastName
    );
    let updatedUserSettings = {
      ...userSettings,
      ...updatedSettings,
      fullName: combinedName,
    };
    if (userId) {
      return api
        .updateUserSettings({
          userId: userId as string,
          userSettings: updatedUserSettings,
        })
        .then((response) => {
          setUserSettings(response);
          return Promise.resolve(response);
        });
    } else {
      return Promise.reject();
    }
  };
  const generateFullName = (
    firstName: string | undefined | null,
    middleName: string | undefined | null,
    lastName: string | undefined | null
  ): string => {
    const parts = [firstName, middleName, lastName].filter(Boolean);
    return parts.join(' ');
  };

  const handleSubmit = async () => {
    return sendTermsOfAgreement();
  };

  const sendTermsOfAgreement = async () => {
    let existingData;
    try {
      existingData = await api.retrieveTermsOfAgreement({
        userId: userId as string,
      });
    } catch (err) {
      existingData = null;
    }
    const termsOfAgreementData = {
      userId: userId,
      termsOfAgreement: {
        user: userId as unknown as number,
        standardAgreement: termsOfAgreement.standardAgreement,
        commissionRate: termsOfAgreement.standardAgreement
          ? ''
          : termsOfAgreement.commissionRate,
        minimumPayment: termsOfAgreement.standardAgreement
          ? ''
          : termsOfAgreement.minimumPayment,
        universalSocietyCommissionRate:
          termsOfAgreement.universalSocietyCommissionRate,
        societyCommissionRates: termsOfAgreement.universalSocietyCommissionRate
          ? []
          : termsOfAgreement.societyCommissionRates,
        performerOrProducer: termsOfAgreement.performerOrProducer,
        masterRights: termsOfAgreement.masterRights,
        universalMandate: termsOfAgreement.universalMandate,
        slidingScaleRequired: termsOfAgreement.slidingScaleRequired,
        slidingScaleBasedOn: termsOfAgreement.slidingScaleRequired
          ? (termsOfAgreement.slidingScaleBasedOn?.replace(' ', '_') as
              | TermsOfAgreementSlidingScaleBasedOnEnum
              | undefined)
          : undefined,
        slidingScales: termsOfAgreement.slidingScaleRequired
          ? termsOfAgreement.slidingScales
          : [],
        bankPaymentMethod: termsOfAgreement.bankPaymentMethod,
        representativeFeeRequired: termsOfAgreement.representativeFeeRequired,
        representativeFee: termsOfAgreement.representativeFeeRequired
          ? termsOfAgreement.representativeFee
          : null,
        clientRequiresAdvance: termsOfAgreement.clientRequiresAdvance,
        advanceAmount: termsOfAgreement.clientRequiresAdvance
          ? termsOfAgreement.advanceAmount
          : '',
        mandatedSocieties:
          selectAllSocieties || termsOfAgreement.universalMandate
            ? societyList.map((society) => society.id ?? '')
            : termsOfAgreement.mandatedSocieties,
      },
    };
    try {
      if (!existingData) {
        await api.createTermsOfAgreement({
          userId: userId as string,
          termsOfAgreement: termsOfAgreementData.termsOfAgreement,
        });
      } else {
        await api.updateTermsOfAgreement({
          userId: userId as string,
          termsOfAgreement: termsOfAgreementData.termsOfAgreement,
        });
      }

      toggleModal();
    } catch (err) {
      throwError(new WError('Error creating Terms of Agreement'));
    }
  };

  const handleOnCancel = () => {
    setFormSentToClient(false);

    toggleModal();
  };

  const handleSendToClient = async () => {
    if (taskId) {
      try {
        await api.destroyAdminTaskClient({ taskId: taskId });
      } catch {
        throwError(new WError('Failed to delete task'));
      }
    } else {
      throwError(new WError('No task id provided'));
    }
    setIsLoading(true);
    try {
      await api.createClientTasksTermsOfAgreementConfirmation({
        userId: userId as string,
        termsOfAgreementConfirmation: {
          pandadocDocumentIds: pandadocDocumentIds,
        },
      });
      toggleModal();
    } catch {
      throwError(
        new WError('Failed to create terms of agreement confirmation')
      );
    }
    setIsLoading(false);
    setIsModalOpen(false);
    setShowSuccessModal(true);
  };

  const checkUserStatus = async () => {
    const tasks = await api.listClientSocietyFormTasks({
      userId: userId as string,
    });
    const filteredTasks = tasks.filter(
      (task) => task.status === ClientSocietyFormTaskStatusEnum.ClientToSign
    );

    setShowPage(filteredTasks.length === 0);
  };

  useEffect(() => {
    societiesFetch();
    userFetch(userId as string);
    checkUserStatus();
  }, [userId]);

  return (
    <PageLayout>
      <h2 className="tw-text-2xl tw-text-white tw-mb-4">Terms Of Agreement</h2>
      <WButton
        variant="link-secondary"
        icon="chevron-left"
        onClick={() => navigate('/dashboard')}
        label="Back"
      />

      {/* If the admin has already submitted forms, we default to the AgreementSent component */}
      {!showPage && (
        <TermsOfAgreementSent
          onGoBackToDashboard={() => {
            navigate('/dashboard');
          }}
        />
      )}

      {showPage && (
        <>
          {!formSentToClient && userSettings && (
            <ArtistDetails
              userSettings={userSettings}
              onSave={saveUserSettings}
            />
          )}

          {formSentToClient && !isModalOpen && (
            <div>
              <TermsOfAgreementSent
                onGoBackToDashboard={() => {
                  navigate('/dashboard');
                }}
              />
            </div>
          )}

          {!formSentToClient && (
            <WFormProvider
              submitOnEnter={false}
              editByDefault={true}
              handleSubmit={handleSubmit}
              schema={undefined}
              formData={{}}
            >
              <TermsOfAgreementForm
                termsOfAgreement={termsOfAgreement}
                setTermsOfAgreement={setTermsOfAgreement}
                societyList={societyList}
                setSocietyList={setSocietyList}
                selectAllSocieties={selectAllSocieties}
                setSelectAllSocieties={setSelectAllSocieties}
              ></TermsOfAgreementForm>
              <WFormFooter successLabel="Continue" />
            </WFormProvider>
          )}
          <AgreementModal
            showModal={isModalOpen}
            modalClose={handleOnCancel}
            clientId={userId}
            clientEmailAddress={userSettings?.emailAddress as string}
            preview={true}
            setPandadocDocumentIds={setPandadocDocumentIds}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          >
            <div className="tw-flex tw-my-4 tw-space-x-4">
              <WButton
                variant="primary"
                label="Send to client"
                onClick={handleSendToClient}
              />
              <WButton
                onClick={handleOnCancel}
                label="Cancel"
                variant="link-secondary"
              />
            </div>
          </AgreementModal>
        </>
      )}

      <WModal
        isOpen={showSuccessModal}
        onClose={() => {
          setShowSuccessModal(false);
        }}
        disableBackdropClick={true}
        hasCloseButton={false}
        title=""
      >
        <ThankyouBlock
          title="Sent to client"
          description="The WMC forms have been sent to the client and you will be notified once completed"
          cta={{
            ctaText: 'Return to Dashboard',
            onClick: () => {
              navigate('/dashboard');
            },
          }}
        />
      </WModal>
    </PageLayout>
  );
}

export default TermsOfAgreement;
