/* tslint:disable */
/* eslint-disable */
/**
 * Westbury Music API Admin
 * API for Westbury Music admin users
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TaxFormRole
 */
export interface TaxFormRole {
    /**
     * 
     * @type {string}
     * @memberof TaxFormRole
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof TaxFormRole
     */
    society?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TaxFormRole
     */
    determinedBy?: TaxFormRoleDeterminedByEnum;
    /**
     * 
     * @type {string}
     * @memberof TaxFormRole
     */
    country?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TaxFormRole
     */
    form?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TaxFormRole
     */
    signature?: TaxFormRoleSignatureEnum;
    /**
     * 
     * @type {string}
     * @memberof TaxFormRole
     */
    sendVia?: TaxFormRoleSendViaEnum;
    /**
     * 
     * @type {string}
     * @memberof TaxFormRole
     */
    signedBy?: TaxFormRoleSignedByEnum;
}


/**
 * @export
 */
export const TaxFormRoleDeterminedByEnum = {
    Nationality: 'Nationality',
    CountryOfResidence: 'Country of Residence',
    IndividualEntity: 'Individual/Entity'
} as const;
export type TaxFormRoleDeterminedByEnum = typeof TaxFormRoleDeterminedByEnum[keyof typeof TaxFormRoleDeterminedByEnum];

/**
 * @export
 */
export const TaxFormRoleSignatureEnum = {
    Digital: 'Digital',
    Physical: 'Physical'
} as const;
export type TaxFormRoleSignatureEnum = typeof TaxFormRoleSignatureEnum[keyof typeof TaxFormRoleSignatureEnum];

/**
 * @export
 */
export const TaxFormRoleSendViaEnum = {
    Email: 'Email',
    Post: 'Post',
    Portal: 'Portal'
} as const;
export type TaxFormRoleSendViaEnum = typeof TaxFormRoleSendViaEnum[keyof typeof TaxFormRoleSendViaEnum];

/**
 * @export
 */
export const TaxFormRoleSignedByEnum = {
    Admin: 'Admin',
    Client: 'Client',
    Both: 'Both'
} as const;
export type TaxFormRoleSignedByEnum = typeof TaxFormRoleSignedByEnum[keyof typeof TaxFormRoleSignedByEnum];


/**
 * Check if a given object implements the TaxFormRole interface.
 */
export function instanceOfTaxFormRole(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TaxFormRoleFromJSON(json: any): TaxFormRole {
    return TaxFormRoleFromJSONTyped(json, false);
}

export function TaxFormRoleFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaxFormRole {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'society': !exists(json, 'society') ? undefined : json['society'],
        'determinedBy': !exists(json, 'determined_by') ? undefined : json['determined_by'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'form': !exists(json, 'form') ? undefined : json['form'],
        'signature': !exists(json, 'signature') ? undefined : json['signature'],
        'sendVia': !exists(json, 'send_via') ? undefined : json['send_via'],
        'signedBy': !exists(json, 'signed_by') ? undefined : json['signed_by'],
    };
}

export function TaxFormRoleToJSON(value?: TaxFormRole | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'society': value.society,
        'determined_by': value.determinedBy,
        'country': value.country,
        'form': value.form,
        'signature': value.signature,
        'send_via': value.sendVia,
        'signed_by': value.signedBy,
    };
}

