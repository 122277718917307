import React from 'react';

interface AgreementModalIFrameProps {
  documentId: String;
  title: string;
  style?: React.CSSProperties;
}

function AgreementModalIFrame({
  documentId,
  title,
  style = { width: '70vw', height: '75vh' },
}: AgreementModalIFrameProps) {
  return (
    <iframe
      style={style}
      title={title}
      src={`https://app.pandadoc.com/s/${documentId}`}
    ></iframe>
  );
}

export default AgreementModalIFrame;
