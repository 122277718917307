import React from 'react';
import WButton from 'shared_components/components/WForms/WButton/WButton';
import WFileUpload from 'shared_components/components/WForms/WFileUpload/WFileUpload';
import { useFormContext } from 'shared_components/components/WForms/WFormContext';
import { WFormProvider } from 'shared_components/components/WForms/WFormProvider';

export interface fileUploadData {
  formId?: string | undefined;
  societyFormSubRoleId?: string | undefined;
  user?: number | undefined;
  societyForm?: string | undefined;
  file: undefined | Blob;
}

interface FormUploadProps {
  initialFormData?: fileUploadData;
  upload: (
    isValid?: boolean,
    data?: fileUploadData
  ) => Promise<any> | undefined;
  onSuccess?: () => void | undefined;
}

const FormUpload: React.FC<FormUploadProps> = ({
  upload,
  initialFormData,
  onSuccess,
}) => {
  const [formData, setFormData] = React.useState(initialFormData);
  const onFileChange = (event: React.ChangeEvent<HTMLInputElement> | null) => {
    if (event === null) {
      return;
    }
    if (!event.target.value) {
      return;
    }
    if (event.target.files === null) {
      return;
    }
    if (event.target.value) {
      setFormData({
        ...formData,
        file: event.target.files[0],
      });
    }
  };
  return (
    <div className="tw-flex tw-flex-col tw-text-sm tw-text-textWhite tw-w-[500px]">
      <WFormProvider
        schema={undefined}
        handleSubmit={upload}
        editByDefault={true}
        formData={formData}
        onSuccess={onSuccess}
      >
        <WFileUpload
          label="Upload Physically Signed Form"
          name="uploadForm"
          subTitle="Drag & drop or click to upload"
          onFileChange={(event) => {
            onFileChange(event);
          }}
          value={undefined}
        />
        <FormUploadFooter />
      </WFormProvider>
    </div>
  );
};

export default FormUpload;

function FormUploadFooter() {
  const { handleFormSubmit, formData } = useFormContext();
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  return (
    <div>
      <WButton
        label="Upload"
        variant="primary"
        onClick={() => {
          setIsSubmitting(true);
          handleFormSubmit(formData);
          // set isSubmitting to false again in case of error
          setIsSubmitting(false);
        }}
        disabled={isSubmitting}
      />
    </div>
  );
}
