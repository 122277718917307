/* tslint:disable */
/* eslint-disable */
/**
 * Westbury Music API Admin
 * API for Westbury Music admin users
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdminSocietyFormTaskTasksInner } from './AdminSocietyFormTaskTasksInner';
import {
    AdminSocietyFormTaskTasksInnerFromJSON,
    AdminSocietyFormTaskTasksInnerFromJSONTyped,
    AdminSocietyFormTaskTasksInnerToJSON,
} from './AdminSocietyFormTaskTasksInner';
import type { AdminSocietyFormTaskUser } from './AdminSocietyFormTaskUser';
import {
    AdminSocietyFormTaskUserFromJSON,
    AdminSocietyFormTaskUserFromJSONTyped,
    AdminSocietyFormTaskUserToJSON,
} from './AdminSocietyFormTaskUser';

/**
 * 
 * @export
 * @interface AdminSocietyFormTask
 */
export interface AdminSocietyFormTask {
    /**
     * 
     * @type {AdminSocietyFormTaskUser}
     * @memberof AdminSocietyFormTask
     */
    user: AdminSocietyFormTaskUser;
    /**
     * 
     * @type {Array<AdminSocietyFormTaskTasksInner>}
     * @memberof AdminSocietyFormTask
     */
    tasks: Array<AdminSocietyFormTaskTasksInner>;
}

/**
 * Check if a given object implements the AdminSocietyFormTask interface.
 */
export function instanceOfAdminSocietyFormTask(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "user" in value;
    isInstance = isInstance && "tasks" in value;

    return isInstance;
}

export function AdminSocietyFormTaskFromJSON(json: any): AdminSocietyFormTask {
    return AdminSocietyFormTaskFromJSONTyped(json, false);
}

export function AdminSocietyFormTaskFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdminSocietyFormTask {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'user': AdminSocietyFormTaskUserFromJSON(json['user']),
        'tasks': ((json['tasks'] as Array<any>).map(AdminSocietyFormTaskTasksInnerFromJSON)),
    };
}

export function AdminSocietyFormTaskToJSON(value?: AdminSocietyFormTask | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user': AdminSocietyFormTaskUserToJSON(value.user),
        'tasks': ((value.tasks as Array<any>).map(AdminSocietyFormTaskTasksInnerToJSON)),
    };
}

