import React, { FC, ReactNode, useEffect } from 'react';
import clsx from 'clsx';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  modalClasses?: string;
  children: ReactNode;
  hasCloseButton?: boolean;
  disableBackdropClick?: boolean;
}

export const WModal: FC<ModalProps> = ({
  isOpen,
  onClose,
  title,
  modalClasses,
  children,
  hasCloseButton = true, // if false, the modal will not have a close button
  disableBackdropClick = false, // if true, clicking on the backdrop will not close the modal
}) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="tw-fixed tw-inset-0 tw-flex tw-items-center tw-justify-center tw-z-50">
      <div
        className="tw-absolute tw-inset-0 tw-opacity-50 tw-animate-fadeIn"
        style={{
          background: 'rgba(255, 255, 255, 0.30)',
          backdropFilter: 'blur(3px)',
        }}
        onClick={() => {
          if (!disableBackdropClick) onClose();
        }}
      ></div>

      <div
        className={clsx(
          modalClasses,
          'tw-relative tw-p-6 tw-bg-surfaceBlack90 tw-rounded-2xl tw-transform tw-animate-scaleIn'
        )}
      >
        <header className="tw-flex tw-justify-between tw-items-center">
          <span className="tw-text-white tw-text-xl">{title}</span>
          {hasCloseButton && (
            <span
              className="tw-text-xl tw-cursor-pointer tw-text-white"
              onClick={onClose}
            >
              <i className="wi wi-sm wi-close" />
            </span>
          )}
        </header>
        <main className="tw-mt-4 tw-text-white">{children}</main>
      </div>
    </div>
  );
};
