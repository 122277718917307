import React, { createContext, useContext, useState } from 'react';

interface WFormContextProps {
  isEditing: boolean;
  editByDefault: boolean;
  onSuccess?: () => void;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  isFormSubmitted: boolean;
  setFormSubmitted: React.Dispatch<React.SetStateAction<boolean>>;
  successMessage: React.ReactNode | undefined;
  errors: any;
  setErrors: React.Dispatch<React.SetStateAction<any>>;
  handleFormSubmit: (formData: any) => void;
  formData?: any;
  validateForm: () => any;
}

export const WFormContext = createContext<WFormContextProps | undefined>(
  undefined
);

export const useFormContext = () => {
  const context = useContext(WFormContext);
  if (!context) {
    throw new Error('useFormContext must be used within a FormProvider');
  }
  return context;
};
