/* tslint:disable */
/* eslint-disable */
/**
 * Westbury Music API
 * API for Westbury Music
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PandadocEgress
 */
export interface PandadocEgress {
    /**
     * 
     * @type {object}
     * @memberof PandadocEgress
     */
    pandadocsData: object;
}

/**
 * Check if a given object implements the PandadocEgress interface.
 */
export function instanceOfPandadocEgress(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "pandadocsData" in value;

    return isInstance;
}

export function PandadocEgressFromJSON(json: any): PandadocEgress {
    return PandadocEgressFromJSONTyped(json, false);
}

export function PandadocEgressFromJSONTyped(json: any, ignoreDiscriminator: boolean): PandadocEgress {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pandadocsData': json['pandadocs_data'],
    };
}

export function PandadocEgressToJSON(value?: PandadocEgress | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pandadocs_data': value.pandadocsData,
    };
}

