import React, { useState, useEffect } from 'react';
import { useApi } from 'shared_components/context';
import { useClientId } from 'shared_components/context/client';
import {
  SocietyLogins,
  SocietyLoginsData,
} from 'shared_components/generated/admin';

import { WTable } from 'shared_components/WTable/WTable';
import { WModal } from 'shared_components/components/WModal/WModal';
import clsx from 'clsx';
import WButton from 'shared_components/components/WForms/WButton/WButton';
function SocietyLoginDetails() {
  const { adminApi: api } = useApi();
  const { clientId } = useClientId();
  const [societyLogins, setSocietyLogins] = useState<SocietyLogins[]>([]);
  const [userPasswordAuth, setUserPasswordAuth] = useState(false);
  const [userPassword, setUserPassword] = useState('');
  const [isEditingIndex, setIsEditingIndex] = useState(-1);
  const [showPassIndex, setShowPassIndex] = useState(-1);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    fetchData();
  }, [clientId]);

  const fetchData = () => {
    if (clientId === undefined) {
      return;
    }
    api
      .listSocietyLogins({ userId: clientId })
      .then((logins) => {
        setSocietyLogins(logins);
      })
      .catch(() => {
        setSocietyLogins([]);
      });
  };

  const handleChange = (
    index: number,
    key: keyof SocietyLoginsData,
    value: string
  ) => {
    setSocietyLogins(
      societyLogins.map((login, i) => {
        return i === index
          ? { ...login, data: { ...login.data!, [key]: value } }
          : login;
      })
    );
  };

  const saveDetails = (index: number) => {
    if (clientId === undefined) {
      return;
    }
    api
      .updateAdminUpdateSocietyCredentials({
        userId: clientId,
        societyId: societyLogins[index].societyId,
        adminUpdateSocietyCredentials: {
          ...societyLogins[index].data,
        },
      })
      .then(() => {
        setIsEditingIndex(-1);
      })
      .catch(() => {});
  };

  const authenticatePassword = () => {
    setUserPasswordAuth(!userPasswordAuth);
    setShowPassword(true);
  };

  const columns = [
    { title: 'Society', width: '1fr', dataKey: 'societyName' },
    { title: 'Name', width: '1fr', dataKey: 'registeredArtistName' },
    { title: 'ID', width: '1fr', dataKey: 'accountId' },
    { title: 'Username', width: '2fr', dataKey: 'username' },
    { title: 'Password', width: '1.5fr', dataKey: 'password' },
    { title: 'Portal Link', width: '1.5fr', dataKey: 'societyPortal' },
    { title: 'Action', width: '1fr', dataKey: 'action' },
  ];

  const inputClasses = clsx(
    'tw-bg-formInputBackground',
    'tw-text-gray-400',
    'tw-rounded-lg',
    'tw-w-full',
    'tw-h-10',
    'tw-placeholder-textSeal',
    'tw-text-sm',
    'tw-leading-5',
    'tw-p-1',
    'tw-mb-2',
    'tw-border-2',
    'tw-border-formInputBackground',
    'focus:tw-border-white',
    'focus:tw-text-white',
    'focus:tw-outline-none'
  );

  const rows = societyLogins.map((societyLog, index) => {
    {
      /* id, username and password turn into edit inputs when edit index is set to current value */
    }
    const undefinedFiller = '-';
    return {
      societyName: societyLog.societyName,
      registeredArtistName: (
        <WSimpleInput
          name={`registered_artist_name_${index}`}
          type={'text'}
          id={`registered_artist_name_${index}`}
          className={inputClasses}
          readOnly={isEditingIndex === index ? false : true}
          onChange={(e) =>
            handleChange(index, 'registeredArtistName', e.target.value)
          }
          value={societyLog.data?.registeredArtistName ?? undefinedFiller}
        />
      ),
      accountId: (
        <WSimpleInput
          name={`artist_id_${index}`}
          type={'text'}
          id={`artist_id_${index}`}
          className={inputClasses}
          readOnly={isEditingIndex === index ? false : true}
          onChange={(e) => handleChange(index, 'accountId', e.target.value)}
          value={societyLog.data?.accountId ?? undefinedFiller}
        />
      ),
      username: (
        <WSimpleInput
          name={`artist_username_${index}`}
          type={'text'}
          id={`artist_username_${index}`}
          className={inputClasses}
          readOnly={isEditingIndex === index ? false : true}
          onChange={(e) => handleChange(index, 'username', e.target.value)}
          value={societyLog.data?.username ?? undefinedFiller}
        />
      ),
      password: (
        <div className="tw-flex tw-items-center">
          <WSimpleInput
            name={`artist_password_${index}`}
            type={showPassIndex === index && showPassword ? 'text' : 'password'}
            id={`artist_password_${index}`}
            className={inputClasses}
            readOnly={isEditingIndex === index ? false : true}
            onChange={(e) => handleChange(index, 'password', e.target.value)}
            value={societyLog.data?.password ?? undefinedFiller}
          />

          {showPassIndex === index && showPassword ? (
            <button
              className="passShow passHide"
              data-socid={societyLog.societyId}
              onClick={() => {
                setShowPassIndex(-1);
              }}
            >
              <span>
                <i className={`wi wi-sm wi-shut-eye tw-ml-2`} />
              </span>
            </button>
          ) : (
            <button
              className="tw-bg-transparent tw-border-none tw-outline-none tw-cursor-pointer tw-text-white tw-text-base tw-font-medium tw-px-4 tw-py-2 tw-rounded-md tw-bg-primary"
              // className="passShow"
              data-socid={societyLog.societyId}
              onClick={() => {
                setShowPassIndex(index);
                setUserPasswordAuth(!userPasswordAuth);
                setUserPassword('');
              }}
            >
              <i className={`wi wi-sm wi-eye`} />
            </button>
          )}
        </div>
      ),
      societyPortal: societyLog.societyPortal ? (
        <WButton
          label={societyLog.societyPortal}
          variant={'secondary'}
          onClick={() => {
            window.open(societyLog.societyPortal);
          }}
        />
      ) : (
        '-'
      ),
      action: (
        <div className="d-flex signButtond align-items-center">
          {isEditingIndex === index ? (
            <WButton
              label="Save"
              variant="secondary"
              onClick={() => saveDetails(index)}
            />
          ) : (
            <i
              className={`wi wi-edit tw-cursor-pointer`}
              style={{ width: '25px', height: '25px' }}
              onClick={() => setIsEditingIndex(index)}
            ></i>
          )}
        </div>
      ),
    };
  });

  return (
    <>
      {userPasswordAuth ? (
        <WModal
          isOpen={userPasswordAuth}
          onClose={() => setUserPasswordAuth(!userPasswordAuth)}
          title="Password Authentication"
        >
          <div className={`tw-text-center tw-flex tw-justify-center`}></div>
          <div className="entText ModalFormGroup col-10 mx-auto mt-5">
            <p className={`tw-mb-4`}>
              You are trying to view passwords. Please enter your password to
              allow this:
            </p>

            {/* Hacky workaround to disable chrome autocomplete */}
            <label htmlFor={Math.random().toString()}>
              Password <span className="text-danger">*</span>
            </label>

            <input
              name={Math.random().toString()}
              type={'password'}
              value={userPassword}
              onChange={(e) => setUserPassword(e.target.value)}
              placeholder="Enter Password"
              className={clsx(
                'tw-bg-formInputBackground',
                'tw-text-gray-400',
                'tw-rounded-lg',
                'tw-w-full',
                'tw-h-12',
                'tw-placeholder-textSeal',
                'tw-text-sm',
                'tw-leading-5',
                'tw-p-3',
                'tw-mb-2',
                'tw-border-2',
                'tw-border-formInputBackground',
                'focus:tw-border-white',
                'focus:tw-text-white',
                'focus:tw-outline-none'
              )}
            />

            <div className="tw-flex tw-space-x-4 tw-mt-4">
              <WButton
                variant="primary"
                label="OK"
                onClick={authenticatePassword}
              />

              <WButton
                label="Cancel"
                variant="outline-light"
                onClick={() => {
                  setUserPasswordAuth(!userPasswordAuth);
                  setUserPassword('');
                }}
              />
            </div>
          </div>
        </WModal>
      ) : (
        ''
      )}

      <WTable columns={columns} data={rows} />
    </>
  );
}

export default SocietyLoginDetails;

function WSimpleInput({
  name,
  type,
  id,
  className,
  readOnly,
  onChange,
  value,
}) {
  return (
    <>
      {readOnly && type != 'password' && (
        <span className="tw-text-textDove">{value} </span>
      )}
      {readOnly && type == 'password' && (
        <span className="tw-text-textDove tw-leading-8">
          {value !== '-' ? '●'.repeat(String(value).length) : '--------'}
        </span>
      )}
      {!readOnly && (
        <input
          type={type}
          name={name}
          id={id}
          className={className}
          readOnly={readOnly}
          onChange={onChange}
          value={value}
        />
      )}
    </>
  );
}
