import React from 'react';
import facebookSVG from 'shared_components/public/assets/facebook.svg';
import xSVG from 'shared_components/public/assets/x.svg';
import linkedInSVG from 'shared_components/public/assets/linkedIn.svg';

// TODO: add hrefs to all icons and links

function Footer() {
  return (
    <footer className="tw-flex tw-h-[269px] tw-justify-start tw-space-x-10 tw-bg-surfaceBlack90 tw-p-4">
      {/* Left Side: Company Title and Socials */}
      <div className="tw-flex tw-flex-col tw-items-start tw-p-4">
        <span className="tw-text-white tw-text-md tw-mb-4">
          Westbury Music Consultants Ltd. 2022
        </span>
        <div className="tw-flex tw-space-x-3 tw-mt-2">
          {/* Placeholder icons for socials, replace with actual icons */}
          <span className="tw-text-white">
            <i className="wi wi-20px wi-facebook"></i>
          </span>
          <span className="tw-text-white">
            <i className="wi wi-20px wi-twitter"></i>
          </span>
          <span className="tw-text-white">
            <i className="wi wi-20px wi-linkedin"></i>
          </span>
        </div>
      </div>

      {/* Right Side: List of Links */}
      <div className="tw-list-none tw-mt-3 tw-font-light tw-space-y-2">
        <div className="tw-mb-1">
          <a href=" " className="tw-text-textDove">
            Contact Us
          </a>
        </div>
        <div className="tw-mb-1">
          <a href=" " className="tw-text-textDove">
            Privacy Policy
          </a>
        </div>
        <div className="tw-mb-1">
          <a href=" " className="tw-text-textDove">
            Cookie Policy
          </a>
        </div>
        <div className="tw-mb-1">
          <a href=" " className="tw-text-textDove">
            Terms & Conditions
          </a>
        </div>
        <div className="tw-mb-1">
          <a href=" " className="tw-text-textDove">
            Modern Slavery Act
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
