/* tslint:disable */
/* eslint-disable */
/**
 * Westbury Music API
 * API for Westbury Music
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreatePandadocsSessionId
 */
export interface CreatePandadocsSessionId {
    /**
     * 
     * @type {string}
     * @memberof CreatePandadocsSessionId
     */
    sessionId?: string;
}

/**
 * Check if a given object implements the CreatePandadocsSessionId interface.
 */
export function instanceOfCreatePandadocsSessionId(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CreatePandadocsSessionIdFromJSON(json: any): CreatePandadocsSessionId {
    return CreatePandadocsSessionIdFromJSONTyped(json, false);
}

export function CreatePandadocsSessionIdFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreatePandadocsSessionId {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sessionId': !exists(json, 'session_id') ? undefined : json['session_id'],
    };
}

export function CreatePandadocsSessionIdToJSON(value?: CreatePandadocsSessionId | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'session_id': value.sessionId,
    };
}

