import React from 'react';
import Countries from '../../../../../Forms/Countries.json';
import Nationalities from '../../../../../Forms/Nationalities.json';
import {
  SocietyForm,
  SocietyFormRoleFormsInnerSendViaEnum,
  SocietyFormRoleFormsInnerSignatureEnum,
  SocietyFormRoleFormsInnerSignedByEnum,
  TaxFormRoleDeterminedByEnum,
} from 'shared_components/generated/admin';
import { WSelect } from 'shared_components/components/WForms/WSelect/WSelect';
import WButton from 'shared_components/components/WForms/WButton/WButton';
import { useFormContext } from 'shared_components/components/WForms/WFormContext';
type TaxForm = {
  determined_by: string;
  country: string;
  form: string;
  signed_by: string;
  signature: string;
  send_via: string;
};

interface FormTaxFormsDetailSegmentProps {
  isEditingSociety: boolean;
  differentTaxForm: string;
  taxForms: Array<TaxForm>;
  setTaxForms: (newState: Array<TaxForm>) => void;
  societyForms: Array<SocietyForm>;
}

const determinedByOptions = Object.values(TaxFormRoleDeterminedByEnum).map(
  (option) => ({ value: option, label: option })
);
const signatureOptions = Object.values(
  SocietyFormRoleFormsInnerSignatureEnum
).map((role) => ({ label: role, value: role }));
const sendViaOptions = Object.values(SocietyFormRoleFormsInnerSendViaEnum).map(
  (role) => ({ label: role, value: role })
);
const signedByOptions = Object.values(
  SocietyFormRoleFormsInnerSignedByEnum
).map((role) => ({ label: role, value: role }));

const countryOptions = [
  { label: 'Any other', value: 'Any other' },
  ...Countries.map((country) => ({
    label: country,
    value: country,
  })),
];

const nationalityOptions = [
  { label: 'Any other', value: 'Any other' },
  ...Nationalities.map((nationality) => ({
    label: nationality,
    value: nationality,
  })),
];

export const FormTaxFormsDetailSegment = ({
  isEditingSociety,
  differentTaxForm,
  taxForms,
  setTaxForms,
  societyForms,
}: FormTaxFormsDetailSegmentProps) => {
  const addForm = () => {
    setTaxForms([
      ...taxForms,
      {
        determined_by: '',
        country: '',
        form: '',
        signed_by: '',
        signature: '',
        send_via: '',
      },
    ]);
  };

  const updateForm = (index: number, newForm: TaxForm) => {
    const newTaxforms = [...taxForms];
    newTaxforms[index] = newForm;
    setTaxForms(newTaxforms);
  };

  const deleteForm = (index: number) => {
    const newTaxforms = [...taxForms];
    newTaxforms.splice(index, 1);
    setTaxForms(newTaxforms);
  };
  const { isEditing } = useFormContext();

  return (
    <>
      {differentTaxForm == 'different_tax_form_Yes' ? (
        <>
          <div className="tw-flex tw-w-full tw-flex-col">
            {taxForms.map(
              (
                {
                  determined_by,
                  country,
                  form,
                  signature,
                  send_via,
                  signed_by,
                },
                index: number
              ) =>
                taxFormDetail({
                  isEditingSociety,
                  index,
                  determined_by,
                  country,
                  form,
                  send_via,
                  signature,
                  signed_by,
                  updateForm,
                  deleteForm,
                  societyForms,
                })
            )}
          </div>
          <div className="tw-flex tw-justify-end">
            {isEditing && (
              <WButton
                label="Add another form"
                icon="plus"
                variant="link"
                onClick={() => {
                  console.log('called');
                  addForm();
                }}
              />
            )}
          </div>
        </>
      ) : (
        ''
      )}
    </>
  );
};

interface taxFormDetailProps {
  isEditingSociety: boolean;
  index: number;
  determined_by: string;
  country: string;
  form: string;
  send_via: string;
  signature: string;
  signed_by: string;
  updateForm: (index: number, newForm: TaxForm) => void;
  deleteForm: (index: number) => void;
  societyForms: Array<SocietyForm>;
}

const taxFormDetail = ({
  isEditingSociety,
  index,
  determined_by,
  country,
  form,
  send_via,
  signature,
  signed_by,
  updateForm,
  deleteForm,
  societyForms,
}: taxFormDetailProps) => {
  const { isEditing } = useFormContext();

  const taxFormOptions = societyForms.map((societyForm: SocietyForm) => ({
    label: societyForm.readableName,
    value: societyForm.readableName,
  }));
  const updateDeterminedBy = (selectedDeterminedBy: string) => {
    updateForm(index, {
      determined_by: selectedDeterminedBy,
      country,
      form,
      send_via,
      signature,
      signed_by,
    });
  };
  const updateCountry = (selectedCountry: string) => {
    updateForm(index, {
      determined_by,
      country: selectedCountry,
      form,
      send_via,
      signature,
      signed_by,
    });
  };
  const updateSignedBy = (selectedSignedBy: string) => {
    updateForm(index, {
      determined_by,
      country,
      form,
      send_via,
      signature,
      signed_by: selectedSignedBy,
    });
  };
  const updateSignature = (selectedSignature: string) => {
    updateForm(index, {
      determined_by,
      country,
      form,
      send_via,
      signature: selectedSignature,
      signed_by,
    });
  };
  const updateSendVia = (selectedSendVia: string) => {
    updateForm(index, {
      determined_by,
      country,
      form,
      send_via: selectedSendVia,
      signature,
      signed_by,
    });
  };
  const updateTaxForm = (selectedTaxForm: string) => {
    updateForm(index, {
      determined_by,
      country,
      form: selectedTaxForm,
      send_via,
      signature,
      signed_by,
    });
  };
  const societyNameToId = (readableName: string): string => {
    const societyForm = societyForms.find(
      (societyForm) => societyForm.readableName === readableName
    );
    return societyForm?.id ?? '';
  };
  const societyIdToName = (id: string): string => {
    const societyForm = societyForms.find(
      (societyForm) => societyForm.id === id
    );
    return societyForm?.readableName ?? '';
  };
  return (
    <div className="tw-flex tw-flex-col" key={index}>
      <div className="tw-flex tw-space-x-2 tw-mt-4">
        <div className="tw-w-1/3">
          <WSelect
            label="Determined by"
            name="determined_by"
            defaultValue={
              determined_by && determined_by != '' ? determined_by : undefined
            }
            options={determinedByOptions}
            onBlur={() => {}}
            onChange={(e) => updateDeterminedBy(e.target.value)}
          />
        </div>
        <div className="tw-w-1/3">
          <WSelect
            label={determined_by == 'Nationality' ? 'Nationality' : 'Country'}
            name="country"
            defaultValue={country && country != '' ? country : undefined}
            options={
              determined_by == 'Nationality'
                ? nationalityOptions
                : countryOptions
            }
            onBlur={() => {}}
            onChange={(e) => updateCountry(e.target.value)}
          />
        </div>
        <div className="tw-w-1/3">
          <WSelect
            label="Form"
            name="form"
            defaultValue={
              form && form != '' ? societyIdToName(form) : undefined
            }
            options={taxFormOptions}
            onBlur={() => {}}
            onChange={(e) => updateTaxForm(societyNameToId(e.target.value))}
          />
        </div>
      </div>
      <div className="tw-flex tw-space-x-2">
        <div className="tw-w-1/3">
          <WSelect
            name="signedBy"
            defaultValue={signed_by && signed_by !== '' ? signed_by : undefined}
            label={'Signed by'}
            options={signedByOptions}
            onChange={(e) => updateSignedBy(e.target.value)}
          />
        </div>
        <div className="tw-w-1/3">
          <WSelect
            name="signature"
            defaultValue={signature && signature !== '' ? signature : undefined}
            label={'Signature'}
            options={signatureOptions}
            onChange={(e) => updateSignature(e.target.value)}
          />
        </div>
        <div className="tw-w-1/3">
          <WSelect
            name="sendVia"
            defaultValue={send_via && send_via !== '' ? send_via : undefined}
            label={'Send via'}
            options={sendViaOptions}
            onChange={(e) => updateSendVia(e.target.value)}
          />
        </div>
      </div>
      <div className="tw-flex">
        <div className="tw-flex tw-space-x-1">
          {isEditing && index != 0 ? (
            <WButton
              onClick={() => deleteForm(index)}
              label="Remove"
              variant="link-secondary"
              icon="trash"
            />
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
};
