export const placeholderRips = [
  {
    soc: "SPEDIDAM",
    date: "19/05/22",
    file: "https://www.westburymusic.com",
  },
  {
    soc: "PlayRight",
    date: "19/05/22",
    file: "https://www.westburymusic.com",
  },
  {
    soc: "GRAMEX Finland",
    date: "19/05/22",
    file: "https://www.westburymusic.com",
  },
  {
    soc: "ABRAMUS",
    date: "19/05/22",
    file: "https://www.westburymusic.com",
  },
  {
    soc: "ADAMI",
    date: "19/05/22",
    file: "https://www.westburymusic.com",
  },
  {
    soc: "VOIS",
    date: "19/05/22",
    file: "https://www.westburymusic.com",
  },
  {
    soc: "SPEDIDAM",
    date: "19/05/22",
    file: "https://www.westburymusic.com",
  },
  {
    soc: "NORMA",
    date: "19/05/22",
    file: "https://www.westburymusic.com",
  },
  {
    soc: "RAAP",
    date: "17/04/22",
    file: "https://www.westburymusic.com",
  },
  {
    soc: "PlayRight",
    date: "17/04/22",
    file: "https://www.westburymusic.com",
  },
  {
    soc: "SPEDIDAM",
    date: "19/05/22",
    file: "https://www.westburymusic.com",
  },
  {
    soc: "PlayRight",
    date: "19/05/22",
    file: "https://www.westburymusic.com",
  },
  {
    soc: "GRAMEX Finland",
    date: "19/05/22",
    file: "https://www.westburymusic.com",
  },
  {
    soc: "ABRAMUS",
    date: "19/05/22",
    file: "https://www.westburymusic.com",
  },
  {
    soc: "ADAMI",
    date: "19/05/22",
    file: "https://www.westburymusic.com",
  },
  {
    soc: "VOIS",
    date: "19/05/22",
    file: "https://www.westburymusic.com",
  },
  {
    soc: "SPEDIDAM",
    date: "19/05/22",
    file: "https://www.westburymusic.com",
  },
  {
    soc: "NORMA",
    date: "19/05/22",
    file: "https://www.westburymusic.com",
  },
  {
    soc: "RAAP",
    date: "17/04/22",
    file: "https://www.westburymusic.com",
  },
  {
    soc: "PlayRight",
    date: "17/04/22",
    file: "https://www.westburymusic.com",
  },
];
export const placeholderSocDubs = [
  {
    soc: "SoundExchange",
    date: "19/05/22",
    file: "https://www.westburymusic.com",
  },
  {
    soc: "GRAMEX Denmark",
    date: "19/05/22",
    file: "https://www.westburymusic.com",
  },
  {
    soc: "GRAMEX Finland",
    date: "19/05/22",
    file: "https://www.westburymusic.com",
  },
  {
    soc: "ABRAMUS",
    date: "19/05/22",
    file: "https://www.westburymusic.com",
  },
  {
    soc: "ADAMI",
    date: "19/05/22",
    file: "https://www.westburymusic.com",
  },
  {
    soc: "VOIS",
    date: "19/05/22",
    file: "https://www.westburymusic.com",
  },
  {
    soc: "SPEDIDAM",
    date: "19/05/22",
    file: "https://www.westburymusic.com",
  },
  {
    soc: "NORMA",
    date: "19/05/22",
    file: "https://www.westburymusic.com",
  },
  {
    soc: "RAAP",
    date: "17/04/22",
    file: "https://www.westburymusic.com",
  },
  {
    soc: "PlayRight",
    date: "17/04/22",
    file: "https://www.westburymusic.com",
  },
  {
    soc: "SPEDIDAM",
    date: "19/05/22",
    file: "https://www.westburymusic.com",
  },
  {
    soc: "PlayRight",
    date: "19/05/22",
    file: "https://www.westburymusic.com",
  },
  {
    soc: "GRAMEX Finland",
    date: "19/05/22",
    file: "https://www.westburymusic.com",
  },
  {
    soc: "ABRAMUS",
    date: "19/05/22",
    file: "https://www.westburymusic.com",
  },
  {
    soc: "ADAMI",
    date: "19/05/22",
    file: "https://www.westburymusic.com",
  },
  {
    soc: "VOIS",
    date: "19/05/22",
    file: "https://www.westburymusic.com",
  },
  {
    soc: "SPEDIDAM",
    date: "19/05/22",
    file: "https://www.westburymusic.com",
  },
  {
    soc: "NORMA",
    date: "19/05/22",
    file: "https://www.westburymusic.com",
  },
  {
    soc: "RAAP",
    date: "17/04/22",
    file: "https://www.westburymusic.com",
  },
  {
    soc: "PlayRight",
    date: "17/04/22",
    file: "https://www.westburymusic.com",
  },
];

export const placeholderPlayedRecs = [
  {
    date: "19/05/22",
    deadline: "19/06/22",
    date_send: "19/05/22",
    file: "https://www.westburymusic.com",
  },
  {
    date: "19/03/21",
    deadline: "17/06/21",
    date_send: "17/05/21",
    file: "https://www.westburymusic.com",
  },
  {
    date: "19/03/20",
    deadline: "16/06/20",
    date_send: "16/05/20",
    file: "https://www.westburymusic.com",
  },
];