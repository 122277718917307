// DropdownContent.tsx
import React from 'react';
import clsx from 'clsx';
interface WDropdownContentProps {
  content: React.ReactNode;
}

export const WDropdownContent: React.FC<WDropdownContentProps> = ({
  content,
}) => {
  return (
    <div
      className={clsx(
        'tw-bg-surfaceBlack90',
        'tw-rounded-[16px]',
        'tw-p-2',
        'tw-text-white'
      )}
      style={{ boxShadow: '0px 4px 40px 0px rgba(0, 0, 0, 0.50)' }}
    >
      {content}
    </div>
  );
};

export default WDropdownContent;
