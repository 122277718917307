import React, { useState, useRef, useEffect } from 'react';
import clsx from 'clsx';
export type WTabItem = {
  label: string;
  content: JSX.Element;
  disabled?: boolean;
};

interface TabsProps {
  tabs: WTabItem[];
  variant?: 'default' | 'transparent';
}

// TODO: Fix animation

export const WTabs: React.FC<TabsProps> = ({ tabs, variant = 'default' }) => {
  const [activeTab, setActiveTab] = useState(0);
  const contentRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.style.animation = 'none'; // reset animation
      setTimeout(() => {
        contentRef.current!.style.animation =
          'tw-fadeIn 0.3s ease-in-out forwards'; // apply animation
      }, 0);
    }
  }, [activeTab]);

  let tabsClasses = clsx('tw-bg-surfaceBlack90 tw-rounded tw-p-4');
  switch (variant) {
    case 'default':
      tabsClasses = clsx('tw-bg-surfaceBlack90 tw-rounded tw-p-4');
      break;
    case 'transparent':
      tabsClasses = clsx('tw-rounded tw-p-4');
      break;
    default:
      tabsClasses = clsx('tw-bg-surfaceBlack90 tw-rounded tw-p-4');
      break;
  }

  return (
    <div className={tabsClasses}>
      {/*  Parent container to distribute tabs evenly */}
      <div className="tw-flex tw-justify-start tw-mb-4 tw-w-full tw-relative">
        <div className="tw-absolute tw-inset-x-0 tw-bottom-0 tw-border-b-2 tw-border-surfaceGraphite"></div>
        {tabs.map((tab, index) => (
          <span
            key={index}
            role="button"
            tabIndex={0}
            className={`
      tw-pl-4 tw-pr-4 tw-pt-2
      tw-pb-4 
      tw-font-medium
      ${
        tab.disabled
          ? 'tw-text-textSeal tw-cursor-not-allowed'
          : activeTab === index
          ? 'tw-border-b-[6px] tw-border-textLime tw-text-textLime tw-cursor-pointer'
          : 'tw-text-textDove tw-font-light tw-text-4 tw-cursor-pointer'
      }
      
    `}
            aria-disabled={tab.disabled ? 'true' : 'false'}
            onClick={() => {
              if (!tab.disabled) {
                setActiveTab(index);
              }
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !tab.disabled) {
                setActiveTab(index);
              }
            }}
          >
            {tab.label}
          </span>
        ))}
      </div>
      <div>{tabs[activeTab].content}</div>
    </div>
  );
};
