import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Modal } from 'react-bootstrap';
// import { Document, pdfjs, Page } from "react-pdf";
import TermsOfAgreement from 'shared_components/public/assets/TermsOfAgreement.pdf';
import { useApi } from 'shared_components/context';
// pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

interface Props {
  showModal: boolean;
  clientId: string;
  modalClose: () => void;
}

interface DocumentData {
  name: string;
  template_uuid: string;
  recipients: object[];
  fields: {
    [key: string]: { value: string; title: string; assigned_to: object };
  };
}

var documentData: DocumentData = {
  name: 'Membership Form [GRAMEX (Denmark)]',
  template_uuid: 'grSAyFuPNtoP2bhsQbEokZ',
  recipients: [
    {
      email: 'admin@westbury.com',
      role: 'Westbury Admin',
    },
  ],
  fields: {
    client_sign: {
      value: '',
      title: 'Signature',
      assigned_to: {
        email: 'admin@westbury.com',
        type: 'recipient',
      },
    },
    sign_date: {
      value: '',
      title: 'Date of Signature',
      assigned_to: {
        email: 'admin@westbury.com',
        type: 'recipient',
      },
    },
    date_of_birth: {
      value: '15th April 1999',
      title: 'Date of Birth',
      assigned_to: {
        email: 'admin@westbury.com',
        type: 'recipient',
      },
    },
    nationality: {
      value: 'Uk',
      title: 'nationality',
      assigned_to: {
        email: 'admin@westbury.com',
        type: 'recipient',
      },
    },
    instrument: {
      value: 'xxx xxx xxx',
      title: '',
      assigned_to: {
        email: 'admin@westbury.com',
        type: 'recipient',
      },
    },
    full_name: {
      value: 'xxx xxx xxx',
      title: '',
      assigned_to: {
        email: 'admin@westbury.com',
        type: 'recipient',
      },
    },
    stage_name: {
      value: 'xxx xxx xxx',
      title: '',
      assigned_to: {
        email: 'admin@westbury.com',
        type: 'recipient',
      },
    },
    band_member_of: {
      value: 'xxx xxx xxx',
      title: '',
      assigned_to: {
        email: 'admin@westbury.com',
        type: 'recipient',
      },
    },
    address: {
      value: 'xxx xxx xxx',
      title: '',
      assigned_to: {
        email: 'admin@westbury.com',
        type: 'recipient',
      },
    },
    zip_code: {
      value: 'xxx xxx xxx',
      title: '',
      assigned_to: {
        email: 'admin@westbury.com',
        type: 'recipient',
      },
    },
    country_of_residence: {
      value: 'xxx xxx xxx',
      title: '',
      assigned_to: {
        email: 'admin@westbury.com',
        type: 'recipient',
      },
    },
    tel_no: {
      value: 'xxx xxx xxx',
      title: '',
      assigned_to: {
        email: 'admin@westbury.com',
        type: 'recipient',
      },
    },
    mobile_no: {
      value: 'xxx xxx xxx',
      title: '',
      assigned_to: {
        email: 'admin@westbury.com',
        type: 'recipient',
      },
    },
    email: {
      value: 'xxx xxx xxx',
      title: '',
      assigned_to: {
        email: 'admin@westbury.com',
        type: 'recipient',
      },
    },
    website: {
      value: 'xxx xxx xxx',
      title: '',
      assigned_to: {
        email: 'admin@westbury.com',
        type: 'recipient',
      },
    },
    account_holder: {
      value: 'xxx xxx xxx',
      title: '',
      assigned_to: {
        email: 'admin@westbury.com',
        type: 'recipient',
      },
    },
    bic_code: {
      value: 'xxx xxx xxx',
      title: '',
      assigned_to: {
        email: 'admin@westbury.com',
        type: 'recipient',
      },
    },
    account_no: {
      value: 'xxx xxx xxx',
      title: '',
      assigned_to: {
        email: 'admin@westbury.com',
        type: 'recipient',
      },
    },
    bank_name: {
      value: 'xxx xxx xxx',
      title: '',
      assigned_to: {
        email: 'admin@westbury.com',
        type: 'recipient',
      },
    },
    bank_address: {
      value: 'xxx xxx xxx',
      title: '',
      assigned_to: {
        email: 'admin@westbury.com',
        type: 'recipient',
      },
    },
    bank_zipcode: {
      value: 'xxx xxx xxx',
      title: '',
      assigned_to: {
        email: 'admin@westbury.com',
        type: 'recipient',
      },
    },
  },
};

function MembershipFormModal({ showModal: show, modalClose, clientId }: Props) {
  const { clientApi: api } = useApi();
  localStorage.setItem('document_id', '');
  var docId = localStorage.getItem('document_id');
  const [documentID, setDocumentID] = useState(null);
  const apiKey = '462ef621474a2ea833ac60418b8d36176af04996';
  let documentPDFIds = [];
  const sendDocument = async (doc: DocumentData) => {
    var config = {
      method: 'post',
      url: 'https://api.pandadoc.com/public/v1/documents',
      headers: {
        Authorization: 'API-Key 462ef621474a2ea833ac60418b8d36176af04996',
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(doc),
    };
    axios(config)
      .then(function (response) {
        if (response.data.id) {
          var Senddata = JSON.stringify({
            message: 'Hello! This document was sent from the PandaDoc API.',
            email: 'admin@westbury.com',
            silent: true,
          });
          var config = {
            method: 'post',
            url: `https://api.pandadoc.com/public/v1/documents/${response.data.id}/send`,
            headers: {
              Authorization: 'API-Key 462ef621474a2ea833ac60418b8d36176af04996',
              'Content-Type': 'application/json',
            },
            data: Senddata,
          };
          setTimeout(() => {
            axios(config)
              .then(function (response) {
                if (
                  response.data.id &&
                  response.data.status === 'document.sent'
                ) {
                  var data = JSON.stringify({
                    recipient: 'admin@westbury.com',
                    lifetime: 315359990,
                  });
                  var config = {
                    async: true,
                    method: 'post',
                    url: `https://api.pandadoc.com/public/v1/documents/${response.data.id}/session`,
                    headers: {
                      Authorization:
                        'API-Key 462ef621474a2ea833ac60418b8d36176af04996',
                      'Content-Type': 'application/json',
                    },
                    data: data,
                  };
                  axios(config)
                    .then(function (response) {
                      //localStorage.setItem("document_id", response.data.id);
                      var storeID = response.data.id;
                      documentPDFIds.push(storeID);
                      setDocumentID(storeID);
                      console.log(documentID);
                    })
                    .catch(function (error) {
                      console.log(error);
                    });
                  setTimeout(() => {}, 5000);
                } else if (response.data.status === 'document.completed') {
                }
              })
              .catch(function (error) {});
          }, 3000);
        }
      })
      .catch(function (error) {});
  };

  const fetchData = async () => {
    if (clientId === undefined) {
      return;
    }
    try {
      const res = await api.retrievePandadocsEgress({
        userId: clientId,
        societyFormId: 'd6b59095-05b5-453f-a996-46cc3f06d0fb',
      });
      if (res) {
        Object.entries(res.pandadocsData).forEach(([key, value]) => {
          if (key === 'title') {
            documentData.fields[key + '_' + value] = {
              value: value,
              title: key,
              assigned_to: {
                email: 'admin@westbury.com',
                type: 'recipient',
              },
            };
          } else {
            documentData.fields[key] = {
              value: value,
              title: key,
              assigned_to: {
                email: 'admin@westbury.com',
                type: 'recipient',
              },
            };
          }
        });
        var PDdocs = [
          {
            name: 'Membership Form [ADAMI]',
            uuid: 'VFxqARh3yB48Snj7rkdPMR',
          },
        ];
        documentData.template_uuid = 'VFxqARh3yB48Snj7rkdPMR';
        documentData.name = 'Membership Form [ADAMI]';
        sendDocument(documentData);
      }

      const societies = await api
        .listSocieties({ userId: clientId })
        .then((societies) => {
          console.log(societies);
          return Promise.all(
            societies.map((society) => {
              return api.listSocietyForms({
                userId: clientId,
                societyId: society.id!,
              });
            })
          );
        });

      for await (const forms of societies) {
        /**
         * @todo Handle multiple forms for same society?
         */
        const form = forms[0];
        console.log(form);
        Object.entries(form.prepopulatedData).forEach(([key, value]) => {
          if (!Object.hasOwn(documentData.fields, key)) {
            return;
          }
          documentData.fields[key].value = value;
        });
      }
    } catch {}

    if (!documentID) {
      //sendDocument();
    }
  };

  useEffect(() => {
    fetchData();
  }, [clientId]);

  return (
    <Modal show={show} onHide={modalClose} className="AgreementModal">
      <Button className="ModalClose" onClick={modalClose}>
        close
      </Button>
      <Modal.Body>
        <div className="AgreementModalData">
          <div className="agreementHeader d-none">
            <div className="d-flex justify-content-end modalTopButtons">
              <button className="sign-btn btn">Sign</button>
              <button className="send-to-btn btn">Send to Society</button>
              <a href={TermsOfAgreement} className="btn download-btn" download>
                Download
              </a>
            </div>
          </div>
          <div className="modalDocument">
            {documentID ? (
              <>
                <iframe
                  src={`https://app.pandadoc.com/s/${documentID}`}
                  width="100%"
                  height="720px"
                ></iframe>
              </>
            ) : (
              <>
                <div style={{ paddingLeft: '20px' }}>Loading PDF...</div>
              </>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
export default MembershipFormModal;
