import React, { useState, useEffect } from 'react';
import Countries from '../../../../Forms/Countries.json';
import { AdminSociety } from 'shared_components/generated/admin';
import { handleInputChange } from '../SocietyDetails';
import WContainer from 'shared_components/components/WContainer';
import { WInput } from 'shared_components/components/WForms/WInput/WInput';
import { WRadioButton } from 'shared_components/components/WForms/WRadioButton/WRadioButton';
import { WSelect } from 'shared_components/components/WForms/WSelect/WSelect';
import WSelectBoxWithCheckboxes from 'shared_components/components/WForms/WSelectBoxWithCheckboxes/WSelectBoxWithCheckboxes';
import WImageUpload from 'shared_components/components/WForms/WFileUpload/WImageUpload';
import { useFormContext } from 'shared_components/components/WForms/WFormContext';
import WButton from 'shared_components/components/WForms/WButton/WButton';
interface SocietyInfoDetailsProps {
  societySelected?: AdminSociety;
  setSocietyInfoDetails: (societyInfoDetails: societyFormValue) => void;
  setIsEditingSociety: (isEditing: boolean) => void;
}

export interface societyFormValue extends AdminSociety {
  additionalTerritories?: string[];
  logo?: File;
}

export const SocietyInfoDetails = ({
  societySelected,
  setSocietyInfoDetails,
  setIsEditingSociety,
}: SocietyInfoDetailsProps) => {
  const [id, setId] = useState<string>('');
  const [internalIdentifier, setInternalIdentifier] = useState<string>('');
  const [readableName, setReadableName] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [country, setCountry] = useState<string>('');
  const [website, setWebsite] = useState<string>('');
  const [additionalTerritoriesChecked, setAdditionalTerritoriesChecked] =
    useState<string>('');
  const [additionalTerritories, setAdditionalTerritories] = useState<string[]>(
    []
  );

  const [phone, setPhone] = useState<string>('');
  const [portal, setPortal] = useState<string>('');
  const [societyLogo, setSocietyLogo] = useState<File>();

  useEffect(() => {
    setId(societySelected?.id ? societySelected.id : '');
    setInternalIdentifier(
      societySelected?.internalIdentifier
        ? societySelected.internalIdentifier
        : ''
    );
    setReadableName(
      societySelected?.readableName ? societySelected.readableName : ''
    );
    setAddress(societySelected?.address ? societySelected.address : '');
    setCountry(societySelected?.country ? societySelected.country : '');
    setWebsite(societySelected?.website ? societySelected.website : '');
    const additionalTerritoriesCondition =
      societySelected?.additionalTerritories &&
      societySelected.additionalTerritories != undefined &&
      societySelected.additionalTerritories.length > 0;
    setAdditionalTerritoriesChecked(
      additionalTerritoriesCondition ? 'Yes' : 'No'
    );
    setAdditionalTerritories(
      additionalTerritoriesCondition
        ? societySelected.additionalTerritories
        : []
    );
    setPhone(societySelected?.phone ? societySelected.phone : '');
    setPortal(societySelected?.portal ? societySelected.portal : '');
    setSocietyLogo(undefined);
  }, [societySelected]);

  useEffect(() => {
    const societyInfoDetailsForm: societyFormValue = {
      id: societySelected?.id,
      internalIdentifier: internalIdentifier,
      readableName: readableName,
      address: address,
      country: country,
      website: website,
      additionalTerritories: [],
      phone: phone,
      portal: portal,
    };
    if (societyLogo && societyLogo !== undefined) {
      societyInfoDetailsForm.logo = societyLogo;
    }
    if (
      additionalTerritoriesChecked &&
      additionalTerritoriesChecked === 'Yes'
    ) {
      if (additionalTerritories && additionalTerritories.length > 0) {
        societyInfoDetailsForm.additionalTerritories = additionalTerritories;
      }
    }
    setSocietyInfoDetails(societyInfoDetailsForm);
  }, [
    id,
    internalIdentifier,
    readableName,
    address,
    country,
    website,
    additionalTerritoriesChecked,
    additionalTerritories,
    phone,
    portal,
    societyLogo,
  ]);

  const handleSocietyLogoChange = (event: React.ChangeEvent<any>) => {
    if (!event.target.files) return;
    setSocietyLogo(event.target.files[0]);
  };

  // debugger returns 0 when inspecting Countries
  const countries = Countries;

  const baseSelectCountries = countries.map((country) => ({
    id: country,
    label: country,
    checked: additionalTerritories.includes(country),
  }));
  const [selectCountries, setSelectCountries] = useState(baseSelectCountries);

  // If a country is selected, remove it from the list of territories
  useEffect(() => {
    if (country === '') return;
    const territories = [...baseSelectCountries];
    // remove the selected country from the selectCountries list
    const index = territories.findIndex(
      (territory) => territory.id === country
    );
    territories.splice(index, 1);
    setSelectCountries(territories);
  }, [country]);

  const countriesSelectList = Countries.map((country) => ({
    value: country,
    label: country,
  }));

  return (
    <WContainer extraClasses="tw-max-w-[650px]">
      <div className="tw-flex tw-justify-between">
        <WImageUpload
          value={societySelected?.logoUrl ? societySelected?.logoUrl : ''}
          label="Upload society logo"
          subTitle="Drag and drop or click to upload"
          onFileChange={(e) => {
            handleSocietyLogoChange(e);
          }}
          name="logo"
        />
        <FormEditSection setIsEditingSocietyGlobal={setIsEditingSociety} />
      </div>

      <WInput
        label="Name"
        type="text"
        onBlur={() => {}}
        value={readableName ? readableName : ''}
        onChange={(e) => handleInputChange(e, setReadableName)}
        name="readableName"
      />
      <WInput
        label="Address"
        type="text"
        onBlur={() => {}}
        value={address ? address : ''}
        onChange={(e) => handleInputChange(e, setAddress)}
        name="address"
      />
      <WSelect
        label="Country"
        name="country"
        defaultValue={country ? country : undefined}
        onChange={(e) => {
          setCountry(e.target.value);

          const territories = [...baseSelectCountries];
          // remove the selected country from the selectCountries list
          const index = territories.findIndex(
            (territory) => territory.id === e.target.value
          );
          territories.splice(index, 1);

          setSelectCountries(territories);
        }}
        options={countriesSelectList}
        onBlur={() => {}}
      />
      <hr className="tw-h-px tw-my-8 tw-bg-gray-200 tw-border-0 tw-dark:bg-gray-700" />
      <WRadioButton
        label="Covers Additional Territories"
        name="additionalTerritoriesChecked"
        selectedValue={additionalTerritoriesChecked}
        items={[
          { value: 'Yes', label: 'Yes' },
          { value: 'No', label: 'No' },
        ]}
        onChange={(e) => {
          setAdditionalTerritoriesChecked(e.target.value as string);
        }}
      />

      {additionalTerritoriesChecked === 'Yes' && (
        <>
          <WSelectBoxWithCheckboxes
            label={`Additional Territories`}
            options={selectCountries}
            onBlur={() => {}}
            onChange={(selectedTerritories) =>
              setAdditionalTerritories(selectedTerritories)
            }
          />
        </>
      )}
      <hr className="tw-h-px tw-my-8 tw-bg-gray-200 tw-border-0 tw-dark:bg-gray-700" />
      <WInput
        label="Website"
        type="text"
        onBlur={() => {}}
        value={website ? website : ''}
        onChange={(e) => handleInputChange(e, setWebsite)}
        name="website"
      />

      <WInput
        label="Phone"
        type="text"
        onBlur={() => {}}
        value={phone ? phone : ''}
        onChange={(e) => handleInputChange(e, setPhone)}
        name="phone"
      />
      <WInput
        label="Portal"
        type="text"
        onBlur={() => {}}
        value={portal ? portal : ''}
        onChange={(e) => handleInputChange(e, setPortal)}
        name="portal"
      />
    </WContainer>
  );
};

type FormEditSectionProps = {
  setIsEditingSocietyGlobal: (isEditing: boolean) => void;
};

function FormEditSection({ setIsEditingSocietyGlobal }: FormEditSectionProps) {
  const { isEditing, setIsEditing, errors } = useFormContext(); // Fetch errors from context
  return (
    <>
      {' '}
      {!isEditing && (
        <WButton
          variant="secondary"
          icon="edit"
          onClick={() => {
            setIsEditingSocietyGlobal(true);
            setIsEditing(true);
          }}
          label="Edit"
        />
      )}
    </>
  );
}
