import React, { useEffect } from 'react';
import RadioGroupWithHideShow from './RadioGroupWithHideShow';
import SocietyCommissionRateItem from './SocietyCommissionRateItem';
import {
  AdminSociety,
  TermsOfAgreement,
  TermsOfAgreementBankPaymentMethodEnum,
  TermsOfAgreementRepresentativeFee,
  TermsOfAgreementRepresentativeFeeTakenFromEnum,
  TermsOfAgreementSlidingScaleBasedOnEnum,
  TermsOfAgreementSlidingScalesInner,
  TermsOfAgreementSocietyCommissionRatesInner,
} from 'shared_components/generated/admin';
import ClientAdvanceDetails from './ClientAdvanceDetails';
import RepresentativeFeeDetails from './RepresentativeFeeDetails';
import CheckboxList from './CheckBoxList';
import SlidingScaleItem from './SlidingScaleItem';
import { AdminSocietyWithCheckbox } from '../../TermsOfAgreement';
import WContainer from 'shared_components/components/WContainer';
import { WRadioButton } from 'shared_components/components/WForms/WRadioButton/WRadioButton';
import { WFormProvider } from 'shared_components/components/WForms/WFormProvider';
import { WInput } from 'shared_components/components/WForms/WInput/WInput';
import { WSelect } from 'shared_components/components/WForms/WSelect/WSelect';
import SlidingScaleSection from './SlidingScalesSection';
import { WCheckBoxGroup } from 'shared_components/components/WForms/WCheckBoxGroup/WCheckBoxGroup';

interface TermsOfAgreementFormProps {
  termsOfAgreement: TermsOfAgreement;
  setTermsOfAgreement: React.Dispatch<React.SetStateAction<TermsOfAgreement>>;
  societyList: AdminSocietyWithCheckbox[];
  setSocietyList: React.Dispatch<
    React.SetStateAction<AdminSocietyWithCheckbox[]>
  >;
  selectAllSocieties: boolean;
  setSelectAllSocieties: React.Dispatch<React.SetStateAction<boolean>>;
}

const TermsOfAgreementForm: React.FC<TermsOfAgreementFormProps> = ({
  termsOfAgreement,
  setTermsOfAgreement,
  societyList,
  setSocietyList,
  selectAllSocieties,
  setSelectAllSocieties,
}) => {
  const [performerProducerCheckedValues, setPerformerProducerCheckedValues] =
    React.useState<string[]>([]);
  useEffect(() => {
    const checkedValues = [];
    if (termsOfAgreement.performerOrProducer) {
      checkedValues.push('Performer/Producer');
    }
    if (termsOfAgreement.masterRights) {
      checkedValues.push('Rightsholder');
    }
    setPerformerProducerCheckedValues(checkedValues);
  }, [termsOfAgreement.performerOrProducer, termsOfAgreement.masterRights]);

  const handleRadioButtonChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    set_attribute_to_true_value: string
  ) => {
    const { name, value } = e.target;
    // ... (rest of the code)
    if (name === 'bankPaymentMethod') {
      setTermsOfAgreement({
        ...termsOfAgreement,
        bankPaymentMethod:
          TermsOfAgreementBankPaymentMethodEnum[
            value as keyof typeof TermsOfAgreementBankPaymentMethodEnum
          ],
      });
    } else {
      const newBool = set_attribute_to_true_value === 'Yes' ? true : false;
      setTermsOfAgreement({
        ...termsOfAgreement,
        [name]: newBool,
      });
    }
  };

  const addSocietyCommissionRate = () => {
    const updatedSocietyCommissionRates = [
      ...(termsOfAgreement.societyCommissionRates as TermsOfAgreementSocietyCommissionRatesInner[]),
    ];
    updatedSocietyCommissionRates.push({
      commissionRate: '',
      society: '',
    });
    setTermsOfAgreement({
      ...termsOfAgreement,
      societyCommissionRates: updatedSocietyCommissionRates,
    });
  };
  const deleteSocietyCommissionRate = (index: number) => {
    const updatedSocietyCommissionRates = [
      ...(termsOfAgreement.societyCommissionRates as TermsOfAgreementSocietyCommissionRatesInner[]),
    ];
    updatedSocietyCommissionRates.splice(index, 1);
    setTermsOfAgreement({
      ...termsOfAgreement,
      societyCommissionRates: updatedSocietyCommissionRates,
    });
  };
  const addSlidingScale = () => {
    const updatedSlidingScales = [
      ...(termsOfAgreement.slidingScales as TermsOfAgreementSlidingScalesInner[]),
    ];

    updatedSlidingScales.push({
      commissionRate: '',
      threshold: '',
    });
    setTermsOfAgreement({
      ...termsOfAgreement,
      slidingScales: updatedSlidingScales,
    });
  };
  const deleteSlidingScale = (index: number) => {
    const updatedSlidingScales = [
      ...(termsOfAgreement.slidingScales as TermsOfAgreementSlidingScalesInner[]),
    ];
    updatedSlidingScales.splice(index, 1);
    setTermsOfAgreement({
      ...termsOfAgreement,
      slidingScales: updatedSlidingScales,
    });
  };

  const handleSlidingScaleChange = (
    index: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = e.target;
    const updatedSlidingScales = [
      ...(termsOfAgreement.slidingScales as TermsOfAgreementSlidingScalesInner[]),
    ];
    updatedSlidingScales[index] = {
      ...updatedSlidingScales[index],
      [name]: value,
    };
    setTermsOfAgreement({
      ...termsOfAgreement,
      slidingScales: updatedSlidingScales,
    });
  };
  const handleSelectAllChange = (checked: boolean) => {
    setSelectAllSocieties(checked);
    const updatedSocietyList = societyList.map((society) => ({
      ...society,
      isChecked: checked,
    }));
    setSocietyList(updatedSocietyList);
    setTermsOfAgreement({
      ...termsOfAgreement,
      mandatedSocieties: updatedSocietyList
        .filter((society) => society.isChecked)
        .map((society) => society.id) as string[],
    });
  };
  const handleIndividualCheckboxChange = (societyId: string) => {
    const updatedSocietyList = societyList.map((society) =>
      society.id === societyId
        ? { ...society, isChecked: !society.isChecked }
        : society
    );
    const isAllChecked = updatedSocietyList.every(
      (society) => society.isChecked
    );
    setSelectAllSocieties(isAllChecked);
    setSocietyList(updatedSocietyList);
    setTermsOfAgreement({
      ...termsOfAgreement,
      mandatedSocieties: updatedSocietyList
        .filter((society) => society.isChecked)
        .map((society) => society.id) as string[],
    });
  };
  const handleSlidingScaleBasedOnChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setTermsOfAgreement({
      ...termsOfAgreement,
      slidingScaleBasedOn:
        TermsOfAgreementSlidingScaleBasedOnEnum[
          e.target.value as keyof typeof TermsOfAgreementSlidingScaleBasedOnEnum
        ],
    });
  };
  const handleCommissionRateChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    index: number
  ) => {
    const { name, value } = e.target;
    const updatedSocietyCommissionRates = [
      ...(termsOfAgreement.societyCommissionRates as TermsOfAgreementSocietyCommissionRatesInner[]),
    ];
    if (name === 'takenFrom') {
      const society = societyList.find(
        (society) => society.readableName === value
      );
      if (society) {
        updatedSocietyCommissionRates[index] = {
          ...updatedSocietyCommissionRates[index],
          society: society.id,
        };
      }
    } else if (name === 'commission') {
      updatedSocietyCommissionRates[index] = {
        ...updatedSocietyCommissionRates[index],
        commissionRate: value,
      };
    }
    setTermsOfAgreement({
      ...termsOfAgreement,
      societyCommissionRates: updatedSocietyCommissionRates,
    });
  };
  const handleRepresentativeFeesChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;

    if (name === 'takenFrom') {
      setTermsOfAgreement({
        ...termsOfAgreement,
        representativeFee: {
          ...(termsOfAgreement.representativeFee as TermsOfAgreementRepresentativeFee),
          takenFrom:
            TermsOfAgreementRepresentativeFeeTakenFromEnum[
              value as keyof typeof TermsOfAgreementRepresentativeFeeTakenFromEnum
            ],
        },
      });
    } else {
      name == 'expirationDate'
        ? setTermsOfAgreement({
            ...termsOfAgreement,
            representativeFee: {
              ...(termsOfAgreement.representativeFee as TermsOfAgreementRepresentativeFee),
              [name]: new Date(value),
            },
          })
        : setTermsOfAgreement({
            ...termsOfAgreement,
            representativeFee: {
              ...(termsOfAgreement.representativeFee as TermsOfAgreementRepresentativeFee),
              [name]: value,
            },
          });
    }
  };

  return (
    <WContainer extraClasses="tw-text-white tw-flex tw-flex-col tw-w-[575px]">
      <WRadioButton
        label="Standard Agreement?"
        name="standardAgreement"
        selectedValue={termsOfAgreement.standardAgreement ? 'Yes' : 'No'}
        items={[
          { value: 'Yes', label: 'Yes' },
          { value: 'No', label: 'No' },
        ]}
        onChange={(e) => {
          handleRadioButtonChange(e, e.target.value);
        }}
      />
      {!termsOfAgreement.standardAgreement && (
        <div>
          <WInput
            type="text"
            name="commission"
            label="Commission"
            placeholder="Enter Commission"
            value={termsOfAgreement.commissionRate as string}
            onChange={(e) =>
              setTermsOfAgreement({
                ...termsOfAgreement,
                commissionRate: e.target.value,
              })
            }
          />
          <WInput
            type="text"
            name="minPayment"
            label="Min Payment"
            placeholder="Enter Min Payment"
            value={termsOfAgreement.minimumPayment as string}
            onChange={(e) =>
              setTermsOfAgreement({
                ...termsOfAgreement,
                minimumPayment: e.target.value,
              })
            }
          />
        </div>
      )}
      <hr className="tw-h-px tw-mt-2 tw-mb-6 tw-bg-gray-200 tw-border-0 tw-dark:bg-gray-700" />

      <WRadioButton
        label="Universal society commission rate?"
        name="universalSocietyCommissionRate"
        selectedValue={
          termsOfAgreement.universalSocietyCommissionRate ? 'Yes' : 'No'
        }
        items={[
          { value: 'Yes', label: 'Yes' },
          { value: 'No', label: 'No' },
        ]}
        onChange={(e) => {
          handleRadioButtonChange(e, e.target.value);
        }}
      />

      {!termsOfAgreement.universalSocietyCommissionRate && (
        <>
          <div>
            {termsOfAgreement.societyCommissionRates?.map((element, index) => (
              <>
                <SocietyCommissionRateItem
                  key={index}
                  societyList={societyList}
                  termsOfAgreement={termsOfAgreement}
                  onAdd={addSocietyCommissionRate}
                  onDelete={() => deleteSocietyCommissionRate(index)}
                  onChange={(e) => handleCommissionRateChange(e, index)}
                  index={index}
                  length={termsOfAgreement.societyCommissionRates?.length || 0}
                />
                {index < termsOfAgreement.societyCommissionRates.length - 1 && (
                  <hr className="tw-h-px tw-my-8 tw-bg-gray-200 tw-border-0 tw-dark:bg-gray-700" />
                )}
              </>
            ))}
          </div>
        </>
      )}
      <hr className="tw-h-px tw-mt-2 tw-mb-6 tw-bg-gray-200 tw-border-0 tw-dark:bg-gray-700" />

      <WRadioButton
        label="Sliding scale required?"
        name="slidingScaleRequired"
        selectedValue={
          (termsOfAgreement.slidingScaleRequired as boolean) ? 'Yes' : 'No'
        }
        items={[
          { value: 'Yes', label: 'Yes' },
          { value: 'No', label: 'No' },
        ]}
        onChange={(e) => {
          handleRadioButtonChange(e, e.target.value);
        }}
      />
      {termsOfAgreement.slidingScaleRequired && (
        <>
          <SlidingScaleSection
            addSlidingScale={addSlidingScale}
            deleteSlidingScale={deleteSlidingScale}
            handleSlidingScaleChange={handleSlidingScaleChange}
            termsOfAgreement={termsOfAgreement}
          />
        </>
      )}
      <hr className="tw-h-px tw-mt-2 tw-mb-6 tw-bg-gray-200 tw-border-0 tw-dark:bg-gray-700" />
      <WCheckBoxGroup
        name="performerOrProducer"
        label="This Client Is A"
        options={[
          { value: 'Performer/Producer', label: 'Performer/Producer' },
          { value: 'Rightsholder', label: 'Rightsholder' },
        ]}
        defaultCheckedValues={performerProducerCheckedValues}
        onChange={({ newValues }) => {
          const isPerformerProducer = newValues.includes('Performer/Producer');
          const isRightsholder = newValues.includes('Rightsholder');
          setTermsOfAgreement({
            ...termsOfAgreement,
            performerOrProducer: isPerformerProducer,
            masterRights: isRightsholder,
          });
        }}
      />
      <hr className="tw-h-px tw-mt-2 tw-mb-6 tw-bg-gray-200 tw-border-0 tw-dark:bg-gray-700" />

      <WRadioButton
        name="universalMandate"
        label="Universal mandate?"
        selectedValue={termsOfAgreement.universalMandate ? 'Yes' : 'No'}
        items={[
          { value: 'Yes', label: 'Yes' },
          { value: 'No', label: 'No' },
        ]}
        onChange={(e) => {
          if (e.target.value === 'Yes') {
            setTermsOfAgreement({
              ...termsOfAgreement,
              universalMandate: true,
            });
          }
          if (e.target.value === 'No') {
            setTermsOfAgreement({
              ...termsOfAgreement,
              universalMandate: false,
            });
          }
        }}
      />

      {!termsOfAgreement.universalMandate && (
        <div className="tw-mt-4">
          <CheckboxList
            selectAllOnChange={handleSelectAllChange}
            onCheckboxChange={handleIndividualCheckboxChange}
            societyList={societyList}
          />
        </div>
      )}

      <hr className="tw-h-px tw-mt-2 tw-mb-6 tw-bg-gray-200 tw-border-0 tw-dark:bg-gray-700" />

      <WRadioButton
        label="Method of payment"
        name="bankPaymentMethod"
        selectedValue={
          termsOfAgreement.bankPaymentMethod ===
          TermsOfAgreementBankPaymentMethodEnum.BankTransfer
            ? 'BankTransfer'
            : 'Cheque'
        }
        items={[
          { value: 'BankTransfer', label: 'Bank Transfer' },
          { value: 'Cheque', label: 'Cheque' },
        ]}
        onChange={(e) => {
          handleRadioButtonChange(e, e.target.value);
        }}
      />
      <hr className="tw-h-px tw-mt-2 tw-mb-6 tw-bg-gray-200 tw-border-0 tw-dark:bg-gray-700" />

      <WRadioButton
        name="representativeFeeRequired"
        label="Representative fees?"
        selectedValue={
          termsOfAgreement.representativeFeeRequired ? 'Yes' : 'No'
        }
        items={[
          { value: 'Yes', label: 'Yes' },
          { value: 'No', label: 'No' },
        ]}
        onChange={(e) => {
          if (e.target.value === 'Yes') {
            setTermsOfAgreement({
              ...termsOfAgreement,
              representativeFeeRequired: true,
            });
          }
          if (e.target.value === 'No') {
            setTermsOfAgreement({
              ...termsOfAgreement,
              representativeFeeRequired: false,
            });
          }
        }}
      />
      {termsOfAgreement.representativeFeeRequired && (
        <div>
          <RepresentativeFeeDetails
            onChange={handleRepresentativeFeesChange}
            termsOfAgreement={termsOfAgreement}
          />
        </div>
      )}
      <hr className="tw-h-px tw-mt-2 tw-mb-6 tw-bg-gray-200 tw-border-0 tw-dark:bg-gray-700" />

      <WRadioButton
        name="clientRequiresAdvance"
        label="Client Requires an Advance?"
        selectedValue={termsOfAgreement.clientRequiresAdvance ? 'Yes' : 'No'}
        items={[
          { value: 'Yes', label: 'Yes' },
          { value: 'No', label: 'No' },
        ]}
        onChange={(e) => {
          if (e.target.value === 'Yes') {
            setTermsOfAgreement({
              ...termsOfAgreement,
              clientRequiresAdvance: true,
            });
          }
          if (e.target.value === 'No') {
            setTermsOfAgreement({
              ...termsOfAgreement,
              clientRequiresAdvance: false,
            });
          }
        }}
      />
      {termsOfAgreement.clientRequiresAdvance && (
        <ClientAdvanceDetails
          advanceAmount={termsOfAgreement.advanceAmount as string}
          onAmountChange={(e) =>
            setTermsOfAgreement({ ...termsOfAgreement, advanceAmount: e })
          }
        />
      )}
    </WContainer>
  );
};

export default TermsOfAgreementForm;
