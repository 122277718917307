import { Link } from 'react-router-dom';
import WContainer from 'shared_components/components/WContainer';
import WButton from 'shared_components/components/WForms/WButton/WButton';
function DeclineMessage(props) {
  return (
    <div className="declineMessageContainer" style={{ maxWidth: '600px' }}>
      <div className="declineContainer">
        <div className="piBack d-flex">
          <WButton
            variant="outline-light"
            onClick={() => props.setShowDeclineMessage(false)}
            label="Back"
          />
        </div>
        <WContainer extraClasses="tw-max-w-[600px]">
          <div className="">
            <div className="">
              <span className="passportDeclineTitle">Passport declined</span>
            </div>
            <div className="">
              <p>
                You will need to contact the client to inform them that their
                passport is not valid.
              </p>
            </div>
            <div className="tw-mt-4">
              <Link to="/clients" style={{ textDecoration: 'none' }}>
                <WButton variant="secondary" label="OK" />
              </Link>
            </div>
          </div>
        </WContainer>
      </div>
    </div>
  );
}
export default DeclineMessage;
