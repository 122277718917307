import React from 'react';
import z from 'zod';
import { WInput } from 'shared_components/components/WForms/WInput/WInput';
import WButton from 'shared_components/components/WForms/WButton/WButton';
import WMultiTextInput from 'shared_components/components/WForms/WMultiTextInput';
import { WFormProvider } from 'shared_components/components/WForms/WFormProvider';
import { useFormContext } from 'shared_components/components/WForms/WFormContext';

interface EOIFormProps {
  handleSubmit: (formData: any) => Promise<any> | undefined;
  onSuccess: () => void;
  formData: any;
  setFormData: any;
  onSuccessMessage?: React.ReactNode;
}

export const EOIForm = ({
  handleSubmit,
  onSuccess,
  onSuccessMessage,
  formData,
  setFormData,
}: EOIFormProps) => {
  const inputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const schema = z.object({
    firstName: z.string().nonempty({ message: 'First name is required' }),
    middleName: z.string(),
    lastName: z.string().nonempty({ message: 'Last name is required' }),
    email: z
      .string()
      .email({ message: 'Please provide a valid email address' }),
  });

  return (
    <div className="tw-flex tw-flex-col tw-w-[600px] tw-justify-center tw-items-center tw-content-center">
      <div className="tw-mt-4 tw-flex tw-flex-col tw-min-w-[536px]">
        <WFormProvider
          editByDefault={true}
          handleSubmit={handleSubmit}
          schema={schema}
          formData={formData}
          onSuccessMessage={onSuccessMessage}
          onSuccess={onSuccess}
        >
          <div className="tw-mb-4">
            <WInput
              type="text"
              name="firstName"
              label="First Name"
              value={formData.firstName}
              onChange={inputChange}
            />
          </div>
          <div className="tw-mb-4">
            <WInput
              type="text"
              name="middleName"
              label="Middle Name"
              value={formData.middleName}
              onChange={inputChange}
            />
          </div>
          <div className="tw-mb-4">
            <WInput
              type="text"
              name="lastName"
              label="Last Name"
              value={formData.lastName}
              onChange={inputChange}
            />
          </div>
          <div className="tw-mb-8">
            <WMultiTextInput
              name="aliases"
              label="Aliases"
              optionalLabel={true}
              addCaption="Add another alias"
              values={formData.aliases}
              onChange={(aliases) => setFormData({ ...formData, aliases })}
            />
          </div>
          <div className="tw-mb-4">
            <WInput
              type="text"
              name="pplPerformerName"
              label="PPL Contributor Name"
              optionalLabel={true}
              value={formData.pplPerformerName}
              onChange={inputChange}
            />
          </div>
          <div className="tw-mb-4">
            <WInput
              type="text"
              name="pplPerformerId"
              label="PPL Performer ID"
              optionalLabel={true}
              value={formData.pplPerformerId}
              onChange={inputChange}
            />
          </div>
          <div className="tw-mb-4">
            <WInput
              type="email"
              name="email"
              label="Email"
              value={formData.email}
              onChange={inputChange}
            />
          </div>
          <div className="tw-flex tw-items-center tw-justify-center">
            <EOIFormFooter />
          </div>
        </WFormProvider>
      </div>
    </div>
  );
};

function EOIFormFooter() {
  // large submit button
  const { handleFormSubmit, formData } = useFormContext();
  return (
    <>
      <div className="tw-flex tw-items-center">
        <WButton
          label="Send"
          extraClasses="tw-w-[200px]"
          variant="primary-lg"
          onClick={() => {
            handleFormSubmit(formData);
          }}
        />
      </div>
    </>
  );
}
