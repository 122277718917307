interface WDot {
  selected: boolean;
  onClick: () => void;
}

export const WDot = ({ selected, onClick }: WDot) => (
  <div
    className={`tw-w-2.5 tw-h-2.5 tw-rounded-full tw-mr-1 ${
      selected ? 'tw-bg-surfaceLime' : 'tw-bg-textDove'
    }`}
    onClick={onClick}
  />
);
