import React, { useEffect, useState } from 'react';
import SocietyDetails from './Components/Settings/SocietyDetails';
import { useApi } from 'shared_components/context';
import { AdminSociety } from 'shared_components/generated/admin';
import PageLayout from './PageLayout';
import { SocietiesListBox } from 'shared_components/components/SocietiesArtists/SocietiesListBox';

function SocietyConfig() {
  const { adminApi: api } = useApi();
  const [societyList, setSocietyList] = useState<Array<AdminSociety>>([]);
  const [societySelected, setSocietySelected] = useState<AdminSociety>();
  const [isEditingSociety, setIsEditingSociety] = useState<boolean>(false);

  const societiesFetch = async (retries: number = 0, societyId?: string) => {
    api
      .listAdminSocieties()
      .then((response) => {
        if (response.length === 0) return;
        setSocietyList(response);
        if (societyId !== undefined) {
          const society = response.find((society) => society.id === societyId);
          if (society === undefined) return;
          setSocietySelected(society);
        }
        if (societySelected !== undefined) return;
      })
      .catch((err) => {
        retries++;
        setTimeout(() => {
          societiesFetch(retries);
        }, 500);
        return;
      });
  };

  const societyClickHandler = (society: AdminSociety) => {
    if (isEditingSociety) return;
    if (society === societySelected) return;
    setSocietySelected(society);
  };

  const addNewSocietyCallback = (id: string) => {
    societiesFetch(0, id);
  };

  useEffect(() => {
    societiesFetch();
  }, []);

  return (
    <PageLayout>
      <h2>Settings</h2>
      <div className="tw-flex tw-space-x-6">
        <SocietiesListBox
          societies={societyList}
          societySelected={societySelected}
          societyClickHandler={societyClickHandler}
          isEditingSociety={isEditingSociety}
        ></SocietiesListBox>

        <div className="tw-pt-0 tw-w-3/4 tw-max-w-[650px]">
          <SocietyDetails
            societySelected={societySelected}
            setSocietySelected={setSocietySelected}
            addNewSocietyCallback={addNewSocietyCallback}
            isEditingSociety={isEditingSociety}
            setIsEditingSociety={setIsEditingSociety}
          />
        </div>
      </div>
    </PageLayout>
  );
}

export default SocietyConfig;
