import React from 'react';
import WButton from 'shared_components/components/WForms/WButton/WButton';

export interface ClientDetailsRowProps {
  label: string;
  data: string;
  copy?: boolean;
  link?: string;
}

const ClientDetailsRow: React.FC<ClientDetailsRowProps> = ({
  label,
  data,
  copy = true,
  link = '',
}) => {
  const handleCopyToClipboard = (data: string) => {
    navigator.clipboard.writeText(data).catch((err) => {
      console.error('Failed to copy text: ', err);
    });
  };
  return (
    <div className="tw-flex tw-flex-col tw-text-base tw-pt-2">
      <div className="tw-pb-1 tw-text-textWhite">{label}</div>
      <div className="tw-flex tw-items-center tw-justify-between tw-text-textDove tw-border-b tw-border-outlineGraphite tw-pb-2">
        {link ? (
          <a
            className="tw-underline"
            href={link}
            target="_blank"
            rel="noreferrer"
          >
            {data ? data : `Filename for ${label} missing`}
          </a>
        ) : (
          <div>{data ? data : '\u00A0'}</div>
        )}
        {copy && data && (
          <WButton
            variant="link-secondary"
            label="Copy"
            onClick={() => handleCopyToClipboard(data)}
            extraClasses="tw-text-sm"
          />
        )}
      </div>
    </div>
  );
};

export default ClientDetailsRow;
