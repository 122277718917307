import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { useApi } from 'shared_components/context';
import { PandadocDocumentIdsDocumentsInner } from 'shared_components/generated/admin';
import { useClientId } from 'shared_components/context/client';

import WModal from 'shared_components/components/WModal';
import WButton from 'shared_components/components/WForms/WButton/WButton';
import ThankyouBlock from 'shared_components/components/ThankYouBlock/ThankyouBlock';
import { WError } from 'shared_components/components/WError/WError';
import { useWError } from 'shared_components/components/WError/WErrorProvider';
import AgreementModalPage from 'shared_components/components/PandadocPage/AgreementModalPage';
import AgreementModalIFrame from 'shared_components/components/PandadocPage/AgreementModaliFrame';
import PandadocPage from 'shared_components/components/PandadocPage/PandadocPage';

interface AgreementModalProps {
  showModal: boolean;
  modalClose: () => void;
  clientId?: string;
  clientEmailAddress?: string;
  children?: React.ReactNode;
  preview?: boolean;
  setPandadocDocumentIds?: Dispatch<SetStateAction<documentIds[]>>;
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}

interface documentIds {
  documentId: string;
  documentName: string;
}

interface PandaDocsTemplateData extends PandadocDocumentIdsDocumentsInner {
  pandadocsResponse?: documentIds;
}

function AgreementModal({
  showModal,
  modalClose,
  clientId,
  clientEmailAddress,
  children,
  preview,
  setPandadocDocumentIds,
  isLoading,
  setIsLoading,
}: AgreementModalProps) {
  const { clientApi: api } = useApi();
  const { throwError } = useWError();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { clientId: clientUserId, clientEmail } = useClientId();
  const [showWaitMessage, setShowWaitMessage] = useState(false);
  const [pandadocsTemplateIds, setPandadocsTemplateIds] = useState<
    Array<PandaDocsTemplateData>
  >([]);
  const [selectedPageIndex, setSelectedPageIndex] = useState(1);
  const [documentsSigned, setDocumentsSigned] = useState<Set<string>>(
    new Set()
  );

  useEffect(() => {
    clientEmailAddress = clientEmailAddress ? clientEmailAddress : clientEmail;
    clientId = clientId ? clientId : clientUserId;
  });

  useEffect(() => {
    if (clientId === undefined) {
      return;
    }

    const fetchData = async () => {
      // the api.createPandadocDocumentIds call should only be made once the Terms Of Agreement has been submitted (when showModal is true in this case)
      // we could refactor it to rename the showModal flag in the future
      if (!showModal) {
        return;
      }

      // get pandadoc template ids
      setIsLoading(true);

      api
        .createPandadocDocumentIds({
          pandadocDocumentIds: {
            userId: clientId || 'missing',
            email: clientEmailAddress || 'missing',
          },
        })
        .then(({ documents: pandadocsTemplateIdsData }) => {
          if (
            pandadocsTemplateIdsData &&
            pandadocsTemplateIdsData.length == 0
          ) {
            setIsSubmitted(true);
            return;
          }
          pandadocsTemplateIdsData?.map((templateId) => {
            if (templateId.sessionId !== null) {
              setPandadocsTemplateIds((prev) => [
                ...prev,
                {
                  ...templateId,
                  pandadocsResponse: {
                    documentId: templateId.sessionId as string,
                    documentName: templateId.documentName,
                  },
                },
              ]);
            } else {
              throwError(
                new WError("Couldn't load the document. Please try again.")
              );
            }
            if (
              templateId.documentId !== null &&
              setPandadocDocumentIds !== undefined
            ) {
              setPandadocDocumentIds((prev) => [
                ...prev,
                {
                  documentId: templateId.pandadocDocumentId,
                  documentName: templateId.documentName,
                },
              ]);
            }
          });
        })
        .catch((err) => {
          throwError(new WError('Something went wrong, please try again.'));
        });
    };
    fetchData();
  }, [showModal]);

  useEffect(() => {
    if (selectedPageIndex != pandadocsTemplateIds.length)
      setSelectedPageIndex(documentsSigned.size + 1);
    if (preview) return;
    if (documentsSigned.size === 0) return;
    if (documentsSigned.size != pandadocsTemplateIds.length) return;
    try {
      api
        .updateClientSocietyFormTask({
          userId: clientId as string,
        })
        .then(() => setIsSubmitted(true));
    } catch (error) {
      throwError(new WError('Something went wrong, please try again.'));
    }
  }, [documentsSigned]);

  const isButtonDisabled = (
    pandadocsResponse: documentIds | undefined,
    index: number
  ) => {
    if (pandadocsResponse === undefined) return true;
    if (pandadocsResponse.documentId === undefined) return true;
    if (documentsSigned.size < index) return true;
    return false;
  };

  // pandadocs iframe event message listeners
  // https://developers.pandadoc.com/reference/embed-a-document#embedded-document-javascript-events
  window.addEventListener('message', (event: MessageEvent<any>) => {
    const type = event.data && event.data.type;
    const payload = event.data && event.data.payload;
    switch (type) {
      case 'session_view.document.loaded':
        if (isLoading) setIsLoading(false);
      case 'session_view.document.completed':
        if (payload && payload.uuid) {
          const payloadUUID = payload.uuid;
          if (!(payloadUUID in documentsSigned)) {
            setDocumentsSigned(
              (prev) => new Set([...Array.from(prev), payloadUUID])
            );
          }
        }
    }
  });

  if (isSubmitted)
    return <SubmittedModal isOpen={showModal} onClose={modalClose} />;

  return (
    <WModal
      isOpen={showModal}
      modalClasses="tw-min-w-[70vw]"
      onClose={() => {
        setPandadocsTemplateIds([]);
        modalClose();
      }}
      title="Sign WMC Forms"
    >
      {isLoading && (
        <>
          <div
            className="
                  tw-flex tw-flex-col tw-justify-center tw-items-center
                  tw-w-[70vw] tw-h-[75vh]

                "
          >
            <div
              className="tw-flex tw-justify-center tw-w-[500px]"
              style={{ minHeight: '500px' }}
            >
              <p className="tw-my-auto tw-animate-ping">⚪</p>
            </div>
          </div>
        </>
      )}
      <div>
        <div style={{ maxHeight: isLoading ? '0px' : '' }}>
          <div
            className={
              isLoading ? 'tw-hidden' : 'tw-mb-4 tw-flex tw-gap-4 tw-space-x-2'
            }
          >
            {pandadocsTemplateIds.map(
              ({ documentName, pandadocsResponse }, index: number) => (
                <WButton
                  key={index}
                  label={documentName}
                  variant="link-secondary"
                  disabled={isButtonDisabled(pandadocsResponse, index)}
                  onClick={() => setSelectedPageIndex(index + 1)}
                />
              )
            )}
          </div>
          {pandadocsTemplateIds.map(({ pandadocsResponse }, index: number) => (
            <PandadocPage
              key={index}
              index={index}
              sessionId={pandadocsResponse?.documentId}
              selectedPageIndex={selectedPageIndex}
              isLoading={isLoading}
            />
          ))}
          {!isLoading && documentsSigned.size > 0 && children}
        </div>
      </div>
    </WModal>
  );
}

export default AgreementModal;

type SubmittedModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const SubmittedModal = ({ isOpen, onClose }: SubmittedModalProps) => {
  return (
    <WModal
      title=""
      disableBackdropClick={true}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ThankyouBlock
        title="Thank you"
        description="Your WMC forms have been submitted successfully. WMC will be in contact once your witness has signed the Power of Attorney."
      />
    </WModal>
  );
};
