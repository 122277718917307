import React, { useMemo, useState } from 'react';
import clsx from 'clsx';
import { useFormContext } from '../WFormContext';

interface WMultiTextInputProps {
  name: string;
  label: string;
  optionalLabel?: boolean;
  addCaption?: string;
  values: string[];
  placeholder?: string;
  disabled?: boolean;
  onChange: (values: string[]) => void;
}

export const WMultiTextInput = ({
  name,
  label,
  optionalLabel = false,
  addCaption = 'Add',
  values,
  placeholder,
  disabled = false,
  onChange,
}: WMultiTextInputProps) => {
  const { isEditing, errors, setErrors } = useFormContext();

  const valuesComputed = useMemo(() => {
    if (!values.length) {
      return [''];
    }

    return values;
  }, [values]);

  const handleChange = (value: string, index: number) => {
    let newValues = [...values];
    newValues[index] = value;
    onChange(newValues);
  };

  const handleAdd = () => {
    onChange([...valuesComputed, '']);
  };

  const handleRemove = (index: number) => {
    const newErrors = { ...errors };
    for (let index = 0; index < values.length; index++) {
      delete newErrors[`${name}.${index}`];
    }
    setErrors(newErrors);

    let newValues = [...values];
    newValues.splice(index, 1);
    onChange(newValues);
  };

  return (
    <div>
      {label != '' && (
        <label
          htmlFor={name}
          className={clsx(
            'tw-flex tw-space-x-1 tw-justify-between tw-leading-4 tw-text-white tw-mb-2 tw-capitalize',
            {
              'tw-text-[14px]': !isEditing,
            }
          )}
        >
          <span
            className={clsx({
              'tw-text-red-500': isEditing && errors[name],
            })}
          >
            {label}
          </span>
          {optionalLabel && (
            <span className="tw-text-xs tw-leading-4 tw-text-gray-400">
              Optional
            </span>
          )}
        </label>
      )}

      {valuesComputed.map((value, i) => (
        <div
          key={i}
          className={clsx({
            'tw-mb-4': isEditing,
            'tw-mb-1.5': !isEditing,
          })}
        >
          <div className="tw-flex tw-items-center tw-gap-1">
            <div className="tw-flex-1">
              <TextInput
                name={`${name}.${i}`}
                value={value}
                error={errors[`${name}.${i}`]}
                placeholder={placeholder}
                isEditing={isEditing}
                isFirst={i === 0}
                disabled={disabled}
                onChange={(e) => handleChange(e.target.value, i)}
              />
            </div>

            <button
              type="button"
              className={clsx('tw-text-sm tw-ml-2', {
                'tw-hidden': !isEditing || valuesComputed.length === 1,
              })}
              onClick={() => handleRemove(i)}
            >
              <i className="wi wi-20px wi-minus tw-mr-1" />
            </button>
          </div>

          {isEditing && errors[`${name}.${i}`] && (
            <div className="tw-text-red-500 tw-text-xs tw-mt-2">
              {errors[`${name}.${i}`]}
            </div>
          )}

          <div
            className={clsx('tw-flex tw-justify-end tw-mt-2', {
              'tw-hidden':
                // Display only while editing and only under last field
                !isEditing || i !== valuesComputed.length - 1,
            })}
          >
            <button
              type="button"
              className="tw-text-sm tw-ml-2"
              onClick={handleAdd}
            >
              <div className="tw-flex tw-items-center">
                <i className="wi wi-20px wi-plus tw-mr-1" />
                <span className="tw-underline">{addCaption}</span>
              </div>
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

interface TextInputProps {
  name: string;
  value: string;
  error?: string;
  placeholder?: string;
  isEditing: boolean;
  isFirst?: boolean;
  disabled?: boolean;
  onChange: (value: React.ChangeEvent<HTMLInputElement>) => void;
}

const TextInput = ({
  name,
  value,
  error,
  placeholder,
  isEditing,
  isFirst = false,
  disabled,
  onChange,
}: TextInputProps) => {
  const [focused, setFocused] = useState(false);

  return (
    <div>
      {isEditing && (
        <div className="tw-relative">
          <input
            type="text"
            name={name}
            id={name}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            placeholder={placeholder}
            value={value}
            disabled={disabled}
            onChange={onChange}
            className={clsx(
              'tw-bg-formInputBackground',
              'tw-text-gray-400',
              'tw-rounded-lg',
              'tw-h-12',
              'tw-w-full',
              'tw-placeholder-textSeal',
              'tw-appearance-none',
              'tw-leading-5',
              'tw-p-3',
              'tw-border-2',
              { 'tw-border-formInputBackground': error },
              'focus:tw-border-white',
              'focus:tw-text-white',
              'focus:tw-outline-none',
              { 'tw-border-transparent': !error },
              { 'tw-border-red-500': isEditing && error },
              { 'tw-font-extraLight': disabled },
              { 'tw-cursor-not-allowed': disabled }
            )}
          />
          {isEditing && !focused && error && (
            <i className="tw-absolute tw-right-0 tw-top-1 tw-h-10 tw-w-10 tw-flex tw-justify-center tw-items-center">
              <i className="wi wi-20px wi-alert tw-text-red-500" />
            </i>
          )}
          {disabled && (
            <i className="tw-absolute tw-right-0 tw-top-1 tw-h-10 tw-w-10 tw-flex tw-justify-center tw-items-center">
              <i className="wi wi-20px wi-ban" />
            </i>
          )}
        </div>
      )}
      {!isEditing && (
        <div className="tw-text-textDove tw-text-[14px]">
          {value != '' ? value : isFirst && '-'}
        </div>
      )}
    </div>
  );
};
