import React from 'react';
import { TermsOfAgreementSlidingScaleBasedOnEnum } from 'shared_components/generated/admin';
import { WInput } from 'shared_components/components/WForms/WInput/WInput';
import { WSelect } from 'shared_components/components/WForms/WSelect/WSelect';
interface SlidingScaleItemProps {
  item: any;
  index: number;
  handleSlidingScaleChange: (
    index: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => void;
}

const SlidingScaleItem: React.FC<SlidingScaleItemProps> = ({
  item,
  index,
  handleSlidingScaleChange,
}) => {
  const inputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleSlidingScaleChange(index, e);
  };

  return (
    <div>
      <div className="tw-flex">
        <WSelect
          name="termsOfAgreementSlidingScaleBasedOn"
          layout="horizontal"
          label="Based On"
          options={[
            {
              value: TermsOfAgreementSlidingScaleBasedOnEnum.NetIncome,
              label: 'Net Income',
            },
            {
              value: TermsOfAgreementSlidingScaleBasedOnEnum.GrossIncome,
              label: 'Gross Income',
            },
          ]}
          defaultValue={TermsOfAgreementSlidingScaleBasedOnEnum.NetIncome}
          onChange={inputChange}
        />
      </div>
      <div className="tw-flex">
        <WInput
          layout="horizontal"
          type="text"
          value={item.threshold}
          name="threshold"
          label="After"
          placeholder="Enter Sum"
          onChange={inputChange}
        />
      </div>
      <div className="tw-flex">
        <WInput
          layout="horizontal"
          type="text"
          value={item.commission_rate}
          name="commissionRate"
          label="Commission Rate"
          placeholder="Enter Commission Rate"
          onChange={inputChange}
        />
      </div>
    </div>
  );
};

export default SlidingScaleItem;
