import React, { useState, useEffect } from 'react';
import profileImg from 'shared_components/public/assets/userProfile.png';
import { Form } from 'react-bootstrap';
import clsx from 'clsx';
import ArtistPersonalInfo from './ArtistProfileTabs/PersonalInfo';
import DocumentsUpdateForm from './ArtistProfileTabs/Documents';
import ArtistBankDetails from './ArtistProfileTabs/BankDetails';
import SocietyLoginDetails from './ArtistProfileTabs/SocietyLoginDetails';
import ArtistAdditionalDetails from './ArtistProfileTabs/AdditionalContacts';
import ArtistActivityLog from './ArtistProfileTabs/ArtistActivityLog';
import ArtistStatements from './ArtistProfileTabs/Statements';
import { useApi } from 'shared_components/context';
import { useClientId } from 'shared_components/context/client';
import { AdminPersonalInformation } from 'shared_components/generated/admin';
import { ClientMemberships } from 'shared_components/components/ClientMemberships';

import { WModal } from 'shared_components/components/WModal/WModal';
import WButton from 'shared_components/components/WForms/WButton/WButton';

import WContainer from 'shared_components/components/WContainer';
import { WTabs, WTabItem } from 'shared_components/WTabs/WTabs';

function ArtistProfile() {
  const { adminApi: api } = useApi();
  const { clientId } = useClientId();

  const [clientUserSettings, setClientUserSettings] =
    useState<AdminPersonalInformation>();
  const [showNotesModal, setShowNotesModal] = useState(false);
  const [notes, setNotes] = useState('');

  const handleSaveNotes = async (e: React.MouseEvent) => {
    await saveNotes();
    setShowNotesModal(false);
  };

  const handleEditNotes = (e: React.MouseEvent) => {
    setShowNotesModal(true);
  };

  const fetchData = () => {
    if (clientId === undefined) {
      return;
    }
    api
      .retrieveUserSettings({ userId: clientId })
      .then((userSettings) => {
        setClientUserSettings(userSettings);
        setNotes(userSettings.notes ?? '');
      })
      .catch(() => {});
  };

  useEffect(() => {
    fetchData();
  }, [clientId]);

  const saveNotes = async () => {
    if (clientId === undefined) {
      return;
    }
    await api
      .partialUpdateUserSettings({
        userId: clientId,
        userSettings: {
          notes,
        },
      })
      .catch(() => {});
  };

  const tabsData: WTabItem[] = [
    {
      label: 'Memberships',
      content: <ClientMemberships clientId={clientId} />,
    },
    {
      label: 'Documents',
      content: <DocumentsUpdateForm />,
    },
    {
      label: 'Logins',
      content: <SocietyLoginDetails />,
    },
    {
      label: 'Profile',
      content: <ArtistPersonalInfo />,
    },
    {
      label: 'Statements',
      content: <ArtistStatements />,
      disabled: true,
    },
    {
      label: 'Bank Details',
      content: <ArtistBankDetails />,
    },
    {
      label: 'Representatives',
      content: <ArtistAdditionalDetails />,
    },
    {
      label: 'Activity log',
      content: <ArtistActivityLog clientId={clientId} />,
      disabled: true,
    },
  ];

  return (
    <div className="tw-w-3/4">
      <WContainer>
        {/* HEADER SECTION OF INNER CONTENT */}
        <div className="tw-w-full tw-h-24 tw-justify-between tw-items-start tw-gap-2 tw-inline-flex">
          <div className="tw-justify-start tw-items-start tw-gap-2.5 tw-flex">
            <div className="tw-justify-start tw-items-start tw-gap-4 tw-flex">
              <img
                className="tw-w-24 tw-h-24 tw-rounded-full"
                src={profileImg}
              />
              <div className="tw-h-24 tw-flex-col tw-justify-between tw-items-start tw-gap-3 tw-inline-flex">
                <div className="tw-flex-col tw-justify-center tw-items-start tw-gap-1 tw-flex">
                  <div className="tw-text-textWhite tw-text-lg tw-font-semibold tw-leading-tight">
                    {`${clientUserSettings?.firstName ?? ''} ${
                      clientUserSettings?.lastName ?? ''
                    }`}
                  </div>
                </div>
                {/* Phase II when BE functionality */}
                {/* <div className="tw-flex-col tw-justify-start tw-items-start tw-gap-2 tw-flex">
                  <WProgressBar
                    percentage={(52 / 53) * 100}
                    label="Registered at 52 out of 53"
                  />
                </div> */}
                <div className="tw-py-2 tw-rounded-full tw-justify-center tw-items-center tw-gap-1 tw-inline-flex">
                  <div className="tw-text-textWhite tw-text-sm tw-font-medium tw-underline tw-leading-none tw-tracking-tight">
                    <Form onSubmit={saveNotes}>
                      <WButton
                        label="Notes"
                        variant="outline-light"
                        icon="edit"
                        onClick={(e) => handleEditNotes(e)}
                      />

                      <WModal isOpen={showNotesModal} title={`Notes`}>
                        <div className="tw-flex tw-flex-col">
                          <textarea
                            name="notes"
                            rows={20}
                            cols={100}
                            value={notes}
                            onChange={(e) => setNotes(e.target.value)}
                            className={clsx(
                              'tw-bg-formInputBackground',
                              'tw-resize-none',
                              'tw-text-gray-400',
                              'tw-rounded-lg',
                              'tw-w-full',
                              'tw-w-[450px]',
                              'tw-placeholder-textSeal',
                              'tw-text-sm',
                              'tw-leading-5',
                              'tw-p-3',
                              'tw-mb-2',
                              'tw-border-2',
                              'tw-border-formInputBackground',
                              'focus:tw-border-white',
                              'focus:tw-text-white',
                              'focus:tw-outline-none'
                            )}
                          />
                          <div className="tw-w-[135px] tw-mt-4">
                            <WButton
                              label="Save Changes"
                              variant="primary"
                              onClick={handleSaveNotes}
                            />
                          </div>
                        </div>
                      </WModal>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* TABS SECTION OF INNER CONTENT */}
        <WTabs tabs={tabsData}></WTabs>
      </WContainer>
    </div>
    //   <div className="clientMembershipForms">
    //     <div className="clientMembershipFormsWrap">
    //       <div className="titleCol d-flex justify-content-between">
    //         <h4>Society registrations</h4>
    //   </div>
    // </div>
  );
}

export default ArtistProfile;
