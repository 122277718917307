/* tslint:disable */
/* eslint-disable */
/**
 * Westbury Music API
 * API for Westbury Music
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MembershipForms
 */
export interface MembershipForms {
    /**
     * 
     * @type {string}
     * @memberof MembershipForms
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof MembershipForms
     */
    readonly url?: string;
    /**
     * 
     * @type {Date}
     * @memberof MembershipForms
     */
    readonly dateCreated?: Date;
    /**
     * 
     * @type {Date}
     * @memberof MembershipForms
     */
    readonly dateUpdated?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof MembershipForms
     */
    complete?: boolean;
    /**
     * When the form was completed
     * @type {Date}
     * @memberof MembershipForms
     */
    dateCompleted?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof MembershipForms
     */
    pandadocsSessionId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MembershipForms
     */
    pandadocsDocumentId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MembershipForms
     */
    pandadocsDocumentS3Key?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MembershipForms
     */
    status?: MembershipFormsStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof MembershipForms
     */
    user: number;
    /**
     * 
     * @type {string}
     * @memberof MembershipForms
     */
    societyForm: string;
}


/**
 * @export
 */
export const MembershipFormsStatusEnum = {
    ClientToSign: 'client_to_sign',
    WithClient: 'with_client',
    AdminToSign: 'admin_to_sign',
    AwaitingUpload: 'awaiting_upload',
    Approved: 'approved'
} as const;
export type MembershipFormsStatusEnum = typeof MembershipFormsStatusEnum[keyof typeof MembershipFormsStatusEnum];


/**
 * Check if a given object implements the MembershipForms interface.
 */
export function instanceOfMembershipForms(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "user" in value;
    isInstance = isInstance && "societyForm" in value;

    return isInstance;
}

export function MembershipFormsFromJSON(json: any): MembershipForms {
    return MembershipFormsFromJSONTyped(json, false);
}

export function MembershipFormsFromJSONTyped(json: any, ignoreDiscriminator: boolean): MembershipForms {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'url': !exists(json, 'url') ? undefined : json['url'],
        'dateCreated': !exists(json, 'date_created') ? undefined : (new Date(json['date_created'])),
        'dateUpdated': !exists(json, 'date_updated') ? undefined : (new Date(json['date_updated'])),
        'complete': !exists(json, 'complete') ? undefined : json['complete'],
        'dateCompleted': !exists(json, 'date_completed') ? undefined : (json['date_completed'] === null ? null : new Date(json['date_completed'])),
        'pandadocsSessionId': !exists(json, 'pandadocs_session_id') ? undefined : json['pandadocs_session_id'],
        'pandadocsDocumentId': !exists(json, 'pandadocs_document_id') ? undefined : json['pandadocs_document_id'],
        'pandadocsDocumentS3Key': !exists(json, 'pandadocs_document_s3_key') ? undefined : json['pandadocs_document_s3_key'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'user': json['user'],
        'societyForm': json['society_form'],
    };
}

export function MembershipFormsToJSON(value?: MembershipForms | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'complete': value.complete,
        'date_completed': value.dateCompleted === undefined ? undefined : (value.dateCompleted === null ? null : value.dateCompleted.toISOString()),
        'pandadocs_session_id': value.pandadocsSessionId,
        'pandadocs_document_id': value.pandadocsDocumentId,
        'pandadocs_document_s3_key': value.pandadocsDocumentS3Key,
        'status': value.status,
        'user': value.user,
        'society_form': value.societyForm,
    };
}

