import React from 'react';
import { TermsOfAgreement } from 'shared_components/generated/admin';
import { WSelect } from 'shared_components/components/WForms/WSelect/WSelect';
import { WInput } from 'shared_components/components/WForms/WInput/WInput';
import { TermsOfAgreementSlidingScaleBasedOnEnum } from 'shared_components/generated/admin';
import SlidingScaleItem from './SlidingScaleItem';
import WButton from 'shared_components/components/WForms/WButton/WButton';
interface SlidingScaleItemProps {
  addSlidingScale: () => void;
  deleteSlidingScale: (index: number) => void;
  handleSlidingScaleChange: (
    index: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => void;
  termsOfAgreement: TermsOfAgreement;
}

const SlidingScaleSection: React.FunctionComponent<SlidingScaleItemProps> = ({
  addSlidingScale,
  deleteSlidingScale,
  handleSlidingScaleChange,
  termsOfAgreement,
}) => {
  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log(e.target.value);
  };

  //remap TermsOfAgreementSlidingScaleBasedOnEnum to label, value key value pair
  const slidingScaleBasedOnOptions = Object.keys(
    TermsOfAgreementSlidingScaleBasedOnEnum
  ).map((option) => ({
    value: option,
    label:
      TermsOfAgreementSlidingScaleBasedOnEnum[
        option as keyof typeof TermsOfAgreementSlidingScaleBasedOnEnum
      ],
  }));

  return (
    <>
      <div className="tw-flex tw-flex-col">
        {termsOfAgreement.slidingScales?.map((slidingScale, index) => {
          return (
            <>
              <SlidingScaleItem
                handleSlidingScaleChange={handleSlidingScaleChange}
                item={slidingScale}
                index={index}
              />
              {index !== 0 ? (
                <div className="tw-flex tw-justify-end tw-mb-6">
                  <WButton
                    variant="link-secondary"
                    icon="trash"
                    label="Delete commission scale"
                    onClick={() => {
                      deleteSlidingScale(index);
                    }}
                  />
                </div>
              ) : null}
              {index < termsOfAgreement.slidingScales.length - 1 && (
                <hr className="tw-h-px tw-my-8 tw-bg-gray-200 tw-border-0 tw-dark:bg-gray-700" />
              )}
            </>
          );
        })}

        <div className="tw-flex tw-justify-end tw-mb-6">
          <WButton
            variant="link-secondary"
            icon="plus"
            onClick={() => {
              addSlidingScale();
            }}
            label="Add another sliding scale"
          />
        </div>
      </div>
    </>
  );
};

export default SlidingScaleSection;
