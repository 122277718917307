import React from 'react';
import { Society } from 'shared_components/generated/admin';
import { WInput } from 'shared_components/components/WForms/WInput/WInput';
import { WSelect } from 'shared_components/components/WForms/WSelect/WSelect';
import { TermsOfAgreement } from 'shared_components/generated/admin';
// import TermsOfAgreement from '../../TermsOfAgreement';
import WButton from 'shared_components/components/WForms/WButton/WButton';
interface SocietyCommissionRateProps {
  societyList: Society[];
  termsOfAgreement: TermsOfAgreement;
  onAdd: () => void;
  onDelete: (index: number) => void;
  onChange: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>,
    index: number
  ) => void;
  index: number;
  length: number;
}

const SocietyCommissionRateItem = ({
  societyList,
  termsOfAgreement,
  onAdd,
  onDelete,
  onChange,
  index,
  length,
}: SocietyCommissionRateProps) => {
  return (
    <div>
      <WSelect
        name="society"
        label="Society"
        onBlur={() => {}}
        onChange={() => onChange}
        defaultValue={
          societyList.length > 0 ? societyList[0]?.readableName : ''
        }
        options={societyList.map((society) => ({
          value: society.readableName,
          label: society.readableName,
        }))}
        row
      />
      <WInput
        type="text"
        value={
          termsOfAgreement &&
          termsOfAgreement.societyCommissionRates &&
          termsOfAgreement.societyCommissionRates[index]
            ? termsOfAgreement.societyCommissionRates[index].commissionRate
            : ''
        }
        name="commission"
        label="Commission"
        placeholder="Enter Commission Rate"
        onChange={(e) => onChange(e, index)}
        row
      />
      {index !== 0 ? (
        <div className="tw-flex tw-justify-end tw-mb-8">
          <WButton
            variant="link-secondary"
            onClick={() => onDelete(index)}
            icon="trash"
            label="Remove society commission rate"
          />
        </div>
      ) : null}
      {index === length - 1 ? (
        <div className="tw-flex tw-justify-end">
          <WButton
            variant="link-secondary"
            icon="plus"
            onClick={onAdd}
            className="tw-flex tw-justify-end hover:tw-underline"
            label="Add another society commission rate"
          />
        </div>
      ) : null}
    </div>
  );
};

export default SocietyCommissionRateItem;
