// a React typscript component to confirm that the terms of agreement have been sent to the user. It has a thumbs up icon and a message.
// The title says Sent to client, beneath the icon. The message says The agreement has been sent and you will be notified once signed by client
// It has a button that says Go back to dashboard. When clicked, it takes the user back to the dashboard.

import React from 'react';

import WContainer from 'shared_components/components/WContainer';
import WButton from 'shared_components/components/WForms/WButton/WButton';
type TermsOfAgreementSentProps = {
  onGoBackToDashboard: () => void;
};

const TermsOfAgreementSent: React.FC<TermsOfAgreementSentProps> = ({
  onGoBackToDashboard,
}) => {
  return (
    <div className="tw-p-4 tw-pl-0 tw-text-center tw-w-[575px]">
      <WContainer>
        <p>
          <i
            className="wi wi-verification-check-filled wi-lg"
            style={{ backgroundColor: '#6EE038' }}
          />
        </p>
        <p className="tw-text-3xl tw-my-4">Sent to client</p>
        <p style={{ marginBottom: '2rem' }}>
          The agreement has been sent and you will be notified once signed by
          client
        </p>
        <WButton
          variant="primary-lg"
          label="Return To Dashboard"
          onClick={onGoBackToDashboard}
        />
      </WContainer>
    </div>
  );
};

export default TermsOfAgreementSent;
