import React, { createContext, useContext } from 'react';
import { PortalApi as ClientPortalApi } from '../generated/client';
import { PortalApi as AdminPortalApi } from '../generated/admin';
import { clientApi, adminApi } from './api';

type ApiContextType = {
  clientApi: ClientPortalApi;
  adminApi: AdminPortalApi;
};

const ApiContext = createContext<ApiContextType>({
  clientApi,
  adminApi,
});

type ApiProviderProps = {
  children: React.ReactNode;
};

export const ApiProvider = ({ children }: ApiProviderProps) => {
  return (
    <ApiContext.Provider value={{ clientApi, adminApi }}>
      {children}
    </ApiContext.Provider>
  );
};

export const useApi = () => {
  const { clientApi, adminApi } = useContext(ApiContext);
  return { clientApi, adminApi };
};
