import React, { useState } from 'react';
import { AdminClientList } from 'shared_components/generated/admin';
import { useClientId } from 'shared_components/context/client';
import SquarePlaceholder100 from 'shared_components/public/assets/SquarePlaceholder100.svg';
import clsx from 'clsx';
export interface ArtistListProps {
  artists: AdminClientList[];
}

// ArtistList component is responsible for displaying a list of artists with a search functionality.
// This component is reused in the SocietiesArtists component and the ArtistListBox component.

export const ArtistList: React.FC<ArtistListProps> = ({ artists }) => {
  const [artistsSearch, setArtistsSearch] = useState<string>('');
  const searchRegex = new RegExp(artistsSearch, 'i');
  const { clientId, setClientId } = useClientId();
  return (
    <div className="tw-mb-6 tw-w-full">
      Search
      <div className="tw-relative tw-mt-2">
        <i
          className={`wi wi-20px wi-search tw-absolute tw-left-3 tw-top-[14px]`}
        ></i>
        <input
          className={clsx(
            'tw-bg-formInputBackground',
            'tw-text-gray-400',
            'tw-rounded-lg',
            'tw-w-full',
            'tw-h-12',
            'tw-placeholder-textSeal',
            'tw-text-sm',
            'tw-leading-5',
            'tw-p-3',
            'tw-pl-9',
            'tw-mb-2',
            'tw-border-2',
            'tw-border-formInputBackground',
            'focus:tw-border-white',
            'focus:tw-text-white',
            'focus:tw-outline-none'
          )}
          name={Math.random().toString()} // prevent autofill
          placeholder="Search artists..."
          value={artistsSearch}
          autoComplete="off"
          onChange={(e) => setArtistsSearch(e.target.value)}
        />
      </div>
      <ul className="tw-mt-4 tw-max-h-[650px] tw-overflow-y-auto tw-scrollbar-thin tw-scrollbar-thumb-textDove tw-scrollbar-track-surfaceBlack90 tw-scrollbar-thumb-rounded-full">
        {artists
          .filter((artist) => searchRegex.test(artist.fullName))
          .map((artist) => (
            <li
              key={artist.userId}
              className={`
                            tw-pt-2
                            tw-pb-2
                            tw-flex
                            tw-items-center
                            tw-cursor-pointer
                            tw-border-b
                            tw-border-surfaceGraphite
                            ${
                              clientId === artist.userId
                                ? 'tw-bg-surfaceGraphite'
                                : ''
                            }`}
              onClick={() => setClientId(artist?.userId)}
            >
              <img
                className="tw-w-10 tw-h-10 tw-rounded-full tw-m-2 tw-mr-4"
                src={artist?.image ? artist?.image : SquarePlaceholder100}
                alt={artist?.fullName}
              />
              <span className="tw-text-textWhite">{artist.fullName}</span>
            </li>
          ))}
      </ul>
    </div>
  );
};
