/* tslint:disable */
/* eslint-disable */
/**
 * Westbury Music API
 * API for Westbury Music
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdditionalContactDetailsRepresentativeBankDetails } from './AdditionalContactDetailsRepresentativeBankDetails';
import {
    AdditionalContactDetailsRepresentativeBankDetailsFromJSON,
    AdditionalContactDetailsRepresentativeBankDetailsFromJSONTyped,
    AdditionalContactDetailsRepresentativeBankDetailsToJSON,
} from './AdditionalContactDetailsRepresentativeBankDetails';
import type { ClientInterestRegistrationRepresentative } from './ClientInterestRegistrationRepresentative';
import {
    ClientInterestRegistrationRepresentativeFromJSON,
    ClientInterestRegistrationRepresentativeFromJSONTyped,
    ClientInterestRegistrationRepresentativeToJSON,
} from './ClientInterestRegistrationRepresentative';

/**
 * 
 * @export
 * @interface AdditionalContactDetails
 */
export interface AdditionalContactDetails {
    /**
     * 
     * @type {string}
     * @memberof AdditionalContactDetails
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof AdditionalContactDetails
     */
    witnessFirstName?: string;
    /**
     * 
     * @type {string}
     * @memberof AdditionalContactDetails
     */
    witnessLastName?: string;
    /**
     * 
     * @type {string}
     * @memberof AdditionalContactDetails
     */
    witnessEmail?: string;
    /**
     * 
     * @type {ClientInterestRegistrationRepresentative}
     * @memberof AdditionalContactDetails
     */
    representative?: ClientInterestRegistrationRepresentative;
    /**
     * 
     * @type {AdditionalContactDetailsRepresentativeBankDetails}
     * @memberof AdditionalContactDetails
     */
    representativeBankDetails?: AdditionalContactDetailsRepresentativeBankDetails;
}

/**
 * Check if a given object implements the AdditionalContactDetails interface.
 */
export function instanceOfAdditionalContactDetails(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AdditionalContactDetailsFromJSON(json: any): AdditionalContactDetails {
    return AdditionalContactDetailsFromJSONTyped(json, false);
}

export function AdditionalContactDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdditionalContactDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'password': !exists(json, 'password') ? undefined : json['password'],
        'witnessFirstName': !exists(json, 'witness_first_name') ? undefined : json['witness_first_name'],
        'witnessLastName': !exists(json, 'witness_last_name') ? undefined : json['witness_last_name'],
        'witnessEmail': !exists(json, 'witness_email') ? undefined : json['witness_email'],
        'representative': !exists(json, 'representative') ? undefined : ClientInterestRegistrationRepresentativeFromJSON(json['representative']),
        'representativeBankDetails': !exists(json, 'representative_bank_details') ? undefined : AdditionalContactDetailsRepresentativeBankDetailsFromJSON(json['representative_bank_details']),
    };
}

export function AdditionalContactDetailsToJSON(value?: AdditionalContactDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'password': value.password,
        'witness_first_name': value.witnessFirstName,
        'witness_last_name': value.witnessLastName,
        'witness_email': value.witnessEmail,
        'representative': ClientInterestRegistrationRepresentativeToJSON(value.representative),
        'representative_bank_details': AdditionalContactDetailsRepresentativeBankDetailsToJSON(value.representativeBankDetails),
    };
}

