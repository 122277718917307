import React, { useState } from 'react';
import WContainer from 'shared_components/components/WContainer';
import { UserSettings } from 'shared_components/generated/admin';
import { WFormProvider } from 'shared_components/components/WForms/WFormProvider';
import { WInput } from 'shared_components/components/WForms/WInput/WInput';
import { useFormContext } from 'shared_components/components/WForms/WFormContext';
import WButton from 'shared_components/components/WForms/WButton/WButton';
import FullName from 'shared_components/components/FullName';

interface ArtistDetailsProps {
  userSettings: UserSettings;
  onSave: (updatedSettings: UserSettings) => Promise<void>;
}

const ArtistDetails: React.FC<ArtistDetailsProps> = ({
  userSettings,
  onSave,
}) => {
  const [editedSettings, setEditedSettings] = useState({
    emailAddress: userSettings?.emailAddress,
    firstName: userSettings.firstName,
    lastName: userSettings.lastName,
    middleName: userSettings.middleName,
  });

  const handleSaveClick = () => {
    return onSave(editedSettings);
  };

  function FormEditSection() {
    const { isEditing, setIsEditing, errors } = useFormContext();

    return (
      <div className="tw-flex tw-w-1/10">
        {!isEditing ? (
          <WButton
            label="Edit"
            icon="edit"
            variant="secondary"
            onClick={() => setIsEditing(!isEditing)}
          />
        ) : (
          <WButton
            label="Save"
            variant="primary"
            onClick={() => {
              setIsEditing(!isEditing);
              handleSaveClick();
            }}
          />
        )}
      </div>
    );
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEditedSettings((prevSettings) => ({
      ...prevSettings,
      [name]: value,
    }));
  };

  return (
    <div>
      <WContainer extraClasses="tw-flex tw-flex-col tw-w-[575px]">
        <WFormProvider
          editByDefault={false}
          handleSubmit={handleSaveClick}
          schema={undefined}
          formData={editedSettings}
        >
          <div className="tw-flex tw-justify-between tw-mb-6">
            <p className="tw-text-2xl">Artist Details</p>
            <FormEditSection />
          </div>
          <FullName
            editedSettings={editedSettings}
            handleInputChange={handleInputChange}
          />

          <WInput
            type="email"
            name="emailAddress"
            label="Email"
            value={editedSettings.emailAddress}
            onChange={handleInputChange}
          />
        </WFormProvider>
      </WContainer>
    </div>
  );
};

export default ArtistDetails;
