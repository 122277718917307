import React, { useEffect } from 'react';
import { useFormContext } from 'shared_components/components/WForms/WFormContext';

import {
  SocietyFormRoleRoleEnum,
  SocietyFormRoleFormsInnerSignatureEnum,
  SocietyFormRoleFormsInnerSendViaEnum,
  SocietyFormRoleFormsInnerSignedByEnum,
} from 'shared_components/generated/admin';
import { WSelect } from 'shared_components/components/WForms/WSelect/WSelect';
import WButton from 'shared_components/components/WForms/WButton/WButton';
type formRoleForm = {
  form: string;
  signature: string;
  send_via: string;
  signed_by: string;
};

type formRole = {
  role: string;
  forms: Array<formRoleForm>;
};

type societyForm = {
  id: string;
  readableName: string;
};

interface FormRolesProps {
  isEditingSociety: boolean;
  formRoles: Array<formRole>;
  setFormRoles: (newState: Array<{}>) => void;
  societyForms: Array<societyForm>;
}

const roleSelectOptions = Object.values(SocietyFormRoleRoleEnum).map(
  (role) => ({ label: role, value: role })
);
const signatureOptions = Object.values(
  SocietyFormRoleFormsInnerSignatureEnum
).map((role) => ({ label: role, value: role }));
const sendViaOptions = Object.values(SocietyFormRoleFormsInnerSendViaEnum).map(
  (role) => ({ label: role, value: role })
);
const signedByOptions = Object.values(
  SocietyFormRoleFormsInnerSignedByEnum
).map((role) => ({ label: role, value: role }));

export const FormRoles = ({
  isEditingSociety,
  formRoles,
  setFormRoles,
  societyForms,
}: FormRolesProps) => {
  const [roleFormToggle, setRoleFormToggle] = React.useState(-1);

  const societyIdToName = (id: string): string => {
    const societyForm = societyForms.find(
      (societyForm) => societyForm.id == id
    );
    return societyForm ? societyForm.readableName : '';
  };

  const societyNameToId = (readableName: string): string => {
    const societyForm = societyForms.find(
      (societyForm) => societyForm.readableName == readableName
    );
    return societyForm ? societyForm.id : '';
  };

  const addForm = () => {
    const newFormRoles = [...formRoles];
    newFormRoles.push({
      role: '',
      forms: [{ form: '', signature: '', send_via: '', signed_by: '' }],
    });
    setFormRoles(newFormRoles);
  };

  const deleteForm = (index: number) => {
    const newFormRoles = [...formRoles];
    newFormRoles.splice(index, 1);
    setFormRoles(newFormRoles);
  };

  const addSubForm = (index: number) => {
    const newFormRoles = [...formRoles];
    newFormRoles[index].forms.push({
      form: '',
      signature: '',
      send_via: '',
      signed_by: '',
    });
    setFormRoles(newFormRoles);
  };

  const deleteSubForm = (index: number, fIndex: number) => {
    const newFormRoles = [...formRoles];
    newFormRoles[index].forms.splice(fIndex, 1);
    setFormRoles(newFormRoles);
  };

  const updateRole = (index: number, newRole: string) => {
    const newFormRoles = [...formRoles];
    newFormRoles[index].role = newRole;
    setFormRoles(newFormRoles);
    // auto-open the accordion
    setRoleFormToggle(index);
  };

  const updateSubForm = (
    index: number,
    fIndex: number,
    newForms: formRoleForm
  ) => {
    const newFormRoles = [...formRoles];
    const oldForm = newFormRoles[index].forms[fIndex];
    newFormRoles[index].forms[fIndex] = { ...oldForm, ...newForms };
    setFormRoles(newFormRoles);
  };

  useEffect(() => {
    // accordion should be closed when you've just opened the page
    // when editing a society, it should be allowed to open
    if (!isEditingSociety) {
      setRoleFormToggle(-1);
    }
  }, [formRoles]);

  const { isEditing } = useFormContext();
  const getSocietyFormOptions = (roleIndex: number, currentFormId: string) => {
    const selectedFormsForRole = new Set(
      formRoles[roleIndex].forms?.map((form) => form?.form)
    );

    return societyForms
      .filter((societyForm) => {
        return (
          societyForm.id === currentFormId ||
          !selectedFormsForRole.has(societyForm.id)
        );
      })
      .map((societyForm) => {
        return {
          value: societyForm.readableName,
          label: societyForm.readableName,
        };
      });
  };

  return (
    <>
      <hr className="tw-h-px tw-my-8 tw-bg-gray-200 tw-border-0 tw-dark:bg-gray-700" />
      <div className="">
        <p className="tw-text-2xl tw-mt-6 tw-mb-6">
          Membership Form Configuration
        </p>
        {formRoles.map(({ role, forms }, index: number) => {
          return (
            <div key={index}>
              <div className="tw-flex tw-w-full tw-flex-col" key={index}>
                <div className="tw-w-full">
                  <WSelect
                    name="roleSelect"
                    defaultValue={role != '' ? role : undefined}
                    label={'What Is This Role?'}
                    options={roleSelectOptions.filter(
                      (role) =>
                        !formRoles
                          .map((societyFormRole) => societyFormRole.role)
                          .includes(role.value as SocietyFormRoleRoleEnum) ||
                        role.value === formRoles[index].role
                    )}
                    onChange={(e) => updateRole(index, e.target.value)}
                  />
                </div>

                <div>
                  <p
                    className={`tw-text-textDove tw-uppercase tw-mt-6 tw-mb-6 tw-text-xs`}
                  >
                    Add The Forms That Are Required For This Role
                  </p>
                  <div className="tw-flex tw-flex-col">
                    {forms && forms.length > 0
                      ? forms.map(
                          (
                            { form, signature, send_via, signed_by: signedBy },
                            fIndex: number
                          ) =>
                            RoleFormDetail({
                              isEditingSociety,
                              societyFormOptions: getSocietyFormOptions(
                                index,
                                form
                              ),
                              index,
                              fIndex,
                              form,
                              signature,
                              send_via,
                              signed_by: signedBy,
                              addSubForm,
                              updateSubForm,
                              deleteSubForm,
                              societyIdToName,
                              societyNameToId,
                            })
                        )
                      : ''}
                    {isEditing && (
                      <div className="tw-flex tw-justify-end">
                        <WButton
                          label="Add form"
                          variant="link-secondary"
                          icon="plus"
                          onClick={() => addSubForm(index)}
                        />
                      </div>
                    )}
                  </div>
                </div>

                {isEditing && (
                  <div className="tw-flex tw-justify-start">
                    <WButton
                      variant="link-secondary"
                      onClick={() => deleteForm(index)}
                      label="Remove role"
                      icon="trash"
                    />
                  </div>
                )}
              </div>

              <hr className="tw-h-px tw-my-8 tw-bg-gray-200 tw-border-0 tw-dark:bg-gray-700" />
            </div>
          );
        })}
        {isEditing && (
          <div className="tw-flex tw-justify-end">
            <WButton
              onClick={() => addForm()}
              variant="link-secondary"
              icon="plus"
              label="Add another role"
            />
          </div>
        )}
      </div>
    </>
  );
};

interface RoleFormDetailProps {
  isEditingSociety: boolean;
  societyFormOptions: Array<{ label: string; value: string }>;
  index: number;
  fIndex: number;
  form: string;
  signature: string;
  send_via: string;
  signed_by: string;
  addSubForm: (index: number) => void;
  updateSubForm: (
    index: number,
    fIndex: number,
    newForms: formRoleForm
  ) => void;
  deleteSubForm: (index: number, fIndex: number) => void;
  societyIdToName: (id: string) => string;
  societyNameToId: (readableName: string) => string;
}

const RoleFormDetail = ({
  isEditingSociety,
  societyFormOptions,
  index,
  fIndex,
  form,
  signature,
  send_via,
  signed_by,
  addSubForm,
  updateSubForm,
  deleteSubForm,
  societyIdToName,
  societyNameToId,
}: RoleFormDetailProps) => {
  const { isEditing } = useFormContext();
  const updateForm = (newValue: string) => {
    updateSubForm(index, fIndex, {
      form: newValue,
      signature,
      send_via,
      signed_by,
    });
  };
  const updateSignature = (newValue: string) => {
    updateSubForm(index, fIndex, {
      form,
      signature: newValue,
      send_via,
      signed_by,
    });
  };
  const updateSendVia = (newValue: string) => {
    updateSubForm(index, fIndex, {
      form,
      signature,
      send_via: newValue,
      signed_by,
    });
  };
  const updateSignedBy = (newValue: string) => {
    updateSubForm(index, fIndex, {
      form,
      signature,
      send_via,
      signed_by: newValue,
    });
  };

  return (
    <div className="tw-flex tw-w-full tw-flex-col" key={fIndex}>
      <div>
        <WSelect
          name="formName"
          defaultValue={form && form !== '' ? societyIdToName(form) : undefined}
          label={'Form'}
          options={societyFormOptions}
          onChange={(e) => updateForm(societyNameToId(e.target.value))}
        />
      </div>
      <div className="tw-flex tw-w-full tw-space-x-2">
        <div className="tw-w-1/3">
          <WSelect
            name="signedBy"
            defaultValue={signed_by && signed_by !== '' ? signed_by : undefined}
            label={'Signed by'}
            options={signedByOptions}
            onChange={(e) => updateSignedBy(e.target.value)}
            disabled={form === ''}
          />
        </div>
        <div className="tw-w-1/3">
          <WSelect
            name="signature"
            defaultValue={signature && signature !== '' ? signature : undefined}
            label={'Signature'}
            options={signatureOptions}
            onChange={(e) => updateSignature(e.target.value)}
            disabled={form === ''}
          />
        </div>
        <div className="tw-w-1/3">
          <WSelect
            name="sendVia"
            defaultValue={send_via && send_via !== '' ? send_via : undefined}
            label={'Send via'}
            options={sendViaOptions}
            onChange={(e) => updateSendVia(e.target.value)}
            disabled={form === ''}
          />
        </div>
      </div>
      <div className="tw-flex tw-justify-between">
        {isEditing && fIndex != 0 && (
          <WButton
            variant="link-secondary"
            icon="trash"
            label="Remove form"
            onClick={() => deleteSubForm(index, fIndex)}
          />
        )}
      </div>
    </div>
  );
};
