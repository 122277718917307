import { Location, useLocation } from "react-router-dom";
import React, { createContext, useContext, useState, useEffect } from "react";

type NavigationContextType = {
  selectedDashboardPath: string | null;
};

const NavigationContext = createContext<NavigationContextType>({
  selectedDashboardPath: null,
});

type NavigationProviderProps = {
  children: React.ReactNode;
};

export const NavigationProvider = ({ children }: NavigationProviderProps) => {
  const [selectedDashboardPath, setSelectedDashboardPath] = useState(() =>
    window.sessionStorage.getItem("selectedDashboard")
  );
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes("dashboard")) {
      setSelectedDashboardPath(location.pathname);
      window.sessionStorage.setItem("selectedDashboard", location.pathname);
    }
  }, [location]);

  return (
    <NavigationContext.Provider value={{ selectedDashboardPath }}>
      {children}
    </NavigationContext.Provider>
  );
};

export const useSelectedDashboardPath = () => {
  const { selectedDashboardPath } = useContext(NavigationContext);
  return selectedDashboardPath;
};
