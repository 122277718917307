import React, { useState, useEffect } from 'react';
import { useApi } from 'shared_components/context';
import { useClientId } from 'shared_components/context/client';
import { AdminPersonalInformation } from 'shared_components/generated/admin';
import { WFormProvider } from 'shared_components/components/WForms/WFormProvider';
import z from 'zod';
import { WInput } from 'shared_components/components/WForms/WInput/WInput';
import { WSelect } from 'shared_components/components/WForms/WSelect/WSelect';
import { WFormFooter } from 'shared_components/components/WForms/WFormFooter';
import { useFormContext } from 'shared_components/components/WForms/WFormContext';
import WButton from 'shared_components/components/WForms/WButton/WButton';
import WContainer from 'shared_components/components/WContainer';
import moment from 'moment';
import FullName from 'shared_components/components/FullName';
function ArtistPersonalInfo() {
  const { adminApi: api } = useApi();
  const { clientId } = useClientId();

  const [formValue, setFormValue] = useState<AdminPersonalInformation>({
    title: '',
    gender: '',
    firstName: '',
    middleName: '',
    lastName: '',
    role: '',
    aliases: [],
    dateOfBirth: null,
    nationality: '',
    cityOfBirth: '',
    countryOfBirth: '',
    taxationCountry: '',
    vatNumber: '',
    taxIdType: '',
    taxIdNumber: '',
    emailAddress: '',
    phoneNumber: '',
    streetAddress: '',
    county: '',
    country: '',
    postcode: '',
  });

  useEffect(() => {
    fetchData();
  }, [clientId]);

  const fetchData = () => {
    if (clientId === undefined) {
      return;
    }
    api
      .retrieveAdminPersonalInformation({ userId: clientId })
      .then((personalInfo) => {
        setFormValue({
          title: personalInfo.title,
          gender: personalInfo.gender,
          firstName: personalInfo.firstName,
          middleName: personalInfo.middleName,
          lastName: personalInfo.lastName,
          role: personalInfo.role,
          aliases: personalInfo.aliases,
          dateOfBirth: personalInfo.dateOfBirth,
          nationality: personalInfo.nationality,
          cityOfBirth: personalInfo.cityOfBirth,
          countryOfBirth: personalInfo.countryOfBirth,
          taxationCountry: personalInfo.taxationCountry,
          vatNumber: personalInfo.vatNumber,
          taxIdType: personalInfo.taxIdType,
          taxIdNumber: personalInfo.taxIdNumber,
          emailAddress: personalInfo.emailAddress,
          phoneNumber: personalInfo.phoneNumber,
          streetAddress: personalInfo.streetAddress,
          county: personalInfo.county,
          country: personalInfo.country,
          postcode: personalInfo.postcode,
        });
      })
      .catch(() => {});
  };

  const inputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setFormValue({
      ...formValue,
      [event.target.name]: event.target.value,
    });
  };

  const aliasesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormValue({
      ...formValue,
      aliases: event.target.value.split(',').filter(Boolean),
    });
  };

  const saveDetails = () => {
    if (clientId === undefined) {
      return;
    }
    api
      .partialUpdateAdminPersonalInformation({
        userId: clientId,
        adminPersonalInformation: formValue,
      })
      .then(() => {
        console.log('success');
      })
      .catch(() => {
        console.log('error');
      });

    return Promise.resolve();
  };

  const resetData = (event: React.MouseEvent) => {};

  return (
    <>
      <WFormProvider
        handleSubmit={saveDetails}
        schema={undefined}
        formData={formValue}
      >
        <div className="tw-flex tw-px-4 tw-justify-between">
          <div className="tw-max-w-[365px] tw-mt-14">
            <WInput
              type="text"
              name="title"
              label="Title"
              value={formValue.title}
              onChange={inputChange}
            />

            <FullName
              editedSettings={{
                firstName: formValue.firstName,
                lastName: formValue.lastName,
                middleName: formValue.middleName,
              }}
              handleInputChange={inputChange}
            />

            <WSelect
              defaultValue={formValue.role}
              name="role"
              label="Role"
              onChange={inputChange}
              options={[
                { label: 'Performer/Producer', value: 'Performer/Producer' },
                { label: 'Rightsholder', value: 'Rightsholder' },
                { label: 'Representative', value: 'Representative' },
              ]}
            />

            <WInput
              type="text"
              name="aliases"
              label="Aliases"
              value={formValue?.aliases?.join(',')}
              onChange={aliasesChange}
            />

            <WInput
              type="date"
              name="dateOfBirth"
              label="Date of Birth"
              value={moment(formValue.dateOfBirth).format('YYYY-MM-DD')}
              onChange={inputChange}
            />

            <WInput
              type="text"
              name="nationality"
              label="Nationality"
              value={formValue.nationality}
              onChange={inputChange}
            />
            <WInput
              type="text"
              name="cityOfBirth"
              label="City of Birth"
              value={formValue.cityOfBirth}
              onChange={inputChange}
            />
            <WInput
              type="text"
              name="countryOfBirth"
              label="Country of Birth"
              value={formValue.countryOfBirth}
              onChange={inputChange}
            />
            <WInput
              type="text"
              name="taxationCountry"
              label="Taxation Country"
              value={formValue.taxationCountry}
              onChange={inputChange}
            />
            <WInput
              type="text"
              name="vatNumber"
              label="VAT Number"
              value={formValue.vatNumber}
              onChange={inputChange}
            />
            <WInput
              type="text"
              name="taxIdType"
              label="Tax ID Type"
              value={formValue.taxIdType}
              onChange={inputChange}
            />
            <WInput
              type="text"
              name="taxIdNumber"
              label="Tax ID Number"
              value={formValue.taxIdNumber}
              onChange={inputChange}
            />
            <WInput
              type="text"
              name="emailAddress"
              label="Email Address"
              value={formValue.emailAddress}
              onChange={inputChange}
            />
            <WInput
              type="text"
              name="phoneNumber"
              label="Phone Number"
              value={formValue.phoneNumber}
              onChange={inputChange}
            />
            <WInput
              type="text"
              name="streetAddress"
              label="Street Address"
              value={formValue.streetAddress}
              onChange={inputChange}
            />
            <WInput
              type="text"
              name="county"
              label="County"
              value={formValue.county}
              onChange={inputChange}
            />
            <WInput
              type="text"
              name="country"
              label="Country"
              value={formValue.country}
              onChange={inputChange}
            />
            <WInput
              type="text"
              name="postcode"
              label="Postcode"
              value={formValue.postcode}
              onChange={inputChange}
            />
            <WFormFooter />
          </div>
          <div className="tw-flex tw-justify-end tw-mt-7">
            <FormEditSection />
          </div>
        </div>
      </WFormProvider>
    </>
  );
}

function FormEditSection() {
  const { isEditing, setIsEditing, errors } = useFormContext(); // Fetch errors from context
  return (
    <>
      {!isEditing && (
        <WButton
          icon="edit"
          variant="secondary"
          label="Edit"
          onClick={() => setIsEditing(true)}
        />
      )}
    </>
  );
}

export default ArtistPersonalInfo;
