import React, { useEffect, useState } from 'react';
import { WCheckBoxGroup } from 'shared_components/components/WForms/WCheckBoxGroup/WCheckBoxGroup';
import { useFormContext } from 'shared_components/components/WForms/WFormContext';

export interface DiscographySocietyCheckbox {
  id: string;
  label: string;
  isChecked: boolean;
}

interface DiscographyCheckboxListProps {
  selectAllOnChange: (checked: boolean) => void; // toggle between Select All / Unselect all
  onCheckboxChange: (id: string) => void;
  societyList: DiscographySocietyCheckbox[];
}

const DiscographyCheckboxList: React.FC<DiscographyCheckboxListProps> = ({
  selectAllOnChange,
  onCheckboxChange,
  societyList,
}) => {
  const [checkedValues, setCheckedValues] = useState<string[]>([]);
  const [isCheckedAll, setIsCheckedAll] = useState<boolean>(false);
  const societyCheckBoxList = societyList.map((society) => {
    return {
      value: society.id!,
      label: society.label,
    };
  });
  const { setIsEditing } = useFormContext();
  setIsEditing(true);

  useEffect(() => {
    // list of society ID's if society.isChecked is true
    const checkedSocieties = societyList
      .filter((society) => society.isChecked)
      .map((society) => society.id!);

    setCheckedValues(checkedSocieties);
  }, [societyList]);

  useEffect(() => {
    // iterate through societies, if all are checked set isCheckedAll to true
    const checkedSocieties = societyList.filter(
      (society) => !society.isChecked
    );

    if (checkedSocieties.length !== 0) {
      setIsCheckedAll(false);
    } else {
      setIsCheckedAll(true);
    }
  }, [societyList]);
  const grid = 'tw-grid-cols-2 tw-gap-2';

  return (
    <div>
      <div className="">
        <WCheckBoxGroup
          name="selectAll"
          label=""
          options={
            [
              {
                value: 'select all',
                label: 'Select All',
              },
            ] as any
          }
          defaultCheckedValues={isCheckedAll ? ['select all'] : []}
          onChange={({ newValues }) => {
            selectAllOnChange(!isCheckedAll);
          }}
        />
        <WCheckBoxGroup
          name="society"
          label=""
          checkboxSize="xs"
          options={societyCheckBoxList}
          defaultCheckedValues={checkedValues}
          onChange={({ oldValues, newValues }) => {
            selectAllOnChange(false);

            // Find the changed checkbox
            const diff = oldValues.filter((x) => !newValues.includes(x));
            const diff2 = newValues.filter((x) => !oldValues.includes(x));
            // check if it was checked or unchecked
            if (diff.length > 0) {
              onCheckboxChange(diff[0]);
            } else {
              onCheckboxChange(diff2[0]);
            }
          }}
          grid="tw-grid-cols-2 tw-gap-2"
        />
      </div>
    </div>
  );
};

export default DiscographyCheckboxList;
