/* tslint:disable */
/* eslint-disable */
/**
 * Westbury Music API Admin
 * API for Westbury Music admin users
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CompanyFormRoleFormsInner } from './CompanyFormRoleFormsInner';
import {
    CompanyFormRoleFormsInnerFromJSON,
    CompanyFormRoleFormsInnerFromJSONTyped,
    CompanyFormRoleFormsInnerToJSON,
} from './CompanyFormRoleFormsInner';

/**
 * 
 * @export
 * @interface CompanyRoleFormsInner
 */
export interface CompanyRoleFormsInner {
    /**
     * 
     * @type {string}
     * @memberof CompanyRoleFormsInner
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyRoleFormsInner
     */
    role: CompanyRoleFormsInnerRoleEnum;
    /**
     * 
     * @type {Array<CompanyFormRoleFormsInner>}
     * @memberof CompanyRoleFormsInner
     */
    forms?: Array<CompanyFormRoleFormsInner>;
}


/**
 * @export
 */
export const CompanyRoleFormsInnerRoleEnum = {
    PerformerProducer: 'Performer/Producer'
} as const;
export type CompanyRoleFormsInnerRoleEnum = typeof CompanyRoleFormsInnerRoleEnum[keyof typeof CompanyRoleFormsInnerRoleEnum];


/**
 * Check if a given object implements the CompanyRoleFormsInner interface.
 */
export function instanceOfCompanyRoleFormsInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "role" in value;

    return isInstance;
}

export function CompanyRoleFormsInnerFromJSON(json: any): CompanyRoleFormsInner {
    return CompanyRoleFormsInnerFromJSONTyped(json, false);
}

export function CompanyRoleFormsInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyRoleFormsInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'role': json['role'],
        'forms': !exists(json, 'forms') ? undefined : ((json['forms'] as Array<any>).map(CompanyFormRoleFormsInnerFromJSON)),
    };
}

export function CompanyRoleFormsInnerToJSON(value?: CompanyRoleFormsInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'role': value.role,
        'forms': value.forms === undefined ? undefined : ((value.forms as Array<any>).map(CompanyFormRoleFormsInnerToJSON)),
    };
}

