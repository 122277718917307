export interface SignedUrl {
  parentRecordingId: string;
  parentReleaseId: string;
  url: string;
}

export async function* asyncEvidenceUrlIterator(reader: ReadableStreamDefaultReader<Uint8Array>) {
  try {
    while (true) {
      const decoder = new TextDecoder();
      const { done, value } = await reader.read();
      if (done) {
        return;
      }

      const lines = decoder.decode(value).split(/\n/).filter(Boolean);
      let buffer = '';
      for (const line of lines) {
        buffer += line;
        if (!line.endsWith('}')) {
          return;
        }
        const signedUrl: SignedUrl = JSON.parse(buffer);
        buffer = '';
        yield signedUrl;
      }
    }
  } catch (error) {
    console.error(error);
    throw error;
  } finally {
    reader.releaseLock();
  }
}
