import React, { useId } from 'react';
import { WInput } from 'shared_components/components/WForms/WInput/WInput';
import { CompanyInfoFormData } from './CompanyInfo';
import { useFormContext } from 'shared_components/components/WForms/WFormContext';
import Countries from '../../../Forms/Countries.json';
import { WSelect } from 'shared_components/components/WForms/WSelect/WSelect';
import { WAvatar } from 'shared_components/components/WForms/WAvatar/WAvatar';
import WButton from 'shared_components/components/WForms/WButton/WButton';
interface CompanyDetailsProps {
  formData: CompanyInfoFormData;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleLogoSubmit: (e: React.ChangeEvent) => void;
}
export const CompanyDetails = ({
  formData,
  handleChange,
  handleLogoSubmit,
}: CompanyDetailsProps) => {
  const { isEditing } = useFormContext();

  const containerClasses = isEditing
    ? `tw-bg-surfaceBlack90
  tw-p-6
  tw-mt-6
  tw-text-white
  tw-rounded-2xl
  tw-max-w-full`
    : ``;

  const selectCountries = Countries.map((country) => {
    return {
      value: country,
      label: country,
    };
  });

  return (
    <div className={containerClasses}>
      <div className="tw-flex tw-justify-between">
        <span className={`tw-text-2xl tw-mb-6`}>Company Info</span>
        <FormEditSection />
      </div>
      <div className="tw-flex tw-justify-between tw-align-top tw-mb-6">
        <WAvatar
          label={``}
          onChange={(e) => {
            if (e.target.files === null) {
              return;
            }
            handleLogoSubmit(e);
          }}
          type={`avatar`}
          value={formData.logoImageUrl}
          name={`avatar`}
        />
      </div>

      <WInput
        type="text"
        label="Company name"
        name="name"
        value={formData.name}
        onChange={handleChange}
        onBlur={() => {}}
      />
      <WInput
        type="text"
        label="Email address"
        name="emailAddress"
        value={formData.emailAddress}
        onChange={handleChange}
        onBlur={() => {}}
      />
      <WInput
        type="text"
        label="Address Line 1"
        name="streetAddress1"
        value={formData.streetAddress1}
        onChange={handleChange}
        onBlur={() => {}}
      />
      <WInput
        type="text"
        label="Address Line 2"
        name="streetAddress2"
        value={formData.streetAddress2}
        onChange={handleChange}
        onBlur={() => {}}
      />
      <WInput
        type="text"
        label="County"
        name="county"
        value={formData.county}
        onChange={handleChange}
        onBlur={() => {}}
      />
      <WInput
        type="text"
        label="Postcode"
        name="postcode"
        value={formData.postcode}
        onChange={handleChange}
        onBlur={() => {}}
      />
      <WSelect
        label="Country"
        name="country"
        defaultValue={formData.country}
        onChange={handleChange}
        options={selectCountries}
        onBlur={() => {}}
      />

      <WInput
        type="text"
        label="Phone number"
        name="phoneNumber"
        value={formData.phoneNumber}
        onChange={handleChange}
        onBlur={() => {}}
      />
      <WInput
        type="text"
        label="Website"
        name="marketingUrl"
        value={formData.marketingUrl}
        onChange={handleChange}
        onBlur={() => {}}
      />
    </div>
  );
};

const FormEditSection = () => {
  const { isEditing, setIsEditing, setFormSubmitted } = useFormContext();
  return (
    <>
      {!isEditing && (
        <WButton
          label="Edit"
          variant="secondary"
          icon="edit"
          onClick={() => {
            setIsEditing(true);
            setFormSubmitted(false);
          }}
        />
      )}
    </>
  );
};
