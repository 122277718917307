import React, {
  createContext,
  useState,
  useCallback,
  useEffect,
  useContext,
} from 'react';
import { WError } from './WError';
import WModal from 'shared_components/components/WModal';
import WButton from '../WForms/WButton/WButton';
import ErrorBlock from '../ErrorBlock/ErrorBlock';

interface ErrorContextType {
  throwError: (error: WError) => void;
}

const WErrorContext = createContext<ErrorContextType | undefined>(undefined);

// Custom Hook for using ErrorContext
export const useWError = () => {
  const context = useContext(WErrorContext);
  if (!context) {
    throw new Error('useWError must be used within a WErrorProvider');
  }
  return context;
};

interface WErrorProviderProps {
  children: React.ReactNode;
}

export const WErrorProvider: React.FC<WErrorProviderProps> = ({ children }) => {
  const [error, setError] = useState<WError | null>(null);
  const [errorModalOpen, setErrorModalOpen] = useState(false);

  useEffect(() => {
    if (error !== null) {
      setErrorModalOpen(true);
    }
  }, [error]);

  const throwError = useCallback((error: WError) => {
    if (error instanceof WError) {
      setError(error);
    }
  }, []);

  const handleClose = useCallback(() => {
    setError(null);
    setErrorModalOpen(false);
  }, []);

  return (
    <WErrorContext.Provider value={{ throwError }}>
      {children}
      {error && (
        <WModal isOpen={errorModalOpen} onClose={handleClose} title={''}>
          <ErrorBlock title={error.title} description={error.message} />
        </WModal>
      )}
    </WErrorContext.Provider>
  );
};
