/* tslint:disable */
/* eslint-disable */
/**
 * Westbury Music API
 * API for Westbury Music
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IdentityVerficationSubmission
 */
export interface IdentityVerficationSubmission {
    /**
     * 
     * @type {Blob}
     * @memberof IdentityVerficationSubmission
     */
    passportFile: Blob;
    /**
     * 
     * @type {Blob}
     * @memberof IdentityVerficationSubmission
     */
    proofOfAddressFile?: Blob;
    /**
     * 
     * @type {boolean}
     * @memberof IdentityVerficationSubmission
     */
    registeredWithPpl: boolean;
    /**
     * 
     * @type {string}
     * @memberof IdentityVerficationSubmission
     */
    pplPerformerName?: string;
    /**
     * 
     * @type {string}
     * @memberof IdentityVerficationSubmission
     */
    pplPerformerId?: string;
    /**
     * 
     * @type {string}
     * @memberof IdentityVerficationSubmission
     */
    streetAddress: string;
    /**
     * 
     * @type {string}
     * @memberof IdentityVerficationSubmission
     */
    postcode: string;
    /**
     * 
     * @type {string}
     * @memberof IdentityVerficationSubmission
     */
    county: string;
    /**
     * 
     * @type {string}
     * @memberof IdentityVerficationSubmission
     */
    country: string;
    /**
     * 
     * @type {string}
     * @memberof IdentityVerficationSubmission
     */
    witnessFirstName: string;
    /**
     * 
     * @type {string}
     * @memberof IdentityVerficationSubmission
     */
    witnessLastName: string;
    /**
     * 
     * @type {string}
     * @memberof IdentityVerficationSubmission
     */
    witnessEmail: string;
}

/**
 * Check if a given object implements the IdentityVerficationSubmission interface.
 */
export function instanceOfIdentityVerficationSubmission(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "passportFile" in value;
    isInstance = isInstance && "registeredWithPpl" in value;
    isInstance = isInstance && "streetAddress" in value;
    isInstance = isInstance && "postcode" in value;
    isInstance = isInstance && "county" in value;
    isInstance = isInstance && "country" in value;
    isInstance = isInstance && "witnessFirstName" in value;
    isInstance = isInstance && "witnessLastName" in value;
    isInstance = isInstance && "witnessEmail" in value;

    return isInstance;
}

export function IdentityVerficationSubmissionFromJSON(json: any): IdentityVerficationSubmission {
    return IdentityVerficationSubmissionFromJSONTyped(json, false);
}

export function IdentityVerficationSubmissionFromJSONTyped(json: any, ignoreDiscriminator: boolean): IdentityVerficationSubmission {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'passportFile': json['passport_file'],
        'proofOfAddressFile': !exists(json, 'proof_of_address_file') ? undefined : json['proof_of_address_file'],
        'registeredWithPpl': json['registered_with_ppl'],
        'pplPerformerName': !exists(json, 'ppl_performer_name') ? undefined : json['ppl_performer_name'],
        'pplPerformerId': !exists(json, 'ppl_performer_id') ? undefined : json['ppl_performer_id'],
        'streetAddress': json['street_address'],
        'postcode': json['postcode'],
        'county': json['county'],
        'country': json['country'],
        'witnessFirstName': json['witness_first_name'],
        'witnessLastName': json['witness_last_name'],
        'witnessEmail': json['witness_email'],
    };
}

export function IdentityVerficationSubmissionToJSON(value?: IdentityVerficationSubmission | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'passport_file': value.passportFile,
        'proof_of_address_file': value.proofOfAddressFile,
        'registered_with_ppl': value.registeredWithPpl,
        'ppl_performer_name': value.pplPerformerName,
        'ppl_performer_id': value.pplPerformerId,
        'street_address': value.streetAddress,
        'postcode': value.postcode,
        'county': value.county,
        'country': value.country,
        'witness_first_name': value.witnessFirstName,
        'witness_last_name': value.witnessLastName,
        'witness_email': value.witnessEmail,
    };
}

