import React from 'react';

interface ButtonProps {
  label: string;
  icon?: string;
  variant:
    | 'primary'
    | 'primary-lg'
    | 'warning'
    | 'secondary'
    | 'secondary-lg'
    | 'dark'
    | 'link-secondary'
    | 'link'
    | 'outline-light';

  onClick?: () => void;
  disabled?: boolean;
  extraClasses?: string;
}

const WButton: React.FC<ButtonProps> = ({
  label,
  icon = '',
  variant,
  disabled = false,
  onClick,
  extraClasses = '',
}) => {
  const baseClasses =
    'tw-flex tw-inline-flex tw-items-center tw-font-size-[14px] tw-justify-center tw-items-center tw-whitespace-nowrap';

  const variantClasses = {
    primary:
      'tw-py-1 tw-px-4 tw-rounded-3xl tw-bg-surfaceLime tw-text-surfaceBlack90 tw-h-[32px]',
    'primary-lg':
      'tw-py-1 tw-px-6 tw-rounded-3xl tw-bg-surfaceLime tw-text-surfaceBlack90 tw-h-[48px]',
    secondary:
      'tw-py-1 tw-px-4 tw-rounded-3xl tw-bg-transparent tw-text-textWhite tw-border-[2px] tw-border-textWhite tw-h-[32px]',
    'secondary-lg':
      'tw-py-1 tw-px-4 tw-rounded-3xl tw-bg-transparent tw-text-textWhite tw-border-[2px] tw-border-textWhite tw-h-[48px]',
    warning:
      'tw-py-1 tw-px-4 tw-rounded-3xl tw-bg-surfaceLime tw-text-surfaceBlack90 tw-h-[32px]',
    dark: 'tw-py-1 tw-px-4 tw-rounded-3xl tw-bg-surfaceBlack90 tw-text-textWhite tw-h-[32px]',
    'outline-light':
      'tw-underline tw-bg-transparent tw-text-textWhite tw-h-[32px]',
    link: 'tw-underline tw-underline-offset-2 tw-bg-transparent tw-text-textLime tw-h-[32px]',
    'link-secondary':
      'tw-underline tw-underline-offset-2 tw-bg-transparent tw-text-textWhite tw-h-[32px]',
    disabled:
      'tw-py-1 tw-px-4 tw-rounded-3xl tw-bg-transparent tw-text-textGraphite tw-border-[2px] tw-border-textGraphite tw-h-[32px]',
  };

  const combinedClasses = `${baseClasses} ${
    disabled === true ? variantClasses['disabled'] : variantClasses[variant]
  } ${extraClasses} ${
    disabled ? 'tw-cursor-not-allowed' : 'tw-cursor-pointer'
  }`;

  return (
    <div
      className={combinedClasses}
      onClick={() => {
        if (disabled === true) return;
        if (typeof onClick != 'undefined') onClick();
      }}
      style={{ lineHeight: '16px' }}
    >
      {icon != '' && (
        <i
          className={`wi wi-sm wi-${icon} ${
            icon == 'Back' ? 'tw-mr-1' : 'tw-mr-2'
          }`}
        />
      )}
      {label}
    </div>
  );
};

export default WButton;
