import { useEffect, useState } from 'react';
import WModal from '../WModal';
import WButton from '../WForms/WButton/WButton';
import WCheckBox from '../WCheckBox';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import clsx from 'clsx';

interface AnnotatedEvidenceModalProps {
  isOpen: boolean;
  onClose: () => void;
  urls: string[];
}

type UrlIsCheckedMap = {
  [url: string]: boolean;
};

export const AnnotatedEvidenceModal = ({
  isOpen,
  onClose,
  urls,
}: AnnotatedEvidenceModalProps) => {
  const [urlIsCheckedMap, setUrlIsCheckedMap] = useState<UrlIsCheckedMap>({});
  const [isSelectAll, setIsSelectAll] = useState<boolean>(true);

  const handleChecked = (url: string) => {
    setUrlIsCheckedMap({
      ...urlIsCheckedMap,
      [url]: !urlIsCheckedMap[url],
    });
  };

  useEffect(() => {
    setIsSelectAll(
      Object.values(urlIsCheckedMap).every((isChecked) => isChecked)
    );
  }, [urlIsCheckedMap]);

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUrlIsCheckedMap(
      Object.fromEntries(
        Object.entries(urlIsCheckedMap).map(([url]) => [
          url,
          event.target.checked,
        ])
      )
    );
  };

  const handleDownload = async () => {
    const zip = new JSZip();

    const urlsForDownload = Object.entries(urlIsCheckedMap).flatMap(
      ([url, isChecked]) => (isChecked ? [url] : [])
    );

    for await (const [index, url] of Object.entries(urlsForDownload)) {
      try {
        const imageBlob = await fetch(url).then((response) => response.blob());
        const pathComponents = new URL(url).pathname.split('/');
        const fileName = pathComponents.pop() ?? `evidence${index}.jpg`;
        const imageFile = new File([imageBlob], fileName);
        zip.file(fileName, imageFile);
      } catch (error) {
        console.error(error);
      }
    }

    zip
      .generateAsync({ type: 'blob' })
      .then((content) => saveAs(content, 'annotated-evidence'));
  };

  const handleCopyToClipboard = () => {
    const text = Object.entries(urlIsCheckedMap)
      .flatMap(([url, isChecked]) => (isChecked ? [url] : []))
      .join('\n');
    navigator.clipboard.writeText(text);
  };

  return (
    <WModal title="Annotated Evidence" isOpen={isOpen} onClose={onClose}>
      <div className="tw-w-full tw-flex tw-justify-between gap-2 tw-pl-2 tw-pr-3.5 tw-pt-1 tw-pb-3.5">
        <WCheckBox
          name="selectAll"
          label="Select All"
          checked={isSelectAll}
          onChange={handleSelectAll}
          checkboxSize="sm"
          extraLabelClasses="tw-text-white"
        />
        <div className="tw-space-x-2.5">
          <WButton
            variant="primary"
            label="Copy Selected URLs"
            onClick={handleCopyToClipboard}
          />
          <WButton
            variant="secondary"
            label="Download Selected"
            onClick={handleDownload}
          />
        </div>
      </div>
      <div className="tw-w-[44rem] tw-h-[42rem] tw-overflow-y-scroll tw-pl-2 tw-pr-3.5">
        <div className="tw-grid tw-grid-cols-2 tw-gap-5">
          {urls.map((url) => (
            <div key={url} className="tw-flex tw-gap-0.5 tw-items-start">
              <WCheckBox
                name={url}
                checked={urlIsCheckedMap[url]}
                onChange={() => handleChecked(url)}
                checkboxSize="sm"
                extraClasses={clsx({ invisible: !(url in urlIsCheckedMap) })}
              />
              <button
                className={clsx(
                  'tw-h-[20rem] tw-w-[20rem] tw-border tw-border-solid',
                  urlIsCheckedMap[url]
                    ? 'tw-border-white'
                    : 'tw-border-transparent'
                )}
                onClick={() => handleChecked(url)}
              >
                <img
                  src={url}
                  onLoad={() =>
                    setUrlIsCheckedMap((urlIsCheckedMap) => {
                      return {
                        ...urlIsCheckedMap,
                        [url]: true,
                      };
                    })
                  }
                  className="tw-w-full tw-h-full tw-object-contain tw-object-top"
                />
              </button>
            </div>
          ))}
        </div>
      </div>
    </WModal>
  );
};
