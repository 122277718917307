import React from 'react';
import WButton from '../WForms/WButton/WButton';

interface Props {
  title?: string;
  description?: string;
  cta?: { ctaText: string; onClick: () => void } | undefined;
}

const defaultTitle = 'Thank You';
const defaultDescription =
  'A member of our team will verify your information and you will receive an email shortly';
export const ThankyouBlock: React.FC<Props> = ({
  title = defaultTitle,
  description = defaultDescription,
  cta = undefined,
}) => {
  return (
    <div
      className={`tw-flex tw-flex-col tw-items-center tw-w-[400px] ${
        cta === undefined ? 'tw-mb-12' : 'tw-mb-4'
      }`}
    >
      <div className="tw-mt-4 tw-mb-3">
        <i className="wi wi-verification-check-filled tw-w-20 tw-h-20 tw-text-formOutlineSuccessGreen" />
      </div>
      <p className="tw-text-white tw-text-2xl tw-mt-2 tw-text-center">
        {title}
      </p>
      <p className="tw-text-md tw-text-center tw-mt-2 tw-font-light">
        {description}
      </p>

      {cta != undefined && (
        <p className="tw-mt-6">
          <WButton
            variant="primary-lg"
            label={cta.ctaText}
            onClick={cta.onClick}
          />
        </p>
      )}
    </div>
  );
};

export default ThankyouBlock;
