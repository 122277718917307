import React, { useState, useEffect } from 'react';
import Countries from 'shared_components/utils/Countries';
import { WSelect } from 'shared_components/components/WForms/WSelect/WSelect';
import { useApi } from 'shared_components/context';
import { useClientId } from 'shared_components/context/client';
import {
  AdminBankDetails,
  AdminBankDetailsBankPaymentMethodEnum,
} from 'shared_components/generated/admin';
import { WModal } from 'shared_components/components/WModal/WModal';
import { WRadioButton } from 'shared_components/components/WForms/WRadioButton/WRadioButton';
import { WInput } from 'shared_components/components/WForms/WInput/WInput';
import { WFormProvider } from 'shared_components/components/WForms/WFormProvider';
import z from 'zod';
import { WFormFooter } from 'shared_components/components/WForms/WFormFooter';
import { useFormContext } from 'shared_components/components/WForms/WFormContext';
import WButton from 'shared_components/components/WForms/WButton/WButton';
import { useWError } from 'shared_components/components/WError/WErrorProvider';
import { WError } from 'shared_components/components/WError/WError';
import ThankyouBlock from 'shared_components/components/ThankYouBlock/ThankyouBlock';
function ArtistBankDetails() {
  const { adminApi: api } = useApi();
  const { clientId } = useClientId();
  const [show, setShow] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const { throwError } = useWError();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formError, setFormError] = useState('');
  const [transferMethod, setTransferMethod] = useState<
    AdminBankDetailsBankPaymentMethodEnum | undefined
  >('bank_transfer');

  const [formValue, setFormValue] = useState<AdminBankDetails>({
    bankAccountName: '',
    bankSortCode: '',
    bankAccountNumber: '',
    bankName: '',
    bankAddress: '',
    bankIban: '',
    bankRoutingCode: '',
    bankAccountHoldingBranch: '',
    bankBicSwiftCode: '',
    intermediaryBankName: '',
    intermediaryBankAddress: '',
    intermediaryBankBicSwiftCode: '',
    intermediaryBankCountry: '',
  });

  const schema = z.object({
    bankAccountName: z.string().nonempty(),
    bankSortCode: z.string().nonempty(),
    bankAccountNumber: z.string().nonempty(),
    bankName: z.string().nonempty(),
    bankAddress: z.string().nonempty(),
    bankIban: z.string().nonempty(),
    bankRoutingCode: z.string().nonempty(),
    bankAccountHoldingBranch: z.string().nonempty(),
    bankBicSwiftCode: z.string().nonempty(),
    intermediaryBankName: z.string().nonempty(),
    intermediaryBankAddress: z.string().nonempty(),
    intermediaryBankBicSwiftCode: z.string().nonempty(),
    intermediaryBankCountry: z.string().nonempty(),
  });

  const inputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormValue({
      ...formValue,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    fetchData();
  }, [clientId]);

  const fetchData = () => {
    if (clientId === undefined) {
      throwError(new WError('Client ID is not defined'));
      return;
    }
    api
      .retrieveAdminBankDetails({ userId: clientId })
      .then((bankDetails) => {
        setFormValue({
          bankAccountName: bankDetails.bankAccountName,
          bankSortCode: bankDetails.bankSortCode,
          bankAccountNumber: bankDetails.bankAccountNumber,
          bankName: bankDetails.bankName,
          bankAddress: bankDetails.bankAddress,
          bankIban: bankDetails.bankIban,
          bankRoutingCode: bankDetails.bankRoutingCode,
          bankAccountHoldingBranch: bankDetails.bankAccountHoldingBranch,
          bankBicSwiftCode: bankDetails.bankBicSwiftCode,
          intermediaryBankName: bankDetails.intermediaryBankName,
          intermediaryBankAddress: bankDetails.intermediaryBankAddress,
          intermediaryBankBicSwiftCode:
            bankDetails.intermediaryBankBicSwiftCode,
          intermediaryBankCountry: bankDetails.intermediaryBankCountry,
        });
        setTransferMethod(bankDetails.bankPaymentMethod);
      })
      .catch(() => {
        setFormValue({
          bankAccountName: '',
          bankSortCode: '',
          bankAccountNumber: '',
          bankName: '',
          bankAddress: '',
          bankIban: '',
          bankRoutingCode: '',
          bankAccountHoldingBranch: '',
          bankBicSwiftCode: '',
          intermediaryBankName: '',
          intermediaryBankAddress: '',
          intermediaryBankBicSwiftCode: '',
          intermediaryBankCountry: '',
        });
      });
  };

  const saveDetails = () => {
    if (clientId === undefined) {
      throwError(new WError('Client ID is not defined'));
    }
    api
      .updateAdminBankDetails({
        userId: clientId,
        adminBankDetails: { ...formValue, bankPaymentMethod: transferMethod },
      })
      .then(() => {
        setFormSubmitted(true);
        setShow(true);
      })
      .catch(() => {
        setFormSubmitted(true);
        setFormError('Your request has failed.');
        setShow(true);
      });
  };

  const resetData = () => {
    setFormSubmitted(false);
    setFormError('');
    setShow(false);
    setIsEditing(false);
  };

  return (
    <>
      <WModal isOpen={formSubmitted} onClose={resetData} title="">
        {formError ? (
          <div className="tw-flex tw-justify-center tw-text-white">
            <span className="tw-text-lg">Sorry!</span>
            <p className="">
              There is an error while submitting the details! <br /> Please try
              again and make sure all the fields are filled.
            </p>
            <WButton variant="primary" label="OK" onClick={resetData} />
            Ok
          </div>
        ) : (
          <ThankyouBlock
            title="Information Updated"
            description="Thank you for updating the client's information"
          />
        )}
      </WModal>
      <WFormProvider
        handleSubmit={saveDetails}
        schema={schema}
        formData={formValue}
      >
        <div className="tw-flex tw-justify-end">
          <FormEditSection />
        </div>
        <div className="tw-max-w-[350px]">
          <WRadioButton
            label="Method of payment"
            name="payment_method"
            selectedValue={transferMethod}
            items={[
              { value: 'bank_transfer', label: 'Bank transfer' },
              { value: 'cheque', label: 'Cheque' },
            ]}
            onChange={(event) => {
              setTransferMethod(
                event.target.value as AdminBankDetailsBankPaymentMethodEnum
              );
            }}
          />
          <WInput
            type="text"
            label="Bank Account Name"
            name="bankAccountName"
            value={formValue.bankAccountName}
            onChange={inputChange}
          />
          <WInput
            type="text"
            label="Bank Sort Code"
            name="bankSortCode"
            value={formValue.bankSortCode}
            onChange={inputChange}
          />
          <WInput
            type="text"
            label="Bank Account Number"
            name="bankAccountNumber"
            value={formValue.bankAccountNumber}
            onChange={inputChange}
          />
          <WInput
            type="text"
            label="Bank Name"
            name="bankName"
            value={formValue.bankName}
            onChange={inputChange}
          />
          <WInput
            type="text"
            label="Bank Address"
            name="bankAddress"
            value={formValue.bankAddress}
            onChange={inputChange}
          />
          <WInput
            type="text"
            label="Bank IBAN"
            name="bankIban"
            value={formValue.bankIban}
            onChange={inputChange}
          />
          <WInput
            type="text"
            label="Bank Routing Code"
            name="bankRoutingCode"
            value={formValue.bankRoutingCode}
            onChange={inputChange}
          />
          <WInput
            type="text"
            label="Bank Account Holding Branch"
            name="bankAccountHoldingBranch"
            value={formValue.bankAccountHoldingBranch}
            onChange={inputChange}
          />
          <WInput
            type="text"
            label="Bank BIC/SWIFT Code"
            name="bankBicSwiftCode"
            value={formValue.bankBicSwiftCode}
            onChange={inputChange}
          />

          {/* Intermediary Bank section */}
          <WInput
            type="text"
            label="Intermediary Bank Name"
            name="intermediaryBankName"
            value={formValue.intermediaryBankName}
            onChange={inputChange}
          />

          <WInput
            type="text"
            label="Intermediary Bank Address"
            name="intermediaryBankAddress"
            value={formValue.intermediaryBankAddress}
            onChange={inputChange}
          />

          <WInput
            type="text"
            label="Intermediary Bank BIC/SWIFT Code"
            name="intermediaryBankBicSwiftCode"
            value={formValue.intermediaryBankBicSwiftCode}
            onChange={inputChange}
          />

          <WSelect
            label="Intermediary Bank Country"
            name="intermediaryBankCountry"
            defaultValue={formValue.intermediaryBankCountry!}
            options={Countries.map((country) => ({
              label: country,
              value: country,
            }))}
            onBlur={() => {}}
            onChange={inputChange}
          />
          <WFormFooter></WFormFooter>
        </div>
      </WFormProvider>
    </>
  );
}

export default ArtistBankDetails;

function FormEditSection() {
  const { isEditing, setIsEditing, errors } = useFormContext(); // Fetch errors from context
  return (
    <>
      {' '}
      {!isEditing && (
        <WButton
          icon="edit"
          variant="secondary"
          label="Edit"
          onClick={() => setIsEditing(true)}
        />
      )}
    </>
  );
}

function OnboardedFormFooter() {
  const { handleFormSubmit, formData, isEditing, setIsEditing } =
    useFormContext();
  return (
    // show primary button with edit icon when not editing, show Save button when editing
    <div className="tw-flex tw-justify-start tw-mt-4">
      {!isEditing && (
        <WButton
          onClick={() => {
            setIsEditing(true);
          }}
          variant="primary-lg"
          label="Edit"
          icon="edit"
        />
      )}
      {isEditing && (
        <WButton
          onClick={() => {
            handleFormSubmit(formData);
            setIsEditing(false);
          }}
          variant="primary-lg"
          label="Save"
        />
      )}
    </div>
  );
}
