/* tslint:disable */
/* eslint-disable */
/**
 * Westbury Music API Admin
 * API for Westbury Music admin users
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdminSocietySocietyFormsInner } from './AdminSocietySocietyFormsInner';
import {
    AdminSocietySocietyFormsInnerFromJSON,
    AdminSocietySocietyFormsInnerFromJSONTyped,
    AdminSocietySocietyFormsInnerToJSON,
} from './AdminSocietySocietyFormsInner';

/**
 * 
 * @export
 * @interface AdminSociety
 */
export interface AdminSociety {
    /**
     * 
     * @type {string}
     * @memberof AdminSociety
     */
    readonly id?: string;
    /**
     * 
     * @type {Date}
     * @memberof AdminSociety
     */
    readonly dateCreated?: Date;
    /**
     * 
     * @type {string}
     * @memberof AdminSociety
     */
    internalIdentifier: string;
    /**
     * 
     * @type {string}
     * @memberof AdminSociety
     */
    readableName: string;
    /**
     * 
     * @type {string}
     * @memberof AdminSociety
     */
    readonly logoUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminSociety
     */
    country?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminSociety
     */
    website?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminSociety
     */
    portal?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminSociety
     */
    phone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminSociety
     */
    address?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminSociety
     */
    additionalTerritories?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AdminSociety
     */
    formEmail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminSociety
     */
    formPostalAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminSociety
     */
    formPortal?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminSociety
     */
    formReminderFrequency?: AdminSocietyFormReminderFrequencyEnum;
    /**
     * 
     * @type {string}
     * @memberof AdminSociety
     */
    formStopReminderFrequency?: AdminSocietyFormStopReminderFrequencyEnum;
    /**
     * 
     * @type {string}
     * @memberof AdminSociety
     */
    formReminderSendTo?: string | null;
    /**
     * 
     * @type {Array<AdminSocietySocietyFormsInner>}
     * @memberof AdminSociety
     */
    societyForms: Array<AdminSocietySocietyFormsInner>;
    /**
     * 
     * @type {string}
     * @memberof AdminSociety
     */
    readonly taxForms?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminSociety
     */
    readonly roleForms?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminSociety
     */
    readonly contactInformation?: string;
}


/**
 * @export
 */
export const AdminSocietyFormReminderFrequencyEnum = {
    Daily: 'Daily',
    Weekly: 'Weekly',
    Monthly: 'Monthly',
    Yearly: 'Yearly'
} as const;
export type AdminSocietyFormReminderFrequencyEnum = typeof AdminSocietyFormReminderFrequencyEnum[keyof typeof AdminSocietyFormReminderFrequencyEnum];

/**
 * @export
 */
export const AdminSocietyFormStopReminderFrequencyEnum = {
    Daily: 'Daily',
    Weekly: 'Weekly',
    Monthly: 'Monthly',
    Yearly: 'Yearly'
} as const;
export type AdminSocietyFormStopReminderFrequencyEnum = typeof AdminSocietyFormStopReminderFrequencyEnum[keyof typeof AdminSocietyFormStopReminderFrequencyEnum];


/**
 * Check if a given object implements the AdminSociety interface.
 */
export function instanceOfAdminSociety(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "internalIdentifier" in value;
    isInstance = isInstance && "readableName" in value;
    isInstance = isInstance && "societyForms" in value;

    return isInstance;
}

export function AdminSocietyFromJSON(json: any): AdminSociety {
    return AdminSocietyFromJSONTyped(json, false);
}

export function AdminSocietyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdminSociety {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'dateCreated': !exists(json, 'date_created') ? undefined : (new Date(json['date_created'])),
        'internalIdentifier': json['internal_identifier'],
        'readableName': json['readable_name'],
        'logoUrl': !exists(json, 'logo_url') ? undefined : json['logo_url'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'website': !exists(json, 'website') ? undefined : json['website'],
        'portal': !exists(json, 'portal') ? undefined : json['portal'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'additionalTerritories': !exists(json, 'additional_territories') ? undefined : json['additional_territories'],
        'formEmail': !exists(json, 'form_email') ? undefined : json['form_email'],
        'formPostalAddress': !exists(json, 'form_postal_address') ? undefined : json['form_postal_address'],
        'formPortal': !exists(json, 'form_portal') ? undefined : json['form_portal'],
        'formReminderFrequency': !exists(json, 'form_reminder_frequency') ? undefined : json['form_reminder_frequency'],
        'formStopReminderFrequency': !exists(json, 'form_stop_reminder_frequency') ? undefined : json['form_stop_reminder_frequency'],
        'formReminderSendTo': !exists(json, 'form_reminder_send_to') ? undefined : json['form_reminder_send_to'],
        'societyForms': ((json['society_forms'] as Array<any>).map(AdminSocietySocietyFormsInnerFromJSON)),
        'taxForms': !exists(json, 'tax_forms') ? undefined : json['tax_forms'],
        'roleForms': !exists(json, 'role_forms') ? undefined : json['role_forms'],
        'contactInformation': !exists(json, 'contact_information') ? undefined : json['contact_information'],
    };
}

export function AdminSocietyToJSON(value?: AdminSociety | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'internal_identifier': value.internalIdentifier,
        'readable_name': value.readableName,
        'country': value.country,
        'website': value.website,
        'portal': value.portal,
        'phone': value.phone,
        'address': value.address,
        'additional_territories': value.additionalTerritories,
        'form_email': value.formEmail,
        'form_postal_address': value.formPostalAddress,
        'form_portal': value.formPortal,
        'form_reminder_frequency': value.formReminderFrequency,
        'form_stop_reminder_frequency': value.formStopReminderFrequency,
        'form_reminder_send_to': value.formReminderSendTo,
        'society_forms': ((value.societyForms as Array<any>).map(AdminSocietySocietyFormsInnerToJSON)),
    };
}

