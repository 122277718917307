import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import WModal from 'shared_components/components/WModal';
import WButton from 'shared_components/components/WForms/WButton/WButton';
import { useApi } from 'shared_components/context/providers';
import { WFormProvider } from 'shared_components/components/WForms/WFormProvider';
import DiscographyCheckboxList, {
  DiscographySocietyCheckbox,
} from './DiscographyCheckBoxList';
import { useWError } from 'shared_components/components/WError/WErrorProvider';
import { WError } from 'shared_components/components/WError/WError';
import ThankyouBlock from 'shared_components/components/ThankYouBlock/ThankyouBlock';
import { TermsOfAgreement } from 'shared_components/generated/admin';

interface SocietyDiscographySubmissionModalProps {
  isOpen: boolean;
  onClose: () => void;
  userId: string;
}

export const SocietyDiscographySubmissionModal = ({
  isOpen,
  onClose,
  userId,
}: SocietyDiscographySubmissionModalProps) => {
  const { adminApi: api } = useApi();
  const [societyOptions, setSocietyOptions] = useState<
    DiscographySocietyCheckbox[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { throwError } = useWError();
  const [successful, setSuccessful] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, [userId]);

  const fetchData = async () => {
    if (!userId) {
      throwError(new WError('User ID is required'));
    }
    setIsLoading(true);
    let termsOfAgreementData: TermsOfAgreement | undefined = undefined;
    try {
      termsOfAgreementData = await api.retrieveTermsOfAgreement({
        userId: userId,
      });
    } catch (error) {
      // don't throw error if terms of agreement is not found - user may not have created one yet
    }
    try {
      let societiesData = await api.listAdminSocieties();

      if (termsOfAgreementData && !termsOfAgreementData.universalMandate) {
        societiesData = societiesData.filter((society) => {
          return termsOfAgreementData?.mandatedSocieties?.includes(
            society.id ?? ''
          );
        });
      }
      setSocietyOptions(
        societiesData.map((society) => ({
          id: society.id!,
          label: society.readableName,
          isChecked: false,
        }))
      );
    } catch (error) {
      console.log(error);
      throwError(
        new WError(
          'Society Discography Generation Failed. Please try again later'
        )
      );
      onClose();
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelectAllChange = (checked: boolean) => {
    const updatedSocietyList = societyOptions.map((society) => ({
      ...society,
      isChecked: checked,
    }));
    setSocietyOptions(updatedSocietyList);
  };
  const handleIndividualCheckboxChange = (societyId: string) => {
    const updatedSocietyList = societyOptions.map((society) =>
      society.id === societyId
        ? { ...society, isChecked: !society.isChecked }
        : society
    );
    setSocietyOptions(updatedSocietyList);
  };

  const handleSubmit = async () => {
    setIsLoading(true);

    let societyIds = societyOptions
      .filter((society) => society.isChecked)
      .map((society) => society.id);
    if (societyIds.length === 0) {
      throwError(new WError('Please select at least one society'));
      setIsLoading(false);
      onClose();
      return;
    }
    return api
      .generateSecondaryDiscography({
        userId: userId,
        secondaryDiscography: { societyIds: societyIds },
      })
      .then(() => {
        setSuccessful(true);
      })
      .catch(async (error) => {
        const title = 'Society Discography Generation Failed';
        let message = 'Something went wrong';
        if (error.response.status === 400) {
          const data = await error.response.json();
          const childMessages = data.society_ids ?? [];
          if (childMessages.length) {
            message = childMessages[0];
          }
        }
        throwError(new WError(message, undefined, title));
        return Promise.reject();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onSuccess = () => {
    setSuccessful(true);
  };

  if (successful) {
    return (
      <WModal title="" isOpen={isOpen} onClose={onClose}>
        <ThankyouBlock
          title="Society Discography Generation In Progress"
          description="You can view progress of society discography generation on the artist’s Discography tab"
          cta={{
            ctaText: 'Go To Discography Page ',
            onClick: () => {
              navigate('/discography');
            },
          }}
        />
      </WModal>
    );
  }

  return (
    <WModal
      title="Society Discography Submission"
      isOpen={isOpen}
      onClose={onClose}
      modalClasses="tw-max-h-[95vh] tw-overflow-y-scroll"
    >
      <WFormProvider
        handleSubmit={handleSubmit}
        schema={undefined}
        onSuccess={onSuccess}
      >
        <div className="tw-flex tw-flex-row tw-justify-between">
          <div className="tw-pr-8">
            <p className="tw-font-light tw-pb-4">
              Choose the societies to receive the discography
            </p>
          </div>
          <WButton
            label="Generate Society Discographies"
            onClick={handleSubmit}
            variant="primary"
          />
        </div>

        <div className="tw-w-full tw-flex tw-flex-col">
          {isLoading ? (
            <div className="tw-flex tw-justify-center">
              <p className="tw-my-auto tw-animate-ping">⚪</p>
            </div>
          ) : (
            <DiscographyCheckboxList
              selectAllOnChange={handleSelectAllChange}
              societyList={societyOptions}
              onCheckboxChange={handleIndividualCheckboxChange}
            ></DiscographyCheckboxList>
          )}
        </div>
      </WFormProvider>
    </WModal>
  );
};
