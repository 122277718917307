import React, { useState, FormEvent, useEffect } from 'react';
import { useApi } from 'shared_components/context';
import { useUserId } from 'shared_components/context/user';
import { useNavigate } from 'react-router-dom';
import LoginPageLayout from 'shared_components/PageLayout/LoginPageLayout';
import WContainer from 'shared_components/components/WContainer';
import Logo from 'shared_components/public/assets/Logo.png';
import { WFormProvider } from 'shared_components/components/WForms/WFormProvider';
import { z } from 'zod';
import WModal from 'shared_components/components/WModal';
import { WInput } from 'shared_components/components/WForms/WInput/WInput';
import { useFormContext } from 'shared_components/components/WForms/WFormContext';
import WButton from 'shared_components/components/WForms/WButton/WButton';
import { adminLoginHandler } from 'shared_components/context/auth';
import { WPassword } from 'shared_components/components/WForms/WPassword/WPassword';
import { getPrevLocationFromSessionStorage } from 'shared_components/context/auth';
import { sendToast } from 'shared_components/utils/Toast';
import ErrorBlock from 'shared_components/components/ErrorBlock/ErrorBlock';

function AdminLogin() {
  let history = useNavigate();
  const { adminApi: api } = useApi();
  const { setUserId } = useUserId();
  const [formData, setFormData] = useState({
    identifier: '',
    password: '',
  });

  const [submitError, setSubmitError] = useState(false);

  const inputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    let response;
    try {
      response = await api.createSigninAuthToken({
        loginWithEmailorUsernameAuthToken: formData,
      });
      adminLoginHandler(response);

      setUserId(response.userId);
    } catch (error) {
      setSubmitError(true);
      return Promise.reject(error);
      return;
    }

    try {
      const userSettings = await api.retrieveUserSettings({
        userId: response.userId,
      });

      const defaultDashboard = {
        Membership: '/dashboard',
        Discography: '/discography-dashboard',
        Distribution: '/distribution-dashboard',
      }[userSettings.department ?? 'Membership'];

      history(defaultDashboard);
    } catch {
      history('/dashboard');
    }
  };

  const schema = z.object({
    identifier: z.string().email(),
    password: z
      .string()
      .refine((value) => value !== '', 'Password cannot be empty'),
  });
  const inputClass =
    'tw-h-[48px] tw-bg-surfaceBlack80 tw-p-3 tw-textSeal tw-mt-4 tw-placeholder-textSeal sm:tw-w-50';

  useEffect(() => {
    const prev = getPrevLocationFromSessionStorage();
    if (prev) {
      sendToast({
        text: 'Your session expired. Please login again and you will be redirected to your previous page.',
        variant: 'success',
        duration: 10000,
      });
    }
  }, []);

  return (
    <LoginPageLayout bgImage="signIn">
      <WContainer extraClasses="tw-w-[600px] sm:tw-w-50 ">
        <WModal
          title=""
          isOpen={submitError}
          onClose={() => {
            setSubmitError(false);
          }}
        >
          <ErrorBlock />
        </WModal>

        <div className="tw-flex tw-flex-col tw-justify-center tw-items-center">
          <img
            src={Logo}
            alt="Westbury Music Consultants"
            className="tw-w-[158px] tw-h-[56px] tw-mt-6"
          ></img>
          <div className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-mt-6">
            <h1 className="tw-font-sans tw-mb-4 tw-font-semibold tw-text-2xl tw-uppercase">
              Welcome Back!
            </h1>
            <p className="tw-mt-4 tw-mb-6 tw-font-light">
              Please enter your details to sign in
            </p>
          </div>

          <div className="tw-w-[536px]">
            {/* TODO: Have a superEdit state that isn't dependant on onSuccess/successMessage to stay open */}
            <WFormProvider
              editByDefault={true}
              handleSubmit={handleSubmit}
              schema={schema}
              onSuccess={() => {}}
              onSuccessMessage={<></>}
              formData={formData}
            >
              <WInput
                type="text"
                name="identifier"
                label="Email"
                value={formData.identifier}
                onChange={inputChange}
              />
              <WPassword
                name="password"
                label="Password"
                value={formData.password}
                onChange={inputChange}
              />
              <div className="tw-flex tw-justify-center">
                <FormFooter />
              </div>
            </WFormProvider>
          </div>
        </div>
      </WContainer>
    </LoginPageLayout>
  );
}
function FormFooter() {
  const { handleFormSubmit, formData } = useFormContext();
  return (
    <>
      <WButton
        label="Sign In"
        variant="primary-lg"
        onClick={() => handleFormSubmit(formData)}
        extraClasses="tw-mt-4 tw-w-[200px]"
      />
    </>
  );
}
export default AdminLogin;
