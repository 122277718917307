/* tslint:disable */
/* eslint-disable */
/**
 * Westbury Music API Admin
 * API for Westbury Music admin users
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FinancialYears
 */
export interface FinancialYears {
    /**
     * 
     * @type {Array<string>}
     * @memberof FinancialYears
     */
    fiscalYearsUnique: Array<string>;
}

/**
 * Check if a given object implements the FinancialYears interface.
 */
export function instanceOfFinancialYears(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "fiscalYearsUnique" in value;

    return isInstance;
}

export function FinancialYearsFromJSON(json: any): FinancialYears {
    return FinancialYearsFromJSONTyped(json, false);
}

export function FinancialYearsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FinancialYears {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fiscalYearsUnique': json['fiscal_years_unique'],
    };
}

export function FinancialYearsToJSON(value?: FinancialYears | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fiscal_years_unique': value.fiscalYearsUnique,
    };
}

