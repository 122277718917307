import React, { useState, useEffect } from "react";
import {
    Form,
    FormControl,

} from "react-bootstrap";
import Select from 'react-select';
import uploadedIcon from "shared_components/public/assets/uploadedFile.png";
import plusIcon from "shared_components/public/assets/plus-button.svg";
import minusIcon from "shared_components/public/assets/minus-button.svg";
import { WModal } from "shared_components/components/WModal/WModal";




function uploadCredit(props) {

  const handleUploadDistri = (e)=>{
    e.preventDefault();

  }
  const [creditOrEditDis, setcreditOrEditDis] = useState(null);
  const [enterManually, setEnterManually] = useState(false);

  const handlecreateOrEditChange = (event) => {
    setcreditOrEditDis(event.target.value);
  };


  const handleSubmitDistribution =(e)=>{

    e.preventDefault();
  }

  const societyDropList = ['One', 'Two', 'Three', 'Four'].map((societyDrop) => ({
      value: societyDrop,
      label: societyDrop
  }));

  const distributionDate = ['dd/mm/yy', 'dd/mm/yy', 'dd/mm/yy', 'dd/mm/yy'].map((societyDrop) => ({
    value: societyDrop,
    label: societyDrop
}));

const currencies = ['EUR', 'USD', 'GBP'].map((currenciesDrop) => ({
  value: currenciesDrop,
  label: currenciesDrop
}));


  const [selectedValue, setSelectedValue] = useState('combined_payment');

  const handleSwitchChange = (event) => {
    const checkedValue = event.target.value;

    if(checkedValue == "combined_payment"){
      setSelectedValue("individual_payment");

    }
    else{
      setSelectedValue("combined_payment");
    }
  };

  const [uploadedDistributionFile, setuploadedDistributionFile] = useState(null);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    setuploadedDistributionFile(file);
    console.log(file);
  };

  const deleteUploaded = ()=>{
    setuploadedDistributionFile(null);
  }
  // Simulating upload progress (replace with your actual upload logic)
  const [uploadPercentage, setUploadPercentage] = useState(0);

  
  const [uploadedInputForm, setuploadedInputForm] = useState([
    {
      currency: "",
      conversinRate: "",
      bankCharges: "",
      transactionAmount: "",
      dateReceived: "",
      amountReceived: "",
    },
  ]);

  const handletaxFormInfoReplicate = () => {
    const noOfForms = uploadedInputForm.length;
    console.log(uploadedInputForm);

    const newRole = {
      currency: "",
      conversinRate: "",
      bankCharges: "",
      transactionAmount: "",
      dateReceived: "",
      amountReceived: "",
    };

    const updatedFormsFields = [...uploadedInputForm, newRole];
    setuploadedInputForm(updatedFormsFields);

  };

  // deleteing newly created form
  const deleteCurentForm = (e) => {
    const formToDeleteId = e.target.getAttribute("data-delete");
    const idParts = formToDeleteId.split("-");
    const idNumber = parseInt(idParts[1]);
    const updatedArray = [...uploadedInputForm]; // make a copy of the array
    updatedArray.splice(idNumber, 1); // remove 1 element at index 1
    setuploadedInputForm(updatedArray);

  };
  console.log(uploadedInputForm);


   return( 
    <>
        <WModal
            onClose={props.onCloseBtn}
            isOpen={props.show}
        >
            <h3>Upload new credit advice</h3>
            <Form onSubmit={handleSubmitDistribution}>
                <div className="scInputFields scCheckFields fonmGroupControl align-items-center">
                    <label htmlFor="Edit_create_distribuition_credit">
                    Create or edit a distribution
                    </label>
                    <div className="FormRadioGroup">
                    <div
                        key="inline-radio-distribution"
                        className="mb-0 styledRadioButtons styledCheckboxButtons radioButtonsRound d-flex flex-nowrap"
                    >
                        <Form.Check
                        inline
                        label="Create distribuition"
                        type="radio"
                        value="create_distribuition_credit"
                        name="Edit_create_distribuition_credit"
                        id="create_distribuition_credit"
                        onChange={handlecreateOrEditChange}
                        className="mb-0 mt-2"

                        />
                        <Form.Check
                        inline
                        label="Edit distribuition"
                        type="radio"
                        value="edit_distribuition_credit"
                        name="Edit_create_distribuition_credit"
                        id="edit_distribuition_credit"
                        onChange={handlecreateOrEditChange}
                        className="mb-0 mt-2"
                        />
                    </div>
                    </div>
                </div>
                <div className="scInputFields fonmGroupControl SocietySelect mb-4 pb-1">
                    <label htmlFor="Society_select">Society</label>
                    <Select
                        options={societyDropList}
                        className="customSelect"
                        placeholder="Select society"
                        // defaultValue={selectedCountry}
                        styles={{

                        control: (provided) => ({
                            ...provided,
                            width: '222px',
                            borderColor:'#000!important',
                            height:'30px',
                            minHeight:'30px',
                            color:'#000'
                            }),
                        placeholder: (provided) => ({
                                ...provided,
                                color: '#000', // Set the desired color for the placeholder text
                            }),
                        option: (provided) => ({
                            ...provided,
                            borderBottom: '1px solid #000',
                            color: 'var(--color-black)',
                            fontSize: '1rem',
                            padding:'.51rem 10px',
                            backgroundColor: 'white!important',
                            cursor:'pointer',
                            width: '222px', // Set the desired width for the dropdown

                        }),
                        menu: (provided) => ({
                            ...provided,
                            maxHeight: '256px', // Set maximum height for the dropdown container
                            overflowY: 'hidden', // Enable vertical scrolling
                            width: '222px', // Set the desired width for the dropdown

                        }),
                        }}
                    />
                </div>
                {creditOrEditDis === 'edit_distribuition_credit' && (
                    <div className="scInputFields fonmGroupControl SocietySelect mb-4 pb-1">
                        <label htmlFor="Society_select">Distribution Date</label>
                        <Select
                            options={distributionDate}
                            className="customSelect"
                            placeholder="Please select"
                            // defaultValue={selectedCountry}
                            styles={{

                            control: (provided) => ({
                                ...provided,
                                width: '222px',
                                borderColor:'#000!important',
                                height:'30px',
                                minHeight:'30px',
                                color:'#000'
                                }),
                            placeholder: (provided) => ({
                                ...provided,
                                color: '#000', // Set the desired color for the placeholder text
                                }),
                            option: (provided) => ({
                            ...provided,
                            borderBottom: '1px solid #000',
                            color: 'var(--color-black)',
                            fontSize: '1rem',
                            padding:'.51rem 10px',
                            backgroundColor: 'white!important',
                            cursor:'pointer',
                            width: '222px', // Set the desired width for the dropdown

                            }),
                            menu: (provided) => ({
                            ...provided,
                            maxHeight: '256px', // Set maximum height for the dropdown container
                            overflowY: 'hidden', // Enable vertical scrolling
                            width: '222px', // Set the desired width for the dropdown

                            }),
                        }}
                        />
                    </div>
                )}
                <div className="scInputFields  scSwitch fonmGroupControl align-items-center">
                <h4>
                Enter the credit advice details
                </h4>
                <div className="FormRadioGroup">
                    <div
                    key="inline-radio-distribution"
                    className="mb-0 d-flex flex-nowrap"
                    >
                    <span className="switch-label">Combined Payment</span>
                    <Form.Check
                        inline
                        type="switch"
                        value={selectedValue}
                        name="paymentMode"
                        id="paymentMode"
                        onChange={handleSwitchChange}
                    />
                    <span className="switch-label">Individual Payment</span>
                    </div>
                </div>
                </div>
                <div className=" w-100 my-3 scLogoUpload d-block fonmGroupControl">
                    <div className="distributionFileUpload position-relative">
                        <h4>Upload the distribution file</h4>
                        <div className="d-flex justify-content-between">
                            <p className="fileTypeNote mb-0 pr-4">(.xls, .csv and .pdf are supported)</p>
                            {uploadedDistributionFile === null && (
                                <div className="manualLinkWrap">
                                    {enterManually ? (
                                        <a
                                            href="#" 
                                            className="enterDetailsManually m-0 position-absolute end-0"
                                            onClick={() => setEnterManually(false)}
                                        >
                                            Automatically enter the details
                                        </a>                                
                                    ): (
                                        <a
                                            href="#" 
                                            className="enterDetailsManually m-0 position-absolute end-0"
                                            onClick={() => setEnterManually(true)}
                                        >
                                            Manually enter the details
                                        </a>                                    
                                    )}
                                </div>
                            )}
                        </div>
                        {uploadedDistributionFile === null && (
                            <div>                                
                                <label htmlFor="DistributionFile_credit" className="distributionfileUploadLabel">
                                    <span>
                                    Drag and drop or click to upload
                                    </span>
                                </label>
                                <Form.Control
                                    type="file"
                                    name="DistributionFile_credit"
                                    className="formFileUpload"
                                    id="DistributionFile_credit"
                                    accept=".txt, .pdf, .docx" // Specify the file types you want to allow
                                    onDragOver={(e) => {
                                        e.preventDefault();
                                        // Add any visual indications for drag and drop here
                                    }}
                                    onDrop={(e) => {
                                        e.preventDefault();
                                        const file = e.dataTransfer.files[0];
                                    }}
                                    onChange={handleFileUpload}
                                />
                            </div>
                        )}

                        {uploadedDistributionFile != null && (
                            <div className="uploadedFile my-3">
                                <div className="d-flex align-items-end">
                                    <img src={uploadedIcon} alt="uploaded file icon" style={{'height':'70px'}}/>
                                    <div className="m-0 uploadedFileTilte ps-3">
                                        <p className="mb-0">
                                            {uploadedDistributionFile['name']}
                                        </p>
                                        <div className="uploadprcnt">
                                            <button type="button" className="btn btn-primary" onClick={deleteUploaded}>close</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                {(uploadedDistributionFile != null || enterManually) && (
                    uploadedInputForm.map((item, index) => (
                        <div className="d-flex fileUploadedInputs justify-content-between flex-wrap position-relative" key={index}>
                            <div className="mb-3 scInputFields fonmGroupControl">
                                <label htmlFor={`currency_${index}`}>Currency</label>
                                <Select
                                    options={currencies}
                                    className="customSelect"
                                    placeholder="Please select"
                                    styles={{

                                    control: (provided) => ({
                                        ...provided,
                                        width: '222px',
                                        borderColor:'#000!important',
                                        height:'30px',
                                        minHeight:'30px',
                                        color:'#000'
                                        }),
                                    placeholder: (provided) => ({
                                            ...provided,
                                            color: '#000', // Set the desired color for the placeholder text
                                        }),
                                    option: (provided) => ({
                                        ...provided,
                                        borderBottom: '1px solid #000',
                                        color: 'var(--color-black)',
                                        fontSize: '1rem',
                                        padding:'.51rem 10px',
                                        backgroundColor: 'white!important',
                                        cursor:'pointer',
                                        width: '222px', // Set the desired width for the dropdown
            
                                    }),
                                    menu: (provided) => ({
                                        ...provided,
                                        maxHeight: '256px', // Set maximum height for the dropdown container
                                        overflowY: 'hidden', // Enable vertical scrolling
                                        width: '222px', // Set the desired width for the dropdown
            
                                    }),
                                    }}
                                />
                            </div>
                            <div className="mb-3 scInputFields fonmGroupControl defaultInput">
                                <label htmlFor={`convrsion_rate_${index}`}>Conversion Rate</label>
                                <FormControl
                                    placeholder=""
                                    type="text"
                                    // value=""
                                    // onChange={handleInputChange}
                                    name={`convrsion_rate_${index}`}
                                />
                            </div>
                            <div className="mb-3 scInputFields fonmGroupControl defaultInput">
                                <label htmlFor={`bank_charges_${index}`}>Bank charges (GBP)</label>
                                <FormControl
                                    placeholder=""
                                    type="text"
                                    // value=""
                                    // onChange={handleInputChange}
                                    name={`bank_charges_${index}`}
                                />
                            </div>
                            <div className="mb-3 scInputFields fonmGroupControl defaultInput">
                                <label htmlFor={`transaction_amount_${index}`}>Transaction amount (USD$)</label>
                                <FormControl
                                    placeholder=""
                                    type="text"
                                    // value=""
                                    // onChange={handleInputChange}
                                    name={`transaction_amount_${index}`}
                                />
                            </div>
                            <div className="mb-3 scInputFields fonmGroupControl defaultInput">
                                <label htmlFor={`date_received_${index}`}>Date received</label>
                                <FormControl
                                    placeholder=""
                                    type="text"
                                    // value=""
                                    // onChange={handleInputChange}
                                    name={`date_received_${index}`}
                                />
                            </div>
                            <div className="mb-3 scInputFields fonmGroupControl defaultInput">
                                <label htmlFor={`amount_received_${index}`}>Amount received</label>
                                <FormControl
                                    placeholder=""
                                    type="text"
                                    // value=""
                                    // onChange={handleInputChange}
                                    name={`amount_received_${index}`}
                                />
                            </div>
                            <div className="addformNew addformNew d-flex justify-content-between align-self-end">
                                <div onClick={handletaxFormInfoReplicate}>
                                    <img
                                    src={plusIcon}
                                    className="pointer-none"
                                    title="Add New Form"
                                    />
                                </div>
                            
                                {index !== 0 && (
                                    <div onClick={deleteCurentForm} data-delete={`formId-${index}`} >
                                        <img
                                        src={minusIcon}
                                        className="pointer-none"
                                        title="Remove Form"
                                        className="removeNewForm"
                                        data-delete={`formId-${index}`}
                                        />
                                    </div>
                                )}
                            </div>

                        </div>
                    ))
                )}
            </Form>
            <button className="x-button bg-grad w-100 d-block ms-auto justify-content-center mt-1" onClick={handleUploadDistri}>Upload</button>
            </WModal>
        </>
   )
}

export default uploadCredit;
