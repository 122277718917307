/* tslint:disable */
/* eslint-disable */
/**
 * Westbury Music API Admin
 * API for Westbury Music admin users
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PipelineRunStatus
 */
export interface PipelineRunStatus {
    /**
     * 
     * @type {string}
     * @memberof PipelineRunStatus
     */
    runId: string | null;
    /**
     * 
     * @type {string}
     * @memberof PipelineRunStatus
     */
    status: PipelineRunStatusStatusEnum;
}


/**
 * @export
 */
export const PipelineRunStatusStatusEnum = {
    InProgress: 'IN_PROGRESS',
    Caching: 'CACHING',
    Failed: 'FAILED',
    Succeeded: 'SUCCEEDED',
    Unknown: 'UNKNOWN'
} as const;
export type PipelineRunStatusStatusEnum = typeof PipelineRunStatusStatusEnum[keyof typeof PipelineRunStatusStatusEnum];


/**
 * Check if a given object implements the PipelineRunStatus interface.
 */
export function instanceOfPipelineRunStatus(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "runId" in value;
    isInstance = isInstance && "status" in value;

    return isInstance;
}

export function PipelineRunStatusFromJSON(json: any): PipelineRunStatus {
    return PipelineRunStatusFromJSONTyped(json, false);
}

export function PipelineRunStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): PipelineRunStatus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'runId': json['run_id'],
        'status': json['status'],
    };
}

export function PipelineRunStatusToJSON(value?: PipelineRunStatus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'run_id': value.runId,
        'status': value.status,
    };
}

