import React, { useState } from 'react';
import WContainer from '../WContainer/WContainer';
import clsx from 'clsx';
interface AccordionProps {
  title: string | React.ReactNode;
  extraClasses?: string;
  children: React.ReactNode | React.ReactNode[] | string;
  isOpen?: boolean;
  hasTitle?: boolean;
  disabled?: boolean;
}

export const WAccordion: React.FC<AccordionProps> = ({
  title,
  children,
  extraClasses = '',
  isOpen = false,
  hasTitle = true,
  disabled = false,
}) => {
  const [isOpenInner, setIsOpenInner] = useState(isOpen);

  return (
    <WContainer extraClasses={extraClasses}>
      {hasTitle && (
        <span
          className={clsx(
            'tw-w-full',
            'tw-text-left',
            'tw-text-xl',
            'tw-cursor-pointer',
            'tw-block',
            'tw-bg-surfaceBlack90',
            'tw-flex',
            'tw-justify-between',
            'tw-items-center',
            disabled
              ? 'tw-text-textSeal tw-cursor-not-allowed'
              : 'tw-text-textWhite',
            'tw-rounded-lg',
            {
              'tw-pb-8': isOpenInner,
              // Add other conditional classes here if needed
            }
          )}
          onClick={() => !disabled && setIsOpenInner(!isOpenInner)}
          disabled={disabled}
        >
          {title}
          <i
            className={`wi tw-w-[40px] tw-h-[40px] wi-chevron-${
              isOpenInner ? 'up' : 'down'
            }`}
          />
        </span>
      )}
      <div
        className={`tw-overflow-hidden
        tw-transition-all
        tw-duration-300
        tw-ease-in-out
        ${isOpenInner ? '' : 'tw-max-h-0'}`}
      >
        <div className="tw-w-full tw-bg-tw-surfaceBlack90 tw-rounded-b-lg">
          {children}
        </div>
      </div>
    </WContainer>
  );
};
