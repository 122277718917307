import React, { useState, useEffect } from 'react';
import WTable from 'shared_components/WTable/WTable';
import WContainer from 'shared_components/components/WContainer';
import { WError } from 'shared_components/components/WError/WError';
import { useWError } from 'shared_components/components/WError/WErrorProvider';
import WButton from 'shared_components/components/WForms/WButton/WButton';
import { useFormContext } from 'shared_components/components/WForms/WFormContext';
import { WFormProvider } from 'shared_components/components/WForms/WFormProvider';
import { WInput } from 'shared_components/components/WForms/WInput/WInput';
import { useApi } from 'shared_components/context';
import { ListSecondaryDiscographies } from 'shared_components/generated/admin';

type SocietyDiscographyTableProps = {
  clientId?: string;
};

export const SocietyDiscographyTable = ({
  clientId,
}: SocietyDiscographyTableProps) => {
  const [secondaryDiscographyRuns, setSecondaryDiscographyRuns] = useState<
    Array<ListSecondaryDiscographies>
  >([]);
  const { adminApi: api } = useApi();
  const { throwError } = useWError();
  const [tableData, setTableData] = useState<Array<any>>([]);

  const fetchData = async () => {
    api
      .listListSecondaryDiscographies({ userId: clientId! })
      .then((res) => setSecondaryDiscographyRuns(res))
      .catch(() => {
        throwError(new WError('Failed to fetch secondary discography data'));
      });
  };

  useEffect(() => {
    fetchData();
  }, [clientId]);

  if (!clientId) {
    return null;
  }

  const columnStyle = {
    fontSize: '14px',
    fontWeight: 400,
    borderBottom: '1px solid #AAB2B9',
  };
  const rowStyle = {
    fontSize: '14px',
    fontWeight: 300,
    borderBottom: '1px solid #535D66',
  };
  const columns = [
    {
      title: 'Society',
      width: '1fr',
      dataKey: 'societyName',
    },
    {
      title: 'Territory',
      width: '1fr',
      dataKey: 'country',
    },
    {
      title: 'Status',
      width: '1fr',
      dataKey: 'status',
    },
    {
      title: 'File',
      width: '1fr',
      dataKey: 'url',
    },
    {
      title: 'Date Generated',
      width: '1fr',
      dataKey: 'dateCreated',
    },
    {
      title: 'Date Sent to Society',
      width: '2fr',
      dataKey: 'dateSentToSociety',
    },
  ];

  const statusMap: { [key: string]: string } = {
    'SecondaryDiscographyStatus.SUCCEEDED': 'Succeeded',
    'SecondaryDiscographyStatus.FAILED': 'Failed',
    'SecondaryDiscographyStatus.IN_PROGRESS': 'Pending',
  };

  useEffect(() => {
    console.log(secondaryDiscographyRuns);
    const tableData = secondaryDiscographyRuns.map((data, index) => {
      return {
        societyName: data.societyName,
        country: data.country,
        status: statusMap[data.status],
        url: buildLink(data.s3Url, data.societyName),
        dateCreated: new Date(data.dateCreated).toLocaleDateString(),
        dateSentToSociety: (
          <SentToSocietyDate
            date={
              data.dateSentToSociety
                ? new Date(data.dateSentToSociety).toLocaleDateString()
                : null
            }
            isEditing={false}
            handleSubmit={(newDate) => {
              const dateObject = new Date(newDate);
              updateSentToSocietyDate(data.id, dateObject);
            }}
          />
        ),
      };
    });
    setTableData(tableData);
  }, [secondaryDiscographyRuns]);

  const updateSentToSocietyDate = (exportId: string, date: Date) => {
    api
      .updateUpdateSecondaryDiscography({
        exportId,
        updateSecondaryDiscography: {
          dateSentToSociety: date,
        },
      })
      .catch(() => {
        throwError(new WError('Error updating date sent to society'));
      });
  };

  const buildLink = (s3Url: string, society: string) => {
    return (
      <a
        className={
          'tw-font-medium tw-underline-offset-2 tw-underline tw-truncate'
        }
        href={s3Url}
      >
        {s3Url && `${society}.csv`}
      </a>
    );
  };

  return (
    <WTable
      columns={columns}
      data={tableData}
      columnStyle={columnStyle}
      rowStyle={secondaryDiscographyRuns.map(() => rowStyle)}
    ></WTable>
  );
};

interface EditButtonProps {
  onSave: () => void;
}

const EditButton = ({ onSave }: EditButtonProps) => {
  const { isEditing, setIsEditing } = useFormContext();
  return (
    <>
      {!isEditing ? (
        <WButton
          label=""
          icon="edit"
          variant="secondary"
          onClick={() => setIsEditing(!isEditing)}
        />
      ) : (
        <WButton
          label="Save"
          variant="primary"
          onClick={() => {
            setIsEditing(!isEditing);
            onSave();
          }}
        />
      )}
    </>
  );
};

interface SentToSocietyDateProps {
  date: string | null;
  isEditing: boolean;
  handleSubmit: (newDate: string) => void;
}

const SentToSocietyDate = ({
  date,
  isEditing,
  handleSubmit,
}: SentToSocietyDateProps) => {
  const [newDate, setNewDate] = useState(date || '');

  return (
    <div>
      <WFormProvider handleSubmit={() => {}} schema={undefined}>
        <div className="tw-flex tw-flex-row tw-items-center">
          <div className="tw-mr-2 tw-mt-8 tw-min-w-[120px]">
            <WInput
              type="date"
              name="dateSentToSociety"
              label=""
              value={newDate}
              onChange={(e) => setNewDate(e.target.value)}
              onBlur={() => {}}
              isFieldEditing={isEditing}
            />
          </div>
          <EditButton onSave={() => handleSubmit(newDate)} />
        </div>
      </WFormProvider>
    </div>
  );
};
