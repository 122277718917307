/* tslint:disable */
/* eslint-disable */
/**
 * Westbury Music API Admin
 * API for Westbury Music admin users
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SocietyFormRoleFormsInner } from './SocietyFormRoleFormsInner';
import {
    SocietyFormRoleFormsInnerFromJSON,
    SocietyFormRoleFormsInnerFromJSONTyped,
    SocietyFormRoleFormsInnerToJSON,
} from './SocietyFormRoleFormsInner';

/**
 * 
 * @export
 * @interface SocietyFormRole
 */
export interface SocietyFormRole {
    /**
     * 
     * @type {string}
     * @memberof SocietyFormRole
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof SocietyFormRole
     */
    society?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SocietyFormRole
     */
    role: SocietyFormRoleRoleEnum;
    /**
     * 
     * @type {Array<SocietyFormRoleFormsInner>}
     * @memberof SocietyFormRole
     */
    forms?: Array<SocietyFormRoleFormsInner>;
}


/**
 * @export
 */
export const SocietyFormRoleRoleEnum = {
    PerformerProducer: 'Performer/Producer'
} as const;
export type SocietyFormRoleRoleEnum = typeof SocietyFormRoleRoleEnum[keyof typeof SocietyFormRoleRoleEnum];


/**
 * Check if a given object implements the SocietyFormRole interface.
 */
export function instanceOfSocietyFormRole(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "role" in value;

    return isInstance;
}

export function SocietyFormRoleFromJSON(json: any): SocietyFormRole {
    return SocietyFormRoleFromJSONTyped(json, false);
}

export function SocietyFormRoleFromJSONTyped(json: any, ignoreDiscriminator: boolean): SocietyFormRole {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'society': !exists(json, 'society') ? undefined : json['society'],
        'role': json['role'],
        'forms': !exists(json, 'forms') ? undefined : ((json['forms'] as Array<any>).map(SocietyFormRoleFormsInnerFromJSON)),
    };
}

export function SocietyFormRoleToJSON(value?: SocietyFormRole | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'society': value.society,
        'role': value.role,
        'forms': value.forms === undefined ? undefined : ((value.forms as Array<any>).map(SocietyFormRoleFormsInnerToJSON)),
    };
}

