/* tslint:disable */
/* eslint-disable */
/**
 * Westbury Music API Admin
 * API for Westbury Music admin users
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PandadocDocumentIdsDocumentsInner } from './PandadocDocumentIdsDocumentsInner';
import {
    PandadocDocumentIdsDocumentsInnerFromJSON,
    PandadocDocumentIdsDocumentsInnerFromJSONTyped,
    PandadocDocumentIdsDocumentsInnerToJSON,
} from './PandadocDocumentIdsDocumentsInner';

/**
 * 
 * @export
 * @interface PandadocDocumentIds
 */
export interface PandadocDocumentIds {
    /**
     * 
     * @type {Array<PandadocDocumentIdsDocumentsInner>}
     * @memberof PandadocDocumentIds
     */
    documents?: Array<PandadocDocumentIdsDocumentsInner>;
    /**
     * 
     * @type {string}
     * @memberof PandadocDocumentIds
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof PandadocDocumentIds
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof PandadocDocumentIds
     */
    societyFormSubroleId?: string;
    /**
     * 
     * @type {string}
     * @memberof PandadocDocumentIds
     */
    adminSocietyFormTaskId?: string;
}

/**
 * Check if a given object implements the PandadocDocumentIds interface.
 */
export function instanceOfPandadocDocumentIds(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PandadocDocumentIdsFromJSON(json: any): PandadocDocumentIds {
    return PandadocDocumentIdsFromJSONTyped(json, false);
}

export function PandadocDocumentIdsFromJSONTyped(json: any, ignoreDiscriminator: boolean): PandadocDocumentIds {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'documents': !exists(json, 'documents') ? undefined : ((json['documents'] as Array<any>).map(PandadocDocumentIdsDocumentsInnerFromJSON)),
        'email': !exists(json, 'email') ? undefined : json['email'],
        'userId': !exists(json, 'user_id') ? undefined : json['user_id'],
        'societyFormSubroleId': !exists(json, 'society_form_subrole_id') ? undefined : json['society_form_subrole_id'],
        'adminSocietyFormTaskId': !exists(json, 'admin_society_form_task_id') ? undefined : json['admin_society_form_task_id'],
    };
}

export function PandadocDocumentIdsToJSON(value?: PandadocDocumentIds | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'documents': value.documents === undefined ? undefined : ((value.documents as Array<any>).map(PandadocDocumentIdsDocumentsInnerToJSON)),
        'email': value.email,
        'user_id': value.userId,
        'society_form_subrole_id': value.societyFormSubroleId,
        'admin_society_form_task_id': value.adminSocietyFormTaskId,
    };
}

