/* tslint:disable */
/* eslint-disable */
/**
 * Westbury Music API Admin
 * API for Westbury Music admin users
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TermsOfAgreementRepresentativeFee } from './TermsOfAgreementRepresentativeFee';
import {
    TermsOfAgreementRepresentativeFeeFromJSON,
    TermsOfAgreementRepresentativeFeeFromJSONTyped,
    TermsOfAgreementRepresentativeFeeToJSON,
} from './TermsOfAgreementRepresentativeFee';
import type { TermsOfAgreementSlidingScalesInner } from './TermsOfAgreementSlidingScalesInner';
import {
    TermsOfAgreementSlidingScalesInnerFromJSON,
    TermsOfAgreementSlidingScalesInnerFromJSONTyped,
    TermsOfAgreementSlidingScalesInnerToJSON,
} from './TermsOfAgreementSlidingScalesInner';
import type { TermsOfAgreementSocietyCommissionRatesInner } from './TermsOfAgreementSocietyCommissionRatesInner';
import {
    TermsOfAgreementSocietyCommissionRatesInnerFromJSON,
    TermsOfAgreementSocietyCommissionRatesInnerFromJSONTyped,
    TermsOfAgreementSocietyCommissionRatesInnerToJSON,
} from './TermsOfAgreementSocietyCommissionRatesInner';

/**
 * 
 * @export
 * @interface TermsOfAgreement
 */
export interface TermsOfAgreement {
    /**
     * 
     * @type {string}
     * @memberof TermsOfAgreement
     */
    readonly id?: string;
    /**
     * 
     * @type {Array<TermsOfAgreementSlidingScalesInner>}
     * @memberof TermsOfAgreement
     */
    slidingScales?: Array<TermsOfAgreementSlidingScalesInner>;
    /**
     * 
     * @type {TermsOfAgreementRepresentativeFee}
     * @memberof TermsOfAgreement
     */
    representativeFee?: TermsOfAgreementRepresentativeFee | null;
    /**
     * 
     * @type {Array<TermsOfAgreementSocietyCommissionRatesInner>}
     * @memberof TermsOfAgreement
     */
    societyCommissionRates?: Array<TermsOfAgreementSocietyCommissionRatesInner>;
    /**
     * 
     * @type {Date}
     * @memberof TermsOfAgreement
     */
    readonly dateCreated?: Date;
    /**
     * 
     * @type {Date}
     * @memberof TermsOfAgreement
     */
    readonly dateUpdated?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof TermsOfAgreement
     */
    standardAgreement?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TermsOfAgreement
     */
    commissionRate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TermsOfAgreement
     */
    minimumPayment?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TermsOfAgreement
     */
    universalSocietyCommissionRate?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TermsOfAgreement
     */
    performerOrProducer?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TermsOfAgreement
     */
    masterRights?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TermsOfAgreement
     */
    universalMandate?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TermsOfAgreement
     */
    slidingScaleRequired?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TermsOfAgreement
     */
    slidingScaleBasedOn?: TermsOfAgreementSlidingScaleBasedOnEnum;
    /**
     * 
     * @type {string}
     * @memberof TermsOfAgreement
     */
    bankPaymentMethod?: TermsOfAgreementBankPaymentMethodEnum;
    /**
     * 
     * @type {boolean}
     * @memberof TermsOfAgreement
     */
    representativeFeeRequired?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TermsOfAgreement
     */
    clientRequiresAdvance?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TermsOfAgreement
     */
    advanceAmount?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TermsOfAgreement
     */
    user: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof TermsOfAgreement
     */
    mandatedSocieties?: Array<string>;
}


/**
 * @export
 */
export const TermsOfAgreementSlidingScaleBasedOnEnum = {
    NetIncome: 'net_income',
    GrossIncome: 'gross_income'
} as const;
export type TermsOfAgreementSlidingScaleBasedOnEnum = typeof TermsOfAgreementSlidingScaleBasedOnEnum[keyof typeof TermsOfAgreementSlidingScaleBasedOnEnum];

/**
 * @export
 */
export const TermsOfAgreementBankPaymentMethodEnum = {
    BankTransfer: 'bank_transfer',
    Cheque: 'cheque'
} as const;
export type TermsOfAgreementBankPaymentMethodEnum = typeof TermsOfAgreementBankPaymentMethodEnum[keyof typeof TermsOfAgreementBankPaymentMethodEnum];


/**
 * Check if a given object implements the TermsOfAgreement interface.
 */
export function instanceOfTermsOfAgreement(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "user" in value;

    return isInstance;
}

export function TermsOfAgreementFromJSON(json: any): TermsOfAgreement {
    return TermsOfAgreementFromJSONTyped(json, false);
}

export function TermsOfAgreementFromJSONTyped(json: any, ignoreDiscriminator: boolean): TermsOfAgreement {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'slidingScales': !exists(json, 'sliding_scales') ? undefined : ((json['sliding_scales'] as Array<any>).map(TermsOfAgreementSlidingScalesInnerFromJSON)),
        'representativeFee': !exists(json, 'representative_fee') ? undefined : TermsOfAgreementRepresentativeFeeFromJSON(json['representative_fee']),
        'societyCommissionRates': !exists(json, 'society_commission_rates') ? undefined : ((json['society_commission_rates'] as Array<any>).map(TermsOfAgreementSocietyCommissionRatesInnerFromJSON)),
        'dateCreated': !exists(json, 'date_created') ? undefined : (new Date(json['date_created'])),
        'dateUpdated': !exists(json, 'date_updated') ? undefined : (new Date(json['date_updated'])),
        'standardAgreement': !exists(json, 'standard_agreement') ? undefined : json['standard_agreement'],
        'commissionRate': !exists(json, 'commission_rate') ? undefined : json['commission_rate'],
        'minimumPayment': !exists(json, 'minimum_payment') ? undefined : json['minimum_payment'],
        'universalSocietyCommissionRate': !exists(json, 'universal_society_commission_rate') ? undefined : json['universal_society_commission_rate'],
        'performerOrProducer': !exists(json, 'performer_or_producer') ? undefined : json['performer_or_producer'],
        'masterRights': !exists(json, 'master_rights') ? undefined : json['master_rights'],
        'universalMandate': !exists(json, 'universal_mandate') ? undefined : json['universal_mandate'],
        'slidingScaleRequired': !exists(json, 'sliding_scale_required') ? undefined : json['sliding_scale_required'],
        'slidingScaleBasedOn': !exists(json, 'sliding_scale_based_on') ? undefined : json['sliding_scale_based_on'],
        'bankPaymentMethod': !exists(json, 'bank_payment_method') ? undefined : json['bank_payment_method'],
        'representativeFeeRequired': !exists(json, 'representative_fee_required') ? undefined : json['representative_fee_required'],
        'clientRequiresAdvance': !exists(json, 'client_requires_advance') ? undefined : json['client_requires_advance'],
        'advanceAmount': !exists(json, 'advance_amount') ? undefined : json['advance_amount'],
        'user': json['user'],
        'mandatedSocieties': !exists(json, 'mandated_societies') ? undefined : json['mandated_societies'],
    };
}

export function TermsOfAgreementToJSON(value?: TermsOfAgreement | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sliding_scales': value.slidingScales === undefined ? undefined : ((value.slidingScales as Array<any>).map(TermsOfAgreementSlidingScalesInnerToJSON)),
        'representative_fee': TermsOfAgreementRepresentativeFeeToJSON(value.representativeFee),
        'society_commission_rates': value.societyCommissionRates === undefined ? undefined : ((value.societyCommissionRates as Array<any>).map(TermsOfAgreementSocietyCommissionRatesInnerToJSON)),
        'standard_agreement': value.standardAgreement,
        'commission_rate': value.commissionRate,
        'minimum_payment': value.minimumPayment,
        'universal_society_commission_rate': value.universalSocietyCommissionRate,
        'performer_or_producer': value.performerOrProducer,
        'master_rights': value.masterRights,
        'universal_mandate': value.universalMandate,
        'sliding_scale_required': value.slidingScaleRequired,
        'sliding_scale_based_on': value.slidingScaleBasedOn,
        'bank_payment_method': value.bankPaymentMethod,
        'representative_fee_required': value.representativeFeeRequired,
        'client_requires_advance': value.clientRequiresAdvance,
        'advance_amount': value.advanceAmount,
        'user': value.user,
        'mandated_societies': value.mandatedSocieties,
    };
}

