import React, { createContext, useContext, useState, useEffect } from 'react';

type ClientContextType = {
  clientId?: string;
  setClientId: (id: string) => void;
  clientEmail?: string;
  setClientEmail: (email: string) => void;
};

const ClientContext = createContext<ClientContextType>({
  clientId: undefined,
  setClientId: () => {},
  clientEmail: '',
  setClientEmail: () => {},
});

type ClientProviderProps = {
  children: React.ReactNode;
  initialClientId?: string;
  initialClientEmail?: string;
};

export const ClientProvider = ({
  children,
  initialClientId,
  initialClientEmail,
}: ClientProviderProps) => {
  const [clientId, setClientId] = useState(
    () => localStorage.getItem('clientId') || initialClientId
  );

  const [clientEmail, setClientEmail] = useState(
    () => localStorage.getItem('clientEmail') || initialClientEmail
  );

  useEffect(() => {
    if (!clientId) {
      localStorage.removeItem('clientId');
      return;
    }
    localStorage.setItem('clientId', clientId);
  }, [clientId]);

  useEffect(() => {
    if (!clientEmail) {
      localStorage.removeItem('clientEmail');
      return;
    }
    localStorage.setItem('clientEmail', clientEmail);
  }, [clientEmail]);

  const contextValue = { clientId, setClientId, clientEmail, setClientEmail };

  return (
    <ClientContext.Provider value={contextValue}>
      {children}
    </ClientContext.Provider>
  );
};

export const useClientId = () => {
  const { clientId, setClientId, clientEmail, setClientEmail } =
    useContext(ClientContext);
  if (!setClientId) {
    throw new Error('useClientId must be used within a ClientProvider');
  }
  return { clientId, setClientId, clientEmail, setClientEmail };
};
