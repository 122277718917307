const Constants = {
  MAX_FILE_SIZE: 5300000, // 5 MB, used for any sort of file upload
  MAX_DOCUMENT_SIZE: 26215000, // 25 MB, used for any sort of document upload
  // alternatives
  MAX_FILE_SIZE_IN_MB: 5,
  MAX_FILE_SIZE_IN_KB: 5000,

  // image file types
  IMAGE_FILE_TYPES: ['image/jpeg', 'image/png', 'image/jpg'],

  // document file types
  DOCUMENT_FILE_TYPES: ['application/pdf'],
};

export default Constants;
