import React from 'react';
import { Form } from 'react-bootstrap';
import { handleInputChange } from '../../SocietyDetails';
import { WRadioButton } from 'shared_components/components/WForms/WRadioButton/WRadioButton';

interface FormTaxFormsSegmentProps {
  isEditingSociety: boolean;
  differentTaxForm: string;
  setDifferentTaxForm: (newState: string) => void;
  formRoles: Array<{}>;
  setFormRoles: (newState: Array<{}>) => void;
}

export const FormTaxFormsSegment = ({
  isEditingSociety,
  differentTaxForm,
  setDifferentTaxForm,
  formRoles,
  setFormRoles,
}: FormTaxFormsSegmentProps) => {
  return (
    <>
      <WRadioButton
        label="Are Different Tax Forms Needed?"
        name="different_tax_form"
        selectedValue={differentTaxForm}
        items={[
          { value: 'different_tax_form_Yes', label: 'Yes' },
          { value: 'different_tax_form_No', label: 'No' },
        ]}
        onChange={(e) => {
          setDifferentTaxForm(e.target.value);
        }}
      />
    </>
  );
};
