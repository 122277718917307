/* tslint:disable */
/* eslint-disable */
/**
 * Westbury Music API
 * API for Westbury Music
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PandadocDocumentIdsDocumentsInner
 */
export interface PandadocDocumentIdsDocumentsInner {
    /**
     * 
     * @type {string}
     * @memberof PandadocDocumentIdsDocumentsInner
     */
    documentId: string;
    /**
     * 
     * @type {string}
     * @memberof PandadocDocumentIdsDocumentsInner
     */
    documentPandadocUuid: string;
    /**
     * 
     * @type {string}
     * @memberof PandadocDocumentIdsDocumentsInner
     */
    documentName: string;
    /**
     * 
     * @type {string}
     * @memberof PandadocDocumentIdsDocumentsInner
     */
    sessionId: string;
    /**
     * 
     * @type {string}
     * @memberof PandadocDocumentIdsDocumentsInner
     */
    pandadocDocumentId: string;
}

/**
 * Check if a given object implements the PandadocDocumentIdsDocumentsInner interface.
 */
export function instanceOfPandadocDocumentIdsDocumentsInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "documentId" in value;
    isInstance = isInstance && "documentPandadocUuid" in value;
    isInstance = isInstance && "documentName" in value;
    isInstance = isInstance && "sessionId" in value;
    isInstance = isInstance && "pandadocDocumentId" in value;

    return isInstance;
}

export function PandadocDocumentIdsDocumentsInnerFromJSON(json: any): PandadocDocumentIdsDocumentsInner {
    return PandadocDocumentIdsDocumentsInnerFromJSONTyped(json, false);
}

export function PandadocDocumentIdsDocumentsInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): PandadocDocumentIdsDocumentsInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'documentId': json['document_id'],
        'documentPandadocUuid': json['document_pandadoc_uuid'],
        'documentName': json['document_name'],
        'sessionId': json['session_id'],
        'pandadocDocumentId': json['pandadoc_document_id'],
    };
}

export function PandadocDocumentIdsDocumentsInnerToJSON(value?: PandadocDocumentIdsDocumentsInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'document_id': value.documentId,
        'document_pandadoc_uuid': value.documentPandadocUuid,
        'document_name': value.documentName,
        'session_id': value.sessionId,
        'pandadoc_document_id': value.pandadocDocumentId,
    };
}

