import React, { ChangeEvent } from 'react';
import { useFormContext } from '../WFormContext'; // Make sure to import your useFormContext correctly
import clsx from 'clsx';
interface InputFieldProps {
  name: string;
  value: string | undefined;
  label: string;
  hideOnView?: boolean; // Optional prop to hide the input field when not editing
  onBlur?: (value: ChangeEvent<HTMLInputElement>) => void;
  onChange: (value: ChangeEvent<HTMLInputElement>) => void;
  icon?: string;
  disabled?: boolean;
}

export const WPassword: React.FC<InputFieldProps> = ({
  name,
  value,
  label,
  hideOnView = false,
  icon,
  onBlur,
  onChange,
  disabled = false,
}) => {
  const { isEditing, isFormSubmitted, errors } = useFormContext(); // Fetch errors from context

  const [type, setType] = React.useState('password');
  const [focused, setFocused] = React.useState(false);
  const handleFocus = () => setFocused(true);
  const handleBlur = () => setFocused(false);

  const onIconClick = () => {
    if (type === 'password') {
      setType('text');
    }
    if (type === 'text') {
      setType('password');
    }
  };

  return (
    <>
      {(isEditing || !hideOnView) && (
        <div className="tw-mb-4">
          <label
            htmlFor={name}
            className={clsx(
              'tw-flex tw-space-x-1 tw-justify-start tw-block tw-leading-4 tw-text-white tw-mb-2 tw-capitalize',
              {
                'tw-text-[14px]': !isEditing,
              }
            )}
          >
            {label}
          </label>
          {isEditing && (
            <div className="tw-relative">
              <input
                type={type}
                name={name}
                id={name}
                placeholder={label}
                value={value}
                onChange={(event) => onChange(event)}
                onFocus={handleFocus}
                onBlur={() => {
                  handleBlur();
                }}
                className={clsx(
                  'tw-bg-formInputBackground',
                  'tw-text-gray-400',
                  'tw-rounded-lg',
                  'tw-w-full',
                  'tw-h-12',
                  'tw-placeholder-textSeal',
                  'tw-appearance-none',
                  'tw-leading-5',
                  'tw-p-3',
                  'tw-mb-2',
                  'tw-border-2',
                  { 'tw-border-formInputBackground': !errors[name] },
                  'focus:tw-border-white',
                  'focus:tw-text-white',
                  'focus:tw-outline-none',
                  { 'tw-border-red-500': isEditing && errors[name] },
                  { 'tw-font-extraLight': disabled },
                  { 'tw-cursor-not-allowed': disabled }
                )}
              />

              <span
                onClick={onIconClick}
                className="tw-absolute tw-top-1 tw-right-0 tw-h-10 tw-w-10 tw-flex tw-justify-center tw-items-center tw-cursor-pointer"
              >
                {type === 'password' && (
                  <i tabIndex={0} className="wi wi-20px wi-shut-eye" />
                )}
                {type === 'text' && (
                  <i tabIndex={0} className="wi wi-20px wi-eye" />
                )}
              </span>
            </div>
          )}
          {!isEditing && (
            <span className={`tw-text-textDove`}>● ● ● ● ● ●</span>
          )}
          {errors[name] && (
            <div className="tw-text-red-500 tw-text-xs">{errors[name]}</div>
          )}{' '}
        </div>
      )}
    </>
  );
};
