/* tslint:disable */
/* eslint-disable */
/**
 * Westbury Music API
 * API for Westbury Music
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PrimaryDiscographyRecordingsInnerParentRecording
 */
export interface PrimaryDiscographyRecordingsInnerParentRecording {
    /**
     * 
     * @type {string}
     * @memberof PrimaryDiscographyRecordingsInnerParentRecording
     */
    id: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PrimaryDiscographyRecordingsInnerParentRecording
     */
    recordingTitle: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PrimaryDiscographyRecordingsInnerParentRecording
     */
    duration?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PrimaryDiscographyRecordingsInnerParentRecording
     */
    featuredArtists?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PrimaryDiscographyRecordingsInnerParentRecording
     */
    nonFeaturedArtists?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PrimaryDiscographyRecordingsInnerParentRecording
     */
    mainArtists?: Array<string>;
    /**
     * 
     * @type {Array<number>}
     * @memberof PrimaryDiscographyRecordingsInnerParentRecording
     */
    numFeaturedArtists: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof PrimaryDiscographyRecordingsInnerParentRecording
     */
    numNonFeaturedArtists: Array<number>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PrimaryDiscographyRecordingsInnerParentRecording
     */
    isrcs?: Array<string>;
    /**
     * 
     * @type {Array<boolean>}
     * @memberof PrimaryDiscographyRecordingsInnerParentRecording
     */
    live?: Array<boolean>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PrimaryDiscographyRecordingsInnerParentRecording
     */
    countryOfRecording?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PrimaryDiscographyRecordingsInnerParentRecording
     */
    source: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PrimaryDiscographyRecordingsInnerParentRecording
     */
    evidenceAnnotatedUrls?: Array<string>;
}

/**
 * Check if a given object implements the PrimaryDiscographyRecordingsInnerParentRecording interface.
 */
export function instanceOfPrimaryDiscographyRecordingsInnerParentRecording(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "recordingTitle" in value;
    isInstance = isInstance && "numFeaturedArtists" in value;
    isInstance = isInstance && "numNonFeaturedArtists" in value;
    isInstance = isInstance && "source" in value;

    return isInstance;
}

export function PrimaryDiscographyRecordingsInnerParentRecordingFromJSON(json: any): PrimaryDiscographyRecordingsInnerParentRecording {
    return PrimaryDiscographyRecordingsInnerParentRecordingFromJSONTyped(json, false);
}

export function PrimaryDiscographyRecordingsInnerParentRecordingFromJSONTyped(json: any, ignoreDiscriminator: boolean): PrimaryDiscographyRecordingsInnerParentRecording {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'recordingTitle': json['recordingTitle'],
        'duration': !exists(json, 'duration') ? undefined : json['duration'],
        'featuredArtists': !exists(json, 'featuredArtists') ? undefined : json['featuredArtists'],
        'nonFeaturedArtists': !exists(json, 'nonFeaturedArtists') ? undefined : json['nonFeaturedArtists'],
        'mainArtists': !exists(json, 'mainArtists') ? undefined : json['mainArtists'],
        'numFeaturedArtists': json['numFeaturedArtists'],
        'numNonFeaturedArtists': json['numNonFeaturedArtists'],
        'isrcs': !exists(json, 'isrcs') ? undefined : json['isrcs'],
        'live': !exists(json, 'live') ? undefined : json['live'],
        'countryOfRecording': !exists(json, 'countryOfRecording') ? undefined : json['countryOfRecording'],
        'source': json['source'],
        'evidenceAnnotatedUrls': !exists(json, 'evidence_annotated_urls') ? undefined : json['evidence_annotated_urls'],
    };
}

export function PrimaryDiscographyRecordingsInnerParentRecordingToJSON(value?: PrimaryDiscographyRecordingsInnerParentRecording | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'recordingTitle': value.recordingTitle,
        'duration': value.duration,
        'featuredArtists': value.featuredArtists,
        'nonFeaturedArtists': value.nonFeaturedArtists,
        'mainArtists': value.mainArtists,
        'numFeaturedArtists': value.numFeaturedArtists,
        'numNonFeaturedArtists': value.numNonFeaturedArtists,
        'isrcs': value.isrcs,
        'live': value.live,
        'countryOfRecording': value.countryOfRecording,
        'source': value.source,
        'evidence_annotated_urls': value.evidenceAnnotatedUrls,
    };
}

