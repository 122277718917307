import React, { ReactNode } from 'react';
import Footer from '../../client_portal/src/view/Client/PageLayout/Footer/Footer';
import clsx from 'clsx';
/*
    A component that wraps the page content and provides the sidebar, header, and footer.
    Can be used to wrap any page content.
*/

interface LoginPageLayoutProps {
  children: ReactNode;
  bgImage?: 'signIn' | undefined;
}

function LoginPageLayout({
  children,
  bgImage = undefined,
}: LoginPageLayoutProps) {
  return (
    <div className={clsx('tw-h-screen', 'tw-flex', 'tw-flex-col')}>
      {/* Main content */}
      <div
        className={clsx(
          'tw-flex',
          'tw-grow',
          'tw-items-center',
          'tw-flex-col',
          'tw-py-[60px]',
          'tw-justify-center',
          { 'westbury-bg-login': bgImage === 'signIn' },
          { 'tw-bg-surfacePurple': bgImage === undefined }
        )}
        // style={{ minHeight: '1080px' }}
      >
        {/* Main content area */}
        <main
          className="tw-flex tw-justify-center tw-items-center  tw-w-screen tw-pl-6"
          style={{ maxWidth: '1580px' }}
        >
          {/* 1080px is taken from Figma */}
          {children}
        </main>
      </div>

      {/* Footer */}
      <div className="tw-bg-surfaceBlack90">
        <Footer />
      </div>
    </div>
  );
}

export default LoginPageLayout;
