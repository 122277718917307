import React from 'react';
import AgreementModalPage from './AgreementModalPage';
import AgreementModalIFrame from './AgreementModalIFrame';

interface PandadocPageProps {
  index: number;
  sessionId: string | null | undefined;
  selectedPageIndex: number;
  isLoading: boolean;
  iFrameStyle?: React.CSSProperties;
}

function PandadocPage({
  index,
  sessionId,
  selectedPageIndex,
  isLoading,
  iFrameStyle,
}: PandadocPageProps) {
  return (
    <AgreementModalPage
      key={index}
      pageIndex={index + 1}
      selectedPageIndex={selectedPageIndex}
      loading={isLoading}
    >
      <div>
        {sessionId ? (
          <AgreementModalIFrame
            documentId={sessionId}
            title={`pandadocs_iframe_${index}`}
            style={iFrameStyle ?? undefined}
          />
        ) : (
          ''
        )}
      </div>
    </AgreementModalPage>
  );
}

export default PandadocPage;
