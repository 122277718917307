import React, { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'shared_components/components/WForms/WFormContext';
import clsx from 'clsx';
import { Constants } from 'shared_components/utils/Countries';
import z from 'zod';
interface WImageUploadProps {
  onFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label: string;
  subTitle: string;
  value: string;
  name: string;
}

const WImageUpload: React.FC<WImageUploadProps> = ({
  label,
  subTitle,
  onFileChange,
  value,
  name,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInnerError(null);
    if (!event.target.files) return;
    const file = event.target.files[0];

    const data = {
      image: file,
    };

    // In order to not display the previewed image, we need to prevent the default behavior of the input if the file is not valid
    const imageUploadSchema = z
      .object({
        image: z.any(),
      })
      .refine(
        (data) => {
          if (data.image !== undefined) {
            return (
              data.image.size <= Constants.MAX_FILE_SIZE &&
              Constants.IMAGE_FILE_TYPES.includes(data.image.type)
            );
          }
        },
        {
          path: ['image'],
          message:
            'Image should be a valid jpg, jpeg or png and less than 5MB.',
        }
      );

    const result = imageUploadSchema.safeParse(data);
    if (result.success === false) {
      const error = result.error;
      // get the first error message
      const errorMessage = error.issues[0].message;
      // set the error message in the local state
      setInnerError(errorMessage);
      return;
    }

    setInnerValue(file);
    onFileChange(event);
  };

  const handleClick = () => {
    fileInputRef.current?.click();
  };

  const [innerValue, setInnerValue] = useState<File | null>(null);
  const { isEditing, isFormSubmitted, errors, validateForm } = useFormContext(); // Fetch errors from context
  const [innerError, setInnerError] = useState<string | null>();

  useEffect(() => {
    setInnerError(errors[name]);
  }, [errors, name]);

  // whenever the value changes, reset the inner value and error
  // otherwise component state is kept between different values
  useEffect(() => {
    setInnerValue(null);
    setInnerError(null);
  }, [value]);

  return (
    <>
      {!isEditing && (
        <img
          className="tw-w-[120px] tw-h-[120px] tw-mb-6 tw-bg-textGraphite tw-rounded-full"
          src={value ? value : '/images/default-avatar.png'}
          alt="Profile image"
        />
      )}
      {isEditing && (
        <div
          className={clsx({
            'tw-relative tw-border-dashed tw-border-2 tw-border-textGraphite tw-rounded tw-p-6 tw-cursor-pointer tw-mb-4':
              !innerValue,
          })}
          onClick={handleClick}
        >
          {innerValue && (
            <div className="tw-relative tw-w-[185px] tw-h-[185px]">
              <div
                onClick={() => {
                  fileInputRef.current?.click(); // The optional chaining is used to ensure that the current is not null
                }}
                className="tw-cursor-pointer tw-w-11 tw-h-11 tw-p-2.5 tw-bg-surfaceLime tw-rounded-[360px] tw-justify-center tw-items-center tw-inline-flex tw-absolute tw-bottom-0 tw-right-0"
              >
                <i
                  className="wi wi-20px wi-edit"
                  style={{ backgroundColor: 'black' }}
                />
              </div>
              <img
                className="tw-w-[185px] tw-h-[185px] tw-mb-4 tw-bg-textGraphite tw-rounded-full"
                src={URL.createObjectURL(innerValue)}
                alt="Profile image"
              />
            </div>
          )}
          {!innerValue && (
            <>
              <div className="tw-text-center tw-font-light">
                <p className="">{label}</p>
                <p>{subTitle}</p>
              </div>
              <div className="tw-mb-4 tw-flex tw-justify-center">
                <i className="wi wi-lg wi-export" />
              </div>
            </>
          )}

          <input
            name={name}
            ref={fileInputRef}
            type="file"
            className="tw-hidden"
            onChange={handleFileChange}
          />
          {/* Error section */}
          {innerError && (
            <div className="tw-absolute tw-bottom-0 tw-left-0 tw-right-0 tw-p-2 tw-bg-red-500 tw-text-white tw-text-center">
              {innerError}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default WImageUpload;
