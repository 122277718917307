import jwt_decode from 'jwt-decode';
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
} from 'react';
import { PortalApi as ClientPortalApi } from '../generated/client';
import {
  PortalApi as AdminPortalApi,
  UserSettingsDepartmentEnum,
} from '../generated/admin';
import { set } from 'lodash';

type UserContextType = {
  userId?: string;
  setUserId: (id: string) => void;
  firstName?: string | null;
  setFirstName: (name?: string | null) => void;
  lastName?: string | null;
  setLastName: (name?: string | null) => void;
  initials: string;
  imageUrl?: string;
  setImageUrl: (url?: string) => void;
  department?: UserSettingsDepartmentEnum;
  setDepartment: (department?: UserSettingsDepartmentEnum) => void;
  profileProgress?: number;
  setProfileProgress: (progress: number) => void;
  defaultDashboardPath: string;
  verifiedStatus: boolean;
  signedStatus: boolean;
  pplAccountName?: string | null;
  fetchData: () => void;
};

type UserProviderProps = {
  children: React.ReactNode;
  api: ClientPortalApi | AdminPortalApi;
};

type DecodedToken = {
  token_type: 'access';
  exp: number;
  iat: number;
  jti: string;
  user_id: string;
};

type DepartmentDashboardMap = {
  [DEPARTMENT in UserSettingsDepartmentEnum]: string;
};

const departmentDashboardMap: DepartmentDashboardMap = {
  Membership: '/dashboard',
  Discography: '/discography-dashboard',
  Distribution: '/distribution-dashboard',
};

const UserContext = createContext<UserContextType>({
  userId: undefined,
  setUserId: () => {},
  firstName: undefined,
  setFirstName: () => {},
  lastName: undefined,
  setLastName: () => {},
  initials: '',
  imageUrl: undefined,
  setImageUrl: () => {},
  department: undefined,
  setDepartment: () => {},
  profileProgress: 1,
  setProfileProgress: () => {},
  defaultDashboardPath: departmentDashboardMap.Membership,
  verifiedStatus: false,
  signedStatus: false,
  pplAccountName: undefined,

  fetchData: () => {},
});

export const UserProvider = ({ children, api }: UserProviderProps) => {
  const [userId, setUserId] = useState<string | undefined>();
  const [firstName, setFirstName] = useState<string | null | undefined>();
  const [lastName, setLastName] = useState<string | null | undefined>();
  const [imageUrl, setImageUrl] = useState<string | undefined>();

  const [department, setDepartment] = useState<
    UserSettingsDepartmentEnum | undefined
  >();
  const [profileProgress, setProfileProgress] = useState<number>(0);
  const [verifiedStatus, setVerifiedStatus] = useState<boolean>(false);
  const [signedStatus, setSignedStatus] = useState<boolean>(false);
  const [pplAccountName, setPplAccountName] = useState<
    string | null | undefined
  >();

  const initials = useMemo<string>(() => {
    const firstInitial = firstName?.at(0) ?? '';
    const lastInitial = lastName?.at(0) ?? '';
    return firstInitial.concat(lastInitial);
  }, [firstName, lastName]);

  const defaultDashboardPath = useMemo<string>(() => {
    return departmentDashboardMap[department ?? 'Membership'];
  }, [department]);

  const getSessionUserId = () => {
    const tokenRaw = sessionStorage.getItem('token');
    if (tokenRaw === null) {
      setUserId(undefined);
      return;
    }
    const token = JSON.parse(tokenRaw);
    const decodedToken: DecodedToken = jwt_decode(token.access);
    setUserId(decodedToken.user_id);
  };

  useEffect(() => {
    getSessionUserId();
  }, []);

  const fetchData = () => {
    if (userId === undefined) {
      return;
    }
    api
      .retrieveUserSettings({ userId })
      .then((userSettings) => {
        setFirstName(userSettings.firstName);
        setLastName(userSettings.lastName);
        setImageUrl(userSettings.userPhotoUrl);
        setDepartment(userSettings.department);
        setVerifiedStatus(userSettings.verifiedStatus ?? false);
        setSignedStatus(userSettings.signedStatus ?? false);
        setPplAccountName(userSettings.pplAccountName);
      })
      .catch(() => {});

    api.retrieveOnboardingSteps({ userId }).then((response) => {
      const stepNum =
        response.onboardingSteps
          .toSorted((a, b) => b.order - a.order)
          .find((step) => step.stepCompleted)?.order ?? 1;

      setProfileProgress(stepNum);
    });
  };

  useEffect(() => {
    fetchData();
  }, [userId]);

  return (
    <UserContext.Provider
      value={{
        userId,
        setUserId,
        firstName,
        setFirstName,
        lastName,
        setLastName,
        initials,
        imageUrl,
        setImageUrl,
        department,
        setDepartment,
        profileProgress,
        setProfileProgress,
        defaultDashboardPath,
        verifiedStatus,
        signedStatus,
        pplAccountName,
        fetchData,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUserId = () => {
  const { userId, setUserId } = useContext(UserContext);
  if (!setUserId) {
    throw new Error('useUserId must be used within a UserProvider');
  }
  return { userId, setUserId };
};

export const useUser = () => {
  const {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    initials,
    imageUrl,
    setImageUrl,
    department,
    setDepartment,
    profileProgress,
    setProfileProgress,
    defaultDashboardPath,
    signedStatus,
    verifiedStatus,
    pplAccountName,
    fetchData,
  } = useContext(UserContext);
  if (!setImageUrl) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    initials,
    imageUrl,
    setImageUrl,
    department,
    setDepartment,
    profileProgress,
    setProfileProgress,
    defaultDashboardPath,
    verifiedStatus,
    signedStatus,
    pplAccountName,
    fetchData,
  };
};
