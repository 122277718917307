/* tslint:disable */
/* eslint-disable */
/**
 * Westbury Music API Admin
 * API for Westbury Music admin users
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateSecondaryDiscography
 */
export interface UpdateSecondaryDiscography {
    /**
     * 
     * @type {Date}
     * @memberof UpdateSecondaryDiscography
     */
    dateSentToSociety: Date;
}

/**
 * Check if a given object implements the UpdateSecondaryDiscography interface.
 */
export function instanceOfUpdateSecondaryDiscography(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "dateSentToSociety" in value;

    return isInstance;
}

export function UpdateSecondaryDiscographyFromJSON(json: any): UpdateSecondaryDiscography {
    return UpdateSecondaryDiscographyFromJSONTyped(json, false);
}

export function UpdateSecondaryDiscographyFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateSecondaryDiscography {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dateSentToSociety': (new Date(json['date_sent_to_society'])),
    };
}

export function UpdateSecondaryDiscographyToJSON(value?: UpdateSecondaryDiscography | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'date_sent_to_society': (value.dateSentToSociety.toISOString()),
    };
}

