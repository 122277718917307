/* tslint:disable */
/* eslint-disable */
/**
 * Westbury Music API Admin
 * API for Westbury Music admin users
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PrimaryDiscographyRecordingsInnerParentReleasesInner
 */
export interface PrimaryDiscographyRecordingsInnerParentReleasesInner {
    /**
     * 
     * @type {string}
     * @memberof PrimaryDiscographyRecordingsInnerParentReleasesInner
     */
    id: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PrimaryDiscographyRecordingsInnerParentReleasesInner
     */
    sourceReleaseId: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PrimaryDiscographyRecordingsInnerParentReleasesInner
     */
    dateReleased?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PrimaryDiscographyRecordingsInnerParentReleasesInner
     */
    releaseTitle?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PrimaryDiscographyRecordingsInnerParentReleasesInner
     */
    releaseArtists?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PrimaryDiscographyRecordingsInnerParentReleasesInner
     */
    mediaType?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PrimaryDiscographyRecordingsInnerParentReleasesInner
     */
    format?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PrimaryDiscographyRecordingsInnerParentReleasesInner
     */
    catNumbers?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PrimaryDiscographyRecordingsInnerParentReleasesInner
     */
    releaseLabels?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PrimaryDiscographyRecordingsInnerParentReleasesInner
     */
    ean?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PrimaryDiscographyRecordingsInnerParentReleasesInner
     */
    upc?: Array<string>;
}

/**
 * Check if a given object implements the PrimaryDiscographyRecordingsInnerParentReleasesInner interface.
 */
export function instanceOfPrimaryDiscographyRecordingsInnerParentReleasesInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "sourceReleaseId" in value;

    return isInstance;
}

export function PrimaryDiscographyRecordingsInnerParentReleasesInnerFromJSON(json: any): PrimaryDiscographyRecordingsInnerParentReleasesInner {
    return PrimaryDiscographyRecordingsInnerParentReleasesInnerFromJSONTyped(json, false);
}

export function PrimaryDiscographyRecordingsInnerParentReleasesInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): PrimaryDiscographyRecordingsInnerParentReleasesInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'sourceReleaseId': json['sourceReleaseId'],
        'dateReleased': !exists(json, 'dateReleased') ? undefined : json['dateReleased'],
        'releaseTitle': !exists(json, 'releaseTitle') ? undefined : json['releaseTitle'],
        'releaseArtists': !exists(json, 'releaseArtists') ? undefined : json['releaseArtists'],
        'mediaType': !exists(json, 'mediaType') ? undefined : json['mediaType'],
        'format': !exists(json, 'format') ? undefined : json['format'],
        'catNumbers': !exists(json, 'catNumbers') ? undefined : json['catNumbers'],
        'releaseLabels': !exists(json, 'releaseLabels') ? undefined : json['releaseLabels'],
        'ean': !exists(json, 'ean') ? undefined : json['ean'],
        'upc': !exists(json, 'upc') ? undefined : json['upc'],
    };
}

export function PrimaryDiscographyRecordingsInnerParentReleasesInnerToJSON(value?: PrimaryDiscographyRecordingsInnerParentReleasesInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'sourceReleaseId': value.sourceReleaseId,
        'dateReleased': value.dateReleased,
        'releaseTitle': value.releaseTitle,
        'releaseArtists': value.releaseArtists,
        'mediaType': value.mediaType,
        'format': value.format,
        'catNumbers': value.catNumbers,
        'releaseLabels': value.releaseLabels,
        'ean': value.ean,
        'upc': value.upc,
    };
}

