import React, { useState, useEffect } from 'react';
import { Popover } from 'react-bootstrap';
import { useApi } from 'shared_components/context';
import { AdminSociety } from 'shared_components/generated/admin';
import SocietyInfoDetails, { societyFormValue } from './SocietyInfoDetails';
import OnboardingMembershipDetails from './OnboardingMembershipDetails';
import ContactDetails from './ContactDetails';
import WAccordion from 'shared_components/components/WAccordion';
import { WFormProvider } from 'shared_components/components/WForms/WFormProvider';
import { useFormContext } from 'shared_components/components/WForms/WFormContext';
import { WFormFooter } from 'shared_components/components/WForms/WFormFooter';
import WButton from 'shared_components/components/WForms/WButton/WButton';
import WModal from 'shared_components/components/WModal';
import { sendToast } from 'shared_components/utils/Toast';
import { useWError } from 'shared_components/components/WError/WErrorProvider';
import { WError } from 'shared_components/components/WError/WError';
interface SocietyDetailsProps {
  societySelected?: AdminSociety;
  setSocietySelected: (newSociety?: AdminSociety) => void;
  addNewSocietyCallback: (id: string) => void;
  isEditingSociety: boolean;
  setIsEditingSociety: (newState: boolean) => void;
}

export const SocietyDetails = ({
  societySelected,
  setSocietySelected,
  addNewSocietyCallback,
  isEditingSociety,
  setIsEditingSociety,
}: SocietyDetailsProps) => {
  const { adminApi: api } = useApi();
  const [societyInfoDetails, setSocietyInfoDetails] =
    useState<societyFormValue>();
  const [onboardingMembershipDetails, setOnboardingMembershipDetails] =
    useState<{}>();
  const [distributionDetails, setDistributionDetails] = useState<{}>();
  const [discographyDetails, setDiscographyDetails] = useState<{}>();
  const [contactDetails, setContactDetails] = useState<{}>();
  const [societyDetails, setSocietyDetails] = useState<societyFormValue>();
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
  const { throwError } = useWError();

  const saveData = async () => {
    let req; // determine whether to create or update
    const isSocietyCreate =
      societyInfoDetails && societyInfoDetails.id === undefined;

    // debugger;
    // filter out empty additional territories and keep just the ids if is checked
    const additionalTerritoriesChecked = societyDetails?.additionalTerritories
      ?.filter((territory) => territory.checked === true)
      .map((territory) => territory.id);

    if (additionalTerritoriesChecked) {
      societyDetails.additionalTerritories = additionalTerritoriesChecked;
    }

    if (isSocietyCreate) {
      req = api.createAdminCreateSociety(
        {
          ...societyDetails,
        }
        // TODO: use => societyDetails
      );
    } else {
      req = api.updateAdminUpdateSociety(
        {
          ...societyDetails,
          societyId: societyInfoDetails.id,
        }
        // TODO: use => societyDetails
      );
    }

    return req
      .then((response) => {
        setIsEditingSociety(false);
        addNewSocietyCallback(response.id);
        setShowSuccessModal(true);
      })
      .catch((error) => {
        throwError(new WError('Error saving society details'));
        return Promise.reject(error);
      });
  };

  const addNewSociety = () => {
    setSocietySelected(undefined);
    setSocietyInfoDetails(undefined);
    setOnboardingMembershipDetails(undefined);
    setDistributionDetails(undefined);
    setDiscographyDetails(undefined);
    setContactDetails(undefined);
  };

  useEffect(() => {
    setSocietyDetails({
      ...societyInfoDetails,
      ...onboardingMembershipDetails,
      // ...distributionDetails,
      // ...discographyDetails,
      ...contactDetails,
    });
  }, [
    societyInfoDetails,
    onboardingMembershipDetails,
    // distributionDetails,
    // discographyDetails,
    contactDetails,
  ]);

  const formSchema = undefined;

  return (
    <>
      <WFormProvider
        handleSubmit={saveData}
        schema={formSchema}
        formData={societyDetails}
        onSuccessMessage={null}
        submitOnEnter={false} // the form is too complex to submit on enter, which can happen by accident
        onSuccess={() => {
          sendToast({
            text: 'Society details saved.',
            variant: 'success',
            duration: 10000,
          });
        }}
      >
        <div
          className="tw-flex tw-justify-end tw-text-white tw-space-x-4"
          style={{ marginTop: '-32px', height: '32px' }}
        >
          <AddNewSocietyButton
            addNewSociety={addNewSociety}
            setIsEditingSociety={setIsEditingSociety}
          />
        </div>
        {(isEditingSociety || societySelected) && (
          <>
            <SocietyInfoDetails
              societySelected={societySelected}
              setSocietyInfoDetails={setSocietyInfoDetails}
              setIsEditingSociety={setIsEditingSociety}
            />
            <WAccordion
              title={`Onboarding and Memberships`}
              extraClasses={`tw-max-w-[650px]`}
            >
              <OnboardingMembershipDetails
                societySelected={societySelected}
                isEditingSociety={isEditingSociety}
                setOnboardingMembershipDetails={setOnboardingMembershipDetails}
              />
            </WAccordion>
            <WAccordion title={`Discography`} disabled>
              <></>
              {/* <DiscographyDetails
                  societySelected={societySelected}
                  isEditingSociety={isEditingSociety}
                  setDiscographyDetails={setDiscographyDetails}
                /> */}
            </WAccordion>
            <WAccordion title={`Distribution`} disabled>
              <></>
              {/* <DistributionDetails
                  societySelected={societySelected}
                  isEditingSociety={isEditingSociety}
                  setDistributionDetails={setDistributionDetails}
                /> */}
            </WAccordion>
            <WAccordion
              extraClasses="tw-max-w-[650px]"
              title={`Contact information`}
            >
              <ContactDetails
                societySelected={societySelected}
                isEditingSociety={isEditingSociety}
                setContactDetails={setContactDetails}
              />
            </WAccordion>
            <WFormFooter setIsEditingGlobal={setIsEditingSociety} />
          </>
        )}
      </WFormProvider>
    </>
  );
};

export const handleInputChange = (
  event: React.ChangeEvent<any>,
  setterCallback: (value: any) => void
) => {
  const eventValue = event.target.value;
  setterCallback(eventValue);
};

export const popover = (text: string): React.ReactNode => {
  return (
    <Popover id="popover-basic">
      <Popover.Body>{text}</Popover.Body>
    </Popover>
  );
};

function FormEditSection({ setIsEditingSociety }) {
  const { isEditing, setIsEditing, errors } = useFormContext(); // Fetch errors from context
  return (
    <>
      {' '}
      {!isEditing && (
        <button
          onClick={() => {
            setIsEditing(true);
            setIsEditingSociety(true);
          }}
        >
          Edit
        </button>
      )}
    </>
  );
}

function AddNewSocietyButton({ addNewSociety, setIsEditingSociety }) {
  const { isEditing, setIsEditing, errors } = useFormContext(); // Fetch errors from context
  return (
    <>
      {!isEditing && (
        <WButton
          variant="secondary-lg"
          onClick={() => {
            addNewSociety();
            setIsEditingSociety(true);
            setIsEditing(true);
          }}
          icon="plus"
          label="Add New Society"
        />
      )}
    </>
  );
}
