import React, { useState, useEffect } from 'react';
import { useFormContext } from '../WFormContext';
import clsx from 'clsx';
import WCheckBox from 'shared_components/components/WCheckBox';

interface WCheckBoxGroupProps {
  name: string;
  label: string;
  options: { value: string; label: string }[];
  defaultCheckedValues: string[];
  onChange: (values: { oldValues: string[]; newValues: string[] }) => void;
  grid?: string;
  checkboxSize?: 'sm' | 'md' | 'xs';
}

export const WCheckBoxGroup: React.FC<WCheckBoxGroupProps> = ({
  name,
  label,
  options,
  defaultCheckedValues,
  onChange,
  grid = '', // Optional prop to set the grid layout of the checkboxes
  checkboxSize = 'md',
}) => {
  const { isEditing, errors } = useFormContext();

  const [checkedValues, setCheckedValues] =
    useState<string[]>(defaultCheckedValues);

  useEffect(() => {
    setCheckedValues(defaultCheckedValues);
  }, [defaultCheckedValues]);

  const handleCheckboxChange = (value: string, isChecked: boolean) => {
    const updatedCheckedValues = isChecked
      ? [...checkedValues, value]
      : checkedValues.filter((v) => v !== value);

    // Update the state
    setCheckedValues(updatedCheckedValues);

    // Call onChange with both old and new values
    onChange({
      oldValues: checkedValues,
      newValues: updatedCheckedValues,
    });
  };

  return (
    <div className="tw-flex tw-flex-col tw-mb-4 tw-cursor-pointer">
      {label && (
        <label className="tw-block tw-leading-4 tw-text-white tw-mb-8">
          {label}
        </label>
      )}
      <div
        className={clsx(
          { 'tw-flex tw-flex-row tw-space-x-2': grid === '' },
          { 'tw-grid': grid != '' },
          grid && grid !== '' && grid, // This will apply the grid classes if grid is not empty
          {
            'tw-pointer-events-none': !isEditing,
          }
        )}
      >
        {options.map((option, index) => (
          <WCheckBox
            key={index}
            name={`${name}_${option.value}`}
            label={option.label}
            checked={defaultCheckedValues.includes(option.value)}
            onChange={(e) =>
              handleCheckboxChange(option.value, e.target.checked)
            }
            checkboxSize={checkboxSize}
            extraClasses="tw-mb-2"
          />
        ))}
      </div>
      {errors[name] && (
        <div className="tw-text-red-500 tw-text-xs tw-mt-4">{errors[name]}</div>
      )}
    </div>
  );
};
