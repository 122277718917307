import React, { ChangeEvent } from 'react';
import clsx from 'clsx';
import { useFormContext } from 'shared_components/components/WForms/WFormContext';

interface RadioButtonProps {
  name: string;
  label: string;
  selectedValue: string | boolean | undefined;
  items: { value: string | boolean; label: string }[];
  onChange: (value: ChangeEvent<HTMLInputElement>) => void;
}

export const WRadioButton: React.FC<RadioButtonProps> = ({
  name,
  label,
  selectedValue,
  items,
  onChange,
}) => {
  // if form is not editable, disable interactions with the radio buttons
  const { isEditing } = useFormContext();

  let selectedItem = items.find((item) => item.value === selectedValue);
  return (
    <div className="tw-flex tw-flex-col tw-mb-4">
      <fieldset>
        <legend className="tw-text-textWhite tw-text-base tw-leading-4 tw-mb-4">
          {label}
        </legend>
        {!isEditing && (
          <div className={`tw-text-textDove `}>
            {selectedItem?.label ? selectedItem.label : '-'}
          </div>
        )}
        {isEditing && (
          <div className="tw-flex tw-gap-4">
            {items.map((item) => (
              <label
                key={item.value}
                className={clsx(
                  'tw-flex tw-items-center tw-cursor-pointer tw-capitalize  tw-mr-12',
                  selectedValue === item.value
                    ? 'tw-text-surfaceLime'
                    : 'tw-text-textDove'
                )}
              >
                <input
                  type="radio"
                  name={name}
                  value={item.value.toString()}
                  checked={selectedValue === item.value}
                  onChange={(e) => onChange(e)}
                  className="tw-hidden"
                  disabled={!isEditing}
                />
                <div
                  className={clsx(
                    'tw-w-9 tw-h-9 tw-mr-2 tw-border-2 tw-rounded-full',
                    selectedValue === item.value
                      ? 'tw-border-surfaceLime'
                      : 'tw-border-textDove'
                  )}
                >
                  {selectedValue === item.value && (
                    <div
                      className={clsx(
                        'tw-w-5 tw-h-5 tw-m-[6px]  tw-rounded-full',
                        'tw-bg-surfaceLime'
                      )}
                    ></div>
                  )}
                </div>
                {item.label}
              </label>
            ))}
          </div>
        )}
      </fieldset>
    </div>
  );
};
