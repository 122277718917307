import React from 'react';
import DashboardDetails from './DashboardDetails';
import PageLayout from './PageLayout';

import { ClientProvider } from 'shared_components/context/client';

function Dashboard() {
  return (
    <PageLayout>
      <ClientProvider>
        <DashboardDetails />
      </ClientProvider>
    </PageLayout>
  );
}

export default Dashboard;
