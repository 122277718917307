/* tslint:disable */
/* eslint-disable */
/**
 * Westbury Music API
 * API for Westbury Music
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ArtistDetails
 */
export interface ArtistDetails {
    /**
     * 
     * @type {string}
     * @memberof ArtistDetails
     */
    id: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ArtistDetails
     */
    allNames: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ArtistDetails
     */
    stageName: Array<string>;
}

/**
 * Check if a given object implements the ArtistDetails interface.
 */
export function instanceOfArtistDetails(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "allNames" in value;
    isInstance = isInstance && "stageName" in value;

    return isInstance;
}

export function ArtistDetailsFromJSON(json: any): ArtistDetails {
    return ArtistDetailsFromJSONTyped(json, false);
}

export function ArtistDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ArtistDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'allNames': json['allNames'],
        'stageName': json['stageName'],
    };
}

export function ArtistDetailsToJSON(value?: ArtistDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'allNames': value.allNames,
        'stageName': value.stageName,
    };
}

