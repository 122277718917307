import React, { useState } from 'react';
import { AdminSociety } from 'shared_components/generated/admin';
import SquarePlaceholder100 from 'shared_components/public/assets/SquarePlaceholder100.svg';
import clsx from 'clsx';
// the society interface is used in the SocietiesListComponent, the component doesn't do anything except render the list of societies
export interface SocietiesListProps {
  societies: AdminSociety[];
  societySelected?: AdminSociety;
  isEditingSociety: boolean;
  societyClickHandler: (society: AdminSociety) => void;
}

export const SocietiesList: React.FC<SocietiesListProps> = ({
  societies,
  societySelected,
  isEditingSociety,
  societyClickHandler,
}) => {
  const [societiesSearch, setSocietiesSearch] = useState<string>('');
  // Convert the search string to a regex pattern for flexible matching.
  const searchRegex = new RegExp(societiesSearch, 'i');

  return (
    <div className="tw-mb-6">
      Search
      <div className="tw-relative tw-mt-2">
        <i
          className={`wi wi-20px wi-search tw-absolute tw-left-3 tw-top-[14px]`}
        ></i>

        <input
          className={clsx(
            'tw-bg-formInputBackground',
            'tw-text-gray-400',
            'tw-rounded-lg',
            'tw-w-full',
            'tw-h-12',
            'tw-placeholder-textSeal',
            'tw-text-sm',
            'tw-leading-5',
            'tw-p-3',
            'tw-pl-9',
            'tw-mb-2',
            'tw-border-2',
            'tw-border-formInputBackground',
            'focus:tw-border-white',
            'focus:tw-text-white',
            'focus:tw-outline-none'
          )}
          placeholder="Search societies..."
          value={societiesSearch}
          onChange={(e) => setSocietiesSearch(e.target.value)}
        />
      </div>
      <ul className="tw-mt-4 tw-max-h-[850px] tw-overflow-y-auto tw-scrollbar-thin tw-scrollbar-thumb-textDove tw-scrollbar-track-surfaceBlack90 tw-scrollbar-thumb-rounded-full">
        {societies
          .filter((society) => searchRegex.test(society.readableName))
          .map((society) => (
            <li
              onClick={() => societyClickHandler(society)}
              key={society.id}
              className={`
                        tw-pt-2
                        tw-pb-2
                        tw-flex
                        tw-items-center
                        tw-cursor-pointer
                        tw-border-b
                        tw-border-surfaceGraphite
                        ${
                          societySelected?.id === society.id
                            ? `tw-bg-surfaceGraphite`
                            : ``
                        }
                        `}
            >
              <img
                className="tw-w-10 tw-h-10 tw-rounded-full tw-m-2 tw-mr-4"
                src={society.logoUrl ? society.logoUrl : SquarePlaceholder100}
                alt={society.readableName}
              />
              <span className="tw-ml-4 tw-text-textWhite">
                {society.readableName}
              </span>
            </li>
          ))}
      </ul>
    </div>
  );
};
