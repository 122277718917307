import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Form } from 'react-bootstrap';
import { format } from 'date-fns';
import ViewStatement from '../ViewStatement';
import { useApi } from 'shared_components/context';
import { useClientId } from 'shared_components/context/client';
import {
  DailyPayment,
  PaymentStatusEnum,
  StatementsJson,
  StatementsJsonMetadata,
  StatementsJsonPaymentsInner,
} from 'shared_components/generated/admin';
import {
  basePath,
  getTokenFromSessionStorage,
} from 'shared_components/context/auth';
import StatementsTable from './StatementsTable';
import { Link } from 'react-router-dom';
import customFetch from 'shared_components/utils/customFetch';

type StatusMap = {
  [STATUS in PaymentStatusEnum]: string;
};

const statusMap: StatusMap = {
  cleared: 'Cleared',
  to_be_transferred: 'Awaiting transfer',
  waiting_cheque_clear: 'Awaiting cheque clearance',
  held: 'Held',
  // "failed": "Failed",
};

function ArtistStatements() {
  const { adminApi: api } = useApi();
  const { clientId } = useClientId();
  const [statementView, setStatementView] = useState(false);
  const handleShow = () => setStatementView(!statementView);
  const [financialYears, setFinancialYears] = useState<string[]>([]);

  const [clientPayments, setClientPayments] = useState<DailyPayment[][]>([]);
  const [statementYear, setStatementYear] = useState('');
  const [clientPaymentsByDay, setClientPaymentsByDay] = useState<{
    [date: string]: StatementsJson;
  }>({});
  const [paymentDetail, setPaymentDetail] = useState<StatementsJsonMetadata>();
  const [statementData, setStatementData] = useState<
    StatementsJsonPaymentsInner[]
  >([]);
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState('Loading...');
  const [statementPdf, setStatementPdf] = useState('');
  const [allStatementsPdf, setAllStatementsPdf] = useState('');
  const [selectedPaymentDates, setSelectedPaymentDates] = useState<string[]>(
    []
  );
  const downloadLinkRef = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    setFinancialYears([]);
    setStatementYear('');
    setClientPayments([]);
    setClientPaymentsByDay({});
    setLoadingData('Loading...');
    fetchData();
  }, [clientId]);

  const fetchData = async () => {
    if (clientId === undefined) {
      return;
    }

    try {
      const { fiscalYearsUnique } = await api.retrieveFinancialYears({
        userId: clientId,
      });

      if (!fiscalYearsUnique.length) {
        return;
      }

      setStatementYear(fiscalYearsUnique[0]);
      setFinancialYears(fiscalYearsUnique);

      const dailyPaymentsGroupedByYear = await Promise.all(
        fiscalYearsUnique.map((financialYear) => {
          return api.listDailyPayments({
            userId: clientId,
            financialYear,
          });
        })
      );

      setClientPayments(dailyPaymentsGroupedByYear);

      if (!dailyPaymentsGroupedByYear.length) {
        return;
      }

      const statementsByYear = await Promise.all(
        dailyPaymentsGroupedByYear.map((paymentsForYear) => {
          return Promise.all(
            paymentsForYear.map((dailyPayments) => {
              return api.retrieveStatementsJson({
                userId: clientId,
                paymentDay: dailyPayments.date,
              });
            })
          );
        })
      );

      setClientPaymentsByDay(
        statementsByYear.reduce<{ [date: string]: StatementsJson }>(
          (statementsByYear, statements) => {
            statements.forEach((statement) => {
              statementsByYear[statement.metadata.paymentDate] = statement;
            });
            return statementsByYear;
          },
          {}
        )
      );
    } catch {}
  };

  const viewStatement = async (e: React.MouseEvent, paymentDay: string) => {
    e.stopPropagation();
    e.preventDefault();

    const token = getTokenFromSessionStorage();

    if (token === null) {
      return;
    }
    if (clientId === undefined) {
      return;
    }

    await api
      .retrieveStatementsJson({
        userId: clientId,
        paymentDay,
      })
      .then((statement) => {
        setPaymentDetail(statement.metadata);
        setStatementData(statement.payments);
        setStatementView(true);
      })
      .catch(() => {
        setPaymentDetail(undefined);
        setStatementData([]);
      });

    const headers = new Headers({
      Authorization: 'Bearer ' + token.access,
    });
    /**
     * Schema generation currently does not support non-JSON responses.
     * As a workaround, requests that expect other media types are
     * performed manually using the Fetch API.
     */
    customFetch(
      `${basePath}/portal/users/${clientId}/statements/?` +
        new URLSearchParams({
          payment_day: paymentDay,
          format: 'pdf',
        }),
      { headers }
    )
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        setStatementPdf(url);
        setStatementView(true);
      })
      .catch((e) => {
        setStatementPdf('');
      });
  };

  const handleDownloadSelected = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    const token = getTokenFromSessionStorage();

    if (!selectedPaymentDates.length) {
      return;
    }
    if (clientId === undefined) {
      return;
    }
    if (token === null) {
      return;
    }

    setLoading(true);

    const headers = new Headers({
      Authorization: 'Bearer ' + token.access,
    });
    /**
     * Schema generation currently does not support non-JSON responses.
     * As a workaround, requests that expect other media types are
     * performed manually using the Fetch API.
     */
    customFetch(
      `${basePath}/portal/users/${clientId}/statements/?` +
        new URLSearchParams({
          payment_days: selectedPaymentDates.join(','),
          format: 'zip',
        }),
      { headers }
    )
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        setAllStatementsPdf(url);
        if (downloadLinkRef.current) {
          downloadLinkRef.current.href = url;
          downloadLinkRef.current.setAttribute('download', 'statements.zip');
          downloadLinkRef.current.click();
        }
        setLoading(false);
      })
      .catch(() => {
        setStatementData([]);
        setLoading(false);
      });
  };

  const paymentsByDayForSelectedYear = useMemo(() => {
    return Object.fromEntries(
      Object.entries(clientPaymentsByDay).filter(([date, statement]) => {
        return (
          new Date(date).getFullYear().toString().slice(-2) ===
          statementYear.slice(-2)
        );
      })
    );
  }, [statementYear, clientPaymentsByDay]);

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.checked) {
      setSelectedPaymentDates([]);
      return;
    }
    setSelectedPaymentDates(Object.keys(paymentsByDayForSelectedYear));
  };

  const allSelected = useMemo(() => {
    return (
      Object.keys(paymentsByDayForSelectedYear).length ===
      selectedPaymentDates.length
    );
  }, [selectedPaymentDates, paymentsByDayForSelectedYear]);

  const handleSelect = (
    event: React.ChangeEvent<HTMLInputElement>,
    selectedDate: string
  ) => {
    if (!event.target.checked) {
      setSelectedPaymentDates(
        selectedPaymentDates.filter((date) => date !== selectedDate)
      );
      return;
    }

    if (!selectedPaymentDates.includes(selectedDate)) {
      setSelectedPaymentDates([...selectedPaymentDates, selectedDate]);
    }
  };

  const numToString = (num: number) => {
    const parseNumber = num / 100;
    const toLocale = parseNumber.toLocaleString(navigator.language, {
      minimumFractionDigits: 2,
    });
    return toLocale;
  };

  const handleYearSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setStatementYear(event.target.value);
    setSelectedPaymentDates([]);
  };

  let columns = [
    { dataKey: 'checkbox', title: <input type="checkbox" /> },
    { dataKey: 'statement_date', title: 'Statement Date' },
    { dataKey: 'societies', title: 'Societies' },
    { dataKey: 'amount_received', title: 'Amount received' },
    { dataKey: 'commission', title: 'Commission' },
    { dataKey: 'paid_to_client', title: 'Paid to client' },
    { dataKey: 'status', title: 'Status' },
    { dataKey: 'view_statement', title: '' },
  ];

  // Populate the table with data based on the clientPayments

  let rowsData = [];

  financialYears.forEach((year, yearIndex) => {
    if (statementYear === year && clientPayments.length > 0) {
      const currentPayments = clientPayments[yearIndex];
      currentPayments.forEach((doc: any) => {
        const currentYear = new Date(doc.date)
          .getFullYear()
          .toString()
          .slice(-2);
        // Only show payments for the selected year
        if (currentYear === year.slice(-2)) {
          const res: any = {}; // TODO: type this

          const dayPayments = clientPaymentsByDay[doc.date];
          res.checkbox = (
            <input type="checkbox" onClick={(e) => handleSelect(e, doc.date)} />
          );
          res.statement_date = format(new Date(doc.date), 'dd/MM/yyyy');
          res.societies = dayPayments?.payments.length;
          res.amount_received = dayPayments?.metadata.totalAmountReceived;
          res.commission = dayPayments?.metadata.commission;
          res.paid_to_client = dayPayments?.metadata.totalPayable;
          res.status = statusMap[dayPayments?.metadata.status];
          res.view_statement = (
            <Link
              to=" "
              data-statementday={doc}
              onClick={(e) => viewStatement(e, doc.date)}
            >
              View&nbsp;Statement
            </Link>
          );

          // Loop through detailed payments
          res.payments =
            dayPayments?.payments.map((paymentDoc: any) => ({
              fromSociety: paymentDoc.fromSociety,
              totalAmountReceived: paymentDoc.totalAmountReceived,
              commission: paymentDoc.comission,
              amountPaidToClient: paymentDoc.amountPaidToClient,
              status: statusMap[paymentDoc.status],
            })) || [];

          rowsData.push(res);
        }
      });
    }
  });

  // For each res.payments, we need to return a JSX that shows/hides the detailed payments
  // This child component is heavily dependent on the columns, so we need to generate the child component based on the main columns
  const formattedRows = useMemo(() => {
    return rowsData.map((row) => {
      return {
        ...row,
        childComponent: (
          <div className="tw-pt-2 tw-pb-2">
            {row.payments.map((payment: any, idx) => {
              const gridTemplateColumns = `repeat(${columns.length}, 1fr)`;
              return (
                <div
                  key={idx}
                  className="tw-grid tw-gap-2 tw-w-full tw-text-white tw-cursor-pointer"
                  style={{ gridTemplateColumns }}
                >
                  <div></div>
                  <div></div>
                  <div className="">{payment.fromSociety}</div>
                  <div className="">
                    {numToString(payment.totalAmountReceived)}
                  </div>
                  <div className="">{numToString(payment.commission)}</div>
                  <div className="">
                    {numToString(payment.amountPaidToClient)}
                  </div>
                  <div className="">{payment.status}</div>
                </div>
              );
            })}
          </div>
        ),
      };
    });
  }, [rowsData]);

  return (
    <div className="ArtistDocumentList">
      {statementView && paymentDetail ? (
        <ViewStatement
          show={statementView}
          modalClose={handleShow}
          statementData={statementData}
          paymentData={paymentDetail}
          statementPdf={statementPdf}
        />
      ) : (
        ''
      )}
      {financialYears.length > 0 ? (
        <div className="DocumentsListingTable m-0">
          {loading ? (
            <div className="loaderWrap">
              <span className="Loader">
                <span className="sr-only">Downloading...</span>
              </span>
            </div>
          ) : (
            ''
          )}
          <div className="listingTableWrap">
            <div className="tw-flex tw-align-items-right tw-justify-end">
              <div className="fySelection p-0 d-flex">
                {financialYears.length > 0 ? (
                  <Form.Select
                    className="mt-0 fieldSmaller roleSelected fieldWithBorder"
                    id="financial_year"
                    name="financial_year"
                    onChange={handleYearSelect}
                  >
                    {financialYears.map((year) => (
                      <option value={year} key={year}>
                        {year}
                      </option>
                    ))}
                  </Form.Select>
                ) : (
                  ''
                )}
              </div>
              <span className="downloadZipButtons p-relative">
                <a
                  href={allStatementsPdf}
                  className="btn download-btn secondary"
                  onClick={handleDownloadSelected}
                >
                  Download
                </a>
                <a
                  href=" "
                  ref={downloadLinkRef}
                  className="hidden-button"
                  id="downloadzip"
                  download
                >
                  Download
                </a>
              </span>
            </div>

            <StatementsTable
              columns={columns}
              rows={formattedRows}
            ></StatementsTable>
          </div>
        </div>
      ) : (
        <div className="dataLoading">{loadingData}</div>
      )}
    </div>
  );
}

export default ArtistStatements;
