import React, { ChangeEvent, useEffect } from 'react';
import { useFormContext } from '../WFormContext'; // Make sure to import your useFormContext correctly
import clsx from 'clsx';
interface InputFieldProps {
  type: string;
  layout?: 'vertical' | 'horizontal';
  name: string;
  value: string | null | undefined | number;
  label: string;
  labelSecondary?: string;
  disabled?: boolean;
  isFieldEditing?: boolean;
  hideOnView?: boolean; // Optional prop to hide the input field when not editing
  onBlur?: () => void;
  onChange: (value: ChangeEvent<HTMLInputElement>) => void;
  icon?: string;
  placeholder?: string;
  optionalLabel?: boolean;
}

export const WInput: React.FC<InputFieldProps> = ({
  type = 'text',
  layout = 'vertical', // vertical = label on top of input, horizontal = label on left of input
  name,
  value,
  label,
  labelSecondary = '',
  disabled = false,
  isFieldEditing = false,
  hideOnView = false,
  optionalLabel = false,
  icon,
  placeholder,
  onBlur,
  onChange,
}) => {
  const { isEditing, isFormSubmitted, errors, handleFormSubmit, formData } =
    useFormContext(); // Fetch errors from context

  const input = React.useRef<HTMLInputElement>(null);

  // input should capture enter key press and submit form

  const [focused, setFocused] = React.useState(false);
  const onIconClick = () => {};
  const handleFocus = () => setFocused(true);
  const handleBlur = () => setFocused(false);

  return (
    <>
      {(isEditing || isFieldEditing || !hideOnView) && (
        <div
          className={clsx(
            'tw-flex tw-mb-4',
            {
              'tw-flex-row tw-w-full tw-justify-between tw-items-center':
                layout === 'horizontal',
            },
            {
              'tw-flex-col tw-mb-8 tw-flex tw-justify-between':
                layout === 'vertical',
            }
          )}
        >
          {label != '' && (
            <label
              htmlFor={name}
              className={clsx(
                'tw-flex tw-space-x-1 tw-justify-between tw-leading-4 tw-text-white tw-mb-2 tw-capitalize',
                {
                  'tw-w-1/4': layout === 'horizontal',
                },

                {
                  'tw-text-[14px]': !isEditing && !isFieldEditing,
                }
              )}
            >
              <span
                className={clsx({
                  'tw-text-red-500': isEditing && errors[name],
                })}
              >
                {label}
                {labelSecondary != '' && (
                  <span className="tw-ml-1 tw-text-xs tw-leading-4">
                    {labelSecondary}
                  </span>
                )}
              </span>

              {optionalLabel && (
                <span className="tw-text-xs tw-leading-4 tw-text-gray-400">
                  Optional
                </span>
              )}
            </label>
          )}
          {(isEditing || isFieldEditing) && (
            <div
              className={clsx('tw-relative', {
                'tw-w-3/4': layout === 'horizontal',
              })}
            >
              <input
                ref={input}
                type={type}
                name={name}
                id={name}
                onFocus={handleFocus}
                placeholder={placeholder || label}
                value={value}
                disabled={disabled}
                onChange={(event) => onChange(event)}
                onBlur={() => {
                  handleBlur();
                  if (isFormSubmitted) {
                    if (onBlur) {
                      onBlur();
                    }
                  }
                }}
                className={clsx(
                  'tw-bg-formInputBackground',
                  'tw-text-gray-400',
                  'tw-rounded-lg',
                  'tw-h-12',
                  'tw-w-full',
                  'tw-placeholder-textSeal',
                  'tw-appearance-none',
                  'tw-leading-5',
                  'tw-p-3',
                  'tw-mb-2',
                  'tw-border-2',
                  { 'tw-border-formInputBackground': !errors[name] },
                  'focus:tw-border-white',
                  'focus:tw-text-white',
                  'focus:tw-outline-none',
                  { 'tw-border-red-500': isEditing && errors[name] },
                  { 'tw-font-extraLight': disabled },
                  { 'tw-cursor-not-allowed': disabled }
                )}
                style={{ colorScheme: `${type === 'date' ? 'dark' : ''}` }}
              />
              {icon && onIconClick && (
                <span
                  onClick={onIconClick}
                  className="tw-absolute tw-right-0 tw-top-0 tw-h-10 tw-w-10 tw-flex tw-justify-center tw-items-center"
                >
                  {/* Replace with new icons when implemented */}
                  {icon}
                </span>
              )}
              {(isEditing || isFieldEditing) && !focused && errors[name] && (
                <i className="tw-absolute tw-right-0 tw-top-1 tw-h-10 tw-w-10 tw-flex tw-justify-center tw-items-center">
                  <i className={`wi wi-20px wi-alert tw-text-red-500`}></i>
                </i>
              )}
              {disabled && (
                <i className="tw-absolute tw-right-0 tw-top-1 tw-h-10 tw-w-10 tw-flex tw-justify-center tw-items-center">
                  <i className={`wi wi-20px wi-ban`}></i>
                </i>
              )}
            </div>
          )}
          {!isEditing && !isFieldEditing && (
            <div className={`tw-text-textDove tw-text-[14px]`}>
              {value && value != '' ? value : '-'}
            </div>
          )}
          {isEditing && !isFieldEditing && errors[name] && (
            <div className="tw-text-red-500 tw-text-xs">{errors[name]}</div>
          )}{' '}
        </div>
      )}
    </>
  );
};
