import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { AdminSociety } from 'shared_components/generated/admin';
import {
  FormMethodSegment,
  FormReminderSegment,
  FormReminderFrequencySegment,
  FormTaxFormsSegment,
  FormTaxFormsDetailSegment,
  FormRoles,
} from './Forms';
import WContainer from 'shared_components/components/WContainer';

const defaultTaxForm = [{ determined_by: '', country: '', form: '' }];
const defaultFormRoles = [
  {
    role: '',
    forms: [{ form: '', signature: '', send_via: '' }],
  },
];

interface OnboardingMembershipDetailsProps {
  societySelected?: AdminSociety;
  isEditingSociety: boolean;
  setOnboardingMembershipDetails: (OnboardingMembershipDetails: any) => void;
}

export const OnboardingMembershipDetails = ({
  societySelected,
  isEditingSociety,
  setOnboardingMembershipDetails,
}: OnboardingMembershipDetailsProps) => {
  const [notifyByEmailCheck, setNotifyByEmailCheck] = useState<boolean>(false);
  const [notifyEmail, setNotifyEmail] = useState<string>('');
  const [notifyByPostCheck, setNotifyByPostCheck] = useState<boolean>(false);
  const [notifyPost, setNotifyPost] = useState<string>('');
  const [notifyByPortalCheck, setNotifyByPortalCheck] =
    useState<boolean>(false);
  const [notifyPortal, setNotifyPortal] = useState<string>('');
  const [reminderSociety, setReminderSociety] = useState<string>('');
  const [reminderEmail, setReminderEmail] = useState<string>('');
  const [reminderFrequency, setReminderFrequency] = useState<string>('');
  const [stopReminder, setStopReminder] = useState<string>('');
  const [differentTaxForm, setDifferentTaxForm] = useState<string>('');
  const [taxForms, setTaxForms] = useState<Array<{}>>(defaultTaxForm);
  const [formRoles, setFormRoles] = useState<Array<{}>>(defaultFormRoles);
  const [societyForms, setSocietyForms] = useState<Array<{}>>([{}]);

  useEffect(() => {
    setNotifyByEmailCheck(
      societySelected &&
        societySelected.formEmail &&
        societySelected.formEmail !== ''
        ? true
        : false
    );
    setNotifyEmail(
      societySelected &&
        societySelected.formEmail &&
        societySelected.formEmail != ''
        ? societySelected.formEmail
        : ''
    );
    setNotifyByPostCheck(
      societySelected &&
        societySelected.formPostalAddress &&
        societySelected.formPostalAddress !== ''
        ? true
        : false
    );
    setNotifyPost(
      societySelected &&
        societySelected.formPostalAddress &&
        societySelected.formPostalAddress != ''
        ? societySelected.formPostalAddress
        : ''
    );
    setNotifyByPortalCheck(
      societySelected &&
        societySelected.formPortal &&
        societySelected.formPortal !== ''
        ? true
        : false
    );
    setNotifyPortal(
      societySelected &&
        societySelected.formPortal &&
        societySelected.formPortal != ''
        ? societySelected.formPortal
        : ''
    );
    setReminderSociety(
      societySelected &&
        societySelected.formReminderSendTo &&
        societySelected.formReminderFrequency &&
        societySelected.formStopReminderFrequency
        ? 'reminder_society_Yes'
        : 'reminder_society_No'
    );
    setReminderEmail(
      societySelected &&
        societySelected.formReminderSendTo &&
        societySelected.formReminderSendTo != ''
        ? societySelected.formReminderSendTo
        : ''
    );
    setReminderFrequency(
      societySelected && societySelected.formReminderFrequency
        ? societySelected.formReminderFrequency
        : ''
    );
    setStopReminder(
      societySelected && societySelected.formStopReminderFrequency
        ? societySelected.formStopReminderFrequency
        : ''
    );
    setDifferentTaxForm(
      societySelected &&
        societySelected.taxForms &&
        societySelected.taxForms.length > 0
        ? 'different_tax_form_Yes'
        : 'different_tax_form_No'
    );
    setTaxForms(
      // @ts-ignore - CLI tool thinks .formTaxForms is a string, not an Array<string>
      societySelected &&
        societySelected.taxForms &&
        societySelected.taxForms.length > 0
        ? societySelected.taxForms
        : defaultTaxForm
    );
    setFormRoles(
      // @ts-ignore - CLI tool thinks .formRoleForms is a string, not an Array<string>
      societySelected &&
        societySelected.roleForms &&
        societySelected.roleForms.length > 0
        ? societySelected.roleForms
        : defaultFormRoles
    );
    setSocietyForms(
      // @ts-ignore - CLI tool thinks .societyForms is a string, not an Array<string>
      societySelected &&
        societySelected.societyForms &&
        societySelected.societyForms.length > 0
        ? societySelected.societyForms
        : []
    );
  }, [societySelected]);

  useEffect(() => {
    setOnboardingMembershipDetails({
      formEmail: notifyEmail && notifyByEmailCheck ? notifyEmail : '',
      formPostalAddress: notifyPost && notifyByPostCheck ? notifyPost : '',
      formPortal: notifyPortal && notifyByPortalCheck ? notifyPortal : '',
      formReminderFrequency:
        reminderSociety == 'reminder_society_Yes' && reminderFrequency
          ? reminderFrequency
          : '',
      formStopReminderFrequency:
        reminderSociety == 'reminder_society_Yes' && stopReminder
          ? stopReminder
          : '',
      formReminderSendTo:
        reminderSociety == 'reminder_society_Yes' && reminderEmail
          ? reminderEmail
          : '',
      taxForms:
        differentTaxForm == 'different_tax_form_Yes'
          ? JSON.stringify(taxForms)
          : JSON.stringify([]),
      roleForms: JSON.stringify(formRoles),
    });
  }, [
    notifyByEmailCheck,
    notifyEmail,
    notifyByPostCheck,
    notifyPost,
    notifyByPortalCheck,
    notifyPortal,
    reminderSociety,
    reminderEmail,
    reminderFrequency,
    stopReminder,
    differentTaxForm,
    taxForms,
    formRoles,
  ]);

  return (
    <>
      <FormMethodSegment
        notifyByEmailCheck={notifyByEmailCheck}
        notifyByPostCheck={notifyByPostCheck}
        notifyByPortalCheck={notifyByPortalCheck}
        notifyEmail={notifyEmail}
        notifyPost={notifyPost}
        notifyPortal={notifyPortal}
        setNotifyByEmailCheck={setNotifyByEmailCheck}
        setNotifyByPostCheck={setNotifyByPostCheck}
        setNotifyByPortalCheck={setNotifyByPortalCheck}
        setNotifyEmail={setNotifyEmail}
        setNotifyPost={setNotifyPost}
        setNotifyPortal={setNotifyPortal}
      />
      {/* Phase II */}
      {/* <FormReminderSegment
        isEditingSociety={isEditingSociety}
        reminderSociety={reminderSociety}
        setReminderSociety={setReminderSociety}
      />
      <FormReminderFrequencySegment
        reminderSociety={reminderSociety}
        reminderEmail={reminderEmail}
        reminderFrequency={reminderFrequency}
        stopReminder={stopReminder}
        setReminderFrequency={setReminderFrequency}
        setReminderEmail={setReminderEmail}
        setStopReminder={setStopReminder}
      /> */}
      <FormTaxFormsSegment
        isEditingSociety={isEditingSociety}
        differentTaxForm={differentTaxForm}
        setDifferentTaxForm={setDifferentTaxForm}
        formRoles={formRoles}
        setFormRoles={setFormRoles}
      />
      <FormTaxFormsDetailSegment
        isEditingSociety={isEditingSociety}
        differentTaxForm={differentTaxForm}
        taxForms={taxForms}
        setTaxForms={setTaxForms}
        societyForms={societyForms}
      />
      <FormRoles
        societySelected={societySelected}
        isEditingSociety={isEditingSociety}
        formRoles={formRoles}
        setFormRoles={setFormRoles}
        societyForms={societyForms}
      />
    </>
  );
};
