import React, { useId } from 'react';
import { CompanyInfoFormData } from './CompanyInfo';
import { WInput } from 'shared_components/components/WForms/WInput/WInput';
import { useFormContext } from 'shared_components/components/WForms/WFormContext';
interface BankDetailsProps {
  formData: CompanyInfoFormData;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const BankDetails = ({ formData, handleChange }: BankDetailsProps) => {
  const prefix = useId();
  const { isEditing } = useFormContext();

  const containerClasses = isEditing
    ? `tw-bg-surfaceBlack90
  tw-p-6
  tw-mt-6
  tw-text-white
  tw-rounded-2xl
  tw-max-w-full`
    : ``;

  return (
    <div className={containerClasses}>
      {!isEditing && (
        <hr className="tw-h-px tw-my-8 tw-bg-gray-200 tw-border-0 tw-dark:bg-gray-700" />
      )}
      <p className={`tw-text-2xl tw-mb-6`}>Bank Details</p>
      <WInput
        type="text"
        label="Account name"
        name="bankAccountName"
        value={formData.bankAccountName}
        onChange={handleChange}
        onBlur={() => {}}
      />
      <WInput
        type="text"
        label="Sort code"
        name="bankSortCode"
        value={formData.bankSortCode}
        onChange={handleChange}
        onBlur={() => {}}
      />
      <WInput
        type="text"
        label="Account number"
        name="bankAccountNumber"
        value={formData.bankAccountNumber}
        onChange={handleChange}
        onBlur={() => {}}
      />
      <WInput
        type="text"
        label="Bank Name"
        name="bankName"
        value={formData.bankName}
        onChange={handleChange}
        onBlur={() => {}}
      />
      <WInput
        type="text"
        label="Bank Address"
        name="bankAddress"
        value={formData.bankAddress}
        onChange={handleChange}
        onBlur={() => {}}
      />
      <WInput
        type="text"
        label="Bank Postcode"
        name="bankPostcode"
        value={formData.bankPostcode}
        onChange={handleChange}
        onBlur={() => {}}
      />
      <WInput
        type="text"
        label="IBAN Number"
        name="bankIban"
        value={formData.bankIban}
        onChange={handleChange}
        onBlur={() => {}}
      />
      <WInput
        type="text"
        label="BIC/SWIFT code"
        name="bankBicSwiftCode"
        value={formData.bankBicSwiftCode}
        onChange={handleChange}
        onBlur={() => {}}
      />
    </div>
  );
};
