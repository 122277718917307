/* tslint:disable */
/* eslint-disable */
/**
 * Westbury Music API
 * API for Westbury Music
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ClientInterestRegistrationRepresentative } from './ClientInterestRegistrationRepresentative';
import {
    ClientInterestRegistrationRepresentativeFromJSON,
    ClientInterestRegistrationRepresentativeFromJSONTyped,
    ClientInterestRegistrationRepresentativeToJSON,
} from './ClientInterestRegistrationRepresentative';

/**
 * 
 * @export
 * @interface ClientInterestRegistration
 */
export interface ClientInterestRegistration {
    /**
     * 
     * @type {string}
     * @memberof ClientInterestRegistration
     */
    readonly url?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientInterestRegistration
     */
    readonly user?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientInterestRegistration
     */
    role?: ClientInterestRegistrationRoleEnum;
    /**
     * 
     * @type {ClientInterestRegistrationRepresentative}
     * @memberof ClientInterestRegistration
     */
    representative?: ClientInterestRegistrationRepresentative;
    /**
     * 
     * @type {boolean}
     * @memberof ClientInterestRegistration
     */
    masterRights?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ClientInterestRegistration
     */
    performerOrProducer?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ClientInterestRegistration
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientInterestRegistration
     */
    middleName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientInterestRegistration
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientInterestRegistration
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof ClientInterestRegistration
     */
    phoneNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientInterestRegistration
     */
    masterId?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientInterestRegistration
     */
    masterName?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientInterestRegistration
     */
    masterFullname?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientInterestRegistration
     */
    masterEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientInterestRegistration
     */
    pplPerformerName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientInterestRegistration
     */
    pplPerformerId?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ClientInterestRegistration
     */
    aliases?: Array<string>;
}


/**
 * @export
 */
export const ClientInterestRegistrationRoleEnum = {
    PerformerProducer: 'Performer/Producer'
} as const;
export type ClientInterestRegistrationRoleEnum = typeof ClientInterestRegistrationRoleEnum[keyof typeof ClientInterestRegistrationRoleEnum];


/**
 * Check if a given object implements the ClientInterestRegistration interface.
 */
export function instanceOfClientInterestRegistration(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "email" in value;

    return isInstance;
}

export function ClientInterestRegistrationFromJSON(json: any): ClientInterestRegistration {
    return ClientInterestRegistrationFromJSONTyped(json, false);
}

export function ClientInterestRegistrationFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClientInterestRegistration {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'url': !exists(json, 'url') ? undefined : json['url'],
        'user': !exists(json, 'user') ? undefined : json['user'],
        'role': !exists(json, 'role') ? undefined : json['role'],
        'representative': !exists(json, 'representative') ? undefined : ClientInterestRegistrationRepresentativeFromJSON(json['representative']),
        'masterRights': !exists(json, 'master_rights') ? undefined : json['master_rights'],
        'performerOrProducer': !exists(json, 'performer_or_producer') ? undefined : json['performer_or_producer'],
        'firstName': !exists(json, 'first_name') ? undefined : json['first_name'],
        'middleName': !exists(json, 'middle_name') ? undefined : json['middle_name'],
        'lastName': !exists(json, 'last_name') ? undefined : json['last_name'],
        'email': json['email'],
        'phoneNumber': !exists(json, 'phone_number') ? undefined : json['phone_number'],
        'masterId': !exists(json, 'master_id') ? undefined : json['master_id'],
        'masterName': !exists(json, 'master_name') ? undefined : json['master_name'],
        'masterFullname': !exists(json, 'master_fullname') ? undefined : json['master_fullname'],
        'masterEmail': !exists(json, 'master_email') ? undefined : json['master_email'],
        'pplPerformerName': !exists(json, 'ppl_performer_name') ? undefined : json['ppl_performer_name'],
        'pplPerformerId': !exists(json, 'ppl_performer_id') ? undefined : json['ppl_performer_id'],
        'aliases': !exists(json, 'aliases') ? undefined : json['aliases'],
    };
}

export function ClientInterestRegistrationToJSON(value?: ClientInterestRegistration | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'role': value.role,
        'representative': ClientInterestRegistrationRepresentativeToJSON(value.representative),
        'master_rights': value.masterRights,
        'performer_or_producer': value.performerOrProducer,
        'first_name': value.firstName,
        'middle_name': value.middleName,
        'last_name': value.lastName,
        'email': value.email,
        'phone_number': value.phoneNumber,
        'master_id': value.masterId,
        'master_name': value.masterName,
        'master_fullname': value.masterFullname,
        'master_email': value.masterEmail,
        'ppl_performer_name': value.pplPerformerName,
        'ppl_performer_id': value.pplPerformerId,
        'aliases': value.aliases,
    };
}

