/* tslint:disable */
/* eslint-disable */
/**
 * Westbury Music API
 * API for Westbury Music
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SignupAuthTokenToken } from './SignupAuthTokenToken';
import {
    SignupAuthTokenTokenFromJSON,
    SignupAuthTokenTokenFromJSONTyped,
    SignupAuthTokenTokenToJSON,
} from './SignupAuthTokenToken';

/**
 * 
 * @export
 * @interface SigninAuthToken
 */
export interface SigninAuthToken {
    /**
     * 
     * @type {SignupAuthTokenToken}
     * @memberof SigninAuthToken
     */
    token: SignupAuthTokenToken;
    /**
     * 
     * @type {string}
     * @memberof SigninAuthToken
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof SigninAuthToken
     */
    email: string;
    /**
     * 
     * @type {Array<any>}
     * @memberof SigninAuthToken
     */
    groups: Array<any>;
}

/**
 * Check if a given object implements the SigninAuthToken interface.
 */
export function instanceOfSigninAuthToken(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "token" in value;
    isInstance = isInstance && "userId" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "groups" in value;

    return isInstance;
}

export function SigninAuthTokenFromJSON(json: any): SigninAuthToken {
    return SigninAuthTokenFromJSONTyped(json, false);
}

export function SigninAuthTokenFromJSONTyped(json: any, ignoreDiscriminator: boolean): SigninAuthToken {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'token': SignupAuthTokenTokenFromJSON(json['token']),
        'userId': json['user_id'],
        'email': json['email'],
        'groups': json['groups'],
    };
}

export function SigninAuthTokenToJSON(value?: SigninAuthToken | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'token': SignupAuthTokenTokenToJSON(value.token),
        'user_id': value.userId,
        'email': value.email,
        'groups': value.groups,
    };
}

