/* tslint:disable */
/* eslint-disable */
/**
 * Westbury Music API Admin
 * API for Westbury Music admin users
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MembershipStatus
 */
export interface MembershipStatus {
    /**
     * 
     * @type {string}
     * @memberof MembershipStatus
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof MembershipStatus
     */
    readableName: string;
    /**
     * 
     * @type {string}
     * @memberof MembershipStatus
     */
    country?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MembershipStatus
     */
    readonly forms?: string;
}

/**
 * Check if a given object implements the MembershipStatus interface.
 */
export function instanceOfMembershipStatus(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "readableName" in value;

    return isInstance;
}

export function MembershipStatusFromJSON(json: any): MembershipStatus {
    return MembershipStatusFromJSONTyped(json, false);
}

export function MembershipStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): MembershipStatus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'readableName': json['readable_name'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'forms': !exists(json, 'forms') ? undefined : json['forms'],
    };
}

export function MembershipStatusToJSON(value?: MembershipStatus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'readable_name': value.readableName,
        'country': value.country,
    };
}

