/* tslint:disable */
/* eslint-disable */
/**
 * Westbury Music API Admin
 * API for Westbury Music admin users
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OnboardingStep
 */
export interface OnboardingStep {
    /**
     * 
     * @type {string}
     * @memberof OnboardingStep
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof OnboardingStep
     */
    internalIdentifier: string;
    /**
     * 
     * @type {string}
     * @memberof OnboardingStep
     */
    readableName: string;
    /**
     * 
     * @type {number}
     * @memberof OnboardingStep
     */
    order: number;
    /**
     * 
     * @type {string}
     * @memberof OnboardingStep
     */
    information?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OnboardingStep
     */
    stepCompleted?: boolean;
}

/**
 * Check if a given object implements the OnboardingStep interface.
 */
export function instanceOfOnboardingStep(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "internalIdentifier" in value;
    isInstance = isInstance && "readableName" in value;
    isInstance = isInstance && "order" in value;

    return isInstance;
}

export function OnboardingStepFromJSON(json: any): OnboardingStep {
    return OnboardingStepFromJSONTyped(json, false);
}

export function OnboardingStepFromJSONTyped(json: any, ignoreDiscriminator: boolean): OnboardingStep {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'internalIdentifier': json['internal_identifier'],
        'readableName': json['readable_name'],
        'order': json['order'],
        'information': !exists(json, 'information') ? undefined : json['information'],
        'stepCompleted': !exists(json, 'step_completed') ? undefined : json['step_completed'],
    };
}

export function OnboardingStepToJSON(value?: OnboardingStep | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'internal_identifier': value.internalIdentifier,
        'readable_name': value.readableName,
        'order': value.order,
        'information': value.information,
        'step_completed': value.stepCompleted,
    };
}

