import React from 'react';
import { WInput } from 'shared_components/components/WForms/WInput/WInput';
import { TermsOfAgreement } from 'shared_components/generated/admin';
import moment from 'moment';
import { WSelect } from 'shared_components/components/WForms/WSelect/WSelect';
import { TermsOfAgreementRepresentativeFeeTakenFromEnum } from 'shared_components/generated/admin';
interface FeeDetailsProps {
  termsOfAgreement: TermsOfAgreement;
  onChange: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => void;
}

const RepresentativeFeeDetails: React.FC<FeeDetailsProps> = ({
  termsOfAgreement,
  onChange,
}) => {
  return (
    <>
      <div className="tw-flex tw-flex-col">
        <div className="tw-flex tw-space-x-2">
          <div className="tw-w-1/6">
            <WInput
              name="percentage"
              label=""
              type="text"
              placeholder="%"
              value={termsOfAgreement.representativeFee?.percentage}
              onChange={(e) => onChange(e)}
            />
          </div>
          <div className="tw-w-5/6">
            <WSelect
              name="takenFrom"
              label="Taken from"
              layout="horizontal"
              options={[
                {
                  value:
                    TermsOfAgreementRepresentativeFeeTakenFromEnum.WestburyIncome,
                  label: 'Westbury Income',
                },
                {
                  value:
                    TermsOfAgreementRepresentativeFeeTakenFromEnum.ClientIncome,
                  label: 'Client Income',
                },
              ]}
              defaultValue={termsOfAgreement.representativeFee?.takenFrom}
              onChange={(e) => onChange(e)}
            />
          </div>
        </div>
        <div className="tw-w-full">
          <WInput
            layout="horizontal"
            label="Expiration date"
            type="date"
            value={moment(
              termsOfAgreement.representativeFee?.expirationDate
            ).format('YYYY-MM-DD')}
            name="expirationDate"
            placeholder="DD/MM/YYYY"
            onChange={(e) => onChange(e)}
          />
        </div>
      </div>
    </>
  );
};

export default RepresentativeFeeDetails;
