import { useState } from 'react';
import { useFormContext } from './WFormContext';
import WButton from './WButton/WButton';

interface WFormFooterSecondaryProps {
  disabled?: boolean;
}
export const WFormFooterSecondary: React.FC<WFormFooterSecondaryProps> = ({
  disabled = false,
}) => {
  const {
    isEditing,
    handleFormSubmit,
    formData,
    setIsEditing,
    isFormSubmitted,
    setFormSubmitted,
  } = useFormContext();

  return (
    <>
      <div className="tw-relative tw-w-full tw-text-black tw-mt-4">
        {isEditing && (
          <div className="tw-flex tw-justify-start tw-space-x-4 tw-items-center">
            <WButton
              label="Save Changes"
              variant="secondary"
              disabled={disabled}
              onClick={() => {
                handleFormSubmit(formData);
              }}
            />

            {/* <WButton
              label="Cancel"
              variant="outline-light"
              onClick={() => {
                setFormSubmitted(false);
                setIsEditing(false);
              }}
            ></WButton> */}
          </div>
        )}
        {/* Show success message after form is submitted */}
      </div>
    </>
  );
};
