import React, { useEffect, useState } from 'react';
import AgreementModalIFrame from 'shared_components/components/PandadocPage/AgreementModalIFrame';
import WModal from 'shared_components/components/WModal';

interface SocietyFormModalProps {
  isOpen: boolean;
  onClose: () => void;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  title: string;
  sessionId: string | undefined;
  onComplete?: () => void;
}

function SocietyFormModal({
  isOpen,
  onClose,
  isLoading,
  setIsLoading,
  title,
  sessionId,
  onComplete,
}: SocietyFormModalProps) {
  const [isCompleted, setIsCompleted] = useState(false);
  useEffect(() => {
    setIsCompleted(false);
  }, [isOpen]);
  useEffect(() => {
    const handleMessage = (event: MessageEvent<any>) => {
      const payload = event.data && event.data.payload;
      const type = event.data && event.data.type;
      switch (type) {
        case 'session_view.document.loaded':
          if (isLoading) setIsLoading(false);
          break;
        case 'session_view.document.completed':
          if (payload && payload.uuid) {
            if (onComplete && !isCompleted) {
              setIsCompleted(true);
              onComplete();
            }
          }
          break;
      }
    };
    // pandadocs iframe event message listeners
    // https://developers.pandadoc.com/reference/embed-a-document#embedded-document-javascript-events
    window.addEventListener('message', handleMessage);
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [isLoading, isCompleted, onComplete]);

  return (
    <WModal isOpen={isOpen} onClose={onClose} title={title}>
      <div className="tw-w-[70vw] tw-h-[75vh]">
        {isLoading && (
          <>
            <div className="tw-flex tw-justify-center tw-min-h-[75vh]">
              <p className="tw-my-auto tw-animate-ping">⚪</p>
            </div>
          </>
        )}
        <div className={isLoading ? 'tw-hidden' : ''}>
          {sessionId && (
            <div>
              <AgreementModalIFrame title={title} documentId={sessionId} />
            </div>
          )}
        </div>
      </div>
    </WModal>
  );
}

export default SocietyFormModal;
