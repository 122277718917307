import React from 'react';

interface AgreementModalPageProps {
  pageIndex: number;
  selectedPageIndex: number;
  children: any;
  loading: boolean;
}

function AgreementModalPage({
  pageIndex,
  selectedPageIndex,
  children,
  loading,
}: AgreementModalPageProps) {
  const getClassName = () => {
    if (pageIndex === selectedPageIndex) return loading ? 'tw-hidden' : '';
    return 'tw-hidden';
  };
  return <div className={getClassName()}>{children}</div>;
}

export default AgreementModalPage;
