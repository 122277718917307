import React, { useEffect, useState, useRef } from 'react';
import { z, ZodEffects, ZodObject } from 'zod';
import { WFormContext } from './WFormContext';

/**
 * Interface for WFormProviderProps
 *
 * This interface defines the properties for the WFormProvider component, which is used
 * to manage form state and validation using a Zod schema. It includes options for default
 * editing mode, success message handling, and a custom submit handler.
 *
 * @property schema - Zod schema for form validation.
 * @property editByDefault - If true, the form starts in edit mode.
 * @property handleSubmit -
 *     Function called when the form is submitted. Should handle form data processing and validation.
 * @property children - React components rendered inside the provider.
 * @property formData - Submitted data from the form.
 * @property onSuccess - Callback function executed after successful
 *     form submission.
 * @property onSuccessMessage - Can be anything, usually a WModal. The open/close toggle state of the WModal is handled in onSuccess.
 * An onSuccess callback is required if a success message is provided.
 */

interface WFormProviderProps {
  schema: ZodObject<any> | ZodEffects<any> | undefined;
  editByDefault?: boolean;
  onSuccessMessage?: React.ReactNode | null | undefined;
  handleSubmit: (
    isValid?: boolean,
    data?: any,
    errors?: any
  ) => Promise<any> | undefined;
  children: React.ReactNode;
  formData?: any;
  onSuccess?: () => void | undefined;
  submitOnEnter?: boolean;
}

export const WFormProvider: React.FC<WFormProviderProps> = ({
  schema = undefined,
  editByDefault = false,
  onSuccess = undefined,
  onSuccessMessage = undefined,
  handleSubmit,
  children,
  formData,
  submitOnEnter = true,
}) => {
  const [isEditing, setIsEditing] = useState(editByDefault);
  const [isFormSubmitted, setFormSubmitted] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [successMessage, setSuccessMessage] = useState(onSuccessMessage);

  const form = useRef<HTMLFormElement>(null);
  useEffect(() => {
    // add enter event listener

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        if (submitOnEnter) {
          handleFormSubmit(formData);
        }
      }
    };
    if (form.current) {
      form.current.addEventListener('keydown', handleKeyDown);

      return () => {
        form.current?.removeEventListener('keydown', handleKeyDown);
      };
    }
  }, [form, formData]);
  useEffect(() => {
    setSuccessMessage(onSuccessMessage);
    if (successMessage !== undefined && onSuccess === undefined) {
      throw new Error('Success Message provided but no onSuccess callback');
    }
  }, [onSuccessMessage, onSuccess]);

  // Make sure all zod schema keys are present in the formData object
  useEffect(() => {
    if (schema !== undefined) {
      if (formData === undefined) {
        console.error(
          'Form data is undefined while schema is defined, this will cause form validation to always fail'
        );
        return;
      }
      if (schema.shape === undefined) {
        return;
      }
      const schemaKeys = Object.keys(schema.shape);

      const formDataKeys = Object.keys(formData);

      // make sure all schemaKeys are present in formData, throw an error if not
      // if a schema key doesn't exist in formData, the form validation will always fail

      for (let i = 0; i < schemaKeys.length; i++) {
        if (!formDataKeys.includes(schemaKeys[i])) {
          console.error(
            `Form formData is missing key "${schemaKeys[i]}" while form schema contains it, this will cause form validation to always fail`
          );
          return;
        }
      }
    }
  }, [formData]);

  const validateForm = () => {
    // expose the form validation to be run from the parent component
    // this is useful if the form is combined with other providers, e.g. the Wizard

    if (schema !== undefined) {
      try {
        schema.parse(formData);

        // no errors, set errors to empty object
        setErrors({});
        return {};
      } catch (error) {
        // errors, set errors and return them
        if (error instanceof z.ZodError) {
          const parsedErrors: any = {};
          error.errors.forEach((err) => {
            parsedErrors[err.path.join('.')] = err.message;
          });

          setErrors(parsedErrors);
          return parsedErrors;
        }
      }
    }
  };

  const handleFormSubmit = (formData: any) => {
    try {
      let parsedData = formData;

      if (schema) {
        parsedData = schema.parse(formData);
      }
      // no errors, submit form
      let response = handleSubmit(true, parsedData, {});

      // We need to receive a promise in order to handle server errors
      // Even though we aren't currently using the promise, it's a great way to handle errors in the future if we need to
      // And a clean way to chain code after the form is submitted
      if (response) {
        response
          .then((res) => {
            setFormSubmitted(true);

            // If we don't have a success message and a success callback, we can change the form back to preview mode

            if (typeof onSuccess === 'function') {
              onSuccess();
            }

            if (successMessage === undefined || successMessage === null) {
              setIsEditing(false);
            }

            setErrors({});
          })
          .catch((errors) => {
            // handle server errors
            // server errors need to be parsed and set as errors
            if (errors) {
              setErrors(errors);
            }
            return;
          });
      } else {
        if (successMessage === undefined || successMessage === null) {
          setIsEditing(false);
        }
        setFormSubmitted(true);
      }
      // if we don't have a success message, we can change the form back to preview mode
      // where the default toast message will be shown
    } catch (error) {
      // errors, set errors
      if (error instanceof z.ZodError) {
        const parsedErrors: any = {};
        error.errors.forEach((err) => {
          parsedErrors[err.path.join('.')] = err.message;
        });

        setErrors(parsedErrors);
        return parsedErrors;
      }
    }
  };

  return (
    <WFormContext.Provider
      value={{
        isEditing,
        editByDefault,
        onSuccess,
        setIsEditing,
        isFormSubmitted,
        successMessage,
        setFormSubmitted,
        errors,
        setErrors,
        handleFormSubmit,
        formData,
        validateForm,
      }}
    >
      {/*
      Once the form is submitted, we want to show the success message if a success Message component has been provided
      If no success message has been provided, we want to show the default success message
       */}
      {isFormSubmitted &&
        Object.keys(errors).length === 0 &&
        successMessage === undefined && (
          <div className="tw-flex tw-p-2 tw-text-black tw-bg-[#6EE038] tw-rounded tw-mb-6">
            <i className={`wi wi-sm wi-check tw-mr-2`} />
            Details Saved
          </div>
        )}
      <form ref={form}>{children}</form>

      {/* If the success message is defined, even if it's null, we display it
        You can pass a WModal or any other component as a success message, or you can pass null to disable the default success message
       */}
      {isFormSubmitted &&
        Object.keys(errors).length === 0 &&
        successMessage != undefined &&
        successMessage}
    </WFormContext.Provider>
  );
};
