import React, { useEffect } from 'react';
import { useClientId } from 'shared_components/context/client';
import { useApi } from 'shared_components/context';
import { useWError } from 'shared_components/components/WError/WErrorProvider';
import { WError } from 'shared_components/components/WError/WError';
import WModal from 'shared_components/components/WModal';
import ThankyouBlock from 'shared_components/components/ThankYouBlock/ThankyouBlock';
import { pandadocForm } from './PassportAndForms';
import WButton from 'shared_components/components/WForms/WButton/WButton';
import PandadocPage from 'shared_components/components/PandadocPage/PandadocPage';
interface Props {
  passportFileLocation: string;
  taskId: string;
  setApproveWestburyForms: React.Dispatch<React.SetStateAction<boolean>>;
  fetchTasks: () => void;
}

function ApproveWestburyForms(props: Props) {
  const [pandadocsDocumentIds, setPandadocsDocumentIds] = React.useState<
    pandadocForm[]
  >([]);
  const [showSuccessModal, setShowSuccessModal] =
    React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [selectedPageIndex, setSelectedPageIndex] = React.useState<number>(1);
  const [formsApproved, setFormsApproved] = React.useState<boolean>(false);
  const { adminApi: api } = useApi();
  const { throwError } = useWError();
  const { clientId } = useClientId();

  const fetchData = async () => {
    if (!clientId) {
      throwError(new WError('no client id'));
      return;
    }
    try {
      setIsLoading(true);
      let data = await api.listClientSocietyFormTasks({ userId: clientId });
      data = data.filter((item) => item.status === 'admin_to_approve');
      // set power of attorney as the second document
      data.sort((a, b) => {
        if (a.societyFormName?.toLowerCase().includes('power')) return 1;
        return -1;
      });

      setPandadocsDocumentIds(
        data.map((item) => {
          return {
            sessionId: item.pandadocsSessionId,
            documentName: item.societyFormName,
            documentId: item.id,
          };
        })
      );
    } catch (error) {
      throwError(new WError('error fetching pandadocs document ids'));
    }
  };

  useEffect(() => {
    fetchData();
  }, [clientId]);

  const acceptForms = async () => {
    setIsLoading(true);
    pandadocsDocumentIds.map(async (item) => {
      if (!item.sessionId || !clientId || !item.documentId) return;
      try {
        await api.approveAdminApproveIdentityDocument({
          documentId: item.documentId,
          userId: clientId,
          dateOfBirth: '',
          documentNumber: '',
          documentExpiryDate: '',
        });
        setIsLoading(false);
        setShowSuccessModal(true);
        setFormsApproved(true);
      } catch {
        throwError(new WError(`error approving ${item.documentName}`));
        setIsLoading(false);
      }
    });
  };
  const rejectForms = async () => {
    // TODO: add reject functionality
  };
  window.addEventListener('message', (event: MessageEvent<any>) => {
    const type = event.data && event.data.type;

    switch (type) {
      case 'session_view.document.loaded':
        if (isLoading) setIsLoading(false);
    }
  });
  const passportFileLocationFileExtension = props.passportFileLocation
    .split('?')[0]
    .split('.')
    .pop();

  return (
    <>
      <WModal
        isOpen={formsApproved}
        onClose={() => props.setApproveWestburyForms(false)}
        title=""
        disableBackdropClick={true}
        hasCloseButton={false}
      >
        <ThankyouBlock
          title="Forms Approved"
          description="You have successfully approved the forms."
          cta={{
            ctaText: 'Return to Dashboard',
            onClick: () => {
              props.setApproveWestburyForms(false);
              props.fetchTasks();
            },
          }}
        />
      </WModal>
      <h2 className="tw-mb-4">Verify Signature</h2>
      <div className="tw-flex tw-w-full tw-p-5 tw-mt-2 tw-space-x-4">
        {/* First Column */}
        <div className="tw-w-1/2 tw-p-4 tw-bg-surfaceBlack90 tw-rounded-2 tw-text-white tw-rounded-lg">
          <p className="tw-mb-2">Passport Image</p>
          {passportFileLocationFileExtension?.toLocaleLowerCase() == 'pdf' ? (
            <embed
              src={props.passportFileLocation}
              type="application/pdf"
              className="passportImg"
              height="100%"
              width="100%"
            ></embed>
          ) : (
            <img
              src={props.passportFileLocation}
              alt="passport"
              className="passportImg"
            />
          )}
        </div>
        <div className="tw-w-1/2 tw-p-4 tw-bg-surfaceBlack90 tw-rounded-2 tw-text-white tw-rounded-lg">
          {isLoading && (
            <div
              className="tw-flex tw-items-center tw-justify-center"
              style={{ height: '100%' }}
            >
              <p className="tw-my-auto tw-animate-ping">⚪</p>
            </div>
          )}
          <div
            className={isLoading ? 'invisible' : 'tw-flex tw-flex-row tw-mb-4'}
          >
            <ApproveWestburyFormsTabs
              pandadocDocumentIds={pandadocsDocumentIds}
              setSelectedPageIndex={setSelectedPageIndex}
              selectedPageIndex={selectedPageIndex}
            />
          </div>
          {pandadocsDocumentIds.map(
            ({ sessionId: documentId }, index: number) => (
              <PandadocPage
                key={index}
                index={index}
                sessionId={documentId}
                selectedPageIndex={selectedPageIndex}
                isLoading={isLoading}
                iFrameStyle={{ width: '37vw', height: '70vh' }}
              />
            )
          )}
          {!isLoading && (
            <div className="tw-flex tw-justify-end tw-space-x-2 tw-mt-4">
              <WButton onClick={acceptForms} variant="primary" label="Accept" />
              <WButton
                onClick={rejectForms}
                variant="secondary"
                disabled={true}
                label="Decline"
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
}
interface ApproveWestburyFormsTabsProps {
  pandadocDocumentIds: pandadocForm[];
  setSelectedPageIndex: (arg0: number) => void;
  selectedPageIndex: number;
}
export const ApproveWestburyFormsTabs = ({
  pandadocDocumentIds,
  setSelectedPageIndex,
  selectedPageIndex,
}: ApproveWestburyFormsTabsProps) => {
  return (
    <div
      className="tw-border-b-[1px] tw-border-surfaceGraphite tw-space-x-4 tw-w-full"
      style={{ paddingBottom: '18px' }}
    >
      {pandadocDocumentIds.map((tab, index) => (
        <span
          key={index}
          role="button"
          className={`
      tw-pb-4
      ${
        selectedPageIndex === index + 1
          ? 'tw-border-b-[4px] tw-border-textLime tw-text-textLime tw-cursor-pointer'
          : 'tw-text-textDove tw-font-light tw-text-4 tw-cursor-pointer'
      }
    `}
          onClick={() => {
            setSelectedPageIndex(index + 1);
          }}
        >
          {tab.documentName?.includes('Power')
            ? 'Power of Attorney'
            : 'Client Mandate'}
        </span>
      ))}
    </div>
  );
};

export default ApproveWestburyForms;
