import React from 'react';
import WContainer from 'shared_components/components/WContainer';
import WButton from 'shared_components/components/WForms/WButton/WButton';

interface Props {
  onComplete: () => void;
  onClose: () => void;
  title?: string;
  description?: string;
}
const modalTitle = 'Is the Society Webform Complete?';
const modalDescription =
  'Did you successfully complete all the forms required through this society portal?';

export const WebformComplete = ({
  onClose,
  onComplete,
  title = modalTitle,
  description = modalDescription,
}: Props) => {
  return (
    <WContainer>
      <div className="tw-w-[450px] tw-flex tw-flex-col tw-pb-8">
        <p className="tw-text-2xl tw-pb-4 stw-font-medium">{title}</p>
        <p className="tw-pt-2 tw-text-base tw-font-normal">{description}</p>
      </div>
      <div className="tw-text-sm">
        <WButton
          onClick={onComplete}
          label="Complete"
          variant="primary"
          extraClasses="tw-mr-4"
        />
        <WButton onClick={onClose} label="Not yet" variant="link-secondary" />
      </div>
    </WContainer>
  );
};

export default WebformComplete;
