import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useApi } from 'shared_components/context';
import { CircleChart } from 'shared_components/components/CircleChart/CircleChart';
import { useClientId } from 'shared_components/context/client';
import ClientsChart from './Components/ClientsChart';
import PassportVerification from './Components/PassportVerification';
import DeclineMessage from './Components/DeclineMessage';
import EOIReviewCard from './Components/EOIReviewCard';
import ArtistDiscographyIDCard from './Components/ArtistDiscographyIDCard';
import MembershipFormModal from './Components/Dashboard/MembershipFormModal';
import {
  AdminTaskClient,
  AdminTaskClientClientAdminTasksInnerStatusEnum,
} from 'shared_components/generated/admin/models';
import WContainer from 'shared_components/components/WContainer/WContainer';
import { WTable } from 'shared_components/WTable/WTable';
import { WProgressBar } from 'shared_components/components/WProgressBar/WProgressBar';
import { useNavigate } from 'react-router-dom';
import ApproveWestburyForms from './Components/ApproveWestburyForms';
import { WError } from 'shared_components/components/WError/WError';
import { useWError } from 'shared_components/components/WError/WErrorProvider';
import ApproveMembershipForms from './Components/ApproveMembershipForms';
import saveAs from 'file-saver';

interface MergedClientAdminTask {
  id?: string;
  status?: AdminTaskClientClientAdminTasksInnerStatusEnum;
  societyForm?: string;
  primaryDiscographyExport?: string;
  pplReviewExport?: string;
  dateCreated: Date;
}

export interface MergedClientTask {
  id?: string;
  fullName?: string;
  username?: string;
  clientAdminTasks: Array<MergedClientAdminTask>;
}

function DashboardDetails() {
  const { adminApi: api } = useApi();
  const { throwError } = useWError();
  const [registerUsers, setRegisterUsers] = useState(75);
  const [verifyPassport, setVerifyPassport] = useState(false);
  const [showDeclineMessage, setShowDeclineMessage] = useState(false);
  const [passportId, setPassportId] = useState('');
  const [passportDateOfBirth, setPassportDateOfBirth] = useState('');
  const [passportNumber, setPassportNumber] = useState('');
  const [passportExpiryDate, setPassportExpiryDate] = useState('');
  const [passportFileLocation, setPassportFileLocation] = useState('');
  const [clientTasks, setClientTasks] = useState<Array<MergedClientTask>>([]);
  const [taskId, setTaskId] = useState('');
  const [continueButtonDisabled, setContinueButtonDisabled] = useState(true);
  const [eoiReviewID, setEoiReviewID] = useState<string>('');
  const [DiscographyID, setDiscographyID] = useState<string>();
  const [showEOIcard, setShowEOIcard] = useState(false);
  const [showDiscographyIDcard, setShowDiscographyIDcard] = useState(false);
  const [membershipForm, setMembershipForm] = useState(false);
  const navigate = useNavigate();
  const handleMembershipForm = () => {
    setMembershipForm(!membershipForm);
  };
  const [isLoading, setIsLoading] = useState(false);
  const things_to_do_status = new Map([
    ['verify_passport_and_send_forms', 'Verify passport and send forms'],
    ['expression_of_interest_review', 'Review EOI form'],
    ['submit_discography_id', 'EOI Submitted. Enter discography ID’s'],
    ['sign_society_form', 'Send membership forms'],
    ['approve_westbury_forms', 'Review signed WMC forms'],
    ['approve_membership_forms', 'Review signed society membership forms'],
    ['download_primary_discography_export', 'Download exported discography'],
    ['download_ppl_review_export', 'Download PPL review export'],
  ]);
  const { clientId, setClientId, setClientEmail } = useClientId();
  const [approveWestburyForms, setApproveWestburyForms] = useState(false);
  const [approveMembershipForms, setApproveMembershipForms] = useState(false);

  const fetchData = () => {
    setIsLoading(true);
    api
      .listAdminTaskClients()
      .then((adminClientTasks) => {
        const mergedData: Array<MergedClientTask> = [];
        const clientIds: Array<string> = [];
        adminClientTasks.forEach((clientTask) => {
          const mergedTask = clientTaskToMergedClientTask(clientTask);
          mergedData.push(mergedTask);
          clientIds.push(mergedTask.id?.toString()!);
        });
        setClientTasks(mergedData);
        setIsLoading(false);
      })
      .catch(() => {
        throwError(new WError('Something went wrong'));
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const acceptPassport = () => {
    if (clientId === undefined) {
      throwError(new WError('Something went wrong'));
      return Promise.reject();
    }

    return api
      .approveAdminApproveIdentityDocument({
        userId: clientId,
        documentId: passportId,
        dateOfBirth: passportDateOfBirth,
        documentNumber: passportNumber,
        documentExpiryDate: passportExpiryDate,
      })
      .then((response) => {
        setPassportDateOfBirth('');
        setPassportNumber('');
        setPassportExpiryDate('');
        navigate(`/terms-of-agreement/${clientId}`, {
          state: { taskId: taskId },
        });
        return Promise.resolve(response);
      })
      .catch((error) => {
        setPassportDateOfBirth('');
        setPassportNumber('');
        setPassportExpiryDate('');
        return Promise.reject();
      });
  };

  const declineMessage = () => {
    if (clientId === undefined) {
      return;
    }

    setVerifyPassport(false);
    setPassportDateOfBirth('');
    setPassportNumber('');
    setPassportExpiryDate('');

    setShowDeclineMessage(true);

    api
      .declineAdminDeclineIdentityDocument({
        userId: clientId,
        documentId: passportId,
      })
      .then(() => {
        api.destroyAdminTaskClient({ taskId });
      })
      .catch(() => {});
  };

  const deleteTaskOnConfirmed = () => {
    api
      .destroyAdminTaskClient({
        taskId,
      })
      .then(() => {
        fetchData();
        setShowEOIcard(false);
      })
      .catch(() => {});
  };

  const handleThingsToDoStatusClick = async (
    clientId: string,
    clientUsername: string,
    task: MergedClientAdminTask
  ) => {
    setClientEmail(clientUsername);
    setClientId(clientId);
    setTaskId(task.id!);
    const clientTaskStatus = task.status;
    if (clientTaskStatus == 'verify_passport_and_send_forms') {
      api
        .listAdminIdentityDocumentSubmissions({
          userId: clientId,
        })
        .then((response) => {
          response.forEach((document) => {
            if (document.documentType === 'Passport') {
              setPassportId(document.id!);
              setPassportFileLocation(document.fileUrl!);
            }
          });
        });
      setVerifyPassport(true);
    } else if (clientTaskStatus == 'expression_of_interest_review') {
      showCard();
      setEoiReviewID(clientId);
      setShowEOIcard(true);
    } else if (clientTaskStatus == 'submit_discography_id') {
      showCard();
      setDiscographyID(clientId);
      setShowDiscographyIDcard(true);
    } else if (clientTaskStatus == 'sign_society_form') {
      navigate(`/society-forms/${clientId}`, {
        state: { taskId: task.id! },
      });
    } else if (clientTaskStatus == 'approve_westbury_forms') {
      api
        .listAdminIdentityDocumentSubmissions({
          userId: clientId,
        })
        .then((response) => {
          response.forEach((document) => {
            if (document.documentType === 'Passport') {
              setPassportId(document.id!);
              setPassportFileLocation(document.fileUrl!);
            }
          });
        });
      setApproveWestburyForms(true);
    } else if (clientTaskStatus == 'approve_membership_forms') {
      api
        .listAdminIdentityDocumentSubmissions({
          userId: clientId,
        })
        .then((response) => {
          response.forEach((document) => {
            if (document.documentType === 'Passport') {
              setPassportId(document.id!);
              setPassportFileLocation(document.fileUrl!);
            }
          });
        });
      setApproveMembershipForms(true);
    } else if (clientTaskStatus == 'download_primary_discography_export') {
      if (!task.primaryDiscographyExport) {
        return;
      }

      api
        .retrievePrimaryDiscographyExport({
          userId: clientId,
          exportId: task.primaryDiscographyExport,
        })
        .then((primaryDiscographyExport) => {
          const url = primaryDiscographyExport.s3Url;
          if (!url) {
            return;
          }
          const pathComponents = new URL(url).pathname.split('/');
          const fileName = decodeURIComponent(
            pathComponents.pop() ?? 'export.xlsx'
          );
          return fetch(url).then(async (response) =>
            saveAs(await response.blob(), fileName)
          );
        })
        .then(() => {
          return api.destroyAdminTaskClient({ taskId: task.id! });
        })
        .then(() => fetchData())
        .catch(() => {
          throwError(new WError('Something went wrong'));
        });
    } else if (clientTaskStatus == 'download_ppl_review_export') {
      if (!task.pplReviewExport) {
        return;
      }

      const pplPerformerName = await api
        .retrieveUserSettings({ userId: clientId })
        .then((userSettings) => userSettings.pplPerformerName)
        .catch(() => {});

      api
        .retrievePPLReviewExport({
          userId: clientId,
          exportId: task.pplReviewExport,
        })
        .then((pplReviewExport) => {
          const url = pplReviewExport.s3Url;
          if (!url) {
            return;
          }
          let fileName = 'export.xlsx';
          if (pplPerformerName) {
            fileName = `PPL Review – ${pplPerformerName}.xlsx`;
          }
          return fetch(url).then(async (response) =>
            saveAs(await response.blob(), fileName)
          );
        })
        .then(() => {
          return api.destroyAdminTaskClient({ taskId: task.id! });
        })
        .then(() => fetchData())
        .catch(() => {
          throwError(new WError('Something went wrong'));
        });
    }
  };

  const handleDiscographyRunComplete = () => {
    fetchData();
  };

  const rawData = clientTasks.flatMap((clientTask) => {
    const [onboardingTasks, exportDownloadTasks] =
      clientTask.clientAdminTasks.reduce<
        [MergedClientAdminTask[], MergedClientAdminTask[]]
      >(
        (acc, task) => {
          const i =
            task.status ===
              AdminTaskClientClientAdminTasksInnerStatusEnum.DownloadPrimaryDiscographyExport ||
            task.status ===
              AdminTaskClientClientAdminTasksInnerStatusEnum.DownloadPplReviewExport
              ? 1
              : 0;
          acc[i].push(task);
          return acc;
        },
        [[], []]
      );

    return [...onboardingTasks.slice(0, 1), ...exportDownloadTasks].map(
      (task) => {
        return {
          artist: clientTask.fullName ?? clientTask.username,
          action: things_to_do_status.get(task.status ?? ''),
          dateCreated: task.dateCreated,
          progress: (3 / 8) * 100,
        };
      }
    );
  });

  const tableData = clientTasks.flatMap((clientTask) => {
    const [onboardingTasks, exportDownloadTasks] =
      clientTask.clientAdminTasks.reduce<
        [MergedClientAdminTask[], MergedClientAdminTask[]]
      >(
        (acc, task) => {
          const i =
            task.status ===
              AdminTaskClientClientAdminTasksInnerStatusEnum.DownloadPrimaryDiscographyExport ||
            task.status ===
              AdminTaskClientClientAdminTasksInnerStatusEnum.DownloadPplReviewExport
              ? 1
              : 0;
          acc[i].push(task);
          return acc;
        },
        [[], []]
      );

    return [...onboardingTasks.slice(0, 1), ...exportDownloadTasks].map(
      (task) => {
        return {
          artist: clientTask.fullName ?? clientTask.username,
          action: (
            <div
              role="button"
              tabIndex={0}
              onClick={() =>
                handleThingsToDoStatusClick(
                  clientTask.id!.toString(),
                  clientTask.username!.toString(),
                  task
                )
              }
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleThingsToDoStatusClick(
                    clientTask.id!.toString(),
                    clientTask.username!.toString(),
                    task
                  );
                }
              }}
            >
              {things_to_do_status.get(task.status ?? '')}
            </div>
          ),
          dateCreated: task.dateCreated?.toLocaleDateString().toString() || '-',
          progress: (
            <WProgressBar
              percentage={(3 / 8) * 100}
              label={'Registered at 3 out of 8 '}
            />
          ),
        };
      }
    );
  });

  const showCard = () => {
    document.body.classList.add('hide-scroll');
  };

  const hideCard = () => {
    document.body.classList.remove('hide-scroll');
    setShowEOIcard(false);
    setShowDiscographyIDcard(false);
  };

  return (
    <>
      {membershipForm ? (
        <MembershipFormModal
          showModal={membershipForm}
          modalClose={handleMembershipForm}
          clientId={clientId?.toString()!}
        />
      ) : (
        ''
      )}
      <EOIReviewCard
        deleteTaskOnConfirmed={deleteTaskOnConfirmed}
        onComplete={() => {
          setShowEOIcard(false);
          fetchData();
        }}
        cardEvent={hideCard}
        eoiReviewID={eoiReviewID}
        isOpen={showEOIcard}
      />
      <ArtistDiscographyIDCard
        onClose={hideCard}
        onComplete={handleDiscographyRunComplete}
        isOpen={showDiscographyIDcard}
      />
      {verifyPassport ? (
        <>
          <PassportVerification
            setPassportDateOfBirth={setPassportDateOfBirth}
            setPassportNumber={setPassportNumber}
            setPassportExpiryDate={setPassportExpiryDate}
            setVerifyPassport={setVerifyPassport}
            hideVerification={setVerifyPassport}
            declineMessage={declineMessage}
            acceptPassport={acceptPassport}
            passportFileLocation={passportFileLocation}
            clientTasks={clientTasks}
            taskId={taskId}
            continueButtonDisabled={continueButtonDisabled}
            passportDateOfBirth={passportDateOfBirth}
            passportNumber={passportNumber}
            passportExpiryDate={passportExpiryDate}
          />{' '}
        </>
      ) : showDeclineMessage ? (
        <DeclineMessage
          setShowDeclineMessage={setShowDeclineMessage}
          declineMessage={declineMessage}
        />
      ) : approveWestburyForms ? (
        <>
          <Link
            to="/dashboard"
            onClick={() => setApproveWestburyForms(false)}
            className="back-button tw-text-white"
          >
            Back
          </Link>
          <ApproveWestburyForms
            passportFileLocation={passportFileLocation}
            taskId={taskId}
            setApproveWestburyForms={setApproveWestburyForms}
            fetchTasks={fetchData}
          />
        </>
      ) : approveMembershipForms ? (
        <>
          <Link
            to="/dashboard"
            onClick={() => setApproveMembershipForms(false)}
            className="back-button tw-text-white"
          >
            Back
          </Link>
          <ApproveMembershipForms
            passportFileLocation={passportFileLocation}
            taskId={taskId}
            setApproveMembershipForms={setApproveMembershipForms}
            fetchTasks={fetchData}
          />
        </>
      ) : (
        <>
          <h1 className="tw-text-page-heading tw-text-white tw-mb-4">
            Membership Dashboard
          </h1>
          <div
            className="tw-flex tw-w-full tw-space-x-6 tw-max-w-[1500px]"
            style={{ maxWidth: '1584px', width: '100%' }}
          >
            <WContainer
              extraClasses="tw-min-w-[302px] tw-flex tw-flex-col tw-justify-between"
              disabled
              title="Users Registered"
            >
              <h2>
                <span className="tw-uppercase tw-text-sm">
                  Users registered
                </span>
              </h2>
              <CircleChart
                value={registerUsers}
                text="of all users registered"
              ></CircleChart>
              <div className="tw-text-right">
                <Link to="/" className="viewLink">
                  View
                </Link>
              </div>
            </WContainer>
            <WContainer
              extraClasses="tw-flex-grow tw-overflow-scroll tw-scrollbar-none"
              disabled
              title="Society Overview"
            >
              <h2>
                <span className="tw-uppercase tw-text-sm tw-pb-2">
                  Society overview
                </span>
              </h2>
              <div
                className="tw-overflow-x-scroll tw-overflow-scroll tw-scrollbar-none"
                style={{}}
              >
                <ClientsChart />
              </div>
            </WContainer>
          </div>

          {/*
              BOTTOM ROW
            */}
          <div className="dsCol mt-4 pt-1">
            <div className=" tw-text-white tw-flex tw-justify-between">
              <h2 className="tw-font-sans tw-text-xl tw-font-semibold">
                Tasks
              </h2>
            </div>
            <WContainer>
              {!isLoading && (
                <WTable
                  columnStyle={{ borderBottom: 'none', padding: '0px' }}
                  columns={[
                    {
                      title: 'Artist',
                      dataKey: 'artist',
                      sort: true,
                    },
                    {
                      title: 'Action',
                      dataKey: 'action',
                      sort: true,
                    },
                    {
                      title: 'Date Added',
                      dataKey: 'dateCreated',
                      sort: true,
                    },
                    // Phase II work when BE has functionality
                    // { title: 'Status', dataKey: 'progress' },
                  ]}
                  data={tableData}
                  rawData={rawData}
                ></WTable>
              )}
              {isLoading && (
                <div className="tw-flex tw-justify-center">
                  <p className="tw-my-auto tw-animate-ping">⚪</p>
                </div>
              )}
            </WContainer>
          </div>
        </>
      )}
    </>
  );
}

export default DashboardDetails;

const clientTaskToMergedClientTask = (
  clientTask: AdminTaskClient
): MergedClientTask => {
  const obj: MergedClientTask = {
    id: clientTask.id?.toString(),
    fullName: clientTask.fullName,
    username: clientTask.username,
    clientAdminTasks: clientTask.clientAdminTasks.map<MergedClientAdminTask>(
      (task) => {
        const primaryDiscographyExport =
          clientTask.primaryDiscographyExports?.find(
            (primaryDiscographyExport) =>
              primaryDiscographyExport.task === task.id
          );
        const pplReviewExport = clientTask.pplReviewExports?.find(
          (pplReviewExport) => pplReviewExport.task === task.id
        );
        return {
          id: task.id,
          status: task.status,
          primaryDiscographyExport: primaryDiscographyExport?.id,
          pplReviewExport: pplReviewExport?.id,
          dateCreated: task.dateCreated || new Date(),
        };
      }
    ),
  };
  return obj;
};
