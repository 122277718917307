import clsx from 'clsx';
import { ChangeEvent, forwardRef } from 'react';
import styles from './WCheckBox.module.css';

interface WCheckBoxProps {
  name?: string;
  label?: string;
  checked: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  checkboxSize?: 'xs' | 'sm' | 'md';
  extraClasses?: string;
  extraLabelClasses?: string;
}

type WCheckBoxRef = HTMLInputElement;

export const WCheckBox = forwardRef<WCheckBoxRef, WCheckBoxProps>(
  (
    {
      name,
      label,
      checked,
      onChange,
      checkboxSize = 'md',
      extraClasses,
      extraLabelClasses,
    }: WCheckBoxProps,
    ref
  ) => {
    return (
      <label
        className={clsx(
          'tw-flex tw-items-center tw-cursor-pointer',
          styles.container,
          extraClasses
        )}
      >
        <input
          type="checkbox"
          name={name}
          checked={checked}
          onChange={onChange}
          ref={ref}
          className={clsx('tw-absolute tw-opacity-0', {
            'tw-w-[16px] tw-h-[16px]': checkboxSize === 'xs',
            'tw-w-[20px] tw-h-[20px]': checkboxSize === 'sm',
            'tw-w-[32px] tw-h-[32px]': checkboxSize === 'md',
          })}
        />
        <div
          className={clsx(
            'tw-flex tw-justify-center tw-items-center tw-flex-shrink-0 tw-border-white tw-cursor-pointer',
            {
              'tw-rounded-[1px] tw-border-[1px] tw-w-[16px] tw-h-[16px]':
                checkboxSize === 'xs',
              'tw-rounded-[2px] tw-border-[2px] tw-w-[20px] tw-h-[20px]':
                checkboxSize === 'sm',
              'tw-rounded-[4px] tw-border-[3px] tw-w-[32px] tw-h-[32px]':
                checkboxSize === 'md',
              'tw-bg-surfaceBlack80': checked,
            }
          )}
        >
          {checked && (
            <i
              className={clsx(
                'wi wi-check -tw-translate-x-[12%]',
                { 'tw-w-[16px] tw-h-[16px]': checkboxSize === 'xs' },
                { 'tw-w-[20px] tw-h-[20px]': checkboxSize === 'sm' },
                { 'tw-w-[32px] tw-h-[32px]': checkboxSize === 'md' }
              )}
            />
          )}
        </div>
        {label !== undefined && (
          <div
            className={clsx(
              'tw-ml-2 tw-text-wrap tw-pr-[32px]',
              'tw-font-light',
              {
                'tw-text-white': checked,
                'tw-text-textDove': !checked,
              },
              extraLabelClasses
            )}
          >
            {label}
          </div>
        )}
      </label>
    );
  }
);
