/* tslint:disable */
/* eslint-disable */
/**
 * Westbury Music API Admin
 * API for Westbury Music admin users
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AdminUpdateSocietyCredentials
 */
export interface AdminUpdateSocietyCredentials {
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateSocietyCredentials
     */
    registeredArtistName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateSocietyCredentials
     */
    accountId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateSocietyCredentials
     */
    username?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateSocietyCredentials
     */
    password?: string | null;
}

/**
 * Check if a given object implements the AdminUpdateSocietyCredentials interface.
 */
export function instanceOfAdminUpdateSocietyCredentials(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AdminUpdateSocietyCredentialsFromJSON(json: any): AdminUpdateSocietyCredentials {
    return AdminUpdateSocietyCredentialsFromJSONTyped(json, false);
}

export function AdminUpdateSocietyCredentialsFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdminUpdateSocietyCredentials {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'registeredArtistName': !exists(json, 'registered_artist_name') ? undefined : json['registered_artist_name'],
        'accountId': !exists(json, 'account_id') ? undefined : json['account_id'],
        'username': !exists(json, 'username') ? undefined : json['username'],
        'password': !exists(json, 'password') ? undefined : json['password'],
    };
}

export function AdminUpdateSocietyCredentialsToJSON(value?: AdminUpdateSocietyCredentials | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'registered_artist_name': value.registeredArtistName,
        'account_id': value.accountId,
        'username': value.username,
        'password': value.password,
    };
}

