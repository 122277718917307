interface ProgressBarProps {
  percentage: number;
  label: string;
}

export const WProgressBar: React.FC<ProgressBarProps> = ({
  percentage,
  label,
}) => {
  return (
    //  Container for the progress bar
    <div className="tw-relative tw-w-full">
      {/* Text to display progress */}
      <span className="tw-pb-2 tw-block tw-text-textWhite tw-text-xs">
        {label}
      </span>

      {/* Actual progress bar container */}
      <span className="tw-block tw-bg-surfaceBlack80 tw-h-[4px] tw-relative">
        {/* Progress bar fill */}
        <span
          className="tw-absolute tw-h-full tw-bg-blue-500"
          style={{ maxWidth: `${percentage}%`, width: `${percentage}%` }}
        ></span>
      </span>
    </div>
  );
};
