import React, { useState, Component, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Footer from './Footer/Footer';
import Sidebar from './Sidebar/Sidebar';
import Header from './Header/Header';
import DiscographyDetails from './DiscographyDetails';
import { ClientProvider } from 'shared_components/context/client';
import PageLayout from './PageLayout';

function DiscographyNew() {
  const [sidebarSmall, setSidebarSmall] = useState(false);

  return (
    <PageLayout>
      <h2>Discography Dashboard</h2>
      <DiscographyDetails />
    </PageLayout>
  );
}

export default DiscographyNew;
