import React from 'react';
import { useFormContext } from './WFormContext';
import WButton from './WButton/WButton';

interface WFormFooterProps {
  successLabel?: string;
  cancelLabel?: string;
  setIsEditingGlobal?: (isEditing: boolean) => void;
}

export const WFormFooter: React.FC<WFormFooterProps> = ({
  successLabel = 'Save Changes',
  cancelLabel = 'Cancel',
  setIsEditingGlobal,
}) => {
  const {
    isEditing,
    handleFormSubmit,
    formData,
    setIsEditing,
    isFormSubmitted,
    setFormSubmitted,
  } = useFormContext();

  return (
    <>
      <div className="tw-relative tw-w-full tw-text-black tw-mt-8">
        {isEditing && (
          <div className="tw-flex tw-justify-start tw-space-x-4 tw-items-center">
            <WButton
              label={successLabel}
              variant="primary-lg"
              onClick={() => {
                handleFormSubmit(formData);
              }}
            />

            <WButton
              label={cancelLabel}
              variant="outline-light"
              onClick={() => {
                setFormSubmitted(false);
                setIsEditing(false);
                if (setIsEditingGlobal) {
                  setIsEditingGlobal(false);
                }
              }}
            ></WButton>
          </div>
        )}
        {/* Show success message after form is submitted */}
      </div>
    </>
  );
};
