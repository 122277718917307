import {
  defaultHeaders,
  getTokenFromSessionStorage,
  saveTokenToSessionStorage,
  tokenRefresh,
} from 'shared_components/context/auth';

const customFetch = async (
  url: string,
  init: RequestInit = {}
): Promise<Response> => {
  init.headers = {
    ...init.headers,
    ...defaultHeaders(),
  };

  const response = await fetch(url, init);

  if (response.status === 401) {
    return response.json().then(async ({ code }) => {
      if (code !== 'token_not_valid') {
        return response;
      }

      const token = getTokenFromSessionStorage();

      if (token === null) {
        location.pathname = '/';
        return response;
      }

      await tokenRefresh(token.refresh).then((response) => {
        saveTokenToSessionStorage({ ...token, access: response.access });
        init.headers = {
          ...init.headers,
          ...defaultHeaders(),
        };
      });

      return fetch(url, init);
    });
  }

  return response;
};

export default customFetch;
