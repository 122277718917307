import WContainer from '../WContainer/WContainer';
import { SocietiesList } from './SocietiesList';
import { SocietiesListProps } from './SocietiesList';

interface SocietiesListBoxProps extends SocietiesListProps {
  // addNewSocietyCallback: () => void;
}

export const SocietiesListBox: React.FC<SocietiesListBoxProps> = (props) => {
  return (
    <WContainer extraClasses="tw-w-1/4 tw-max-h-[1100px]">
      <div className={`tw-flex tw-justify-between tw-mb-4`}>
        <span className={`tw-text-2xl`}>Societies</span>
        {/* <button onClick={props.addNewSocietyCallback}>Add new society</button> */}
      </div>

      <SocietiesList {...props} />
    </WContainer>
  );
};
