import { Button, Modal } from "react-bootstrap";
import { format } from "date-fns";
import statementLogo from "shared_components/public/assets/statementLogo.png";
import {
  PaymentStatusEnum,
  StatementsJsonPaymentsInner,
} from "shared_components/generated/admin";
import { StatementsJsonMetadata } from "shared_components/generated/client";

interface Props {
  show: boolean;
  modalClose: () => void;
  statementData: StatementsJsonPaymentsInner[];
  paymentData: StatementsJsonMetadata;
  statementPdf: string;
}

function ViewStatement({
  show,
  modalClose,
  paymentData: paymentDetail,
  statementData,
  statementPdf,
}: Props) {
  const numToString = (num: number) => {
    const parseNumber = num / 100;
    const toLocale = parseNumber.toLocaleString(navigator.language, {
      minimumFractionDigits: 2,
    });
    return toLocale;
  };

  type StatusMap = {
    [STATUS in PaymentStatusEnum]: string;
  };

  const statusMap: StatusMap = {
    cleared: "Cleared",
    to_be_transferred: "Awaiting transfer",
    waiting_cheque_clear: "Awaiting cheque clearance",
    held: "Held",
    // "failed": "Failed",
  };

  return (
    <Modal show={show} onHide={modalClose} className="AgreementModal">
      <Button className="ModalClose" onClick={modalClose}>
        close
      </Button>
      <Modal.Body>
        <div className="AgreementModalData">
          <div className="agreementHeader">
            <div className="d-flex justify-content-end modalTopButtons">
              <a
                href={statementPdf}
                className="btn download-btn button-border"
                download={`${format(
                  new Date(paymentDetail.paymentDate),
                  "do-LLLL-yyyy"
                )}.pdf`}
              >
                Download
              </a>
            </div>
          </div>
          <div className="modalDocument">
            <div className="statementDoc">
              <div className="StatementHeader">
                <img src={statementLogo} className="d-block" />
              </div>
              <div className="statementFor d-flex justify-content-between">
                <div className="clientInfo">
                  <span className="d-block name">Adam Nutter</span>
                  <address>
                    90 westfield Lane
                    <br />
                    Kippax
                    <br />
                    Leeds
                    <br />
                    LS25 7HU
                    <br />
                    United Kingdom
                  </address>
                </div>
                <div className="companyInfo">
                  <span className="d-block name">
                    Westbury Music Consultants Ltd.
                  </span>
                  <address>
                    21 Broadoaks Way
                    <br />
                    Bromley, Kent <br />
                    BR2 0UA
                    <br />
                    United Kingdom
                  </address>
                  <span className="contactNo d-block">
                    +44 (0) 208 1337 265
                  </span>
                  <span className="email d-block">
                    <a href="mailto:royalties@westburymusic.co.uk">
                      royalties@westburymusic.co.uk
                    </a>
                  </span>
                  <span className="d-block mt-3">
                    {format(
                      new Date(paymentDetail.paymentDate),
                      "do LLLL, yyyy"
                    )}
                  </span>
                </div>
              </div>
              <div className="statementDetailTable">
                <h2 className="stTitle text-uppercase">Statement</h2>
                <div className="statementTable">
                  <table className="statement">
                    <thead>
                      <tr>
                        <th colSpan={1}>
                          Received <br />
                          Date
                        </th>
                        <th colSpan={2}>Collection Society</th>
                        <th colSpan={1}>
                          Gross <br />
                          Received
                        </th>
                        <th colSpan={1}>Commission</th>
                        <th colSpan={1}>
                          Amount
                          <br />
                          Paid out
                        </th>
                        <th colSpan={1}>
                          Date <br />
                          Paid out
                        </th>
                        <th colSpan={1}>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {statementData.map((statement, index) => (
                        <tr key={`${index}`}>
                          <td colSpan={1}>
                            {format(
                              new Date(statement.dateCreated!),
                              "dd/MM/yyyy"
                            )}
                          </td>
                          <td colSpan={2}>{statement.fromSociety}</td>
                          <td colSpan={1}>
                            &#163; {numToString(statement.totalAmountReceived)}
                          </td>
                          <td colSpan={1}>
                            &#163; {numToString(statement.comission)}
                          </td>
                          <td colSpan={1}>
                            &#163; {numToString(statement.amountPaidToClient)}
                          </td>
                          <td colSpan={1}>
                            {format(
                              new Date(statement.paymentDate),
                              "dd/MM/yyyy"
                            )}
                          </td>
                          <td colSpan={2}>{statusMap[statement.status]}</td>
                        </tr>
                      ))}
                      <tr>
                        <td colSpan={1}>&nbsp;</td>
                        <td colSpan={2}>&nbsp;</td>
                        <td colSpan={1}>&nbsp;</td>
                        <td colSpan={1}>&nbsp;</td>
                        <td colSpan={1}>&nbsp;</td>
                        <td colSpan={1}>&nbsp;</td>
                        <td colSpan={2}>&nbsp;</td>
                      </tr>
                      <tr>
                        <td colSpan={1}>&nbsp;</td>
                        <td colSpan={2}>&nbsp;</td>
                        <td colSpan={1}>&nbsp;</td>
                        <td colSpan={1}>&nbsp;</td>
                        <td colSpan={1}>&nbsp;</td>
                        <td colSpan={1}>&nbsp;</td>
                        <td colSpan={2}>&nbsp;</td>
                      </tr>
                      <tr className="totalPayRow">
                        <td colSpan={6} className="b-0"></td>
                        <td colSpan={1}>Total</td>
                        <td colSpan={2}>{paymentDetail.totalAmountReceived}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="paymentDetailsTable">
                <table>
                  <thead>
                    <tr>
                      <th colSpan={2} className="payHeader">
                        Payment Details
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="payhead">Client Name</td>
                      <td>{paymentDetail.clientName}</td>
                    </tr>
                    <tr>
                      <td className="payhead">Remittance Method:</td>
                      <td>{paymentDetail.remittanceMethod}</td>
                    </tr>
                    <tr>
                      <td className="payhead">Payment Date:</td>
                      <td>
                        {format(
                          new Date(paymentDetail.paymentDate),
                          "do LLLL, yyyy"
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="payhead">Total Payable:</td>
                      <td>{paymentDetail.totalPayable}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ViewStatement;
