import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useFormContext } from '../WFormContext'; // Make sure to import your useFormContext correctly
import z from 'zod';
import { Constants } from '../../../utils/Countries';
import SquarePlaceholder185 from 'shared_components/public/assets/SquarePlaceholder185.svg';

interface InputFieldProps {
  type: string;
  name: string;
  value: string | undefined;
  label: string;
  hideOnView?: boolean; // Optional prop to hide the input field when not editing
  onBlur?: () => void;
  onChange: (value: ChangeEvent<HTMLInputElement>) => void | boolean; // onChange should return true if the value is valid, false if not
}

export const WAvatar: React.FC<InputFieldProps> = ({
  type,
  name,
  value,
  label,
  hideOnView = false,
  onBlur,
  onChange,
}) => {
  const { isEditing, isFormSubmitted, errors } = useFormContext(); // Fetch errors from context
  const [innerError, setInnerError] = useState<string | null>(null); // Local state for errors
  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return;
    setInnerError(null);
    const fileSchema = z
      .object({
        avatar: z.any(),
      })
      .refine(
        (data) => {
          if (data.avatar !== undefined) {
            return (
              data.avatar.size <= Constants.MAX_FILE_SIZE &&
              Constants.IMAGE_FILE_TYPES.includes(data.avatar.type)
            );
          }
        },
        {
          path: ['avatar'],
          message:
            'Profile picture should be a valid jpg, jpeg or png and less than 5MB',
        }
      );
    const parsedFile = fileSchema.safeParse({
      avatar: event.target.files[0],
    });

    if (parsedFile.success === false) {
      const error = parsedFile.error;
      // get the first error message
      const errorMessage = error.issues[0].message;
      // set the error message in the local state
      setInnerError(errorMessage);
      return;
    }
    onChange(event);
    setInnerValue(URL.createObjectURL(event.target.files[0]));
  };

  const [innerValue, setInnerValue] = useState<string | undefined>();

  useEffect(() => {
    setInnerValue(value);
  }, [value]);

  return (
    <div className="tw-flex tw-flex-col ">
      <div style={{ width: '185px', height: '185px' }} className={``}>
        <div className="tw-relative">
          <img
            className={`tw-rounded-full tw-h-[185px] `}
            src={
              isEditing
                ? innerValue ?? SquarePlaceholder185
                : value ?? SquarePlaceholder185
            }
            alt="Avatar"
          />
          {isEditing && (
            <div
              onClick={() => {
                fileInputRef.current?.click(); // The optional chaining is used to ensure that the current is not null
              }}
              className="tw-cursor-pointer tw-w-11 tw-h-11 tw-p-2.5 tw-bg-surfaceLime tw-rounded-[360px] tw-justify-center tw-items-center tw-inline-flex tw-absolute tw-bottom-0 tw-right-0"
            >
              <i
                className="wi wi-20px wi-edit"
                style={{ backgroundColor: 'black' }}
              />
              <input
                ref={fileInputRef}
                type="file"
                name={name}
                className="tw-hidden"
                onChange={(e) => {
                  handleFileChange(e);
                }}
              />
            </div>
          )}
        </div>
      </div>
      <div className="tw-flex tw-flex-col tw-mt-4">
        <span className={`tw-text-sm`}>{label}</span>
        {innerError && (
          <span className={`tw-text-red-500 tw-text-sm`}>{innerError}</span>
        )}
      </div>
    </div>
  );
};
