/* tslint:disable */
/* eslint-disable */
/**
 * Westbury Music API
 * API for Westbury Music
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ClientIdentityDocumentSubmission
 */
export interface ClientIdentityDocumentSubmission {
    /**
     * 
     * @type {string}
     * @memberof ClientIdentityDocumentSubmission
     */
    readonly id?: string;
    /**
     * 
     * @type {Date}
     * @memberof ClientIdentityDocumentSubmission
     */
    readonly dateCreated?: Date;
    /**
     * 
     * @type {Blob}
     * @memberof ClientIdentityDocumentSubmission
     */
    file?: Blob;
    /**
     * 
     * @type {string}
     * @memberof ClientIdentityDocumentSubmission
     */
    filename?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientIdentityDocumentSubmission
     */
    status?: ClientIdentityDocumentSubmissionStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ClientIdentityDocumentSubmission
     */
    documentExpiryDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientIdentityDocumentSubmission
     */
    documentType?: ClientIdentityDocumentSubmissionDocumentTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ClientIdentityDocumentSubmission
     */
    readonly fileUrl?: string;
}


/**
 * @export
 */
export const ClientIdentityDocumentSubmissionStatusEnum = {
    PendingDecision: 'pending_decision',
    Invalid: 'invalid',
    Accepted: 'accepted'
} as const;
export type ClientIdentityDocumentSubmissionStatusEnum = typeof ClientIdentityDocumentSubmissionStatusEnum[keyof typeof ClientIdentityDocumentSubmissionStatusEnum];

/**
 * @export
 */
export const ClientIdentityDocumentSubmissionDocumentTypeEnum = {
    Passport: 'Passport',
    CurrentAgreement: 'Current_Agreement',
    TerminationConfirmation: 'Termination_Confirmation',
    ProofOfAddress: 'Proof_Of_Address',
    PowerOfAttorney: 'Power_Of_Attorney',
    TermsOfAgreement: 'Terms_Of_Agreement'
} as const;
export type ClientIdentityDocumentSubmissionDocumentTypeEnum = typeof ClientIdentityDocumentSubmissionDocumentTypeEnum[keyof typeof ClientIdentityDocumentSubmissionDocumentTypeEnum];


/**
 * Check if a given object implements the ClientIdentityDocumentSubmission interface.
 */
export function instanceOfClientIdentityDocumentSubmission(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ClientIdentityDocumentSubmissionFromJSON(json: any): ClientIdentityDocumentSubmission {
    return ClientIdentityDocumentSubmissionFromJSONTyped(json, false);
}

export function ClientIdentityDocumentSubmissionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClientIdentityDocumentSubmission {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'dateCreated': !exists(json, 'date_created') ? undefined : (new Date(json['date_created'])),
        'file': !exists(json, 'file') ? undefined : json['file'],
        'filename': !exists(json, 'filename') ? undefined : json['filename'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'documentExpiryDate': !exists(json, 'document_expiry_date') ? undefined : json['document_expiry_date'],
        'documentType': !exists(json, 'document_type') ? undefined : json['document_type'],
        'fileUrl': !exists(json, 'file_url') ? undefined : json['file_url'],
    };
}

export function ClientIdentityDocumentSubmissionToJSON(value?: ClientIdentityDocumentSubmission | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'file': value.file,
        'filename': value.filename,
        'status': value.status,
        'document_expiry_date': value.documentExpiryDate,
        'document_type': value.documentType,
    };
}

