import { Link } from 'react-router-dom';
import clsx from 'clsx';
import React from 'react';
interface WLinkProps {
  to: string;
  label: string;
  icon?: string;
  onClick?: () => void;
  variant?: string; // Currently only 'disabled' is supported, but we can add more variants in the future
  additionalPaths?: Array<string>;
}

export const WLink: React.FC<WLinkProps> = ({
  to,
  label,
  icon = '',
  onClick = () => {},
  variant = 'primary',
  additionalPaths,
}) => {
  const currentPath = location.pathname;

  const isAdditionalPath =
    additionalPaths != undefined
      ? additionalPaths.some((path) => currentPath.includes(path))
      : false;

  // regex to test if current path is the same as the link path
  const isCurrentPage = new RegExp(`^${to}$`).test(currentPath);

  const classes = clsx(
    {
      'tw-text-textLime':
        (isCurrentPage || isAdditionalPath) && variant !== 'disabled',
    },
    {
      'tw-text-textWhite':
        !isCurrentPage && !isAdditionalPath && variant !== 'disabled',
    },
    {
      'tw-text-textDove': variant === 'disabled',
    },
    {
      'tw-cursor-not-allowed': variant === 'disabled',
    },
    {
      'tw-cursor-pointer': variant !== 'disabled',
    },
    'tw-hover:text-textLime'
  );
  const toInner = variant === 'disabled' ? '#' : to;
  return (
    <>
      <Link
        to={toInner}
        onClick={() => {
          if (variant === 'disabled') return;
          onClick();
        }}
      >
        <div className="tw-flex tw-items-center tw-space-x-2">
          {/* Match icon color with text color */}
          {icon != '' && (
            <i
              className={clsx(
                'wi',
                'wi-sm',
                'wi-' + icon,
                {
                  'tw-text-textLime':
                    (isCurrentPage || isAdditionalPath) &&
                    variant !== 'disabled',
                },
                {
                  'tw-text-textWhite':
                    !isCurrentPage &&
                    !isAdditionalPath &&
                    variant !== 'disabled',
                },
                {
                  'tw-text-textDove': variant === 'disabled',
                },
                {
                  'tw-cursor-not-allowed': variant === 'disabled',
                },
                {
                  'tw-cursor-pointer': variant !== 'disabled',
                }
              )}
            ></i>
          )}

          <span className={classes}>{label}</span>
        </div>
      </Link>
    </>
  );
};
