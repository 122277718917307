import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useApi } from 'shared_components/context';
import { useClientId } from 'shared_components/context/client';
import PassportVerification from './Components/PassportVerification';
import DeclineMessage from './Components/DeclineMessage';
import EOIReviewCard from './Components/EOIReviewCard';
import ArtistDiscographyIDCard from './Components/ArtistDiscographyIDCard';
import { AdminTaskClient } from 'shared_components/generated/admin/models';
import ClientsChart from './Components/ClientsChart';
import { CircleChart } from 'shared_components/components/CircleChart/CircleChart';
import WContainer from 'shared_components/components/WContainer/WContainer';
import { WProgressBar } from 'shared_components/components/WProgressBar/WProgressBar';
import { WTable } from 'shared_components/WTable/WTable';

function DiscographyDetails() {
  const { adminApi: api } = useApi();
  const [registerUsers, setRegisterUsers] = useState(75);
  const [verifyPassport, setVerifyPassport] = useState(false);
  const [showDeclineMessage, setShowDeclineMessage] = useState(false);
  const [passportId, setPassportId] = useState('');
  const [passportDateOfBirth, setPassportDateOfBirth] = useState('');
  const [passportNumber, setPassportNumber] = useState('');
  const [passportExpiryDate, setPassportExpiryDate] = useState('');
  const [passportFileLocation, setPassportFileLocation] = useState('');
  const [clientTasks, setClientTasks] = useState<AdminTaskClient[]>([]);
  const [taskId, setTaskId] = useState('');
  const [continueButtonDisabled, setContinueButtonDisabled] = useState(true);
  const [eoiReviewID, setEoiReviewID] = useState<string>('');
  const [DiscographyID, setDiscographyID] = useState<string>();
  const [showEOIcard, setShowEOIcard] = useState(false);
  const [showDiscographyIDcard, setShowDiscographyIDcard] = useState(false);

  const things_to_do_status = new Map([
    ['verify_passport_and_send_forms', 'Curate discography'],
    ['expression_of_interest_review', 'Society rip uploaded'],
    ['submit_discography_id', 'Run discography'],
  ]);
  const { clientId, setClientId, setClientEmail } = useClientId();

  const fetchData = () => {
    api
      .listAdminTaskClients()
      .then((adminClientTasks) => {
        setClientTasks(adminClientTasks);
      })
      .catch(() => {});
  };

  useEffect(() => {
    fetchData();
  }, []);

  const acceptPassport = () => {
    if (clientId === undefined) {
      return;
    }

    api
      .approveAdminApproveIdentityDocument({
        userId: clientId,
        documentId: passportId,
        dateOfBirth: passportDateOfBirth,
        documentNumber: passportNumber,
        documentExpiryDate: passportExpiryDate,
      })
      .then((response) => {
        setPassportDateOfBirth('');
        setPassportNumber('');
        setPassportExpiryDate('');
      })
      .catch((error) => {
        setPassportDateOfBirth('');
        setPassportNumber('');
        setPassportExpiryDate('');
      });
  };

  const declineMessage = () => {
    if (clientId === undefined) {
      return;
    }

    setVerifyPassport(false);
    setPassportDateOfBirth('');
    setPassportNumber('');
    setPassportExpiryDate('');
    setShowDeclineMessage(true);

    api
      .declineAdminDeclineIdentityDocument({
        userId: clientId,
        documentId: passportId,
      })
      .then(() => {
        api.destroyAdminTaskClient({ taskId });
      })
      .catch(() => {});
  };

  const handleThingsToDoStatusClick = (clientTask: AdminTaskClient) => {
    const client_task_status = clientTask.clientAdminTasks[0].status;
    if (client_task_status == 'verify_passport_and_send_forms') {
      setClientId(clientTask.id!.toString());
      api
        .listAdminIdentityDocumentSubmissions({
          userId: clientTask.id!.toString(),
        })
        .then((response) => {
          response.forEach((document) => {
            if (document.documentType === 'Passport') {
              setPassportId(document.id!);
              setPassportFileLocation(document.fileUrl!);
              setTaskId(clientTask.clientAdminTasks[0].id!);
            }
          });
        });

      setVerifyPassport(true);
      setVerifyPassport(true);
    } else if (client_task_status == 'expression_of_interest_review') {
      setClientEmail(clientTask.username);
      setClientId(clientTask.id!.toString());
      showCard();
      setEoiReviewID(clientTask.id!.toString());
      setTaskId(clientTask.clientAdminTasks[0].id!);
      setShowEOIcard(true);
    } else if (client_task_status == 'submit_discography_id') {
      setClientEmail(clientTask.username);
      setClientId(clientTask.id!.toString());
      showCard();
      setDiscographyID(clientTask.id!.toString());
      setTaskId(clientTask.clientAdminTasks[0].id!);
      setShowDiscographyIDcard(true);
    }
  };

  const showCard = () => {
    document.body.classList.add('hide-scroll');
  };

  const hideCard = () => {
    document.body.classList.remove('hide-scroll');
    setShowEOIcard(false);
    setShowDiscographyIDcard(false);
  };

  useEffect(() => {
    const continueButtonDisabled =
      passportDateOfBirth != '' &&
      passportExpiryDate != '' &&
      passportNumber != '';

    setContinueButtonDisabled(continueButtonDisabled);
  }, [passportDateOfBirth, passportExpiryDate, passportNumber]);

  let tableData = clientTasks.map((clientTask) => {
    const [firstThingToDo] = clientTask?.clientAdminTasks ?? [];
    let dateCreated = firstThingToDo?.dateCreated;
    return {
      artist: clientTask.fullName,
      action: (
        <div
          className="pointer-cursor"
          onClick={() => handleThingsToDoStatusClick(clientTask)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleThingsToDoStatusClick(clientTask);
            }
          }}
          role="button"
          tabIndex={0}
        >
          {things_to_do_status.get(firstThingToDo?.status ?? '')}
        </div>
      ),
      status: <WProgressBar percentage={75} label="75% complete" />,
      dateCreated: (
        <div>
          <p>{dateCreated?.toLocaleDateString().toString() || '-'}</p>
        </div>
      ),
    };
  });

  let rawData = clientTasks.map((clientTask) => {
    const [firstThingToDo] = clientTask?.clientAdminTasks ?? [];
    let dateCreated = firstThingToDo?.dateCreated;
    return {
      artist: clientTask.fullName,
      action: things_to_do_status.get(firstThingToDo?.status ?? ''),
      status: '75% complete',
      dateCreated: dateCreated,
    };
  });

  return (
    <>
      <EOIReviewCard
        cardEvent={hideCard}
        eoiReviewID={eoiReviewID}
        taskId={taskId}
        clientTasks={clientTasks}
        setClientTasks={setClientTasks}
        isOpen={showEOIcard}
      />
      <ArtistDiscographyIDCard
        isOpen={showDiscographyIDcard}
        cardEvent={hideCard}
      />
      {verifyPassport ? (
        <PassportVerification
          setPassportDateOfBirth={setPassportDateOfBirth}
          setPassportNumber={setPassportNumber}
          setPassportExpiryDate={setPassportExpiryDate}
          hideVerification={setVerifyPassport}
          declineMessage={declineMessage}
          acceptPassport={acceptPassport}
          passportFileLocation={passportFileLocation}
          clientTasks={clientTasks}
          taskId={taskId}
          continueButtonDisabled={continueButtonDisabled}
          passportDateOfBirth={passportDateOfBirth}
          passportNumber={passportNumber}
          passportExpiryDate={passportExpiryDate}
        />
      ) : showDeclineMessage ? (
        <DeclineMessage declineMessage={declineMessage} />
      ) : (
        <div>
          <div
            className="tw-flex tw-w-full tw-space-x-6 tw-max-w-[1500px]"
            style={{ maxWidth: '1584px', width: '100%' }}
          >
            <WContainer
              extraClasses="tw-min-w-[302px]"
              disabled
              title="Discographies submitted"
            >
              <p className="tw-uppercase tw-text-sm">Discographies submitted</p>
              <CircleChart
                value={registerUsers}
                text="discographies submitted"
              ></CircleChart>
              <div className="tw-text-right">
                <Link to="/" className="viewLink">
                  View
                </Link>
              </div>
            </WContainer>
            <WContainer
              extraClasses="tw-flex-grow tw-overflow-scroll tw-scrollbar-none"
              disabled
              title="Society Overview"
            >
              <h2>
                <span className="tw-uppercase tw-text-sm tw-pb-2">
                  Society overview
                </span>
              </h2>
              <div
                className="tw-overflow-x-scroll tw-flex-grow tw-w-full tw-overflow-scroll tw-scrollbar-none"
                style={{}}
              >
                <ClientsChart />
              </div>
            </WContainer>
          </div>
          <p className={'tw-uppercase tw-text-2xl tw-text-white tw-mt-6'}>
            Tasks
          </p>
          <WContainer>
            <WTable
              columnStyle={{ borderBottom: 'none', padding: '0px' }}
              columns={[
                { title: 'Artist', dataKey: 'artist', sort: true },
                { title: 'Action', dataKey: 'action', sort: true },
                { title: 'Date Added', dataKey: 'dateCreated', sort: true },
                // Phase II job when BE has functionality
                // { title: 'Status', dataKey: 'status' },
              ]}
              data={tableData}
              rawData={rawData}
            ></WTable>
          </WContainer>
        </div>
      )}
    </>
  );
}

export default DiscographyDetails;
