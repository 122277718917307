/* tslint:disable */
/* eslint-disable */
/**
 * Westbury Music API
 * API for Westbury Music
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SignupAuthTokenToken
 */
export interface SignupAuthTokenToken {
    /**
     * 
     * @type {string}
     * @memberof SignupAuthTokenToken
     */
    refresh: string;
    /**
     * 
     * @type {string}
     * @memberof SignupAuthTokenToken
     */
    readonly access?: string;
}

/**
 * Check if a given object implements the SignupAuthTokenToken interface.
 */
export function instanceOfSignupAuthTokenToken(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "refresh" in value;

    return isInstance;
}

export function SignupAuthTokenTokenFromJSON(json: any): SignupAuthTokenToken {
    return SignupAuthTokenTokenFromJSONTyped(json, false);
}

export function SignupAuthTokenTokenFromJSONTyped(json: any, ignoreDiscriminator: boolean): SignupAuthTokenToken {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'refresh': json['refresh'],
        'access': !exists(json, 'access') ? undefined : json['access'],
    };
}

export function SignupAuthTokenTokenToJSON(value?: SignupAuthTokenToken | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'refresh': value.refresh,
    };
}

