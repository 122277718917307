import { ArtistList, ArtistListProps } from "./ArtistList";
import WContainer from "../WContainer/WContainer";

export const ArtistListBox: React.FC<ArtistListProps> = (props) => {

    return (
        <WContainer extraClasses='tw-w-1/4'>
            <ArtistList {...props} />
        </WContainer>
    )
};