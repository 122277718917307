/* tslint:disable */
/* eslint-disable */
/**
 * Westbury Music API Admin
 * API for Westbury Music admin users
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserSettings
 */
export interface UserSettings {
    /**
     * 
     * @type {number}
     * @memberof UserSettings
     */
    readonly id?: number;
    /**
     * 
     * @type {string}
     * @memberof UserSettings
     */
    readonly user?: string;
    /**
     * 
     * @type {string}
     * @memberof UserSettings
     */
    readonly url?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserSettings
     */
    aliases?: Array<string>;
    /**
     * 
     * @type {Blob}
     * @memberof UserSettings
     */
    userPhoto?: Blob;
    /**
     * 
     * @type {string}
     * @memberof UserSettings
     */
    readonly userPhotoUrl?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserSettings
     */
    readonly verifiedStatus?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserSettings
     */
    readonly signedStatus?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserSettings
     */
    accountStatus?: UserSettingsAccountStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof UserSettings
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserSettings
     */
    gender?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserSettings
     */
    cityOfBirth?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserSettings
     */
    countryOfBirth?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserSettings
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserSettings
     */
    middleName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserSettings
     */
    lastName?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof UserSettings
     */
    dateOfBirth?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof UserSettings
     */
    nationality?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserSettings
     */
    department?: UserSettingsDepartmentEnum;
    /**
     * 
     * @type {string}
     * @memberof UserSettings
     */
    taxationCountry?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserSettings
     */
    vatNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserSettings
     */
    taxIdType?: UserSettingsTaxIdTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UserSettings
     */
    taxIdNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserSettings
     */
    emailAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserSettings
     */
    streetAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserSettings
     */
    country?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserSettings
     */
    phoneNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserSettings
     */
    county?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserSettings
     */
    postcode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserSettings
     */
    witnessFirstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserSettings
     */
    witnessLastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserSettings
     */
    witnessEmail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserSettings
     */
    role?: UserSettingsRoleEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UserSettings
     */
    registeredWithPpl?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserSettings
     */
    pplAccountName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserSettings
     */
    pplPerformerName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserSettings
     */
    pplPerformerId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserSettings
     */
    notes?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserSettings
     */
    clientEmailHashed?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserSettings
     */
    readonly westburyId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserSettings
     */
    discogsIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserSettings
     */
    musicbrainzIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserSettings
     */
    deezerIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserSettings
     */
    spotifyIds?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof UserSettings
     */
    performerOrProducer?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserSettings
     */
    masterRights?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserSettings
     */
    masterFullname?: string;
    /**
     * 
     * @type {string}
     * @memberof UserSettings
     */
    masterEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof UserSettings
     */
    masterName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserSettings
     */
    masterId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserSettings
     */
    readonly completedOnboardingSteps?: Array<string>;
}


/**
 * @export
 */
export const UserSettingsAccountStatusEnum = {
    Pending: 'pending',
    Approved: 'approved',
    Active: 'active',
    Rejected: 'rejected',
    Inactive: 'inactive'
} as const;
export type UserSettingsAccountStatusEnum = typeof UserSettingsAccountStatusEnum[keyof typeof UserSettingsAccountStatusEnum];

/**
 * @export
 */
export const UserSettingsDepartmentEnum = {
    Membership: 'Membership',
    Discography: 'Discography',
    Distribution: 'Distribution'
} as const;
export type UserSettingsDepartmentEnum = typeof UserSettingsDepartmentEnum[keyof typeof UserSettingsDepartmentEnum];

/**
 * @export
 */
export const UserSettingsTaxIdTypeEnum = {
    TinTaxpayerIdentificationNumber: 'TIN - Taxpayer Identification Number',
    ItinIndividualTaxpayerIdentificationNumber: 'ITIN - Individual Taxpayer Identification Number',
    FtinForeignTaxpayerIdentificationNumber: 'FTIN - Foreign Taxpayer Identification Number',
    PibPoreskiIdentifikacioniBroj: 'PIB - Poreski Identifikacioni Broj',
    PpsPersonalPublicService: 'PPS - Personal Public Service',
    NipNumerIdentyfikacjiPodatkowej: 'NIP - Numer Identyfikacji Podatkowej',
    FederalTaxId: 'Federal Tax ID',
    DepartmentOfStateId: 'Department of State ID',
    NationalInsuranceNumber: 'National Insurance Number',
    SocialSecurityNumber: 'Social Security Number'
} as const;
export type UserSettingsTaxIdTypeEnum = typeof UserSettingsTaxIdTypeEnum[keyof typeof UserSettingsTaxIdTypeEnum];

/**
 * @export
 */
export const UserSettingsRoleEnum = {
    PerformerProducer: 'Performer/Producer'
} as const;
export type UserSettingsRoleEnum = typeof UserSettingsRoleEnum[keyof typeof UserSettingsRoleEnum];


/**
 * Check if a given object implements the UserSettings interface.
 */
export function instanceOfUserSettings(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UserSettingsFromJSON(json: any): UserSettings {
    return UserSettingsFromJSONTyped(json, false);
}

export function UserSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserSettings {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'user': !exists(json, 'user') ? undefined : json['user'],
        'url': !exists(json, 'url') ? undefined : json['url'],
        'aliases': !exists(json, 'aliases') ? undefined : json['aliases'],
        'userPhoto': !exists(json, 'user_photo') ? undefined : json['user_photo'],
        'userPhotoUrl': !exists(json, 'user_photo_url') ? undefined : json['user_photo_url'],
        'verifiedStatus': !exists(json, 'verified_status') ? undefined : json['verified_status'],
        'signedStatus': !exists(json, 'signed_status') ? undefined : json['signed_status'],
        'accountStatus': !exists(json, 'account_status') ? undefined : json['account_status'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'gender': !exists(json, 'gender') ? undefined : json['gender'],
        'cityOfBirth': !exists(json, 'city_of_birth') ? undefined : json['city_of_birth'],
        'countryOfBirth': !exists(json, 'country_of_birth') ? undefined : json['country_of_birth'],
        'firstName': !exists(json, 'first_name') ? undefined : json['first_name'],
        'middleName': !exists(json, 'middle_name') ? undefined : json['middle_name'],
        'lastName': !exists(json, 'last_name') ? undefined : json['last_name'],
        'dateOfBirth': !exists(json, 'date_of_birth') ? undefined : (json['date_of_birth'] === null ? null : new Date(json['date_of_birth'])),
        'nationality': !exists(json, 'nationality') ? undefined : json['nationality'],
        'department': !exists(json, 'department') ? undefined : json['department'],
        'taxationCountry': !exists(json, 'taxation_country') ? undefined : json['taxation_country'],
        'vatNumber': !exists(json, 'vat_number') ? undefined : json['vat_number'],
        'taxIdType': !exists(json, 'tax_id_type') ? undefined : json['tax_id_type'],
        'taxIdNumber': !exists(json, 'tax_id_number') ? undefined : json['tax_id_number'],
        'emailAddress': !exists(json, 'email_address') ? undefined : json['email_address'],
        'streetAddress': !exists(json, 'street_address') ? undefined : json['street_address'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'phoneNumber': !exists(json, 'phone_number') ? undefined : json['phone_number'],
        'county': !exists(json, 'county') ? undefined : json['county'],
        'postcode': !exists(json, 'postcode') ? undefined : json['postcode'],
        'witnessFirstName': !exists(json, 'witness_first_name') ? undefined : json['witness_first_name'],
        'witnessLastName': !exists(json, 'witness_last_name') ? undefined : json['witness_last_name'],
        'witnessEmail': !exists(json, 'witness_email') ? undefined : json['witness_email'],
        'role': !exists(json, 'role') ? undefined : json['role'],
        'registeredWithPpl': !exists(json, 'registered_with_ppl') ? undefined : json['registered_with_ppl'],
        'pplAccountName': !exists(json, 'ppl_account_name') ? undefined : json['ppl_account_name'],
        'pplPerformerName': !exists(json, 'ppl_performer_name') ? undefined : json['ppl_performer_name'],
        'pplPerformerId': !exists(json, 'ppl_performer_id') ? undefined : json['ppl_performer_id'],
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
        'clientEmailHashed': !exists(json, 'client_email_hashed') ? undefined : json['client_email_hashed'],
        'westburyId': !exists(json, 'westbury_id') ? undefined : json['westbury_id'],
        'discogsIds': !exists(json, 'discogs_ids') ? undefined : json['discogs_ids'],
        'musicbrainzIds': !exists(json, 'musicbrainz_ids') ? undefined : json['musicbrainz_ids'],
        'deezerIds': !exists(json, 'deezer_ids') ? undefined : json['deezer_ids'],
        'spotifyIds': !exists(json, 'spotify_ids') ? undefined : json['spotify_ids'],
        'performerOrProducer': !exists(json, 'performer_or_producer') ? undefined : json['performer_or_producer'],
        'masterRights': !exists(json, 'master_rights') ? undefined : json['master_rights'],
        'masterFullname': !exists(json, 'master_fullname') ? undefined : json['master_fullname'],
        'masterEmail': !exists(json, 'master_email') ? undefined : json['master_email'],
        'masterName': !exists(json, 'master_name') ? undefined : json['master_name'],
        'masterId': !exists(json, 'master_id') ? undefined : json['master_id'],
        'completedOnboardingSteps': !exists(json, 'completed_onboarding_steps') ? undefined : json['completed_onboarding_steps'],
    };
}

export function UserSettingsToJSON(value?: UserSettings | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'aliases': value.aliases,
        'user_photo': value.userPhoto,
        'account_status': value.accountStatus,
        'title': value.title,
        'gender': value.gender,
        'city_of_birth': value.cityOfBirth,
        'country_of_birth': value.countryOfBirth,
        'first_name': value.firstName,
        'middle_name': value.middleName,
        'last_name': value.lastName,
        'date_of_birth': value.dateOfBirth === undefined ? undefined : (value.dateOfBirth === null ? null : value.dateOfBirth.toISOString().substr(0,10)),
        'nationality': value.nationality,
        'department': value.department,
        'taxation_country': value.taxationCountry,
        'vat_number': value.vatNumber,
        'tax_id_type': value.taxIdType,
        'tax_id_number': value.taxIdNumber,
        'email_address': value.emailAddress,
        'street_address': value.streetAddress,
        'country': value.country,
        'phone_number': value.phoneNumber,
        'county': value.county,
        'postcode': value.postcode,
        'witness_first_name': value.witnessFirstName,
        'witness_last_name': value.witnessLastName,
        'witness_email': value.witnessEmail,
        'role': value.role,
        'registered_with_ppl': value.registeredWithPpl,
        'ppl_account_name': value.pplAccountName,
        'ppl_performer_name': value.pplPerformerName,
        'ppl_performer_id': value.pplPerformerId,
        'notes': value.notes,
        'client_email_hashed': value.clientEmailHashed,
        'discogs_ids': value.discogsIds,
        'musicbrainz_ids': value.musicbrainzIds,
        'deezer_ids': value.deezerIds,
        'spotify_ids': value.spotifyIds,
        'performer_or_producer': value.performerOrProducer,
        'master_rights': value.masterRights,
        'master_fullname': value.masterFullname,
        'master_email': value.masterEmail,
        'master_name': value.masterName,
        'master_id': value.masterId,
    };
}

