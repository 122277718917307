import React from 'react';
import ClientDetailsRow, {
  ClientDetailsRowProps,
} from 'admin_portal/src/view/PageLayout/Components/ClientDetails/ClientDetailsRow';
import profileImg from 'shared_components/public/assets/userProfile.png';

interface ClientDetailsProps {
  photo: string;
  data: ClientDetailsRowProps[];
}

const ClientDetails: React.FC<ClientDetailsProps> = ({ photo, data }) => {
  return (
    <div className="tw-flex tw-flex-col">
      <div className="tw-flex tw-items-center tw-flex-row">
        <div className="tw-text-2xl tw-pr-4">Artist Details</div>
        <img
          src={photo || profileImg}
          alt="Client"
          className="tw-rounded-full tw-w-12 tw-h-12"
        />
      </div>
      {data.map((item, index) => (
        <ClientDetailsRow
          key={index}
          label={item.label}
          data={item.data}
          copy={item.copy}
          link={item.link}
        />
      ))}
    </div>
  );
};

export default ClientDetails;
