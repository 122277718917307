/* tslint:disable */
/* eslint-disable */
/**
 * Westbury Music API Admin
 * API for Westbury Music admin users
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CompanyInformationBankDetails } from './CompanyInformationBankDetails';
import {
    CompanyInformationBankDetailsFromJSON,
    CompanyInformationBankDetailsFromJSONTyped,
    CompanyInformationBankDetailsToJSON,
} from './CompanyInformationBankDetails';

/**
 * 
 * @export
 * @interface CompanyCompanyInformation
 */
export interface CompanyCompanyInformation {
    /**
     * 
     * @type {string}
     * @memberof CompanyCompanyInformation
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyCompanyInformation
     */
    readonly logoImageUrl?: string;
    /**
     * 
     * @type {CompanyInformationBankDetails}
     * @memberof CompanyCompanyInformation
     */
    bankDetails?: CompanyInformationBankDetails;
    /**
     * 
     * @type {Date}
     * @memberof CompanyCompanyInformation
     */
    readonly dateCreated?: Date;
    /**
     * 
     * @type {Date}
     * @memberof CompanyCompanyInformation
     */
    readonly dateUpdated?: Date;
    /**
     * 
     * @type {string}
     * @memberof CompanyCompanyInformation
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyCompanyInformation
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyCompanyInformation
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyCompanyInformation
     */
    phoneNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyCompanyInformation
     */
    emailAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyCompanyInformation
     */
    streetAddress1?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyCompanyInformation
     */
    streetAddress2?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyCompanyInformation
     */
    county?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyCompanyInformation
     */
    postcode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyCompanyInformation
     */
    country?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyCompanyInformation
     */
    marketingUrl?: string | null;
}

/**
 * Check if a given object implements the CompanyCompanyInformation interface.
 */
export function instanceOfCompanyCompanyInformation(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CompanyCompanyInformationFromJSON(json: any): CompanyCompanyInformation {
    return CompanyCompanyInformationFromJSONTyped(json, false);
}

export function CompanyCompanyInformationFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyCompanyInformation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'logoImageUrl': !exists(json, 'logo_image_url') ? undefined : json['logo_image_url'],
        'bankDetails': !exists(json, 'bank_details') ? undefined : CompanyInformationBankDetailsFromJSON(json['bank_details']),
        'dateCreated': !exists(json, 'date_created') ? undefined : (new Date(json['date_created'])),
        'dateUpdated': !exists(json, 'date_updated') ? undefined : (new Date(json['date_updated'])),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'firstName': !exists(json, 'first_name') ? undefined : json['first_name'],
        'lastName': !exists(json, 'last_name') ? undefined : json['last_name'],
        'phoneNumber': !exists(json, 'phone_number') ? undefined : json['phone_number'],
        'emailAddress': !exists(json, 'email_address') ? undefined : json['email_address'],
        'streetAddress1': !exists(json, 'street_address_1') ? undefined : json['street_address_1'],
        'streetAddress2': !exists(json, 'street_address_2') ? undefined : json['street_address_2'],
        'county': !exists(json, 'county') ? undefined : json['county'],
        'postcode': !exists(json, 'postcode') ? undefined : json['postcode'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'marketingUrl': !exists(json, 'marketing_url') ? undefined : json['marketing_url'],
    };
}

export function CompanyCompanyInformationToJSON(value?: CompanyCompanyInformation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'bank_details': CompanyInformationBankDetailsToJSON(value.bankDetails),
        'name': value.name,
        'first_name': value.firstName,
        'last_name': value.lastName,
        'phone_number': value.phoneNumber,
        'email_address': value.emailAddress,
        'street_address_1': value.streetAddress1,
        'street_address_2': value.streetAddress2,
        'county': value.county,
        'postcode': value.postcode,
        'country': value.country,
        'marketing_url': value.marketingUrl,
    };
}

