import React from 'react';
import clsx from 'clsx';

interface WContainerProps {
  disabled?: boolean;
  children: React.ReactNode;
  extraClasses?: string;
  title?: string;
}

// If disabled then you must pass a title

export const WContainer: React.FC<WContainerProps> = ({
  disabled = false,
  children,
  extraClasses = 'tw-w-full',
  title,
}) => {
  return (
    <div
      className={clsx(
        'tw-bg-surfaceBlack90',
        'tw-p-6',
        'tw-mt-6',
        'tw-text-white',
        'tw-rounded-2xl',
        'tw-max-w-full',
        extraClasses,
        'IS_W_CONTAINER',
        disabled && 'tw-relative'
      )}
      style={{ pointerEvents: disabled ? 'none' : 'auto' }}
    >
      {disabled ? (
        <>
          <div className={clsx('tw-blur-xl')}>{children}</div>
          <div
            className="tw-rounded-full 
                       tw-absolute 
                       tw-top-1/2 
                       tw-left-1/2 
                       tw-transform 
                       tw--translate-x-1/2 
                       tw--translate-y-1/2
                       tw-w-[140px] tw-h-[140px] 
                       tw-bg-white
                      tw-opacity-20"
          ></div>
          <p
            className="tw-text-md tw-text-white  tw-top-1/2 
                       tw-absolute
                       tw-left-1/2 
                       tw-transform 
                       tw--translate-x-1/2 
                       tw--translate-y-1/2
                       tw-text-center"
          >
            Feature <br />
            Coming <br />
            Soon
          </p>
          <p
            className="tw-text-xl tw-text-white  tw-top-[21px]
                      tw-font-semibold
                       tw-absolute
                       tw-left-[28px] 
                       tw-text-center"
          >
            {title}
          </p>
        </>
      ) : (
        children
      )}
    </div>
  );
};

export default WContainer;
