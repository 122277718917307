/* tslint:disable */
/* eslint-disable */
/**
 * Westbury Music API Admin
 * API for Westbury Music admin users
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AdminApproveIdentityDocument
 */
export interface AdminApproveIdentityDocument {
    /**
     * 
     * @type {string}
     * @memberof AdminApproveIdentityDocument
     */
    dateOfBirth: string;
    /**
     * 
     * @type {string}
     * @memberof AdminApproveIdentityDocument
     */
    documentNumber: string;
    /**
     * 
     * @type {string}
     * @memberof AdminApproveIdentityDocument
     */
    documentExpiryDate: string;
}

/**
 * Check if a given object implements the AdminApproveIdentityDocument interface.
 */
export function instanceOfAdminApproveIdentityDocument(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "dateOfBirth" in value;
    isInstance = isInstance && "documentNumber" in value;
    isInstance = isInstance && "documentExpiryDate" in value;

    return isInstance;
}

export function AdminApproveIdentityDocumentFromJSON(json: any): AdminApproveIdentityDocument {
    return AdminApproveIdentityDocumentFromJSONTyped(json, false);
}

export function AdminApproveIdentityDocumentFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdminApproveIdentityDocument {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dateOfBirth': json['date_of_birth'],
        'documentNumber': json['document_number'],
        'documentExpiryDate': json['document_expiry_date'],
    };
}

export function AdminApproveIdentityDocumentToJSON(value?: AdminApproveIdentityDocument | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'date_of_birth': value.dateOfBirth,
        'document_number': value.documentNumber,
        'document_expiry_date': value.documentExpiryDate,
    };
}

