import React, { useState, useEffect } from 'react';
import { AdminClientList } from 'shared_components/generated/admin';
import ArtistProfile from './Components/ArtistProfile';
import { ArtistListBox } from 'shared_components/components/SocietiesArtists/ArtistListBox';
import { useClientId } from 'shared_components/context/client';
import { useApi } from 'shared_components/context';

import PageLayout from './PageLayout';
import WButton from 'shared_components/components/WForms/WButton/WButton';
import AddNewUserModal from './Components/AddNewUserModal/AddNewUserModal';
function Clients() {
  const [isArtist, setIsArtist] = useState(true);
  const { adminApi: api } = useApi();

  const { clientId, setClientId } = useClientId();
  const [clientList, setClientList] = useState<AdminClientList[]>([]);
  const [addNewUserModalIsOpen, setAddNewUserModalIsOpen] = useState(false);

  let societies = [];
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    api
      .listUsers()
      .then((clients: AdminClientList[]) => {
        setClientList(
          // set image to empty string pending backend implementation
          clients.map((client) => {
            return { ...client, image: '' };
          })
        );
        if (clients.length > 0) {
          const firstId = clients[0].userId;
          setClientId(firstId as string);
        }
      })
      .catch((error) => {});
  };

  return (
    <>
      <PageLayout>
        <div className="tw-flex">
          <h2> Clients </h2>
        </div>
        <div className="tw-flex tw-mt-6 tw-justify-between">
          <WButton
            variant="link-secondary"
            icon="chevron-left"
            onClick={() => {
              window.history.back();
            }}
            label="Back"
          />
          <WButton
            variant="secondary"
            onClick={() => {
              setAddNewUserModalIsOpen(true);
            }}
            label="Add New User"
          />
        </div>
        <div className="tw-flex tw-space-x-6">
          {/* Sidebar */}
          <ArtistListBox artists={clientList}></ArtistListBox>

          {/* Main content */}
          {isArtist ? <ArtistProfile /> : <></>}
        </div>
      </PageLayout>
      <AddNewUserModal
        isOpen={addNewUserModalIsOpen}
        onClose={() => {
          setAddNewUserModalIsOpen(false);
        }}
        fetchData={fetchData}
      />
    </>
  );
}

export default Clients;
