/* tslint:disable */
/* eslint-disable */
/**
 * Westbury Music API Admin
 * API for Westbury Music admin users
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Representative
 */
export interface Representative {
    /**
     * 
     * @type {string}
     * @memberof Representative
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof Representative
     */
    readonly user?: string;
    /**
     * 
     * @type {string}
     * @memberof Representative
     */
    readonly url?: string;
    /**
     * 
     * @type {Date}
     * @memberof Representative
     */
    readonly dateCreated?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Representative
     */
    readonly dateUpdated?: Date;
    /**
     * 
     * @type {string}
     * @memberof Representative
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Representative
     */
    role?: RepresentativeRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof Representative
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Representative
     */
    phoneNumber?: string | null;
}


/**
 * @export
 */
export const RepresentativeRoleEnum = {
    Manager: 'Manager',
    Accountant: 'Accountant',
    Lawyer: 'Lawyer',
    Representative: 'Representative',
    Company: 'Company'
} as const;
export type RepresentativeRoleEnum = typeof RepresentativeRoleEnum[keyof typeof RepresentativeRoleEnum];


/**
 * Check if a given object implements the Representative interface.
 */
export function instanceOfRepresentative(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function RepresentativeFromJSON(json: any): Representative {
    return RepresentativeFromJSONTyped(json, false);
}

export function RepresentativeFromJSONTyped(json: any, ignoreDiscriminator: boolean): Representative {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'user': !exists(json, 'user') ? undefined : json['user'],
        'url': !exists(json, 'url') ? undefined : json['url'],
        'dateCreated': !exists(json, 'date_created') ? undefined : (new Date(json['date_created'])),
        'dateUpdated': !exists(json, 'date_updated') ? undefined : (new Date(json['date_updated'])),
        'name': json['name'],
        'role': !exists(json, 'role') ? undefined : json['role'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'phoneNumber': !exists(json, 'phone_number') ? undefined : json['phone_number'],
    };
}

export function RepresentativeToJSON(value?: Representative | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'role': value.role,
        'email': value.email,
        'phone_number': value.phoneNumber,
    };
}

