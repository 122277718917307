import React from 'react';
import { WInput } from 'shared_components/components/WForms/WInput/WInput';

interface ClientAdvanceDetailsProps {
  advanceAmount: string;
  onAmountChange: (value: string) => void;
}

const ClientAdvanceDetails: React.FC<ClientAdvanceDetailsProps> = ({
  advanceAmount,
  onAmountChange,
}) => {
  return (
    <div>
      <WInput
        type="text"
        name="advanceAmount"
        label="Advance amount"
        value={advanceAmount}
        onChange={(e) => {
          onAmountChange(e.target.value);
        }}
        placeholder="£"
      />
    </div>
  );
};

export default ClientAdvanceDetails;
