import React, { FC } from 'react';
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from 'react-circular-progressbar';

type Props = {
  value: number;
  text: string;
};
const progressValues = [1, 2, 3, 5, 10];
const progressPercentage = {
  1: 34,
  2: 50,
  3: 67,
  5: 84,
  10: 100,
};
export const CircleChart: React.FC<Props> = ({ value, text }) => {
  return (
    <div className="tw-mt-4">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="0"
        height="0"
        viewBox="0 0 0 0"
      >
        <defs>
          <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor="#FF7121" />
            <stop offset="100%" stopColor="#FF1529" />
          </linearGradient>
        </defs>
      </svg>
      <div style={{ maxWidth: '185px', margin: '0 auto' }} className="">
        <CircularProgressbarWithChildren
          value={value}
          styles={buildStyles({
            strokeLinecap: 'butt',
            textSize: '16px',
            pathColor: '#00B3FF',
            trailColor: '#c4c4c4',
            textColor: '#191c1f',
          })}
          strokeWidth={10}
        >
          <div className="tw-flex tw-flex-col tw-text-center tw-text-[13px]">
            <span className="">{value}%</span>
            <span className="">{text}</span>
          </div>
        </CircularProgressbarWithChildren>
      </div>
    </div>
  );
};
