/* tslint:disable */
/* eslint-disable */
/**
 * Westbury Music API Admin
 * API for Westbury Music admin users
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TermsOfAgreementConfirmationPandadocDocumentIdsInner } from './TermsOfAgreementConfirmationPandadocDocumentIdsInner';
import {
    TermsOfAgreementConfirmationPandadocDocumentIdsInnerFromJSON,
    TermsOfAgreementConfirmationPandadocDocumentIdsInnerFromJSONTyped,
    TermsOfAgreementConfirmationPandadocDocumentIdsInnerToJSON,
} from './TermsOfAgreementConfirmationPandadocDocumentIdsInner';

/**
 * 
 * @export
 * @interface TermsOfAgreementConfirmation
 */
export interface TermsOfAgreementConfirmation {
    /**
     * 
     * @type {boolean}
     * @memberof TermsOfAgreementConfirmation
     */
    done?: boolean;
    /**
     * 
     * @type {Array<TermsOfAgreementConfirmationPandadocDocumentIdsInner>}
     * @memberof TermsOfAgreementConfirmation
     */
    pandadocDocumentIds?: Array<TermsOfAgreementConfirmationPandadocDocumentIdsInner>;
}

/**
 * Check if a given object implements the TermsOfAgreementConfirmation interface.
 */
export function instanceOfTermsOfAgreementConfirmation(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TermsOfAgreementConfirmationFromJSON(json: any): TermsOfAgreementConfirmation {
    return TermsOfAgreementConfirmationFromJSONTyped(json, false);
}

export function TermsOfAgreementConfirmationFromJSONTyped(json: any, ignoreDiscriminator: boolean): TermsOfAgreementConfirmation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'done': !exists(json, 'done') ? undefined : json['done'],
        'pandadocDocumentIds': !exists(json, 'pandadoc_document_ids') ? undefined : ((json['pandadoc_document_ids'] as Array<any>).map(TermsOfAgreementConfirmationPandadocDocumentIdsInnerFromJSON)),
    };
}

export function TermsOfAgreementConfirmationToJSON(value?: TermsOfAgreementConfirmation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'done': value.done,
        'pandadoc_document_ids': value.pandadocDocumentIds === undefined ? undefined : ((value.pandadocDocumentIds as Array<any>).map(TermsOfAgreementConfirmationPandadocDocumentIdsInnerToJSON)),
    };
}

