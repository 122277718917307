import React, { useEffect, useRef, useState } from 'react';
import Chart, { ChartConfiguration } from 'chart.js/auto';

const randomInt = (min: number, max: number) =>
  Math.floor(Math.random() * (max - min + 1) + min);
let width: number;
let height: number;
let gradient: CanvasGradient;

const getGradient = (
  ctx: CanvasRenderingContext2D,
  chartArea: { left: number; right: number; top: number; bottom: number }
) => {
  const chartWidth = chartArea.right - chartArea.left;
  const chartHeight = chartArea.bottom - chartArea.top;
  if (!gradient || width !== chartWidth || height !== chartHeight) {
    // Create the gradient because this is either the first render
    // or the size of the chart has changed
    width = chartWidth;
    height = chartHeight;
    gradient = ctx.createLinearGradient(0, 0, 0, chartArea.bottom);
    gradient.addColorStop(0, '#FF1529');
    gradient.addColorStop(1, '#FF7121');
  }

  return gradient;
};
const labels = [
  'AARC',
  'ACTRA',
  'ABRAMUS',
  'ADAMI',
  ['AFM', 'SAG-AFTRA'],
  'AIE',
  'GDA',
  ['GRAMEX', 'Denmark'],
  ['GRAMEX', 'Finland'],
  'GRAMO',
  'GVL',
  'ITSRIGHT',
  'LaIPA',
  'LSG',
  'NORMA',
  'PlayRight',
  'PPL',
  'AARC',
  'ACTRA',
  'ABRAMUS',
  'ADAMI',
  ['AFM', 'SAG-AFTRA'],
  'AIE',
  'GDA',
  ['GRAMEX', 'Denmark'],
];
const chartConfig: ChartConfiguration = {
  type: 'bar',
  data: {
    labels,
    datasets: [
      {
        label: 'Client receiving income',
        data: labels.map(() => {
          return randomInt(0, 15);
        }),
        backgroundColor: '#B380FF', // Color surface blue
        barThickness: 18,
      },
      {
        label: 'Clients registered',
        data: labels.map(() => {
          return randomInt(15, 20);
        }),
        backgroundColor: '#535D66',
        barThickness: 18,
      },
      {
        label: 'No. of clients',
        data: labels.map(() => {
          return randomInt(20, 50);
        }),
        backgroundColor: '#00B3FF', // Color surface purple
        barThickness: 18,
        borderRadius: 3,
      },
    ],
  },
  options: {
    plugins: {
      legend: {
        display: true,
        // position: 'right',
        align: 'end',
        labels: {
          color: '#FFFFFF',
          boxWidth: 9,
          boxHeight: 9,
          font: {
            size: 12,
            weight: 500,
          },
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: 'point',
    },
    scales: {
      x: {
        barPercentage: 0.25,
        categoryPercentage: 1.0,
        barThickness: 18,
        min: 0,
        max: 23,
        stacked: true,
        grid: {
          display: false,
          offsetGridLines: false,
        },
        ticks: {
          fontSize: 10,
          fontWeight: 500,
          color: '#FFFFFF',
          autoSkip: false,
          beginAtZero: true,
          // barPercentage: 0.1,
          categoryPercentage: 1.0,
          stepSize: 1,
          maxRotation: 45,
          minRotation: 45,
          angle: 45,
        },
      },
      y: {
        stacked: false,
        suggestedMin: 5,
        ticks: {
          beginAtZero: false,
          stepSize: 10,
          color: '#FFFFFF',
        },
      },
    },
    events: ['mousemove', 'touchmove'],
  },
};

function ClientsChart() {
  const chartContainer = useRef(null);
  const [chartInstance, setChartInstance] = useState<Chart>();

  useEffect(() => {
    if (chartContainer && chartContainer.current) {
      const newChartInstance = new Chart(chartContainer.current, chartConfig);
      setChartInstance(newChartInstance);
    } else {
      setChartInstance(undefined);
    }
  }, [chartContainer]);

  return (
    <div
      className="chart-container"
      style={{ minWidth: '300px', position: 'relative', height: '305px' }}
    >
      <canvas
        id="clientsChart"
        ref={chartContainer}
        height="300"
        width="1206"
      />
    </div>
  );
}

export default ClientsChart;
