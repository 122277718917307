/* tslint:disable */
/* eslint-disable */
/**
 * Westbury Music API Admin
 * API for Westbury Music admin users
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Debug
 */
export interface Debug {
    /**
     * 
     * @type {string}
     * @memberof Debug
     */
    charField: string;
    /**
     * 
     * @type {string}
     * @memberof Debug
     */
    emailField: string;
    /**
     * 
     * @type {object}
     * @memberof Debug
     */
    jsonField: object;
    /**
     * 
     * @type {string}
     * @memberof Debug
     */
    urlField: string;
    /**
     * 
     * @type {object}
     * @memberof Debug
     */
    dictField: object;
    /**
     * 
     * @type {Array<any>}
     * @memberof Debug
     */
    listField: Array<any>;
    /**
     * 
     * @type {number}
     * @memberof Debug
     */
    integerField: number;
    /**
     * 
     * @type {number}
     * @memberof Debug
     */
    floatField: number;
    /**
     * 
     * @type {string}
     * @memberof Debug
     */
    decimalField: string;
    /**
     * 
     * @type {boolean}
     * @memberof Debug
     */
    booleanField: boolean;
    /**
     * 
     * @type {Date}
     * @memberof Debug
     */
    dateField: Date;
    /**
     * 
     * @type {Date}
     * @memberof Debug
     */
    datetimeField: Date;
    /**
     * 
     * @type {string}
     * @memberof Debug
     */
    timeField: string;
    /**
     * 
     * @type {string}
     * @memberof Debug
     */
    uuidField: string;
    /**
     * 
     * @type {string}
     * @memberof Debug
     */
    readonly methodField?: string;
}

/**
 * Check if a given object implements the Debug interface.
 */
export function instanceOfDebug(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "charField" in value;
    isInstance = isInstance && "emailField" in value;
    isInstance = isInstance && "jsonField" in value;
    isInstance = isInstance && "urlField" in value;
    isInstance = isInstance && "dictField" in value;
    isInstance = isInstance && "listField" in value;
    isInstance = isInstance && "integerField" in value;
    isInstance = isInstance && "floatField" in value;
    isInstance = isInstance && "decimalField" in value;
    isInstance = isInstance && "booleanField" in value;
    isInstance = isInstance && "dateField" in value;
    isInstance = isInstance && "datetimeField" in value;
    isInstance = isInstance && "timeField" in value;
    isInstance = isInstance && "uuidField" in value;

    return isInstance;
}

export function DebugFromJSON(json: any): Debug {
    return DebugFromJSONTyped(json, false);
}

export function DebugFromJSONTyped(json: any, ignoreDiscriminator: boolean): Debug {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'charField': json['char_field'],
        'emailField': json['email_field'],
        'jsonField': json['json_field'],
        'urlField': json['url_field'],
        'dictField': json['dict_field'],
        'listField': json['list_field'],
        'integerField': json['integer_field'],
        'floatField': json['float_field'],
        'decimalField': json['decimal_field'],
        'booleanField': json['boolean_field'],
        'dateField': (new Date(json['date_field'])),
        'datetimeField': (new Date(json['datetime_field'])),
        'timeField': json['time_field'],
        'uuidField': json['uuid_field'],
        'methodField': !exists(json, 'method_field') ? undefined : json['method_field'],
    };
}

export function DebugToJSON(value?: Debug | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'char_field': value.charField,
        'email_field': value.emailField,
        'json_field': value.jsonField,
        'url_field': value.urlField,
        'dict_field': value.dictField,
        'list_field': value.listField,
        'integer_field': value.integerField,
        'float_field': value.floatField,
        'decimal_field': value.decimalField,
        'boolean_field': value.booleanField,
        'date_field': (value.dateField.toISOString().substr(0,10)),
        'datetime_field': (value.datetimeField.toISOString()),
        'time_field': value.timeField,
        'uuid_field': value.uuidField,
    };
}

