/* tslint:disable */
/* eslint-disable */
/**
 * Westbury Music API
 * API for Westbury Music
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AdditionalContactDetailsRepresentativeBankDetails
 */
export interface AdditionalContactDetailsRepresentativeBankDetails {
    /**
     * 
     * @type {string}
     * @memberof AdditionalContactDetailsRepresentativeBankDetails
     */
    readonly url?: string;
    /**
     * 
     * @type {string}
     * @memberof AdditionalContactDetailsRepresentativeBankDetails
     */
    readonly user?: string;
    /**
     * 
     * @type {string}
     * @memberof AdditionalContactDetailsRepresentativeBankDetails
     */
    readonly representative?: string;
    /**
     * 
     * @type {Date}
     * @memberof AdditionalContactDetailsRepresentativeBankDetails
     */
    readonly dateCreated?: Date;
    /**
     * 
     * @type {Date}
     * @memberof AdditionalContactDetailsRepresentativeBankDetails
     */
    readonly dateUpdated?: Date;
    /**
     * 
     * @type {string}
     * @memberof AdditionalContactDetailsRepresentativeBankDetails
     */
    bankAccountName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalContactDetailsRepresentativeBankDetails
     */
    bankAccountNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalContactDetailsRepresentativeBankDetails
     */
    bankSortCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalContactDetailsRepresentativeBankDetails
     */
    bankName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalContactDetailsRepresentativeBankDetails
     */
    bankAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalContactDetailsRepresentativeBankDetails
     */
    bankPostcode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalContactDetailsRepresentativeBankDetails
     */
    bankIban?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalContactDetailsRepresentativeBankDetails
     */
    bankRoutingCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalContactDetailsRepresentativeBankDetails
     */
    bankAccountHoldingBranch?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalContactDetailsRepresentativeBankDetails
     */
    bankBicSwiftCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdditionalContactDetailsRepresentativeBankDetails
     */
    bankPaymentMethod?: AdditionalContactDetailsRepresentativeBankDetailsBankPaymentMethodEnum;
}


/**
 * @export
 */
export const AdditionalContactDetailsRepresentativeBankDetailsBankPaymentMethodEnum = {
    BankTransfer: 'bank_transfer',
    Cheque: 'cheque'
} as const;
export type AdditionalContactDetailsRepresentativeBankDetailsBankPaymentMethodEnum = typeof AdditionalContactDetailsRepresentativeBankDetailsBankPaymentMethodEnum[keyof typeof AdditionalContactDetailsRepresentativeBankDetailsBankPaymentMethodEnum];


/**
 * Check if a given object implements the AdditionalContactDetailsRepresentativeBankDetails interface.
 */
export function instanceOfAdditionalContactDetailsRepresentativeBankDetails(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AdditionalContactDetailsRepresentativeBankDetailsFromJSON(json: any): AdditionalContactDetailsRepresentativeBankDetails {
    return AdditionalContactDetailsRepresentativeBankDetailsFromJSONTyped(json, false);
}

export function AdditionalContactDetailsRepresentativeBankDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdditionalContactDetailsRepresentativeBankDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'url': !exists(json, 'url') ? undefined : json['url'],
        'user': !exists(json, 'user') ? undefined : json['user'],
        'representative': !exists(json, 'representative') ? undefined : json['representative'],
        'dateCreated': !exists(json, 'date_created') ? undefined : (new Date(json['date_created'])),
        'dateUpdated': !exists(json, 'date_updated') ? undefined : (new Date(json['date_updated'])),
        'bankAccountName': !exists(json, 'bank_account_name') ? undefined : json['bank_account_name'],
        'bankAccountNumber': !exists(json, 'bank_account_number') ? undefined : json['bank_account_number'],
        'bankSortCode': !exists(json, 'bank_sort_code') ? undefined : json['bank_sort_code'],
        'bankName': !exists(json, 'bank_name') ? undefined : json['bank_name'],
        'bankAddress': !exists(json, 'bank_address') ? undefined : json['bank_address'],
        'bankPostcode': !exists(json, 'bank_postcode') ? undefined : json['bank_postcode'],
        'bankIban': !exists(json, 'bank_iban') ? undefined : json['bank_iban'],
        'bankRoutingCode': !exists(json, 'bank_routing_code') ? undefined : json['bank_routing_code'],
        'bankAccountHoldingBranch': !exists(json, 'bank_account_holding_branch') ? undefined : json['bank_account_holding_branch'],
        'bankBicSwiftCode': !exists(json, 'bank_bic_swift_code') ? undefined : json['bank_bic_swift_code'],
        'bankPaymentMethod': !exists(json, 'bank_payment_method') ? undefined : json['bank_payment_method'],
    };
}

export function AdditionalContactDetailsRepresentativeBankDetailsToJSON(value?: AdditionalContactDetailsRepresentativeBankDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'bank_account_name': value.bankAccountName,
        'bank_account_number': value.bankAccountNumber,
        'bank_sort_code': value.bankSortCode,
        'bank_name': value.bankName,
        'bank_address': value.bankAddress,
        'bank_postcode': value.bankPostcode,
        'bank_iban': value.bankIban,
        'bank_routing_code': value.bankRoutingCode,
        'bank_account_holding_branch': value.bankAccountHoldingBranch,
        'bank_bic_swift_code': value.bankBicSwiftCode,
        'bank_payment_method': value.bankPaymentMethod,
    };
}

