import {
  SigninAuthToken,
  SignupAuthTokenToken,
} from 'shared_components/generated/client';
import {
  Middleware,
  FetchParams,
  RequestContext,
  ResponseContext,
} from 'shared_components/generated/client';

export const basePath = process.env.WB_API_URL;

interface TokenObtainCredentials {
  identifier: string;
  password: string;
}

export interface TokenObject {
  access: string;
  refresh: string;
}

export interface RefreshTokenResponse {
  access: string;
}

const AuthRoutes = {
  tokenObtain: `${basePath}/portal/api/token/`,
  tokenRefresh: `${basePath}/portal/api/token/refresh/`,
  tokenVerify: `${basePath}/portal/api/token/verify/`,
  signIn: `${basePath}/portal/signin/`,
  signUp: `${basePath}/portal/signup/`,
};

export async function tokenObtain(
  credentials: TokenObtainCredentials
): Promise<TokenObject> {
  const response = await fetch(AuthRoutes.tokenObtain, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      username: credentials.identifier,
      password: credentials.password,
    }),
  });
  return await response.json();
}

export async function tokenRefresh(
  refreshToken: string
): Promise<RefreshTokenResponse> {
  const response = await fetch(AuthRoutes.tokenRefresh, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ refresh: refreshToken }),
  });
  return await response.json();
}

const saveLocationToSessionStorage = () => {
  sessionStorage.setItem('prev', location.href);
};

export const getPrevLocationFromSessionStorage = () => {
  return sessionStorage.getItem('prev');
};

const clearLocationFromSessionStorage = () => {
  sessionStorage.removeItem('prev');
};

export function saveTokenToSessionStorage(token: SignupAuthTokenToken) {
  sessionStorage.setItem('token', JSON.stringify(token));
}

export function removeTokenFromSessionStorage() {
  sessionStorage.removeItem('token');
}

export function getTokenFromSessionStorage(): SignupAuthTokenToken | null {
  const token = sessionStorage.getItem('token');
  if (token === null) return null; // token is not set, raise exception?
  return JSON.parse(token);
}

export function clientLoginHandler(clientLoginResponse: SigninAuthToken) {
  saveTokenToSessionStorage(clientLoginResponse.token);
  const prev = getPrevLocationFromSessionStorage();
  if (prev) {
    clearLocationFromSessionStorage();
    location.href = prev;
  }
}

export function adminLoginHandler(adminLoginResponse: SigninAuthToken) {
  saveTokenToSessionStorage(adminLoginResponse.token);
  const prev = getPrevLocationFromSessionStorage();
  if (prev) {
    clearLocationFromSessionStorage();
    location.href = prev;
  }
}

export function defaultHeaders(): {} | { Authorization: string } {
  const token = getTokenFromSessionStorage();
  if (token === null) return {};
  return { Authorization: `Bearer ${token.access}` };
}

export class AuthMiddleware implements Middleware {
  // https://github.com/OpenAPITools/openapi-generator/issues/2594#issuecomment-808808097
  // Note: this is the only documentation I could find on implementing
  // middleware via this openapi client (typescript-fetch)

  async pre(context: RequestContext): Promise<FetchParams | void> {
    // do we need to sanitize the url??
    if (![AuthRoutes.signIn, AuthRoutes.signUp].includes(context.url)) {
      context.init.headers = {
        ...defaultHeaders(),
        ...context.init.headers,
      };
    }
    return Promise.resolve({ url: context.url, init: context.init });
  }

  async post(context: ResponseContext): Promise<Response | void> {
    if (context.response.status === 401) {
      return context.response
        .json()
        .then(async ({ code }) => {
          saveLocationToSessionStorage();
          removeTokenFromSessionStorage();
          location.pathname = '/signin';

          // [TODO]: when adding remember me functionality, use refresh token if available
          // method moved into 'refreshAccessToken' function
          // await refreshAccessToken();
          if (code !== 'token_not_valid') {
            return Promise.resolve(context.response);
          }

          context.init.headers = {
            ...context.init.headers,
            ...defaultHeaders(),
          };

          return fetch(context.url, context.init);
        })
        .catch(() => Promise.resolve(context.response));
    }
    return Promise.resolve(context.response);
  }
}

const refreshAccessToken = async () => {
  const token = getTokenFromSessionStorage();

  if (token === null) {
    throw new Error();
  }

  await tokenRefresh(token.refresh).then((response) => {
    saveTokenToSessionStorage({ ...token, access: response.access });
  });
};
