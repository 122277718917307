/* tslint:disable */
/* eslint-disable */
/**
 * Westbury Music API Admin
 * API for Westbury Music admin users
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DeleteDeclinedArtistRunStatus
 */
export interface DeleteDeclinedArtistRunStatus {
    /**
     * 
     * @type {string}
     * @memberof DeleteDeclinedArtistRunStatus
     */
    runId: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeleteDeclinedArtistRunStatus
     */
    status: DeleteDeclinedArtistRunStatusStatusEnum;
}


/**
 * @export
 */
export const DeleteDeclinedArtistRunStatusStatusEnum = {
    InProgress: 'IN_PROGRESS',
    Failed: 'FAILED',
    Succeeded: 'SUCCEEDED',
    Unknown: 'UNKNOWN'
} as const;
export type DeleteDeclinedArtistRunStatusStatusEnum = typeof DeleteDeclinedArtistRunStatusStatusEnum[keyof typeof DeleteDeclinedArtistRunStatusStatusEnum];


/**
 * Check if a given object implements the DeleteDeclinedArtistRunStatus interface.
 */
export function instanceOfDeleteDeclinedArtistRunStatus(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "runId" in value;
    isInstance = isInstance && "status" in value;

    return isInstance;
}

export function DeleteDeclinedArtistRunStatusFromJSON(json: any): DeleteDeclinedArtistRunStatus {
    return DeleteDeclinedArtistRunStatusFromJSONTyped(json, false);
}

export function DeleteDeclinedArtistRunStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeleteDeclinedArtistRunStatus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'runId': json['run_id'],
        'status': json['status'],
    };
}

export function DeleteDeclinedArtistRunStatusToJSON(value?: DeleteDeclinedArtistRunStatus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'run_id': value.runId,
        'status': value.status,
    };
}

