import React from 'react';
import WContainer from 'shared_components/components/WContainer';
import WButton from '../WForms/WButton/WButton';

interface Props {
  title?: string;
  description?: string;
  cta?: { ctaText: string; onClick: () => void };
}

const defaultTitle = 'Oops... Something Went Wrong';
const defaultDescription = 'An error occurred';
export const ErrorBlock: React.FC<Props> = ({
  title = defaultTitle,
  description = defaultDescription,
  cta = undefined,
}) => {
  return (
    <WContainer
      extraClasses={`tw-flex tw-flex-col tw-items-center tw-w-[400px] ${
        cta === undefined ? 'tw-pb-24' : 'tw-pb-4'
      }`}
    >
      <i
        className="wi tw-h-20 tw-w-20 wi-alert-warning"
        style={{ background: '#FF2600' }}
      ></i>
      <p className="tw-text-white tw-text-2xl tw-text-center tw-mt-2 tw-font-medium">
        {title}
      </p>
      <p className="tw-text-md tw-text-center tw-mt-2 tw-font-light">
        {description}
      </p>

      {cta != undefined && (
        <p className="tw-mt-6">
          <WButton
            variant="primary-lg"
            label={cta.ctaText}
            onClick={cta.onClick}
          />
        </p>
      )}
    </WContainer>
  );
};

export default ErrorBlock;
