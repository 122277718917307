import React, { useState, Component, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Footer from './Footer/Footer';
import Sidebar from './Sidebar/Sidebar';
import Header from './Header/Header';
import DistributionDetails from './DistributionDetails';
import { ClientProvider } from 'shared_components/context/client';
import PageLayout from './PageLayout';

function Distribution() {
  const [sidebarSmall, setSidebarSmall] = useState(false);

  return (
    <PageLayout>
      <ClientProvider>
        <DistributionDetails />
      </ClientProvider>
    </PageLayout>
  );
}

export default Distribution;
