import React, { useEffect, useState } from 'react';
import { useApi } from 'shared_components/context';
import {
  AdminSociety,
  MembershipForms,
  MembershipFormsStatusEnum,
  SocietyFormRole,
  SocietyFormRoleFormsInner,
  SocietyFormRoleFormsInnerSignedByEnum,
  SocietyPortalTasks,
  SocietyPortalTasksStatusEnum,
  TaxFormRole,
  UserSettings,
} from 'shared_components/generated/admin';
import PageLayout from './PageLayout';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import WTable from 'shared_components/WTable/WTable';
import WAccordion from 'shared_components/components/WAccordion';
import WButton from 'shared_components/components/WForms/WButton/WButton';
import { SocietyFormRoleFormsInnerSignatureEnum } from 'shared_components/generated/admin';
import SocietyFormModal from './SocietyFormModal';
import WModal from 'shared_components/components/WModal';
import ClientDetailsCard from './Components/ClientDetails/ClientDetailsCard';
import {
  WSidebarModal,
  WSidebarModalFooter,
} from 'shared_components/components/WModal/WSidebarModal';
import WContainer from 'shared_components/components/WContainer';
import ClientDetails from './Components/ClientDetails/ClientDetails';
import { ClientDetailsRowProps } from './Components/ClientDetails/ClientDetailsRow';
import FormUpload, {
  fileUploadData,
} from './Components/ClientDetails/FormUpload';
import WebformComplete from './Components/ClientDetails/WebformComplete';
import ThankyouBlock from 'shared_components/components/ThankYouBlock/ThankyouBlock';

interface societyFormData {
  formName: string;
  society: string;
  territory: string;
  signedBy: string;
  withClient: React.ReactNode;
  cta: React.ReactNode;
}
interface columnsData {
  title: string;
  dataKey: string;
  width?: string;
}

function SocietyForms() {
  const { adminApi: api } = useApi();
  const navigate = useNavigate();
  const data = useLocation();
  const taskId: string | undefined = data.state?.taskId
    ? data.state.taskId
    : undefined;
  const [societyList, setSocietyList] = useState<Array<AdminSociety>>([]);
  const { userId } = useParams<{ userId: string }>();
  const [digitalTableData, setDigitalTableData] = useState<societyFormData[]>(
    []
  );
  const [physicalTableData, setPhysicalTableData] = useState<societyFormData[]>(
    []
  );
  const [portalTableData, setPortalTableData] = useState<societyFormData[]>([]);
  const [digitalTaxFormsData, setDigitalTaxFormsData] = useState<
    societyFormData[]
  >([]);
  const [physicalTaxFormsData, setPhysicalTaxFormsData] = useState<
    societyFormData[]
  >([]);
  const [membershipForms, setMembershipForms] = useState<MembershipForms[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [societyFormsModalIsOpen, setSocietyFormsModalIsOpen] =
    useState<boolean>(false);
  const [sessionId, setSessionId] = useState<string | undefined>(undefined);
  const [modalTitle, setModalTitle] = useState<string>('');
  const [successModalIsOpen, setSuccessModalIsOpen] = useState<boolean>(false);
  const [onComplete, setOnComplete] = useState<(() => void) | undefined>(
    undefined
  );
  const [userSettings, setUserSettings] = useState<UserSettings | undefined>(
    undefined
  );
  const [SuccessfulBlockTitle, setSuccessfulBlockTitle] = useState<string>('');
  const [SuccessfulBlockDescription, setSuccessfulBlockDescription] =
    useState<string>('');
  const [artistDetailsSidebarIsOpen, setArtistDetailsSidebarIsOpen] =
    useState<boolean>(false);
  const [clientDetailsData, setClientDetailsData] = useState<
    ClientDetailsRowProps[]
  >([]);
  const [userIdUrl, setUserIdUrl] = useState<{
    fileName: string;
    fileUrl: string;
  }>({ fileName: '', fileUrl: '' });
  const [userPoaUrl, setUserPoaUrl] = useState<{
    fileName: string;
    fileUrl: string;
  }>({ fileName: '', fileUrl: '' });
  const [formUploadIsOpen, setFormUploadIsOpen] = useState<boolean>(false);
  const [openWebformCompleteModal, setOpenWebformCompleteModal] =
    useState<boolean>(false);
  const [webformCompleteModalIsOpen, setWebformCompleteModalIsOpen] =
    useState<boolean>(false);
  const [societyPortalTasks, setSocietyPortalTasks] = useState<
    SocietyPortalTasks[]
  >([]);
  const [allFormsComplete, setAllFormsComplete] = useState<boolean>(false);
  const rowStyle: React.CSSProperties = { borderBottom: '1px solid #505050' };
  const columns: columnsData[] = [
    {
      title: 'Form name',
      dataKey: 'formName',
      width: '2fr',
    },
    {
      title: 'Society',
      dataKey: 'society',
      width: '2fr',
    },
    {
      title: 'Territory',
      dataKey: 'territory',
      width: '2fr',
    },
    {
      title: 'Signed by',
      dataKey: 'signedBy',
      width: '2fr',
    },
    {
      title: '',
      dataKey: 'withClient',
      width: '1fr',
    },
    {
      title: '',
      dataKey: 'cta',
      width: '1fr',
    },
  ];
  const portalColumns: columnsData[] = [
    { title: 'Society', dataKey: 'society', width: '2fr' },
    { title: 'Territory', dataKey: 'territory', width: '2fr' },
    { title: '', dataKey: 'cta', width: '1fr' },
  ];
  const [completePortalTaskFunc, setCompletePortalTaskFunc] = useState<
    () => void
  >(() => {});

  const fetchData = async () => {
    if (userId === undefined) return;
    try {
      let membershipForms = await api.listMembershipForms({ userId: userId });
      let societiesData = await api.listAdminSocieties();
      let termsOfAgreementData = await api.retrieveTermsOfAgreement({
        userId: userId,
      });
      if (!termsOfAgreementData.universalMandate) {
        societiesData = societiesData.filter((society) => {
          return termsOfAgreementData?.mandatedSocieties?.includes(
            society.id ?? ''
          );
        });
      }
      setMembershipForms(membershipForms);
      setSocietyList(societiesData);
      let userSettingsData = await api.retrieveUserSettings({ userId: userId });
      setUserSettings(userSettingsData);
      let passportResult =
        await api.retrieveLatestAdminIdentityDocumentSubmission({
          userId: userId as string,
          documentType: 'Passport',
        });
      setUserIdUrl({
        fileName: passportResult.filename ?? '',
        fileUrl: passportResult.fileUrl ?? '',
      });
      let poaResult = await api.retrieveLatestAdminIdentityDocumentSubmission({
        userId: userId as string,
        documentType: 'Power_Of_Attorney',
      });
      setUserPoaUrl({
        fileName: poaResult.filename ?? '',
        fileUrl: poaResult.fileUrl ?? '',
      });
      let portalTasks = await api.listSocietyPortalTasks({ userId: userId });
      setSocietyPortalTasks(portalTasks);
    } catch (err) {
      console.log('Error retrieving data', err);
      return;
    }
  };
  const renderPandadocDocument = async (
    societyFormSubroleId: string | undefined,
    adminSocietyFormTaskId: string | undefined,
    sessionId: string | undefined | null,
    complete: boolean = false,
    signedBy: string | undefined,
    signature: string | undefined
  ) => {
    setSocietyFormsModalIsOpen(true);
    setIsLoading(true);
    let sessionIdInner = sessionId;
    if (sessionIdInner === undefined || sessionIdInner === null) {
      sessionIdInner = await createPandadocDocument(
        societyFormSubroleId,
        adminSocietyFormTaskId
      );
      if (sessionIdInner === undefined) {
        return;
      }
    }
    if (!complete) {
      setOnComplete(() => () => {
        completePandadocDocument(
          societyFormSubroleId,
          adminSocietyFormTaskId,
          signature
        );
      });

      switch (signedBy) {
        case SocietyFormRoleFormsInnerSignedByEnum.Admin:
          setSuccessfulBlockTitle('Form Signed');
          setSuccessfulBlockDescription(
            'Thank you for signing this form. Don’t forget to sign all forms for this client to process their registration for each society.'
          );
          break;
        default:
          setSuccessfulBlockTitle('Sent to Client');
          setSuccessfulBlockDescription(
            'The form has been successfully forwarded to the client’s portal. They will be able to log in and sign this form digitally.'
          );
          break;
      }
    } else {
      setOnComplete(undefined);
    }
    setSessionId(sessionIdInner);
  };

  const createPandadocDocument = async (
    societyFormSubroleId: string | undefined,
    adminSocietyFormTaskId: string | undefined
  ) => {
    try {
      let pandadocDocumentIdsData = await api.createPandadocDocumentIds({
        pandadocDocumentIds: {
          userId: userId,
          email: userSettings?.emailAddress ?? 'missing',
          adminSocietyFormTaskId: adminSocietyFormTaskId,
          societyFormSubroleId: societyFormSubroleId,
        },
      });
      if (
        pandadocDocumentIdsData === undefined ||
        pandadocDocumentIdsData.documents === undefined ||
        // check if any sessionId is undefined for sessionId in pandadocDocumentIdsData.documents
        pandadocDocumentIdsData.documents?.some(
          (document) => document.sessionId === null
        )
      ) {
        console.log('Error retrieving pandadoc document ids');
        closeSocietyFormsModal();
        return;
      }
      return pandadocDocumentIdsData.documents[0].sessionId;
    } catch (err) {
      console.log('Error retrieving pandadoc document ids', err);
      closeSocietyFormsModal();
    }
  };

  const completePandadocDocument = async (
    societyFormSubroleId: string | undefined,
    adminSocietyFormTaskId: string | undefined,
    signature: string | undefined
  ) => {
    try {
      setIsLoading(true);
      let responseData = await api.adminSignSignMembershipForms({
        formId: adminSocietyFormTaskId as string,
        signMembershipForms: {
          societyFormSubRoleId: societyFormSubroleId as string,
          user: userId as unknown as number,
          societyForm: '',
        },
      });
      let membershipFormsInner = membershipForms;
      setMembershipForms(
        membershipFormsInner.map((membershipForm) => {
          return membershipForm.id === adminSocietyFormTaskId
            ? responseData
            : membershipForm;
        })
      );
      if (signature === SocietyFormRoleFormsInnerSignatureEnum.Physical) {
        setIsLoading(false);
        return;
      }
      closeSocietyFormsModal();
      setSuccessModalIsOpen(true);
    } catch (err) {
      console.log('error completing form', err);
    }
  };

  const closeSocietyFormsModal = () => {
    setSocietyFormsModalIsOpen(false);
    setIsLoading(false);
    setSessionId(undefined);
  };

  useEffect(() => {
    fetchData();
  }, [userId]);

  const checkAllFormsComplete = () => {
    if (membershipForms.length === 0) return;
    let allFormsComplete = true;
    membershipForms.forEach((membershipForm) => {
      if (
        membershipForm.complete === false ||
        membershipForm.status === MembershipFormsStatusEnum.ClientToSign ||
        membershipForm.status === MembershipFormsStatusEnum.WithClient
      ) {
        allFormsComplete = false;
      }
    });
    societyPortalTasks.forEach((task) => {
      if (task.complete === false) {
        allFormsComplete = false;
      }
    });
    setAllFormsComplete(allFormsComplete);
  };

  useEffect(() => {
    if (societyList.length === 0) return;
    checkAllFormsComplete();
    const digitalFormData: societyFormData[] = [];
    const physicalFormData: societyFormData[] = [];
    const portalFormData: societyFormData[] = [];
    const digitalTaxFormData: societyFormData[] = [];
    const physicalTaxFormData: societyFormData[] = [];

    societyList.forEach((society) => {
      // @ts-ignore - CLI tool thinks that roleForms is a string, not SocietyFormRole[]
      society.roleForms?.forEach((role: SocietyFormRole) => {
        role.forms?.forEach((form: SocietyFormRoleFormsInner) => {
          const formName =
            society.societyForms.find(
              (societyForm) => societyForm.id === form.form
            )?.readableName || '';
          const membershipForm = membershipForms.find(
            (membershipForm) => membershipForm.societyForm === form.form
          );
          // only add to table if there's a corresponding membership form
          if (membershipForm) {
            let data: societyFormData = {
              formName: formName,
              society: society.readableName ?? '',
              territory: society.country ?? '',
              withClient:
                membershipForm?.status ===
                  MembershipFormsStatusEnum.ClientToSign ||
                membershipForm?.status ===
                  MembershipFormsStatusEnum.WithClient ? (
                  <div
                    style={{ color: 'black', whiteSpace: 'nowrap' }}
                    className="tw-bg-surfaceBlue"
                  >
                    <p>WITH CLIENT</p>
                  </div>
                ) : (
                  ''
                ),
              // @ts-ignore - signed_by isn't correctly converted to expected camelCase attribute
              signedBy: form.signed_by ?? '',
              cta: buildCta(form, membershipForm, formName),
            };
            if (
              form.signature === SocietyFormRoleFormsInnerSignatureEnum.Digital
            ) {
              digitalFormData.push(data);
            } else if (
              form.signature === SocietyFormRoleFormsInnerSignatureEnum.Physical
            ) {
              physicalFormData.push(data);
            }
          }
        });
      });
      if (society.formPortal && society.formPortal !== '') {
        // find societyportaltask with society id matching society.id
        let societyPortalTask = societyPortalTasks.find(
          (task) => task.society === society.id
        );
        // if task, push to portalFormData
        if (societyPortalTask !== undefined) {
          const complete = societyPortalTask.status === 'complete';
          let portalData: societyFormData = {
            formName: '',
            society: society.readableName ?? '',
            territory: society.country ?? '',
            signedBy: '',
            withClient: '',
            cta: (
              <div className="tw-flex tw-justify-end tw-w-full">
                {complete && completedTick()}
                <WButton
                  variant="secondary"
                  label="Go to portal"
                  onClick={() => {
                    society.formPortal
                      ? openPortal(
                          society.formPortal,
                          societyPortalTask?.id ?? '',
                          society.id ?? ''
                        )
                      : {};
                  }}
                />
              </div>
            ),
          };
          portalFormData.push(portalData);
        }
      }
      // @ts-ignore - CLI tool thinks that taxForms is a string, not TaxFormRole[]
      society.taxForms?.forEach((taxForm: TaxFormRole) => {
        // find membership form with societyForm matching taxForm.form
        let membershipForm = membershipForms.find(
          (membershipForm) => membershipForm.societyForm === taxForm.form
        );
        const formName =
          society.societyForms.find(
            (societyForm) => societyForm.id === taxForm.form
          )?.readableName || '';
        if (membershipForm) {
          let data: societyFormData = {
            formName: formName,
            society: society.readableName ?? '',
            territory: society.country ?? '',
            withClient:
              membershipForm?.status === 'client_to_sign' ? (
                <div
                  style={{ color: 'black', whiteSpace: 'nowrap' }}
                  className="tw-bg-surfaceBlue"
                >
                  <p>WITH CLIENT</p>
                </div>
              ) : (
                ''
              ),
            // @ts-ignore - signed_by isn't correctly converted to expected camelCase attribute
            signedBy: taxForm.signed_by ?? '',
            cta: buildCta(taxForm, membershipForm, formName),
          };
          if (
            taxForm.signature === SocietyFormRoleFormsInnerSignatureEnum.Digital
          ) {
            digitalTaxFormData.push(data);
          } else if (
            taxForm.signature ===
            SocietyFormRoleFormsInnerSignatureEnum.Physical
          ) {
            physicalTaxFormData.push(data);
          }
        }
      });
    });
    setDigitalTableData(digitalFormData);
    setPhysicalTableData(physicalFormData);
    setPortalTableData(portalFormData);
    setDigitalTaxFormsData(digitalTaxFormData);
    setPhysicalTaxFormsData(physicalTaxFormData);
  }, [societyList, membershipForms, userSettings, societyPortalTasks]);

  const completedTick = () => {
    return (
      <i
        style={{ backgroundColor: '#6EE038' }}
        className={`wi-sm wi-circle-check tw-mr-2 tw-mt-1`}
      />
    );
  };

  const buildCta = (
    form: SocietyFormRoleFormsInner | TaxFormRole,
    membershipForm: MembershipForms | undefined,
    formName: string
  ) => {
    const membershipFormId = membershipForm?.id;
    const sessionId = membershipForm?.pandadocsSessionId;
    const adminCompleted =
      membershipForm?.complete ||
      membershipForm?.status !== MembershipFormsStatusEnum.AdminToSign;
    const withClient =
      membershipForm?.status === MembershipFormsStatusEnum.ClientToSign ||
      membershipForm?.status === MembershipFormsStatusEnum.WithClient;
    const classNames = `tw-justify-end ${adminCompleted ? ' tw-mr-8' : ''}`;
    const uploaded = membershipForm?.status !== 'awaiting_upload';
    switch (form.signature) {
      case SocietyFormRoleFormsInnerSignatureEnum.Digital:
        const signText =
          // @ts-ignore - signed_by isn't correctly converted to expected camelCase attribute
          form.signed_by === SocietyFormRoleFormsInnerSignedByEnum.Client
            ? 'View & Send'
            : 'View & Sign';
        return (
          <div className={`tw-flex tw-w-full ${classNames}`}>
            {adminCompleted && !withClient && completedTick()}
            <WButton
              variant={adminCompleted ? 'link-secondary' : 'secondary'}
              label={adminCompleted ? 'View' : signText}
              onClick={() => {
                setModalTitle(formName);
                renderPandadocDocument(
                  form.id,
                  membershipFormId,
                  sessionId,
                  adminCompleted,
                  // @ts-ignore - signed_by isn't correctly converted to expected camelCase attribute
                  form.signed_by,
                  form.signature
                );
              }}
            />
          </div>
        );
      case SocietyFormRoleFormsInnerSignatureEnum.Physical:
        let awaitingUpload =
          membershipForm?.status === MembershipFormsStatusEnum.AwaitingUpload;
        const onClick =
          adminCompleted && !awaitingUpload
            ? () => {
                // download membership form from presigned url
                window.open(membershipForm?.url, '_blank');
              }
            : () => {
                setModalTitle(formName);
                renderPandadocDocument(
                  form.id,
                  membershipFormId,
                  sessionId,
                  adminCompleted,
                  // @ts-ignore - signed_by isn't correctly converted to expected camelCase attribute
                  form.signed_by,
                  form.signature
                );
              };
        const viewButtonText =
          adminCompleted && !awaitingUpload ? 'Download' : 'View';
        return (
          <div
            className={`tw-flex tw-justify-end tw-w-full ${
              adminCompleted && !awaitingUpload ? 'tw-mr-4' : 'tw-mr-8'
            }`}
          >
            {awaitingUpload && (
              <WButton
                variant="link-secondary"
                label="Upload"
                onClick={() => {
                  setFormData({
                    formId: membershipForm?.id ?? '',
                    societyFormSubRoleId: form.id ?? '',
                    user: userId as unknown as number,
                    societyForm: form.form ?? '',
                    file: undefined,
                  });
                  setFormUploadIsOpen(true);
                }}
                extraClasses="tw-mr-4"
              />
            )}
            {adminCompleted && uploaded && completedTick()}
            <WButton
              variant="link-secondary"
              label={viewButtonText}
              onClick={onClick}
            />
          </div>
        );
    }
  };

  useEffect(() => {
    if (userSettings === undefined) return;
    let userSettingsData = [
      {
        label: 'Title',
        data: userSettings?.title ?? '',
        copy: false,
      },
      {
        label: 'First Name',
        data: userSettings?.firstName ?? '',
      },
      {
        label: 'Middle Name',
        data: userSettings?.middleName ?? '',
      },
      {
        label: 'Last Name',
        data: userSettings?.lastName ?? '',
      },
      {
        label: 'Aliases',
        data: userSettings?.aliases?.join(', ') ?? '',
      },
      {
        label: 'Date of Birth',
        data: userSettings?.dateOfBirth?.toLocaleDateString() ?? '',
        copy: false,
      },
      {
        label: 'Gender',
        data: userSettings?.gender ?? '',
        copy: false,
      },
      {
        label: 'Nationality',
        data: userSettings?.nationality ?? '',
        copy: false,
      },
      {
        label: 'City of Birth',
        data: userSettings?.cityOfBirth ?? '',
      },
      {
        label: 'Country of Birth',
        data: userSettings?.countryOfBirth ?? '',
      },
      {
        label: 'Email',
        data: userSettings?.emailAddress ?? '',
      },
      {
        label: 'Phone Number',
        data: userSettings?.phoneNumber ?? '',
      },
      {
        label: 'Street Address of Residence',
        data: userSettings?.streetAddress ?? '',
      },
      {
        label: 'County/State of Residence',
        data: userSettings?.county ?? '',
      },
      {
        label: 'Zip/Postcode of Residence',
        data: userSettings?.postcode ?? '',
      },
      {
        label: 'Country of Residence',
        data: userSettings?.country ?? '',
      },
      {
        label: 'Country of Taxation',
        data: userSettings?.taxationCountry ?? '',
      },
      {
        label: 'VAT Number',
        data: userSettings?.vatNumber ?? '',
      },
      {
        label: 'Tax ID Type',
        data: userSettings?.taxIdType ?? '',
      },
      {
        label: 'Tax ID Number',
        data: userSettings?.taxIdNumber ?? '',
      },
      {
        label: 'ID',
        data: userIdUrl.fileName ?? '',
        copy: false,
        link: userIdUrl.fileUrl ?? '',
      },
      {
        label: 'Power of Attorney',
        data: userPoaUrl.fileName ?? '',
        copy: false,
        link: userPoaUrl.fileUrl ?? '',
      },
    ];
    setClientDetailsData(userSettingsData);
  }, [userSettings, userIdUrl, userPoaUrl]);

  const emptyFormState = {
    formId: '',
    societyFormSubRoleId: '',
    user: userId as unknown as number,
    societyForm: '',
    file: undefined,
  };
  const [formData, setFormData] = useState(emptyFormState);

  const uploadForm = async (isValid?: boolean, data?: fileUploadData) => {
    if (
      data === undefined ||
      data.file === undefined ||
      data.user === undefined
    ) {
      return Promise.reject('data, file or user is undefined');
    }
    const response = api
      .adminUploadUploadMembershipForms({
        formId: data.formId ?? '',
        societyFormSubRoleId: data.societyFormSubRoleId ?? '',
        user: data.user,
        societyForm: data.societyForm ?? '',
        file: data.file,
      })
      .then((responseData) => {
        setMembershipForms(
          membershipForms.map((membershipForm) => {
            return membershipForm.id === responseData.id
              ? responseData
              : membershipForm;
          })
        );
        return Promise.resolve(responseData);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
    return response;
  };
  const onSuccess = () => {
    setFormUploadIsOpen(false);
    setSuccessModalIsOpen(true);
    setSuccessfulBlockTitle('Form Uploaded');
    setSuccessfulBlockDescription(
      'The form has been successfully uploaded to the portal and will be available to view under each the ‘Documents’ tab in the ‘Single Artist Page’.'
    );
  };
  const openPortal = (url: string, taskId: string, societyId: string) => {
    if (url === '' || url === undefined) {
      console.error('url is empty');
      return;
    }
    setArtistDetailsSidebarIsOpen(true);
    setOpenWebformCompleteModal(true);
    setCompletePortalTaskFunc(() => () => {
      completePortalTask(taskId, societyId);
    });
    window.open(url, '_blank');
  };

  const closeWebformCompleteModal = () => {
    setOpenWebformCompleteModal(false);
    setWebformCompleteModalIsOpen(false);
  };
  const closeClientDetailsSidebar = () => {
    setArtistDetailsSidebarIsOpen(false);
    if (openWebformCompleteModal) {
      setWebformCompleteModalIsOpen(true);
    }
  };
  const completePortalTask = async (taskId: string, societyId: string) => {
    try {
      let response = await api.adminCompleteSocietyPortalTasks({
        taskId: taskId,
        societyPortalTasks: {
          user: userId as unknown as number,
          society: societyId,
        },
      });
      setSocietyPortalTasks(
        societyPortalTasks.map((task) => {
          return task.id === taskId ? response : task;
        })
      );
      closeWebformCompleteModal();
    } catch (err) {
      console.log('error completing portal task', err);
    }
  };

  return (
    <PageLayout>
      <div className="tw-flex tw-flex-col  tw-justify-between text-white">
        <h2 className="tw-pb-4">Forms</h2>
        <div className="tw-pb-4">
          <WButton
            variant="link-secondary"
            icon="chevron-left"
            label="Back"
            onClick={() => navigate('/dashboard')}
          />
        </div>
        <div className="tw-flex tw-items-end tw-justify-between">
          <p className="tw-pt-2 tw-text-xl">Society Forms</p>
          {userSettings && (
            <ClientDetailsCard
              photo={userSettings?.userPhotoUrl ?? ''}
              name={`${userSettings?.firstName ?? ''} ${
                userSettings?.lastName ?? ''
              }`}
              address={`${userSettings?.streetAddress ?? ''}, ${
                userSettings?.county ?? ''
              }, ${userSettings?.postcode ?? ''}`}
              onClick={() => setArtistDetailsSidebarIsOpen(true)}
            ></ClientDetailsCard>
          )}
        </div>
        <WAccordion title="Digital" isOpen={true}>
          <WTable
            rowStyle={digitalTableData.map(() => rowStyle)}
            columns={columns}
            data={digitalTableData}
          />
        </WAccordion>
        <WAccordion title="Physical" isOpen={true}>
          <WTable
            rowStyle={physicalTableData.map(() => rowStyle)}
            columns={columns}
            data={physicalTableData}
          />
        </WAccordion>
        <WAccordion title="Society Portal" isOpen={true}>
          <WTable
            rowStyle={portalTableData.map(() => rowStyle)}
            columns={portalColumns}
            data={portalTableData}
          />
        </WAccordion>
        <p className="tw-pt-2 tw-text-xl">Tax Forms</p>
        <WAccordion title="Digital" isOpen={true}>
          <WTable
            rowStyle={digitalTaxFormsData.map(() => rowStyle)}
            columns={columns}
            data={digitalTaxFormsData}
          />
        </WAccordion>
        <WAccordion title="Physical" isOpen={true}>
          <WTable
            rowStyle={physicalTaxFormsData.map(() => rowStyle)}
            columns={columns}
            data={physicalTaxFormsData}
          />
        </WAccordion>
      </div>
      <SocietyFormModal
        isOpen={societyFormsModalIsOpen}
        onClose={closeSocietyFormsModal}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        title={modalTitle}
        sessionId={sessionId}
        onComplete={onComplete}
      />
      <WModal
        isOpen={successModalIsOpen}
        onClose={() => {
          setSuccessModalIsOpen(false);
        }}
        title=""
      >
        <ThankyouBlock
          title={'Form Signed'}
          description={
            'Thank you for signing this form. Don’t forget to sign all forms for this client to process their registration for each society.'
          }
        />
      </WModal>
      <WSidebarModal
        isOpen={artistDetailsSidebarIsOpen}
        cardEvent={() => {
          closeClientDetailsSidebar();
        }}
        title=""
      >
        <WContainer>
          <ClientDetails
            photo={userSettings?.userPhotoUrl ?? ''}
            data={clientDetailsData}
          />
        </WContainer>
        <WSidebarModalFooter>
          <WButton
            variant="primary"
            label="Close"
            onClick={() => {
              closeClientDetailsSidebar();
            }}
          />
        </WSidebarModalFooter>
      </WSidebarModal>
      <WModal
        isOpen={formUploadIsOpen}
        onClose={() => {
          setFormUploadIsOpen(false);
        }}
        title={'Upload Document'}
      >
        <FormUpload
          upload={uploadForm}
          initialFormData={formData}
          onSuccess={onSuccess}
        />
      </WModal>
      <WModal
        isOpen={webformCompleteModalIsOpen}
        onClose={() => {
          closeWebformCompleteModal();
        }}
        title=""
      >
        <WebformComplete
          onClose={() => {
            closeWebformCompleteModal();
          }}
          onComplete={completePortalTaskFunc}
        />
      </WModal>
      {allFormsComplete && (
        <div className="tw-mt-6 tw-flex tw-justify-end">
          <WButton
            variant="primary"
            label="All Forms Complete"
            onClick={async () => {
              if (taskId !== undefined) {
                await api.destroyAdminTaskClient({
                  taskId: taskId,
                });
              }
              navigate('/dashboard');
            }}
          />
        </div>
      )}
    </PageLayout>
  );
}

export default SocietyForms;
