/* tslint:disable */
/* eslint-disable */
/**
 * Westbury Music API Admin
 * API for Westbury Music admin users
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SocietyPortalTasks
 */
export interface SocietyPortalTasks {
    /**
     * 
     * @type {string}
     * @memberof SocietyPortalTasks
     */
    readonly id?: string;
    /**
     * 
     * @type {Date}
     * @memberof SocietyPortalTasks
     */
    readonly dateCreated?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SocietyPortalTasks
     */
    readonly dateUpdated?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof SocietyPortalTasks
     */
    complete?: boolean;
    /**
     * When the portal task was completed
     * @type {Date}
     * @memberof SocietyPortalTasks
     */
    dateCompleted?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof SocietyPortalTasks
     */
    status?: SocietyPortalTasksStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof SocietyPortalTasks
     */
    user: number;
    /**
     * 
     * @type {string}
     * @memberof SocietyPortalTasks
     */
    society: string;
}


/**
 * @export
 */
export const SocietyPortalTasksStatusEnum = {
    ClientToSign: 'client_to_sign',
    AdminToSign: 'admin_to_sign',
    Complete: 'complete',
    Approved: 'approved'
} as const;
export type SocietyPortalTasksStatusEnum = typeof SocietyPortalTasksStatusEnum[keyof typeof SocietyPortalTasksStatusEnum];


/**
 * Check if a given object implements the SocietyPortalTasks interface.
 */
export function instanceOfSocietyPortalTasks(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "user" in value;
    isInstance = isInstance && "society" in value;

    return isInstance;
}

export function SocietyPortalTasksFromJSON(json: any): SocietyPortalTasks {
    return SocietyPortalTasksFromJSONTyped(json, false);
}

export function SocietyPortalTasksFromJSONTyped(json: any, ignoreDiscriminator: boolean): SocietyPortalTasks {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'dateCreated': !exists(json, 'date_created') ? undefined : (new Date(json['date_created'])),
        'dateUpdated': !exists(json, 'date_updated') ? undefined : (new Date(json['date_updated'])),
        'complete': !exists(json, 'complete') ? undefined : json['complete'],
        'dateCompleted': !exists(json, 'date_completed') ? undefined : (json['date_completed'] === null ? null : new Date(json['date_completed'])),
        'status': !exists(json, 'status') ? undefined : json['status'],
        'user': json['user'],
        'society': json['society'],
    };
}

export function SocietyPortalTasksToJSON(value?: SocietyPortalTasks | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'complete': value.complete,
        'date_completed': value.dateCompleted === undefined ? undefined : (value.dateCompleted === null ? null : value.dateCompleted.toISOString()),
        'status': value.status,
        'user': value.user,
        'society': value.society,
    };
}

