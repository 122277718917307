import React, { useState, useEffect, useId, ChangeEvent } from 'react';

import { useApi } from 'shared_components/context';
import { useUserId, useUser } from 'shared_components/context/user';
import SquarePlaceholder185 from 'shared_components/public/assets/SquarePlaceholder185.svg';
import { AdminPersonalInformationDepartmentEnum } from 'shared_components/generated/admin';
import { z } from 'zod';

import WModal from 'shared_components/components/WModal';
import { WFormProvider } from 'shared_components/components/WForms/WFormProvider';
import { WInput } from 'shared_components/components/WForms/WInput/WInput';
import { WPassword } from 'shared_components/components/WForms/WPassword/WPassword';
import { WSelect } from 'shared_components/components/WForms/WSelect/WSelect';
import { WAvatar } from 'shared_components/components/WForms/WAvatar/WAvatar';
import { WFormFooter } from 'shared_components/components/WForms/WFormFooter';
import WContainer from 'shared_components/components/WContainer';
// Single use component to render the edit button
import { useFormContext } from 'shared_components/components/WForms/WFormContext';
import WButton from 'shared_components/components/WForms/WButton/WButton';
import { Constants } from 'shared_components/utils/Countries';
import { useWError } from 'shared_components/components/WError/WErrorProvider';
import { WError } from 'shared_components/components/WError/WError';
import ErrorBlock from 'shared_components/components/ErrorBlock/ErrorBlock';
function FormEditSection() {
  const { isEditing, setIsEditing } = useFormContext();
  return (
    <>
      {!isEditing && (
        <WButton
          label="Edit"
          icon="edit"
          variant="secondary"
          onClick={() => setIsEditing(!isEditing)}
        />
      )}
    </>
  );
}

export const AccountSettings = () => {
  const { throwError } = useWError();
  const { adminApi: api } = useApi();
  const { userId } = useUserId();
  const user = useUser();

  const [isOldPasswordError, setIsOldPasswordError] = useState(false);
  const [departmentOptions, setDepartmentOptions] = useState<
    AdminPersonalInformationDepartmentEnum[]
  >([]);
  const [userPhotoUrl, setUserPhotoUrl] = useState('');
  const [formData, setFormData] = useState<any>({});
  const [userPhoto, setUserPhoto] = useState<File | null>(null);
  const emailRegex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

  // if old password is set, then password and confirm password must be set
  const AccountSettingsSchema = z
    .object({
      firstName: z.string().min(1),
      lastName: z.string().min(1),
      emailAddress: z.string().regex(emailRegex),
      department: z.string().min(1),
      oldPassword: z.string().optional(),
      password: z.string().optional(),
      confirmPassword: z.string().optional(),
    })
    .refine((data) => data.password === data.confirmPassword, {
      message: "Password and confirm password don't match",
      path: ['confirmPassword'],
    });

  const fetchData = () => {
    api
      .retrieveAdminPersonalInformation({
        userId,
      })
      .then((personalInfo) => {
        setFormData({
          firstName: personalInfo.firstName,
          lastName: personalInfo.lastName,
          emailAddress: personalInfo.emailAddress,
          ...personalInfo,
          oldPassword: '',
          password: '',
          confirmPassword: '',
          department: personalInfo.department,
        });

        setDepartmentOptions(
          personalInfo.departmentChoices as AdminPersonalInformationDepartmentEnum[]
        );
        setUserPhotoUrl(personalInfo.userPhotoUrl ?? SquarePlaceholder185);
      })
      .catch((error) => {
        // parse error
        const data = error.response.json();
        console.log(data);
        throwError(new WError('Something went wrong when fetching data'));
      });
  };

  const handleSubmit = (isValid: boolean, data: any, errors: any) => {
    if (!isValid) {
      return;
    }
    let payload = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      // emailAddress: formData.emailAddress,
      department: formData.department,
    };

    if (formData.oldPassword) {
      payload = {
        ...payload,
        oldPassword: formData.oldPassword,
        password: formData.password,
      };
    }

    if (userPhoto && userId) {
      api
        .updatePhotoAdminPersonalInformation({
          userId,
          userPhoto,
        })
        .then((response) => {
          setUserPhotoUrl(response.userPhotoUrl!);
          user.setImageUrl(response.userPhotoUrl);
        })
        .catch(() => {
          throwError(new WError('Something went wrong when updating photo'));
        });
    }

    return api
      .partialUpdateAdminPersonalInformation({
        userId,
        adminPersonalInformation: payload,
      })
      .then(() => {
        user.setFirstName(formData.firstName);
        user.setLastName(formData.lastName);
        user.setDepartment(formData.department);
        return Promise.resolve(user);
      })
      .catch((error) => {
        error.response.json().then((json: any) => {
          if (
            json &&
            json.old_password &&
            json.old_password.includes('Incorrect password')
          ) {
            setIsOldPasswordError(true);
          }
        });

        return Promise.reject(error);
      });
  };

  // TODO: Handle Async file validation in WAvatar
  // TODO: Handle error messages in WAvatar
  const handlePhotoSubmit = (e: React.ChangeEvent) => {
    const image = (e.target as HTMLInputElement).files![0];
    if (userId === undefined) {
      throwError(new WError('User not found'));
      return false;
    }
    if (!image) {
      throwError(new WError('Image not found'));
      return false;
    }
    setUserPhoto(image);

    return true;
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  useEffect(() => {
    if (userId) {
      fetchData();
    }
  }, [userId]);

  return (
    <WFormProvider
      schema={AccountSettingsSchema}
      handleSubmit={handleSubmit}
      onSuccess={() => {
        setFormData({
          ...formData,
          oldPassword: '',
          password: '',
          confirmPassword: '',
        });
      }}
      formData={formData}
    >
      <WContainer extraClasses="tw-w-3/7 tw-min-w-[600px]">
        <div className="tw-flex tw-flex-row tw-justify-between">
          <span className={`tw-text-2xl tw-mb-6`}>My Account</span>
          <FormEditSection />
        </div>

        <WModal
          isOpen={isOldPasswordError}
          onClose={() => {
            setIsOldPasswordError(false);
          }}
        >
          <ErrorBlock
            title="Incorrect password"
            description="Your current password is incorrect"
          />
        </WModal>

        <div className="tw-flex tw-flex-col tw-mb-6">
          <WAvatar
            onBlur={() => {}}
            label={``}
            onChange={(e) => {
              if (e.target.files === null) {
                return false;
              }
              return handlePhotoSubmit(e);
            }}
            type={`avatar`}
            value={userPhotoUrl}
            name={`avatar`}
          />
          {/* Error section */}
        </div>

        <WInput
          type="text"
          name={'firstName'}
          label="First name"
          // take input validation out of AccountSettings and put it in WInput
          onBlur={() => {}}
          onChange={handleChange}
          value={formData.firstName}
        />
        <WInput
          type="text"
          name={`lastName`}
          label="Last name"
          onBlur={() => {}}
          onChange={handleChange}
          value={formData.lastName}
        />

        <WInput
          type="email"
          name={`emailAddress`}
          label="Email"
          onBlur={() => {}}
          onChange={() => {}}
          value={formData.emailAddress}
          disabled={true}
        />
        <WPassword
          name="oldPassword"
          label="Current password"
          icon={'👀'}
          onChange={handleChange}
          onBlur={() => {}}
          value={formData.oldPassword}
        />

        <WPassword
          name="password"
          label="New password"
          onChange={handleChange}
          onBlur={() => {}}
          icon={'👀'}
          hideOnView={true}
          value={formData.password}
        />
        <WPassword
          name="confirmPassword"
          label="Confirm password"
          icon={'👀'}
          onChange={handleChange}
          onBlur={() => {}}
          hideOnView={true}
          value={formData.confirmPassword}
        />
        <WSelect
          name={`department`}
          label="Department"
          onChange={handleChange}
          options={departmentOptions
            .filter((option) => option == 'Membership')
            .map((department) => ({
              value: department,
              label: department,
            }))}
          defaultValue={formData.department}
          onBlur={() => {}}
        />
      </WContainer>
      <WFormFooter />
    </WFormProvider>
  );
};
