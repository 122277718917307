import React from 'react';
import WButton from 'shared_components/components/WForms/WButton/WButton';
import profileImg from 'shared_components/public/assets/userProfile.png';

interface ClientDetailsCardProps {
  photo?: string;
  name: string;
  address: string;
  onClick?: () => void;
}

const ClientDetailsCard: React.FC<ClientDetailsCardProps> = ({
  photo,
  name,
  address,
  onClick,
}) => {
  return (
    <div className=" tw-flex tw-flex-row tw-items-end tw-bg-surfaceBlack90 tw-text-sm tw-text-textWhite tw-rounded-lg tw-p-2">
      <img
        src={photo || profileImg}
        alt="Client"
        className="tw-w-12 tw-h-12 tw-rounded-full"
      />
      <div className="tw-flex tw-flex-col tw-pr-2 tw-pl-2">
        <p className="tw-font-medium tw-text-xs tw-pb-1">DETAILS</p>
        <p className="tw-text-textDove tw-text-normal tw-pb-1">
          {name}, {address}
        </p>
      </div>
      <WButton
        variant="link-secondary"
        label="View more"
        onClick={onClick}
        extraClasses="tw-text-xs tw-pt-0.5"
      />
    </div>
  );
};

export default ClientDetailsCard;
