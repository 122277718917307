/* tslint:disable */
/* eslint-disable */
/**
 * Westbury Music API
 * API for Westbury Music
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OnboardingStepsOnboardingStepsInner } from './OnboardingStepsOnboardingStepsInner';
import {
    OnboardingStepsOnboardingStepsInnerFromJSON,
    OnboardingStepsOnboardingStepsInnerFromJSONTyped,
    OnboardingStepsOnboardingStepsInnerToJSON,
} from './OnboardingStepsOnboardingStepsInner';

/**
 * 
 * @export
 * @interface OnboardingSteps
 */
export interface OnboardingSteps {
    /**
     * 
     * @type {string}
     * @memberof OnboardingSteps
     */
    nextStepInformation: string;
    /**
     * 
     * @type {Array<OnboardingStepsOnboardingStepsInner>}
     * @memberof OnboardingSteps
     */
    onboardingSteps: Array<OnboardingStepsOnboardingStepsInner>;
}

/**
 * Check if a given object implements the OnboardingSteps interface.
 */
export function instanceOfOnboardingSteps(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "nextStepInformation" in value;
    isInstance = isInstance && "onboardingSteps" in value;

    return isInstance;
}

export function OnboardingStepsFromJSON(json: any): OnboardingSteps {
    return OnboardingStepsFromJSONTyped(json, false);
}

export function OnboardingStepsFromJSONTyped(json: any, ignoreDiscriminator: boolean): OnboardingSteps {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nextStepInformation': json['next_step_information'],
        'onboardingSteps': ((json['onboarding_steps'] as Array<any>).map(OnboardingStepsOnboardingStepsInnerFromJSON)),
    };
}

export function OnboardingStepsToJSON(value?: OnboardingSteps | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'next_step_information': value.nextStepInformation,
        'onboarding_steps': ((value.onboardingSteps as Array<any>).map(OnboardingStepsOnboardingStepsInnerToJSON)),
    };
}

