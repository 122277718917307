/* tslint:disable */
/* eslint-disable */
/**
 * Westbury Music API Admin
 * API for Westbury Music admin users
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SignMembershipForms
 */
export interface SignMembershipForms {
    /**
     * 
     * @type {string}
     * @memberof SignMembershipForms
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof SignMembershipForms
     */
    societyFormSubRoleId: string;
    /**
     * 
     * @type {Date}
     * @memberof SignMembershipForms
     */
    readonly dateCreated?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SignMembershipForms
     */
    readonly dateUpdated?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof SignMembershipForms
     */
    complete?: boolean;
    /**
     * When the form was completed
     * @type {Date}
     * @memberof SignMembershipForms
     */
    dateCompleted?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof SignMembershipForms
     */
    pandadocsSessionId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SignMembershipForms
     */
    pandadocsDocumentId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SignMembershipForms
     */
    pandadocsDocumentS3Key?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SignMembershipForms
     */
    status?: SignMembershipFormsStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof SignMembershipForms
     */
    user: number;
    /**
     * 
     * @type {string}
     * @memberof SignMembershipForms
     */
    societyForm: string;
}


/**
 * @export
 */
export const SignMembershipFormsStatusEnum = {
    ClientToSign: 'client_to_sign',
    WithClient: 'with_client',
    AdminToSign: 'admin_to_sign',
    AwaitingUpload: 'awaiting_upload',
    Approved: 'approved'
} as const;
export type SignMembershipFormsStatusEnum = typeof SignMembershipFormsStatusEnum[keyof typeof SignMembershipFormsStatusEnum];


/**
 * Check if a given object implements the SignMembershipForms interface.
 */
export function instanceOfSignMembershipForms(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "societyFormSubRoleId" in value;
    isInstance = isInstance && "user" in value;
    isInstance = isInstance && "societyForm" in value;

    return isInstance;
}

export function SignMembershipFormsFromJSON(json: any): SignMembershipForms {
    return SignMembershipFormsFromJSONTyped(json, false);
}

export function SignMembershipFormsFromJSONTyped(json: any, ignoreDiscriminator: boolean): SignMembershipForms {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'societyFormSubRoleId': json['society_form_sub_role_id'],
        'dateCreated': !exists(json, 'date_created') ? undefined : (new Date(json['date_created'])),
        'dateUpdated': !exists(json, 'date_updated') ? undefined : (new Date(json['date_updated'])),
        'complete': !exists(json, 'complete') ? undefined : json['complete'],
        'dateCompleted': !exists(json, 'date_completed') ? undefined : (json['date_completed'] === null ? null : new Date(json['date_completed'])),
        'pandadocsSessionId': !exists(json, 'pandadocs_session_id') ? undefined : json['pandadocs_session_id'],
        'pandadocsDocumentId': !exists(json, 'pandadocs_document_id') ? undefined : json['pandadocs_document_id'],
        'pandadocsDocumentS3Key': !exists(json, 'pandadocs_document_s3_key') ? undefined : json['pandadocs_document_s3_key'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'user': json['user'],
        'societyForm': json['society_form'],
    };
}

export function SignMembershipFormsToJSON(value?: SignMembershipForms | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'society_form_sub_role_id': value.societyFormSubRoleId,
        'complete': value.complete,
        'date_completed': value.dateCompleted === undefined ? undefined : (value.dateCompleted === null ? null : value.dateCompleted.toISOString()),
        'pandadocs_session_id': value.pandadocsSessionId,
        'pandadocs_document_id': value.pandadocsDocumentId,
        'pandadocs_document_s3_key': value.pandadocsDocumentS3Key,
        'status': value.status,
        'user': value.user,
        'society_form': value.societyForm,
    };
}

