import React, { useState } from 'react';
import AccountSettings from './AccountSettings';
import CompanyInfo from './CompanyInfo';
import { useNavigate } from 'react-router-dom';
/* New components */
import PageLayout from '../../PageLayout';
import Button from 'shared_components/components/Button';
import WContainer from 'shared_components/components/WContainer/WContainer';
import clsx from 'clsx';
function Settings() {
  const [type, setType] = useState<
    'my-account' | 'company-information' | 'notification-settings'
  >('my-account');
  let SelectedComponent = AccountSettings;

  if (type === 'company-information') {
    SelectedComponent = CompanyInfo;
  }
  let navigate = useNavigate();

  return (
    <PageLayout>
      <div className="tw-flex">
        <h2>Settings</h2>
      </div>
      <div className="tw-flex tw-space-x-6">
        {/* Sidebar */}
        <WContainer extraClasses="tw-w-[240px] tw-self-start tw-text-[16px] tw-line-height-20px tw-font-light">
          <div
            className={clsx('tw-mb-4', 'tw-cursor-pointer', {
              'tw-text-surfaceLime tw-font-medium': type === 'my-account',
              'tw-font-normal': type !== 'my-account',
            })}
          >
            <span onClick={() => setType('my-account')}>My Account</span>
          </div>

          <div
            className={clsx('tw-mb-4 tw-cursor-pointer', {
              'tw-text-surfaceLime tw-font-medium':
                type === 'company-information',
              'tw-font-normal': type !== 'company-information',
            })}
          >
            <span onClick={() => setType('company-information')}>
              Company Information
            </span>
          </div>
          <div className={clsx('tw-cursor-pointer tw-font-normal')}>
            <span onClick={() => navigate('/society-config')}>
              Society Configuration
            </span>
          </div>
        </WContainer>

        {/* Main Content */}
        {type === 'my-account' && (
          <div>
            <AccountSettings />
          </div>
        )}
        {/* Company info doesn't have a WContainer because the form is changing the parent container during editing */}
        {type === 'company-information' && <CompanyInfo />}
        {type === 'notification-settings' && (
          <WContainer extraClasses="tw-w-3/7 tw-min-w-[600px]">
            <div className="tw-bg-surfaceBlack90 tw-rounded-lg tw-p-2 tw-w-full">
              <div className="tw-bg-surfaceBlack90 tw-rounded-lg tw-text-white">
                <h4 className="tw-text-white tw-text-2xl tw-font-bold tw-mb-4">
                  Notification settings
                </h4>
              </div>
            </div>
          </WContainer>
        )}
      </div>
    </PageLayout>
  );
}
export default Settings;
