import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import WModal from 'shared_components/components/WModal';
import { useApi } from 'shared_components/context/providers';
import { useWError } from 'shared_components/components/WError/WErrorProvider';
import ThankyouBlock from 'shared_components/components/ThankYouBlock/ThankyouBlock';
import { EOIForm } from 'shared_components/components/EOIForm/EOIForm';
import { WError } from 'shared_components/components/WError/WError';

interface AddNewUserModalProps {
  isOpen: boolean;
  onClose: () => void;
  fetchData: () => void;
}

const AddNewUserModal = ({
  isOpen,
  onClose,
  fetchData,
}: AddNewUserModalProps) => {
  const { adminApi: api } = useApi();
  const [successful, setSuccessful] = useState<boolean>(false);
  const navigate = useNavigate();
  const emptyState = {
    email: '',
    firstName: '',
    middleName: '',
    lastName: '',
    aliases: [],
    pplPerformerName: '',
    pplPerformerId: '',
  };
  const [formData, setFormData] = useState(emptyState);

  const { throwError } = useWError();

  const handleSubmit = async () => {
    return api
      .createAdminInterestRegistration({
        adminInterestRegistration: formData,
      })
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch(async (data) => {
        const errors = await data.response.json();

        // Format server-side validation errors for setting in form context
        const newErrors = { ...errors };
        if (errors.aliases) {
          Object.entries(errors.aliases as { [index: string]: string[] }).map(
            ([i, messages]) => {
              newErrors[`aliases.${i}`] = messages[0];
            }
          );
          delete newErrors.aliases;
        }

        onClose();
        throwError(new WError('Please try again later'));
        return Promise.reject(newErrors);
      });
  };

  const onSuccess = () => {
    fetchData();
    setSuccessful(true);
  };

  const closeModal = () => {
    onClose();
    setSuccessful(false);
  };

  if (successful) {
    return (
      <WModal title="" isOpen={isOpen} onClose={closeModal}>
        <ThankyouBlock
          title="New User Added"
          description="You can now run a discography for this user from your dashboard"
          cta={{
            ctaText: 'Go To Dashboard',
            onClick: () => {
              navigate('/dashboard');
            },
          }}
        />
      </WModal>
    );
  }

  return (
    <WModal
      title="Add New User"
      isOpen={isOpen}
      onClose={closeModal}
      modalClasses="tw-max-h-[100vh] tw-overflow-y-scroll"
    >
      <EOIForm
        handleSubmit={handleSubmit}
        onSuccess={onSuccess}
        formData={formData}
        setFormData={setFormData}
      />
    </WModal>
  );
};

export default AddNewUserModal;
