import React, { useMemo, useEffect, useState } from 'react';

interface TableColumn {
  title: string;
  width?: string;
  dataKey: string;
  sort?: boolean;
}

interface TableProps {
  columns: TableColumn[];
  data: Record<string, any>[];
  columnStyle?: React.CSSProperties;
  rowStyle?: React.CSSProperties[];
  rawData?: Record<string, any>[];
}

export const WTable: React.FC<TableProps> = ({
  columns,
  data,
  columnStyle = {},
  rowStyle = [],
  rawData = [],
}) => {
  const [sortConfig, setSortConfig] = useState<{
    key: TableColumn;
    direction: 'ascending' | 'descending';
  } | null>(null);

  // Function to handle sorting
  const handleSort = (columnKey: TableColumn) => {
    if (
      !sortConfig ||
      sortConfig.key !== columnKey ||
      sortConfig.direction === 'descending'
    ) {
      setSortConfig({ key: columnKey, direction: 'ascending' });
    } else {
      setSortConfig({ key: columnKey, direction: 'descending' });
    }
  };

  // We memoize the sorted data so that we don't have to sort it on every render

  const sortedData = useMemo(() => {
    if (!sortConfig) return data;

    // For easily pairing up raw data with JSX data, we create a common map
    const dataMap = rawData.map((rawItem, index) => ({
      rawItem,
      jsxItem: data[index],
    }));

    dataMap.sort((a, b) => {
      const valueA = a.rawItem[sortConfig.key.dataKey];
      const valueB = b.rawItem[sortConfig.key.dataKey];

      // compareValues is a utility function that does valueA - valueB for all data types
      const comparisonResult = compareValues(valueA, valueB);
      return sortConfig.direction === 'ascending'
        ? comparisonResult
        : -comparisonResult;
    });

    return dataMap.map((entry) => entry.jsxItem);
  }, [data, rawData, sortConfig]);

  const gridTemplateColumns = columns
    .map((col) => col.width || '1fr')
    .join(' ');

  const privateRowStyle = { borderBottom: '1px solid #AAB2B9' };
  const privateColumnStyle = {
    fontWeight: 'bold',
    borderBottom: '1px solid #AAB2B9',
  };

  if (Object.keys(columnStyle).length === 0) {
    columnStyle = privateColumnStyle;
  }

  if (rowStyle.length === 0) {
    rowStyle = sortedData.map(() => privateRowStyle);
  }

  const allColumnsEmpty = columns.every((col) => col.title === '');
  const paddingClass = allColumnsEmpty || columns.length === 0 ? '' : 'tw-p-4';

  return (
    <div className="tw-bg-surfaceBlack80 IS_W_TABLE tw-w-full">
      <div className="tw-bg-surfaceBlack90 tw-p-2 tw-w-full">
        <div
          className="tw-grid tw-gap-2 tw-w-full"
          style={{ gridTemplateColumns, ...columnStyle }}
        >
          {columns.map((column, idx) => (
            <div
              key={idx}
              className={`${paddingClass} tw-pl-0 tw-flex tw-items-center`}
            >
              <div
                className="tw-flex tw-capitalize"
                role="button"
                tabIndex={0}
                onClick={() => {
                  if (column.sort) {
                    handleSort(column);
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleSort(column);
                  }
                }}
              >
                {column.title}
                {column.sort ? (
                  <div className="tw-px-2 tw-text-textSeal">
                    <i className="wi wi-sm wi-sort"></i>
                  </div>
                ) : null}
              </div>
            </div>
          ))}
        </div>

        {sortedData.map((row, rowIndex) => (
          <div
            key={rowIndex}
            className="tw-grid tw-gap-2 tw-w-full"
            style={{ gridTemplateColumns, ...rowStyle[rowIndex] }}
          >
            {columns.map((column, colIndex) => (
              <div
                key={colIndex}
                className={`tw-p-4 tw-pl-0 tw-flex tw-items-center tw-text-sm tw-leading-4`}
              >
                {row[column.dataKey]}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default WTable;

// Utility function to compare different data types
const compareValues = (a: any, b: any) => {
  if (a === null || a === undefined) return -1;
  if (b === null || b === undefined) return 1;

  if (!isNaN(Date.parse(a)) && !isNaN(Date.parse(b))) {
    // Compare as dates
    const dateA = new Date(a);
    const dateB = new Date(b);
    return dateA.getTime() - dateB.getTime();
  } else if (!isNaN(+a) && !isNaN(+b)) {
    // Compare as numbers
    return +a - +b; // +a converts a to a number
  } else {
    // Compare as strings
    return a.toString().localeCompare(b.toString());
  }
};
