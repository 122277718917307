import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useClientId } from 'shared_components/context/client';
import { useApi } from 'shared_components/context';
// import SocietyDiscography from "./Components/SocietyDiscography";
import ArtistDiscography from './Components/ArtistDiscography';
import PageLayout from './PageLayout';
import { SocietiesArtistsListBox } from 'shared_components/components/SocietiesArtists/SocietiesArtistsListBox';
import { SocietiesList } from 'shared_components/components/SocietiesArtists/SocietiesList';
import { ArtistList } from 'shared_components/components/SocietiesArtists/ArtistList';
import { AdminClientList } from 'shared_components/generated/admin';

function Discography() {
  const [isArtist, setIsArtist] = useState(true);
  const { clientId, setClientId } = useClientId();
  const [clientList, setClientList] = useState<[]>([]);
  const { adminApi: api } = useApi();
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    api
      .listUsers()
      .then((clients: AdminClientList[]) => {
        setClientList(
          // set image to empty string pending backend implementation
          clients.map((client) => {
            return { ...client, image: '' };
          })
        );
        if (clients.length > 0) {
          const firstId = clients[0].userId;
          setClientId(firstId as string);
        }
      })
      .catch((error) => {});
  };

  return (
    <PageLayout>
      <div className="tw-flex">
        <h2>Discography</h2>
      </div>
      <div className="tw-full tw-flex tw-space-x-6 tw-justify-between">
        <SocietiesArtistsListBox
          societiesComponent={
            <SocietiesList
              societies={[]}
              societySelected={undefined}
              isEditingSociety={false}
              societyClickHandler={() => {}}
            />
          }
          artistsComponent={<ArtistList artists={clientList} />}
        ></SocietiesArtistsListBox>

        <ArtistDiscography />
      </div>
    </PageLayout>
  );
}

export default Discography;
