/* tslint:disable */
/* eslint-disable */
/**
 * Westbury Music API Admin
 * API for Westbury Music admin users
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdminSocietySocietyFormsInner } from './AdminSocietySocietyFormsInner';
import {
    AdminSocietySocietyFormsInnerFromJSON,
    AdminSocietySocietyFormsInnerFromJSONTyped,
    AdminSocietySocietyFormsInnerToJSON,
} from './AdminSocietySocietyFormsInner';
import type { CompanyCompanyInformation } from './CompanyCompanyInformation';
import {
    CompanyCompanyInformationFromJSON,
    CompanyCompanyInformationFromJSONTyped,
    CompanyCompanyInformationToJSON,
} from './CompanyCompanyInformation';
import type { CompanyRoleFormsInner } from './CompanyRoleFormsInner';
import {
    CompanyRoleFormsInnerFromJSON,
    CompanyRoleFormsInnerFromJSONTyped,
    CompanyRoleFormsInnerToJSON,
} from './CompanyRoleFormsInner';

/**
 * 
 * @export
 * @interface Company
 */
export interface Company {
    /**
     * 
     * @type {CompanyCompanyInformation}
     * @memberof Company
     */
    companyInformation: CompanyCompanyInformation;
    /**
     * 
     * @type {Array<CompanyRoleFormsInner>}
     * @memberof Company
     */
    roleForms: Array<CompanyRoleFormsInner>;
    /**
     * 
     * @type {Array<AdminSocietySocietyFormsInner>}
     * @memberof Company
     */
    societyForms?: Array<AdminSocietySocietyFormsInner>;
}

/**
 * Check if a given object implements the Company interface.
 */
export function instanceOfCompany(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "companyInformation" in value;
    isInstance = isInstance && "roleForms" in value;

    return isInstance;
}

export function CompanyFromJSON(json: any): Company {
    return CompanyFromJSONTyped(json, false);
}

export function CompanyFromJSONTyped(json: any, ignoreDiscriminator: boolean): Company {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companyInformation': CompanyCompanyInformationFromJSON(json['company_information']),
        'roleForms': ((json['role_forms'] as Array<any>).map(CompanyRoleFormsInnerFromJSON)),
        'societyForms': !exists(json, 'society_forms') ? undefined : ((json['society_forms'] as Array<any>).map(AdminSocietySocietyFormsInnerFromJSON)),
    };
}

export function CompanyToJSON(value?: Company | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'company_information': CompanyCompanyInformationToJSON(value.companyInformation),
        'role_forms': ((value.roleForms as Array<any>).map(CompanyRoleFormsInnerToJSON)),
        'society_forms': value.societyForms === undefined ? undefined : ((value.societyForms as Array<any>).map(AdminSocietySocietyFormsInnerToJSON)),
    };
}

