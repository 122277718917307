/* tslint:disable */
/* eslint-disable */
/**
 * Westbury Music API Admin
 * API for Westbury Music admin users
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AdminPersonalInformation
 */
export interface AdminPersonalInformation {
    /**
     * 
     * @type {string}
     * @memberof AdminPersonalInformation
     */
    readonly user?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminPersonalInformation
     */
    readonly url?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminPersonalInformation
     */
    oldPassword?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminPersonalInformation
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminPersonalInformation
     */
    role?: AdminPersonalInformationRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof AdminPersonalInformation
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminPersonalInformation
     */
    gender?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminPersonalInformation
     */
    cityOfBirth?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminPersonalInformation
     */
    countryOfBirth?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminPersonalInformation
     */
    readonly fullName?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminPersonalInformation
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminPersonalInformation
     */
    middleName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminPersonalInformation
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminPersonalInformation
     */
    department?: AdminPersonalInformationDepartmentEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminPersonalInformation
     */
    readonly departmentChoices?: Array<string>;
    /**
     * 
     * @type {Blob}
     * @memberof AdminPersonalInformation
     */
    userPhoto?: Blob;
    /**
     * 
     * @type {string}
     * @memberof AdminPersonalInformation
     */
    readonly userPhotoUrl?: string;
    /**
     * 
     * @type {Date}
     * @memberof AdminPersonalInformation
     */
    dateOfBirth?: Date | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminPersonalInformation
     */
    aliases?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AdminPersonalInformation
     */
    nationality?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminPersonalInformation
     */
    taxationCountry?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminPersonalInformation
     */
    vatNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminPersonalInformation
     */
    taxIdType?: AdminPersonalInformationTaxIdTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AdminPersonalInformation
     */
    taxIdNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminPersonalInformation
     */
    emailAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminPersonalInformation
     */
    streetAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminPersonalInformation
     */
    country?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminPersonalInformation
     */
    phoneNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminPersonalInformation
     */
    county?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminPersonalInformation
     */
    postcode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminPersonalInformation
     */
    readonly westburyId?: string;
}


/**
 * @export
 */
export const AdminPersonalInformationRoleEnum = {
    PerformerProducer: 'Performer/Producer'
} as const;
export type AdminPersonalInformationRoleEnum = typeof AdminPersonalInformationRoleEnum[keyof typeof AdminPersonalInformationRoleEnum];

/**
 * @export
 */
export const AdminPersonalInformationDepartmentEnum = {
    Membership: 'Membership',
    Discography: 'Discography',
    Distribution: 'Distribution'
} as const;
export type AdminPersonalInformationDepartmentEnum = typeof AdminPersonalInformationDepartmentEnum[keyof typeof AdminPersonalInformationDepartmentEnum];

/**
 * @export
 */
export const AdminPersonalInformationTaxIdTypeEnum = {
    TinTaxpayerIdentificationNumber: 'TIN - Taxpayer Identification Number',
    ItinIndividualTaxpayerIdentificationNumber: 'ITIN - Individual Taxpayer Identification Number',
    FtinForeignTaxpayerIdentificationNumber: 'FTIN - Foreign Taxpayer Identification Number',
    PibPoreskiIdentifikacioniBroj: 'PIB - Poreski Identifikacioni Broj',
    PpsPersonalPublicService: 'PPS - Personal Public Service',
    NipNumerIdentyfikacjiPodatkowej: 'NIP - Numer Identyfikacji Podatkowej',
    FederalTaxId: 'Federal Tax ID',
    DepartmentOfStateId: 'Department of State ID',
    NationalInsuranceNumber: 'National Insurance Number',
    SocialSecurityNumber: 'Social Security Number'
} as const;
export type AdminPersonalInformationTaxIdTypeEnum = typeof AdminPersonalInformationTaxIdTypeEnum[keyof typeof AdminPersonalInformationTaxIdTypeEnum];


/**
 * Check if a given object implements the AdminPersonalInformation interface.
 */
export function instanceOfAdminPersonalInformation(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AdminPersonalInformationFromJSON(json: any): AdminPersonalInformation {
    return AdminPersonalInformationFromJSONTyped(json, false);
}

export function AdminPersonalInformationFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdminPersonalInformation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'user': !exists(json, 'user') ? undefined : json['user'],
        'url': !exists(json, 'url') ? undefined : json['url'],
        'oldPassword': !exists(json, 'old_password') ? undefined : json['old_password'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'role': !exists(json, 'role') ? undefined : json['role'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'gender': !exists(json, 'gender') ? undefined : json['gender'],
        'cityOfBirth': !exists(json, 'city_of_birth') ? undefined : json['city_of_birth'],
        'countryOfBirth': !exists(json, 'country_of_birth') ? undefined : json['country_of_birth'],
        'fullName': !exists(json, 'full_name') ? undefined : json['full_name'],
        'firstName': !exists(json, 'first_name') ? undefined : json['first_name'],
        'middleName': !exists(json, 'middle_name') ? undefined : json['middle_name'],
        'lastName': !exists(json, 'last_name') ? undefined : json['last_name'],
        'department': !exists(json, 'department') ? undefined : json['department'],
        'departmentChoices': !exists(json, 'department_choices') ? undefined : json['department_choices'],
        'userPhoto': !exists(json, 'user_photo') ? undefined : json['user_photo'],
        'userPhotoUrl': !exists(json, 'user_photo_url') ? undefined : json['user_photo_url'],
        'dateOfBirth': !exists(json, 'date_of_birth') ? undefined : (json['date_of_birth'] === null ? null : new Date(json['date_of_birth'])),
        'aliases': !exists(json, 'aliases') ? undefined : json['aliases'],
        'nationality': !exists(json, 'nationality') ? undefined : json['nationality'],
        'taxationCountry': !exists(json, 'taxation_country') ? undefined : json['taxation_country'],
        'vatNumber': !exists(json, 'vat_number') ? undefined : json['vat_number'],
        'taxIdType': !exists(json, 'tax_id_type') ? undefined : json['tax_id_type'],
        'taxIdNumber': !exists(json, 'tax_id_number') ? undefined : json['tax_id_number'],
        'emailAddress': !exists(json, 'email_address') ? undefined : json['email_address'],
        'streetAddress': !exists(json, 'street_address') ? undefined : json['street_address'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'phoneNumber': !exists(json, 'phone_number') ? undefined : json['phone_number'],
        'county': !exists(json, 'county') ? undefined : json['county'],
        'postcode': !exists(json, 'postcode') ? undefined : json['postcode'],
        'westburyId': !exists(json, 'westbury_id') ? undefined : json['westbury_id'],
    };
}

export function AdminPersonalInformationToJSON(value?: AdminPersonalInformation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'old_password': value.oldPassword,
        'password': value.password,
        'role': value.role,
        'title': value.title,
        'gender': value.gender,
        'city_of_birth': value.cityOfBirth,
        'country_of_birth': value.countryOfBirth,
        'first_name': value.firstName,
        'middle_name': value.middleName,
        'last_name': value.lastName,
        'department': value.department,
        'user_photo': value.userPhoto,
        'date_of_birth': value.dateOfBirth === undefined ? undefined : (value.dateOfBirth === null ? null : value.dateOfBirth.toISOString().substr(0,10)),
        'aliases': value.aliases,
        'nationality': value.nationality,
        'taxation_country': value.taxationCountry,
        'vat_number': value.vatNumber,
        'tax_id_type': value.taxIdType,
        'tax_id_number': value.taxIdNumber,
        'email_address': value.emailAddress,
        'street_address': value.streetAddress,
        'country': value.country,
        'phone_number': value.phoneNumber,
        'county': value.county,
        'postcode': value.postcode,
    };
}

