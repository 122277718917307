/* tslint:disable */
/* eslint-disable */
/**
 * Westbury Music API
 * API for Westbury Music
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PreregisteredSocietyPost
 */
export interface PreregisteredSocietyPost {
    /**
     * 
     * @type {Blob}
     * @memberof PreregisteredSocietyPost
     */
    file: Blob;
    /**
     * 
     * @type {string}
     * @memberof PreregisteredSocietyPost
     */
    society: string;
}

/**
 * Check if a given object implements the PreregisteredSocietyPost interface.
 */
export function instanceOfPreregisteredSocietyPost(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "file" in value;
    isInstance = isInstance && "society" in value;

    return isInstance;
}

export function PreregisteredSocietyPostFromJSON(json: any): PreregisteredSocietyPost {
    return PreregisteredSocietyPostFromJSONTyped(json, false);
}

export function PreregisteredSocietyPostFromJSONTyped(json: any, ignoreDiscriminator: boolean): PreregisteredSocietyPost {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'file': json['file'],
        'society': json['society'],
    };
}

export function PreregisteredSocietyPostToJSON(value?: PreregisteredSocietyPost | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'file': value.file,
        'society': value.society,
    };
}

