import React, { useState, Component, useEffect } from "react";
import {WTable} from '../../../../../../shared_components/WTable/WTable'

function ArtistActivityLog() {
  const artistActivityLog = [
    {
      user: "James Blunt",
      time: "19:42",
      activity: "Membership forms sent to client",
      date: "08/01/2022",
    },
    {
      user: "James Blunt",
      time: "17:13",
      activity: "Completed mandate, POA and questionnaire",
      date: "08/01/2022",
    },
    {
      user: "James Blunt",
      time: "08:06",
      activity: "Client verified",
      date: "08/01/2022",
    },
    {
      user: "James Blunt",
      time: "9:42",
      activity: "Client signed up",
      date: "08/01/2022",
    },
  ];

  return (
    <WTable
      columns= {
        [
          {
            title: "Date",
            width: "1fr",
            dataKey: "date"
          },
          {
            title: "Time",
            width: "1fr",
            dataKey: "time"
          },
          {
            title: "User",
            width: "1fr",
            dataKey: "user"
          },
          {
            title: "Activity",
            width: "2fr",
            dataKey: "activity"
          }
        ]
      }
      data={artistActivityLog}
      ></WTable>
  );
}

export default ArtistActivityLog;
