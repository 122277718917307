import WDot from 'shared_components/components/WDot';
import React from 'react';
import WButton from 'shared_components/components/WForms/WButton/WButton';
import PandadocPage from 'shared_components/components/PandadocPage/PandadocPage';

interface PassportAndFormsProps {
  passportFileLocation: string;
  onApprove: () => void;
  onDecline: () => void;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  pandadocsDocumentIds: pandadocForm[];
  setDocumentInViewId?: React.Dispatch<
    React.SetStateAction<string | null | undefined>
  >;
}

export interface pandadocForm {
  sessionId: string | null | undefined;
  documentName: string | null | undefined;
  documentId: string | null | undefined;
}

function PassportAndForms({
  passportFileLocation,
  onApprove,
  onDecline,
  isLoading,
  pandadocsDocumentIds,
  setDocumentInViewId = () => {},
}: PassportAndFormsProps) {
  const [selectedPageIndex, setSelectedPageIndex] = React.useState<number>(1);
  const passportFileLocationFileExtension = passportFileLocation
    .split('?')[0]
    .split('.')
    .pop();
  return (
    <div className="tw-flex tw-w-full tw-p-5 tw-mt-2 tw-space-x-4">
      {/* First Column */}
      <div className="tw-w-1/2 tw-p-4 tw-bg-surfaceBlack90 tw-rounded-2 tw-text-white tw-rounded-lg">
        <p className="tw-mb-2">Passport Image</p>
        {passportFileLocationFileExtension?.toLocaleLowerCase() == 'pdf' ? (
          <embed
            src={passportFileLocation}
            type="application/pdf"
            className="passportImg"
            height="100%"
            width="100%"
          ></embed>
        ) : (
          <img
            src={passportFileLocation}
            alt="passport"
            className="passportImg"
          />
        )}
      </div>

      {/* Second Column */}
      <div className="tw-w-1/2 tw-p-4 tw-bg-surfaceBlack90 tw-rounded-2 tw-text-white tw-rounded-lg">
        {isLoading && (
          <div
            className="tw-flex tw-items-center tw-justify-center"
            style={{ height: '100%' }}
          >
            <p className="tw-my-auto tw-animate-ping">⚪</p>
          </div>
        )}
        <div
          className={
            isLoading
              ? 'invisible'
              : 'tw-flex tw-flex-row tw-justify-center tw-mb-2'
          }
        >
          {pandadocsDocumentIds.map(({ documentId }, index: number) => (
            <WDot
              key={index}
              selected={selectedPageIndex === index + 1}
              onClick={() => {
                setSelectedPageIndex(index + 1);
                setDocumentInViewId(documentId);
              }}
            />
          ))}
        </div>
        {pandadocsDocumentIds.map(
          ({ sessionId: documentId }, index: number) => (
            <PandadocPage
              key={index}
              index={index}
              sessionId={documentId}
              selectedPageIndex={selectedPageIndex}
              isLoading={isLoading}
              iFrameStyle={{ width: '37vw', height: '70vh' }}
            />
          )
        )}
        {!isLoading && (
          <div className="tw-flex tw-justify-end tw-space-x-2 tw-mt-4">
            <WButton onClick={onApprove} variant="primary" label="Accept" />
            <WButton
              onClick={onDecline}
              variant="secondary"
              disabled={true}
              label="Decline"
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default PassportAndForms;
