/* tslint:disable */
/* eslint-disable */
/**
 * Westbury Music API Admin
 * API for Westbury Music admin users
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TermsOfAgreementSocietyCommissionRatesInner
 */
export interface TermsOfAgreementSocietyCommissionRatesInner {
    /**
     * 
     * @type {string}
     * @memberof TermsOfAgreementSocietyCommissionRatesInner
     */
    readonly id?: string;
    /**
     * 
     * @type {Date}
     * @memberof TermsOfAgreementSocietyCommissionRatesInner
     */
    readonly dateCreated?: Date;
    /**
     * 
     * @type {Date}
     * @memberof TermsOfAgreementSocietyCommissionRatesInner
     */
    readonly dateUpdated?: Date;
    /**
     * 
     * @type {string}
     * @memberof TermsOfAgreementSocietyCommissionRatesInner
     */
    commissionRate: string;
    /**
     * 
     * @type {string}
     * @memberof TermsOfAgreementSocietyCommissionRatesInner
     */
    termsOfAgreement?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TermsOfAgreementSocietyCommissionRatesInner
     */
    society?: string | null;
}

/**
 * Check if a given object implements the TermsOfAgreementSocietyCommissionRatesInner interface.
 */
export function instanceOfTermsOfAgreementSocietyCommissionRatesInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "commissionRate" in value;

    return isInstance;
}

export function TermsOfAgreementSocietyCommissionRatesInnerFromJSON(json: any): TermsOfAgreementSocietyCommissionRatesInner {
    return TermsOfAgreementSocietyCommissionRatesInnerFromJSONTyped(json, false);
}

export function TermsOfAgreementSocietyCommissionRatesInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): TermsOfAgreementSocietyCommissionRatesInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'dateCreated': !exists(json, 'date_created') ? undefined : (new Date(json['date_created'])),
        'dateUpdated': !exists(json, 'date_updated') ? undefined : (new Date(json['date_updated'])),
        'commissionRate': json['commission_rate'],
        'termsOfAgreement': !exists(json, 'terms_of_agreement') ? undefined : json['terms_of_agreement'],
        'society': !exists(json, 'society') ? undefined : json['society'],
    };
}

export function TermsOfAgreementSocietyCommissionRatesInnerToJSON(value?: TermsOfAgreementSocietyCommissionRatesInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'commission_rate': value.commissionRate,
        'terms_of_agreement': value.termsOfAgreement,
        'society': value.society,
    };
}

