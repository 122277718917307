import React from 'react';
import toast, { Toaster } from 'react-hot-toast';

const variantColor = {
  success: '#6EE038',
  error: '#FF2600',
};

type SendToastProps = {
  text: string;
  variant: 'success' | 'error';
  duration?: number;
  className?: string;
};

/**
 * Global method for dispatching toast notifications, works from anywhere.
 * @param text - The text to display in the toast notification.
 * @param variant - The variant of the toast notification. Can be 'success' or 'error'.
 * @param duration - The duration of the toast notification in milliseconds. Defaults to 5500.
 * @param className - Any additional classes to add to the toast notification.
 **/
export const sendToast = ({
  text,
  variant,
  duration = 5500,
  className = '',
}: SendToastProps): void => {
  const toastProps = {
    duration: duration,
  };

  const animClasses = '';

  const toastElement = (
    <div
      className={`${animClasses} tw-flex tw-cursor-not-allowed tw-p-2 tw-px-3 tw-text-black tw-bg-[${variantColor[variant]}] tw-rounded tw-mb-6 ${className}`}
    >
      <i
        className={`wi my-auto tw-mr-3 ${
          variant == 'success' ? 'wi-sm wi-check' : 'wi-xs wi-close'
        }`}
      />
      {text}
    </div>
  );

  toast.custom(toastElement, toastProps);
};

export const WToaster = () => {
  return <Toaster position="top-center" />;
};
