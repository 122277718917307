import React, { useState, useEffect } from 'react';
import WTable from 'shared_components/WTable/WTable';
import WContainer from 'shared_components/components/WContainer';
import { useApi } from 'shared_components/context';
import { MembershipStatus } from 'shared_components/generated/client';

type ClientMembershipsProps = {
  clientId?: string;
};

export const ClientMemberships = ({ clientId }: ClientMembershipsProps) => {
  const [societies, setSocieties] = useState<Array<MembershipStatus>>([]);
  const [collapse, setCollapse] = useState<number>(-1);
  const { clientApi: api } = useApi();

  const formTitle = collapse == -1 ? '' : 'Forms';

  const resetState = async () => {
    setSocieties([]);
    setCollapse(-1);
  };

  const fetchData = async () => {
    await resetState();
    api
      .listMembershipStatuses({ userId: clientId })
      .then((res) => setSocieties(res))
      .catch(() => {});
  };

  useEffect(() => {
    fetchData();
  }, [clientId]);

  if (!clientId) {
    return null;
  }

  if (societies.length == 0) {
    return <WContainer>No memberships have been configured so far.</WContainer>;
  }
  const columnStyle = {
    fontSize: '14px',
    fontWeight: 400,
    borderBottom: '1px solid #535D66',
  };
  const rowStyle = {
    fontSize: '14px',
    fontWeight: 300,
    borderBottom: '1px solid #535D66',
  };

  return (
    <WContainer extraClasses="tw-font-base">
      <p className="tw-text-2xl tw-ml-2">Membership Forms</p>
      <WTable
        columns={[
          {
            title: 'Society',
            width: '1fr',
            dataKey: 'readableName',
          },
          {
            title: 'Territory',
            width: '1fr',
            dataKey: 'country',
          },
          {
            title: formTitle,
            width: '2fr',
            dataKey: 'forms',
          },
        ]}
        data={societies.map((data, index: number) => {
          return {
            ...data,
            forms: (
              <FormRow
                index={index}
                forms={data.forms}
                collapse={collapse}
                setCollapse={setCollapse}
              />
            ),
          };
        })}
        columnStyle={columnStyle}
        rowStyle={societies.map(() => rowStyle)}
      ></WTable>
    </WContainer>
  );
};

type FormsItem = {
  link: string;
  name: string;
};

type FormRowProps = {
  index: number;
  forms: Array<FormsItem>;
  collapse: number;
  setCollapse: (value: number) => void;
};

const FormRow = ({ index, forms, collapse, setCollapse }: FormRowProps) => {
  return (
    <div
      className={'tw-grid tw-grid-cols-5 tw-w-full tw-justify-between tw-gap-4'}
    >
      <div
        className={`tw-flex tw-flex-col tw-gap-2 tw-pr-1 tw-col-span-4 ${
          collapse == index ? '' : 'tw-h-[0px] tw-overflow-hidden'
        }`}
      >
        {forms.length == 0
          ? ''
          : forms.map(({ link, name }, idx) => (
              <a
                className={
                  'tw-font-medium tw-underline-offset-2 tw-underline tw-truncate'
                }
                key={idx}
                href={link}
              >
                {/* "SOCIETY_FORMS/USER_ID/SOCIETY/DOCUMENT.PDF" */}
                {/* name is shaped as shown above */}
                {name && name.split('/', 4)[3]}
              </a>
            ))}
      </div>
      <button
        className={'tw-flex tw-text-right tw-gap-1 tw-w-max'}
        onClick={() => setCollapse(collapse == index ? -1 : index)}
      >
        <span className={'tw-font-medium tw-underline-offset-2 tw-underline'}>
          {collapse == index ? 'Hide Docs' : 'View Docs'}
        </span>
        <i
          className={`wi wi-xs wi-chevron-down ${
            collapse == index ? 'tw-rotate-180' : ''
          }`}
        />
      </button>
    </div>
  );
};
