/* tslint:disable */
/* eslint-disable */
/**
 * Westbury Music API Admin
 * API for Westbury Music admin users
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AdminCreateSociety
 */
export interface AdminCreateSociety {
    /**
     * 
     * @type {string}
     * @memberof AdminCreateSociety
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateSociety
     */
    readableName: string;
    /**
     * 
     * @type {Blob}
     * @memberof AdminCreateSociety
     */
    logo?: Blob;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateSociety
     */
    country?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateSociety
     */
    website?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateSociety
     */
    portal?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateSociety
     */
    phone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateSociety
     */
    address?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminCreateSociety
     */
    additionalTerritories?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateSociety
     */
    formEmail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateSociety
     */
    formPostalAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateSociety
     */
    formPortal?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateSociety
     */
    formReminderFrequency?: AdminCreateSocietyFormReminderFrequencyEnum;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateSociety
     */
    formStopReminderFrequency?: AdminCreateSocietyFormStopReminderFrequencyEnum;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateSociety
     */
    formReminderSendTo?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateSociety
     */
    taxForms?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateSociety
     */
    roleForms?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateSociety
     */
    contactInformation?: string;
}


/**
 * @export
 */
export const AdminCreateSocietyFormReminderFrequencyEnum = {
    Daily: 'Daily',
    Weekly: 'Weekly',
    Monthly: 'Monthly',
    Yearly: 'Yearly'
} as const;
export type AdminCreateSocietyFormReminderFrequencyEnum = typeof AdminCreateSocietyFormReminderFrequencyEnum[keyof typeof AdminCreateSocietyFormReminderFrequencyEnum];

/**
 * @export
 */
export const AdminCreateSocietyFormStopReminderFrequencyEnum = {
    Daily: 'Daily',
    Weekly: 'Weekly',
    Monthly: 'Monthly',
    Yearly: 'Yearly'
} as const;
export type AdminCreateSocietyFormStopReminderFrequencyEnum = typeof AdminCreateSocietyFormStopReminderFrequencyEnum[keyof typeof AdminCreateSocietyFormStopReminderFrequencyEnum];


/**
 * Check if a given object implements the AdminCreateSociety interface.
 */
export function instanceOfAdminCreateSociety(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "readableName" in value;

    return isInstance;
}

export function AdminCreateSocietyFromJSON(json: any): AdminCreateSociety {
    return AdminCreateSocietyFromJSONTyped(json, false);
}

export function AdminCreateSocietyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdminCreateSociety {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'readableName': json['readable_name'],
        'logo': !exists(json, 'logo') ? undefined : json['logo'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'website': !exists(json, 'website') ? undefined : json['website'],
        'portal': !exists(json, 'portal') ? undefined : json['portal'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'additionalTerritories': !exists(json, 'additional_territories') ? undefined : json['additional_territories'],
        'formEmail': !exists(json, 'form_email') ? undefined : json['form_email'],
        'formPostalAddress': !exists(json, 'form_postal_address') ? undefined : json['form_postal_address'],
        'formPortal': !exists(json, 'form_portal') ? undefined : json['form_portal'],
        'formReminderFrequency': !exists(json, 'form_reminder_frequency') ? undefined : json['form_reminder_frequency'],
        'formStopReminderFrequency': !exists(json, 'form_stop_reminder_frequency') ? undefined : json['form_stop_reminder_frequency'],
        'formReminderSendTo': !exists(json, 'form_reminder_send_to') ? undefined : json['form_reminder_send_to'],
        'taxForms': !exists(json, 'tax_forms') ? undefined : json['tax_forms'],
        'roleForms': !exists(json, 'role_forms') ? undefined : json['role_forms'],
        'contactInformation': !exists(json, 'contact_information') ? undefined : json['contact_information'],
    };
}

export function AdminCreateSocietyToJSON(value?: AdminCreateSociety | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'readable_name': value.readableName,
        'logo': value.logo,
        'country': value.country,
        'website': value.website,
        'portal': value.portal,
        'phone': value.phone,
        'address': value.address,
        'additional_territories': value.additionalTerritories,
        'form_email': value.formEmail,
        'form_postal_address': value.formPostalAddress,
        'form_portal': value.formPortal,
        'form_reminder_frequency': value.formReminderFrequency,
        'form_stop_reminder_frequency': value.formStopReminderFrequency,
        'form_reminder_send_to': value.formReminderSendTo,
        'tax_forms': value.taxForms,
        'role_forms': value.roleForms,
        'contact_information': value.contactInformation,
    };
}

