// WSelect.tsx
import React, { useEffect, useState } from 'react';
import { useFormContext } from '../WFormContext';
import { ChangeEvent } from 'react';
import clsx from 'clsx';
interface WSelectProps {
  name: string;
  label: string;
  layout?: 'vertical' | 'horizontal';
  options: { value: string; label: string }[];
  defaultValue: string | undefined;
  onBlur?: () => void;
  onChange: (value: ChangeEvent<any>) => void;
  row?: boolean;
  disabled?: boolean;
}

export const WSelect: React.FC<WSelectProps> = ({
  name,
  label,
  layout = 'vertical', // vertical = label on top of input, horizontal = label on left of input
  options,
  defaultValue,
  onBlur,
  onChange,
  row = false,
  disabled = false,
}) => {
  const { isEditing, isFormSubmitted, errors } = useFormContext(); // Fetch errors from context
  const [innerOptions, setInnerOptions] = useState<
    { value: string; label: string }[]
  >([
    {
      value: '',
      label: 'Please select',
    },
  ]);
  // Add please select as first option with value of ''
  useEffect(() => {
    // if it's emptyish, add the empty option

    if (
      defaultValue === undefined ||
      defaultValue === null ||
      defaultValue === ''
    ) {
      setInnerOptions([{ value: '', label: '' }, ...options]);
    } else {
      setInnerOptions(options);
    }
  }, [options]);

  return (
    <>
      <div
        className={clsx(
          'tw-flex tw-mb-4',
          {
            'tw-flex-col tw-flex tw-justify-between': layout === 'vertical',
          },
          {
            'tw-flex-row tw-w-full tw-justify-between tw-items-center':
              layout === 'horizontal',
          }
        )}
      >
        <label
          htmlFor={name}
          className={clsx(
            'tw-block tw-leading-4 tw-text-white tw-mb-2 tw-capitalize',
            {
              'tw-text-[14px]': !isEditing,
            },
            { 'tw-w-1/4': layout === 'horizontal' }
          )}
        >
          {label}
        </label>
        {isEditing && (
          <div
            className={clsx('tw-relative', {
              'tw-w-3/4': layout === 'horizontal',
            })}
          >
            <select
              id={name}
              name={name}
              onChange={(event) => onChange(event)}
              className={clsx(
                'tw-w-full',
                'tw-bg-formInputBackground',
                'tw-text-gray-400',
                'tw-rounded-lg',
                `${row ? 'tw-mw-9/10 ' : 'tw-w-full'}`,
                'tw-h-12',
                'tw-placeholder-textSeal',
                'tw-leading-5',
                'tw-p-3',
                'tw-mb-2',
                'tw-pr-3',
                'tw-appearance-none',
                'tw-border-2',
                { 'tw-border-formInputBackground': !errors[name] },
                'focus:tw-border-white',
                'focus:tw-text-white',
                'focus:tw-outline-none',
                { 'tw-border-red-500': isEditing && errors[name] },
                { 'tw-cursor-not-allowed': disabled }
              )}
              disabled={!isEditing || disabled}
              onBlur={() => {
                if (isFormSubmitted && typeof onBlur === 'function') {
                  onBlur();
                }
              }}
              value={defaultValue}
            >
              {innerOptions.map((option, index) => (
                <option key={index} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
            <span className="tw-absolute tw-right-0 tw-top-1 tw-h-10 tw-w-10 tw-flex tw-justify-center tw-items-center tw-pointer-events-none">
              <i className="wi wi-sm wi-chevron-down" />
            </span>
          </div>
        )}
        {!isEditing && (
          <span className={`tw-text-textDove tw-text-[14px]`}>
            {' '}
            {defaultValue ? defaultValue : '-'}{' '}
          </span>
        )}
        {errors[name] && (
          <div className="tw-text-red-500 tw-text-xs tw-mt-4">
            {errors[name]}
          </div>
        )}{' '}
      </div>
    </>
  );
};
