import React from 'react';
import {
  CompanyFormRole,
  CompanyFormRoleRoleEnum,
  CompanyFormRoleFormsInner,
  SocietyForm,
} from 'shared_components/generated/admin';
import WButton from 'shared_components/components/WForms/WButton/WButton';
import { WSelect } from 'shared_components/components/WForms/WSelect/WSelect';
import { useFormContext } from 'shared_components/components/WForms/WFormContext';

interface CompanyFormRolesProps {
  companyFormRoles: Array<CompanyFormRole>;
  setCompanyFormRoles: (companyFormRoles: Array<CompanyFormRole>) => void;
  societyForms: Array<SocietyForm>;
}

// @ts-ignore
interface CompanyFormRoleNew extends CompanyFormRole {
  role: CompanyFormRoleRoleEnum | undefined;
}
export const emptyFormRole: CompanyFormRoleNew = {
  role: undefined,
  forms: [
    {
      form: undefined,
    },
  ],
};

const roleSelectOptions = Object.values(CompanyFormRoleRoleEnum).map(
  (role: string) => {
    return { value: role, label: role };
  }
);

export const CompanyFormRoles = ({
  companyFormRoles,
  setCompanyFormRoles,
  societyForms,
}: CompanyFormRolesProps) => {
  const { isEditing } = useFormContext();

  const getSocietyFormOptions = (roleIndex: number, currentFormId: string) => {
    const selectedFormsForRole = new Set(
      companyFormRoles[roleIndex].forms?.map((form) => form?.form)
    );

    return societyForms
      .filter((societyForm) => {
        return (
          societyForm.id === currentFormId ||
          !selectedFormsForRole.has(societyForm.id)
        );
      })
      .map((societyForm) => {
        return {
          value: societyForm.readableName,
          label: societyForm.readableName,
        };
      });
  };

  const societyIdToName = (id: string): string => {
    const societyForm = societyForms.find(
      (societyForm) => societyForm.id == id
    );
    return societyForm ? societyForm.readableName : '';
  };

  const societyNameToId = (readableName: string): string => {
    const societyForm = societyForms.find(
      (societyForm) => societyForm.readableName == readableName
    );
    return societyForm && societyForm.id ? societyForm.id : '';
  };

  const addForm = () => {
    // debugger;
    const newFormRoles = [...companyFormRoles];
    newFormRoles.push(emptyFormRole);

    setCompanyFormRoles(newFormRoles);
  };

  const deleteForm = (index: number) => {
    const newFormRoles = [...companyFormRoles];
    newFormRoles.splice(index, 1);
    setCompanyFormRoles(newFormRoles);
  };

  const addSubForm = (index: number) => {
    // debugger;
    const newFormRoles = [...companyFormRoles];
    newFormRoles[index].forms?.push({ form: undefined });
    setCompanyFormRoles(newFormRoles);
  };

  const deleteSubForm = (index: number, fIndex: number) => {
    const newFormRoles = [...companyFormRoles];
    newFormRoles[index].forms?.splice(fIndex, 1);
    setCompanyFormRoles(newFormRoles);
  };

  const updateRole = (index: number, newRole: CompanyFormRoleRoleEnum) => {
    const newFormRoles = companyFormRoles.map((role, roleIndex) => {
      if (roleIndex === index) {
        return { ...role, role: newRole };
      }
      return role;
    });

    setCompanyFormRoles(newFormRoles);
  };

  const updateSubForm = (index: number, fIndex: number, newForms: {}) => {
    const newFormRoles = companyFormRoles.map((role, roleIndex) => {
      if (roleIndex === index) {
        const newFormsArray = role.forms?.map((form, formIndex) => {
          if (formIndex === fIndex) {
            return { ...form, ...newForms };
          }
          return form;
        });

        return { ...role, forms: newFormsArray };
      }
      return role;
    });

    setCompanyFormRoles(newFormRoles);
  };

  const containerClasses = isEditing
    ? `tw-bg-surfaceBlack90
      tw-p-6
      tw-mt-6
      tw-text-white
      tw-rounded-2xl
      tw-max-w-full`
    : ``;

  if (!isEditing) {
    return (
      <CompanyFormRolesSummary
        containerClasses={containerClasses}
        companyFormRoles={companyFormRoles}
        societyIdToName={societyIdToName}
      />
    );
  }

  return (
    <div className={containerClasses}>
      <p className={'tw-text-2xl tw-mb-6 tw-capitalize'}>
        configure company documents
      </p>
      {companyFormRoles.map(
        (companyFormRole: CompanyFormRole, index: number) => (
          <div className={'tw-flex tw-flex-col'} key={index}>
            <WSelect
              name="role"
              label="Role"
              options={roleSelectOptions.filter(
                (role) =>
                  !companyFormRoles
                    .map((companyFormRole) => companyFormRole.role)
                    .includes(role.value as CompanyFormRoleRoleEnum) ||
                  role.value === companyFormRole.role
              )}
              defaultValue={
                companyFormRole.role ? companyFormRole.role : undefined
              }
              onChange={(e) => {
                updateRole(index, e.target.value);
              }}
            />
            <p className={'tw-text-xs tw-uppercase tw-text-textDove'}>
              Add The Forms That Are Required For This Role
            </p>
            <div className={'tw-flex tw-flex-col tw-mt-4'}>
              {companyFormRole.forms?.map(
                (form: CompanyFormRoleFormsInner, fIndex: number) => (
                  <>
                    <WSelect
                      name="form"
                      label="Form"
                      options={getSocietyFormOptions(
                        index,
                        form.form as string
                      )}
                      defaultValue={
                        form.form ? societyIdToName(form.form) : undefined
                      }
                      onChange={(e) => {
                        updateSubForm(index, fIndex, {
                          ...form,
                          form: societyNameToId(e.target.value),
                        });
                      }}
                    />
                    {fIndex == 0 ? (
                      ''
                    ) : (
                      <WButton
                        label="Remove form"
                        icon="trash"
                        variant="link-secondary"
                        onClick={() => deleteSubForm(index, fIndex)}
                        extraClasses={
                          companyFormRole.forms?.length &&
                          fIndex === companyFormRole.forms?.length - 1
                            ? '!tw-justify-end tw-text-formOutlineErrorRed tw-mb-4'
                            : '!tw-justify-end tw-text-formOutlineErrorRed'
                        }
                      />
                    )}
                  </>
                )
              )}
            </div>
            <div
              className={
                index == 0
                  ? 'tw-flex tw-justify-end'
                  : 'tw-flex tw-justify-between'
              }
            >
              {index == 0 ? (
                ''
              ) : (
                <WButton
                  label="Remove role"
                  icon="trash"
                  variant="link-secondary"
                  onClick={() => deleteForm(index)}
                  extraClasses="tw-text-formOutlineErrorRed"
                />
              )}
              <WButton
                label="Add another form"
                icon="plus"
                variant="link-secondary"
                onClick={() => addSubForm(index)}
              />
            </div>
            {index != companyFormRoles.length - 1 ? <Ruler /> : ''}
          </div>
        )
      )}
      <div className={'tw-flex tw-flex-col tw-gap-4 tw-mt-4'}>
        <div className="tw-text-left">
          <WButton
            label="Add another role"
            icon="plus"
            variant="link"
            onClick={addForm}
          />
        </div>
      </div>
    </div>
  );
};

const Ruler = () => {
  return (
    <hr
      className={
        'tw-h-px tw-my-8 tw-bg-gray-200 tw-border-0 tw-dark:bg-gray-700'
      }
    />
  );
};

type CompanyFormRolesSummaryProps = {
  containerClasses?: string;
  companyFormRoles: Array<CompanyFormRole>;
  societyIdToName: (id: string) => string;
};

const CompanyFormRolesSummary = ({
  containerClasses,
  companyFormRoles,
  societyIdToName,
}: CompanyFormRolesSummaryProps) => {
  return (
    <div className={containerClasses}>
      <Ruler />
      <p className={'tw-text-2xl tw-mb-6 tw-capitalize'}>
        configure company documents
      </p>
      {companyFormRoles.map(
        (companyFormRole: CompanyFormRole, index: number) => (
          <div key={index}>
            <p>Role</p>
            <p className="tw-text-textDove my-2">{companyFormRole.role}</p>
            <p>{companyFormRole.forms?.length === 1 ? 'Form' : 'Forms'}</p>
            <ul className="tw-text-textDove my-2">
              {companyFormRole.forms?.map(
                (form: CompanyFormRoleFormsInner, fIndex: number) => (
                  <li key={fIndex}>
                    <p>{societyIdToName(form.form ?? '')}</p>
                  </li>
                )
              )}
            </ul>
            {index !== companyFormRoles.length - 1 ? <Ruler /> : ''}
          </div>
        )
      )}
    </div>
  );
};
