import React, { useEffect } from 'react';
import { WFormProvider } from 'shared_components/components/WForms/WFormProvider';
import { WInput } from 'shared_components/components/WForms/WInput/WInput';
import z from 'zod';
import { useFormContext } from 'shared_components/components/WForms/WFormContext';
import WButton from 'shared_components/components/WForms/WButton/WButton';
import { MergedClientTask } from '../DashboardDetails';

import { useApi } from 'shared_components/context';
import { AdminIdentityDocumentSubmissionStatusEnum } from 'shared_components/generated/admin';
import { useUserId } from 'shared_components/context/user';

import { Link, useNavigate } from 'react-router-dom';
import { useClientId } from 'shared_components/context/client';

interface Props {
  setPassportDateOfBirth: React.Dispatch<React.SetStateAction<string>>;
  setPassportExpiryDate: React.Dispatch<React.SetStateAction<string>>;
  setPassportNumber: React.Dispatch<React.SetStateAction<string>>;
  hideVerification: React.Dispatch<React.SetStateAction<boolean>>;
  declineMessage: () => void;
  acceptPassport: () => void;
  passportFileLocation: string;
  clientTasks: MergedClientTask[];
  taskId: string;
  continueButtonDisabled: boolean;
  passportDateOfBirth: string;
  passportExpiryDate: string;
  passportNumber: string;
  setVerifyPassport: React.Dispatch<React.SetStateAction<boolean>>;
}

function PassportVerification(props: Props) {
  const { adminApi: api } = useApi();
  const { clientId } = useClientId();

  const navigate = useNavigate();

  const checkUserStatus = async () => {
    const result = await api.retrieveLatestAdminIdentityDocumentSubmission({
      userId: clientId as string,
      documentType: 'Passport',
    });
    if (result.status === AdminIdentityDocumentSubmissionStatusEnum.Invalid) {
      props.declineMessage();
    } else if (
      result.status !==
      AdminIdentityDocumentSubmissionStatusEnum.PendingDecision
    ) {
      navigate(`/terms-of-agreement/${clientId}`, {
        state: { taskId: props.taskId },
      });
    }
  };

  useEffect(() => {
    checkUserStatus();
  }, [props.taskId]);

  const passportFileLocationFileExtension = props.passportFileLocation
    .split('?')[0]
    .split('.')
    .pop();
  const schema = z.object({
    dateOfBirth: z
      .string()
      .nonempty()
      .max(10)
      .min(10)
      .regex(/^\d{4}-\d{2}-\d{2}$/),
    passportNumber: z.string().nonempty().max(20),
    passportExpiryDate: z
      .string()
      .nonempty()
      .max(10)
      .min(10)
      .regex(/^\d{4}-\d{2}-\d{2}$/),
  });

  return (
    <>
      <h2 className="tw-mb-6">Passport Verification</h2>
      <WButton
        variant="link-secondary"
        icon="chevron-left"
        onClick={() => props.setVerifyPassport(false)}
        label="Back"
      />

      <div
        className="tw-flex tw-w-full tw-bg-surfaceBlack90 tw-p-5 tw-rounded-2 tw-text-white tw-rounded-lg"
        style={{ maxWidth: '1050px' }}
      >
        {/* First Column */}
        <div className="tw-w-1/2 tw-p-4 ">
          {passportFileLocationFileExtension == 'pdf' ? (
            <embed
              src={props.passportFileLocation}
              type="application/pdf"
              className="passportImg"
              height="100%"
              width="100%"
            ></embed>
          ) : (
            <img
              src={props.passportFileLocation}
              alt="passport"
              className="passportImg"
            />
          )}
        </div>

        {/* Second Column */}
        <div className="tw-w-1/2 tw-p-4">
          <WFormProvider
            schema={schema}
            handleSubmit={props.acceptPassport}
            formData={{
              dateOfBirth: props.passportDateOfBirth,
              passportNumber: props.passportNumber,
              passportExpiryDate: props.passportExpiryDate,
            }}
          >
            <WInput
              type="date"
              name="dateOfBirth"
              label="Date of Birth"
              value={props.passportDateOfBirth}
              onChange={(e) => {
                props.setPassportDateOfBirth(e.target.value);
              }}
              onBlur={() => {}}
              isFieldEditing={true}
            />
            <WInput
              type="text"
              name="passportNumber"
              label="Passport Number"
              value={props.passportNumber}
              onChange={(e) => {
                props.setPassportNumber(e.target.value);
              }}
              onBlur={() => {}}
              isFieldEditing={true}
            />
            <WInput
              type="date"
              name="passportExpiryDate"
              label="Passport Expiry Date"
              value={props.passportExpiryDate}
              onChange={(e) => {
                props.setPassportExpiryDate(e.target.value);
              }}
              onBlur={() => {}}
              isFieldEditing={true}
            />
            <div className="tw-flex tw-justify-start tw-space-x-2 tw-mt-4">
              <FormFooter />
              <WButton
                onClick={() => {
                  props.declineMessage();
                }}
                disabled={true}
                variant="secondary"
                label="Decline"
              />
            </div>
          </WFormProvider>
        </div>
      </div>
    </>
  );
}

function FormFooter() {
  // disable continue if any field is empty
  const { formData } = useFormContext();
  const { handleFormSubmit } = useFormContext();

  return (
    <WButton
      onClick={() => {
        handleFormSubmit(formData);
      }}
      disabled={
        formData.dateOfBirth == '' ||
        formData.passportNumber == '' ||
        formData.passportExpiryDate == ''
      }
      label="Continue"
      variant="primary"
    />
  );
}

export default PassportVerification;
