import React, { useState, useEffect } from "react";
import { Row, Col, Form, Button, FormControl, Modal } from "react-bootstrap";
import { format } from "date-fns";
import statementLogo from "shared_components/public/assets/statementLogo.png";
import {
  PaymentStatusEnum,
  StatementsJsonPaymentsInner,
} from "shared_components/generated/admin";
import { StatementsJsonMetadata } from "shared_components/generated/client";

import { WModal } from "shared_components/components/WModal/WModal";
interface Props {
  show: boolean;
  modalClose: () => void;
  statementPdf: string;
}

function XeroFileReady({
  show,
  modalClose,
  statementPdf,
}: Props) {
    const [isEditing, setIsEditing] = useState(false);
    const numToString = (num: number) => {
        const parseNumber = num / 100;
        const toLocale = parseNumber.toLocaleString(navigator.language, {
        minimumFractionDigits: 2,
        });
        return toLocale;
    };
    const [isArtistToggle, setArtistToggle] = useState(false);
    const [isAmountToggle, setAmountToggle] = useState(false);
    const [isAmountAltToggle, setAmountAltToggle] = useState(false);
    const [isStatusToggle, setStatusToggle] = useState(false);
    const [isPercentageToggle, setPercentageToggle] = useState(false);

    
    const [formValue, setFormValue] = useState({
        society_name:'LSG',
        distribution_date: '12/07/22',
        distribution_status: 'Status',
        currency: 'Euro',
        exchange_rate: '0.8865193',
        native_charges: '0.00',
        bank_charges: '10.00'

    });
    const inputChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) => {
    setFormValue({
        ...formValue,
        [event.target.name]: event.target.value,
    });
    };
    const associationDetails = [
        {
            name: "David Bowie",
            amount: "10,844",
            amount_alt: '90.20',
            status: 'held',
            percentage: "57%"
        },
        {
            name: "Emma Bowie",
            amount: "2,844",
            amount_alt: '90.20',
            status: 'cleared',
            percentage: "17%"
        },
        {
            name: "Geri Bowie",
            amount: "3,844",
            amount_alt: '90.20',
            status: 'held',
            percentage: "37%"
        },
        {
            name: "David Bowie",
            amount: "10,844",
            amount_alt: '90.20',
            status: 'held',
            percentage: "57%"
        },
        {
            name: "Emma Bowie",
            amount: "2,844",
            amount_alt: '90.20',
            status: 'cleared',
            percentage: "17%"
        },
        {
            name: "Geri Bowie",
            amount: "3,844",
            amount_alt: '90.20',
            status: 'held',
            percentage: "37%"
        },
        {
            name: "David Bowie",
            amount: "10,844",
            amount_alt: '90.20',
            status: 'held',
            percentage: "57%"
        },
        {
            name: "Emma Bowie",
            amount: "2,844",
            amount_alt: '90.20',
            status: 'cleared',
            percentage: "17%"
        },
        {
            name: "Geri Bowie",
            amount: "3,844",
            amount_alt: '90.20',
            status: 'held',
            percentage: "37%"
        },
        {
            name: "David Bowie",
            amount: "10,844",
            amount_alt: '90.20',
            status: 'held',
            percentage: "57%"
        },
        {
            name: "Emma Bowie",
            amount: "2,844",
            amount_alt: '90.20',
            status: 'cleared',
            percentage: "17%"
        },
        {
            name: "Geri Bowie",
            amount: "3,844",
            amount_alt: '90.20',
            status: 'held',
            percentage: "37%"
        }
    ]
    type StatusMap = {
        [STATUS in PaymentStatusEnum]: string;
    };
    
    const statusMap: StatusMap = {
    cleared: "Cleared",
    to_be_transferred: "Awaiting transfer",
    waiting_cheque_clear: "Awaiting cheque clearance",
    held: "Held",
    // "failed": "Failed",
    };
    return (
        <WModal isOpen={show} onClose={() => modalClose()} title="Xero File Ready">
                    <div className="AgreementModalData">
                        <div className="agreementHeader">
                            <div className="d-flex justify-content-end modalTopButtons">
                                {isEditing ? (
                                    <Button
                                        variant="primary"
                                        className="x-button px-3 py-2 buttonIconSave"
                                        onClick={(e) => {setIsEditing(false);}}
                                    >
                                        Save
                                    </Button>
                                ) : (
                                    <Button
                                        className="x-button sign-btn px-3 py-2"
                                        onClick={(e) => setIsEditing(true)}
                                    >
                                        Edit
                                    </Button>
                                )}
                                <a
                                    href='#'
                                    className="btn download-btn button-border ml-2"
                                    download
                                >
                                    Download
                                </a>
                            </div>
                        </div>
                        <div className="modalDocument">
                            <div className="statementDoc">
                                <Row>
                                    <Col lg={6}>
                                        <Form className="XeroFileForm pb-4">
                                            <div className="d-flex filedListing align-items-center">
                                                <div className="xerofieldLabel">
                                                    <span>Society Name</span>
                                                </div>
                                                <div className="fieldValue">
                                                    <FormControl
                                                        name="society_name"
                                                        className={` mt-0 fieldSmaller ${
                                                            isEditing ? "fieldWithBorder" : "readonlyField"
                                                        }`}
                                                        readOnly={isEditing ? false : true}
                                                        onChange={(e) =>
                                                            inputChange(e as React.ChangeEvent<HTMLInputElement>)
                                                        }
                                                        value={formValue.society_name ? formValue.society_name : ''}
                                                    />
                                                </div>
                                            </div>
                                            <div className="d-flex filedListing align-items-center">
                                                <div className="xerofieldLabel">
                                                    <span>Distribution date</span>
                                                </div>
                                                <div className="fieldValue">
                                                    <FormControl
                                                        name="distribution_date"
                                                        type="date"
                                                        className={` mt-0 fieldSmaller ${
                                                            isEditing ? "fieldWithBorder" : "readonlyField"
                                                        }`}
                                                        readOnly={isEditing ? false : true}
                                                        onChange={(e) =>
                                                            inputChange(e as React.ChangeEvent<HTMLInputElement>)
                                                        }
                                                        value={formValue.distribution_date ? formValue.distribution_date : ''}
                                                    />
                                                </div>
                                            </div>
                                            <div className="d-flex filedListing align-items-center">
                                                <div className="xerofieldLabel">
                                                    <span>Distribution status</span>
                                                </div>
                                                <div className="fieldValue">
                                                    <FormControl
                                                        name="distribution_status"
                                                        className={` mt-0 fieldSmaller ${
                                                            isEditing ? "fieldWithBorder" : "readonlyField"
                                                        }`}
                                                        readOnly={isEditing ? false : true}
                                                        onChange={(e) =>
                                                            inputChange(e as React.ChangeEvent<HTMLInputElement>)
                                                        }
                                                        value={formValue.distribution_status ? formValue.distribution_status : ''}
                                                    />
                                                </div>
                                            </div>
                                            <div className="d-flex filedListing align-items-center">
                                                <div className="xerofieldLabel">
                                                    <span>Currency</span>
                                                </div>
                                                <div className="fieldValue">
                                                    <FormControl
                                                        name="currency"
                                                        className={` mt-0 fieldSmaller ${
                                                            isEditing ? "fieldWithBorder" : "readonlyField"
                                                        }`}
                                                        readOnly={isEditing ? false : true}
                                                        onChange={(e) =>
                                                            inputChange(e as React.ChangeEvent<HTMLInputElement>)
                                                        }
                                                        value={formValue.currency ? formValue.currency : ''}
                                                    />
                                                </div>
                                            </div>
                                            <div className="d-flex filedListing align-items-center">
                                                <div className="xerofieldLabel">
                                                    <span>Exchange rate</span>
                                                </div>
                                                <div className="fieldValue">
                                                    <FormControl
                                                        name="exchange_rate"
                                                        className={` mt-0 fieldSmaller ${
                                                            isEditing ? "fieldWithBorder" : "readonlyField"
                                                        }`}
                                                        readOnly={isEditing ? false : true}
                                                        onChange={(e) =>
                                                            inputChange(e as React.ChangeEvent<HTMLInputElement>)
                                                        }
                                                        value={formValue.exchange_rate ? formValue.exchange_rate : ''}
                                                    />
                                                </div>
                                            </div>
                                            <div className="d-flex filedListing align-items-center">
                                                <div className="xerofieldLabel">
                                                    <span>Native charges </span>
                                                </div>
                                                <div className="fieldValue">
                                                    <FormControl
                                                        name="native_charges"
                                                        className={` mt-0 fieldSmaller ${
                                                            isEditing ? "fieldWithBorder" : "readonlyField"
                                                        }`}
                                                        readOnly={isEditing ? false : true}
                                                        onChange={(e) =>
                                                            inputChange(e as React.ChangeEvent<HTMLInputElement>)
                                                        }
                                                        value={formValue.native_charges ? formValue.native_charges : ''}
                                                    />
                                                </div>
                                            </div>
                                            <div className="d-flex filedListing align-items-center">
                                                <div className="xerofieldLabel">
                                                    <span>Bank charges</span>
                                                </div>
                                                <div className="fieldValue">
                                                    <FormControl
                                                        name="bank_charges"
                                                        className={` mt-0 fieldSmaller ${
                                                            isEditing ? "fieldWithBorder" : "readonlyField"
                                                        }`}
                                                        readOnly={isEditing ? false : true}
                                                        onChange={(e) =>
                                                            inputChange(e as React.ChangeEvent<HTMLInputElement>)
                                                        }
                                                        value={formValue.bank_charges ? formValue.bank_charges : ''}
                                                    />
                                                </div>
                                            </div>
                                        </Form>
                                    </Col>
                                    <Col xs={12} >
                                        <div className="XeroFileTable statementDetailTable">
                                            <div className="ArtistDistibutionAmountTable">
                                            
                                                <div className="listingTableWrap">
                                                    <div className="d-flex ardHeadingsRow ardRow justify-content-between">
                                                        <div className="ard-name ardHeading">
                                                            Artist
                                                            {isArtistToggle ? (
                                                                <button
                                                                    className="dropArrowButton arrowSort arrowSortUp"
                                                                    onClick={() => setArtistToggle(false)}
                                                                >
                                                                    <span className="sr-only">close</span>
                                                                </button>
                                                            ) : (
                                                                <button
                                                                    className="dropArrowButton arrowSort arrowSortDown"
                                                                    onClick={() => setArtistToggle(true)}
                                                                >
                                                                    <span className="sr-only">Open</span>
                                                                </button>
                                                            )}
                                                            <div
                                                            className={`ard-inner ${
                                                                isArtistToggle ? "" : "d-none"
                                                            }`}
                                                            >
                                                                <a href="javascript:void(0);">
                                                                    <span className="arrow-asc"></span>A to Z
                                                                </a>
                                                                <a href="javascript:void(0);">
                                                                    <span className="arrow-dsc"></span>Z to A
                                                                </a>
                                                            </div>
                                                        </div> 
                                                        <div className="percentage ardHeading d-flex">
                                                            % of distribution
                                                            {isPercentageToggle ? (
                                                                <button
                                                                    className="dropArrowButton arrowSort arrowSortUp"
                                                                    onClick={() =>
                                                                    setPercentageToggle(!isPercentageToggle)
                                                                    }
                                                                >
                                                                    <span className="sr-only">close</span>
                                                                </button>
                                                                ) : (
                                                                <button
                                                                    className="dropArrowButton arrowSort arrowSortDown"
                                                                    onClick={() =>
                                                                    setPercentageToggle(!isPercentageToggle)
                                                                    }
                                                                >
                                                                    <span className="sr-only">Open</span>
                                                                </button>
                                                            )}
                                                            <div
                                                            className={`ard-inner ${
                                                                isPercentageToggle ? "" : "d-none"
                                                            }`}
                                                            >
                                                                <a href="javascript:void(0);">
                                                                    <span className="arrow-asc"></span>A to Z
                                                                </a>
                                                                <a href="javascript:void(0);">
                                                                    <span className="arrow-dsc"></span>Z to A
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="amounts ardHeading d-flex">
                                                            Amount in €
                                                            {isAmountToggle ? (
                                                                <button
                                                                    className="dropArrowButton arrowSort arrowSortUp"
                                                                    onClick={() =>
                                                                    setAmountToggle(!isAmountToggle)
                                                                    }
                                                                >
                                                                    <span className="sr-only">close</span>
                                                                </button>
                                                                ) : (
                                                                <button
                                                                    className="dropArrowButton arrowSort arrowSortDown"
                                                                    onClick={() =>
                                                                    setAmountToggle(!isAmountToggle)
                                                                    }
                                                                >
                                                                    <span className="sr-only">Open</span>
                                                                </button>
                                                            )}
                                                            <div
                                                            className={`ard-inner ${
                                                                isAmountToggle ? "" : "d-none"
                                                            }`}
                                                            >
                                                                <a href="javascript:void(0);">
                                                                    <span className="arrow-asc"></span>A to Z
                                                                </a>
                                                                <a href="javascript:void(0);">
                                                                    <span className="arrow-dsc"></span>Z to A
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="amounts ardHeading d-flex">
                                                            Amount in £
                                                            {isAmountToggle ? (
                                                                <button
                                                                    className="dropArrowButton arrowSort arrowSortUp"
                                                                    onClick={() =>
                                                                    setAmountToggle(!isAmountToggle)
                                                                    }
                                                                >
                                                                    <span className="sr-only">close</span>
                                                                </button>
                                                                ) : (
                                                                <button
                                                                    className="dropArrowButton arrowSort arrowSortDown"
                                                                    onClick={() =>
                                                                    setAmountToggle(!isAmountToggle)
                                                                    }
                                                                >
                                                                    <span className="sr-only">Open</span>
                                                                </button>
                                                            )}
                                                            <div
                                                            className={`ard-inner ${
                                                                isAmountToggle ? "" : "d-none"
                                                            }`}
                                                            >
                                                                <a href="javascript:void(0);">
                                                                    <span className="arrow-asc"></span>A to Z
                                                                </a>
                                                                <a href="javascript:void(0);">
                                                                    <span className="arrow-dsc"></span>Z to A
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="status ardHeading d-flex">
                                                            Status
                                                            {isAmountToggle ? (
                                                                <button
                                                                    className="dropArrowButton arrowSort arrowSortUp"
                                                                    onClick={() =>
                                                                    setAmountToggle(!isAmountToggle)
                                                                    }
                                                                >
                                                                    <span className="sr-only">close</span>
                                                                </button>
                                                                ) : (
                                                                <button
                                                                    className="dropArrowButton arrowSort arrowSortDown"
                                                                    onClick={() =>
                                                                    setAmountToggle(!isAmountToggle)
                                                                    }
                                                                >
                                                                    <span className="sr-only">Open</span>
                                                                </button>
                                                            )}
                                                            <div
                                                            className={`ard-inner ${
                                                                isAmountToggle ? "" : "d-none"
                                                            }`}
                                                            >
                                                                <a href="javascript:void(0);">
                                                                    <span className="arrow-asc"></span>A to Z
                                                                </a>
                                                                <a href="javascript:void(0);">
                                                                    <span className="arrow-dsc"></span>Z to A
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {associationDetails.length > 0 ? (
                                                        <div className="ardRowWrap">
                                                            {associationDetails.map((artist, playIndex) => (
                                                                <div
                                                                    className="ardRowInner"
                                                                    key={playIndex.toString()}
                                                                >
                                                                    <div className="ardRow b-0 d-flex justify-content-between">
                                                                        <div className="ard-name">{artist.name}</div>                                
                                                                        <div className="percentage">
                                                                            {artist.percentage}
                                                                        </div>                                
                                                                        <div className="amounts">
                                                                            {artist.amount}
                                                                        </div>
                                                                        <div className="amounts">
                                                                            {artist.amount}
                                                                        </div>
                                                                        <div className={`${artist.status} status`}>
                                                                            {statusMap[artist.status]}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    ) : (
                                                    ""
                                                    )}
                                                </div>
                                                <div className="XeroListingSummary">
                                                    <div className="ardRow b-0 d-flex justify-content-between">
                                                        <div className="ard-name ardHeading"></div>
                                                        <div className="percentage ardHeading"></div>
                                                        <div className="amounts amountTotal d-flex">
                                                            <span className="totalLabel">Total: </span>
                                                            <span className="">10,087</span>
                                                        </div>  
                                                        <div className="amounts amountTotal d-flex">
                                                            <span className="totalLabel">Total: </span>
                                                            <span className="">8,943 </span>
                                                        </div>  
                                                        <div className="statusTotal">63.63% cleared</div>  

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                </div>
        </WModal>
    );
}

export default XeroFileReady;
