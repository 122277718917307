/* tslint:disable */
/* eslint-disable */
/**
 * Westbury Music API
 * API for Westbury Music
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SocietySocietyFormsInner
 */
export interface SocietySocietyFormsInner {
    /**
     * 
     * @type {string}
     * @memberof SocietySocietyFormsInner
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof SocietySocietyFormsInner
     */
    internalIdentifier: string;
    /**
     * 
     * @type {string}
     * @memberof SocietySocietyFormsInner
     */
    readableName: string;
    /**
     * JSON list of fields
     * @type {object}
     * @memberof SocietySocietyFormsInner
     */
    fieldsList?: object | null;
    /**
     * 
     * @type {object}
     * @memberof SocietySocietyFormsInner
     */
    readonly prepopulatedData?: object;
}

/**
 * Check if a given object implements the SocietySocietyFormsInner interface.
 */
export function instanceOfSocietySocietyFormsInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "internalIdentifier" in value;
    isInstance = isInstance && "readableName" in value;

    return isInstance;
}

export function SocietySocietyFormsInnerFromJSON(json: any): SocietySocietyFormsInner {
    return SocietySocietyFormsInnerFromJSONTyped(json, false);
}

export function SocietySocietyFormsInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): SocietySocietyFormsInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'internalIdentifier': json['internal_identifier'],
        'readableName': json['readable_name'],
        'fieldsList': !exists(json, 'fields_list') ? undefined : json['fields_list'],
        'prepopulatedData': !exists(json, 'prepopulated_data') ? undefined : json['prepopulated_data'],
    };
}

export function SocietySocietyFormsInnerToJSON(value?: SocietySocietyFormsInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'internal_identifier': value.internalIdentifier,
        'readable_name': value.readableName,
        'fields_list': value.fieldsList,
    };
}

