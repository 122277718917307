import React, { useState, useEffect } from 'react';
import { Form, FormControl } from 'react-bootstrap';
import moment from 'moment';
import { useApi } from 'shared_components/context';
import { useClientId } from 'shared_components/context/client';
import {
  AdminIdentityDocumentSubmission,
  AdminIdentityDocumentSubmissionStatusEnum,
} from 'shared_components/generated/admin';
import clsx from 'clsx';
import WTable from 'shared_components/WTable/WTable';
import WButton from 'shared_components/components/WForms/WButton/WButton';
import z from 'zod';
import { useWError } from 'shared_components/components/WError/WErrorProvider';
import { WError } from 'shared_components/components/WError/WError';
import { Constants } from 'shared_components/utils/Countries';
type StatusMap = {
  [STATUS in AdminIdentityDocumentSubmissionStatusEnum]: string;
};

const stStatus: StatusMap = {
  pending_decision: 'PENDING',
  invalid: 'INVALID',
  accepted: 'ACCEPTED',
};

function DocumentsUpdateForm() {
  const { adminApi: api } = useApi();
  const { throwError } = useWError();
  const { clientId } = useClientId();
  const [docFiles, setDocFiles] = useState<AdminIdentityDocumentSubmission[]>(
    []
  );
  const [isUploadIndex, setIsUploadIndex] = useState(-1);
  const [isEditingIndex, setIsEditingIndex] = useState(-1);

  const [fileUpdate, setFileUpdate] = useState<{
    file: File | null;
    filename: string;
    docId: string;
  }>({
    file: null,
    filename: '',
    docId: '',
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
    setIsEditingIndex(-1);
    setShowDropdownIndex(-1);
  }, [clientId]);

  const fetchData = () => {
    if (clientId === undefined) {
      return;
    }
    api
      .listAdminIdentityDocumentSubmissions({ userId: clientId })
      .then((docs) => {
        setDocFiles(docs);
      })
      .catch(() => {
        throwError(new WError('Failed to fetch documents'));
      });
  };

  const uploadFileHandle = (
    e: React.ChangeEvent<HTMLInputElement>,
    docId: string
  ) => {
    if (e.target.files === null) {
      setFileUpdate({
        file: null,
        filename: '',
        docId: '',
      });
      return;
    }

    const file = e.target.files[0];
    const schema = z
      .object({
        file: z.any(),
      })
      .refine((data) => {
        const res =
          data.file.size < Constants.MAX_DOCUMENT_SIZE &&
          [
            ...Constants.DOCUMENT_FILE_TYPES,
            ...Constants.IMAGE_FILE_TYPES,
          ].includes(data.file.type);

        return res;
      });
    const res = schema.safeParse({
      file,
    });
    if (!res.success) {
      alert(
        'File size should be less than 50MB and file type should be one of the following: ' +
          [
            ...Constants.DOCUMENT_FILE_TYPES,
            ...Constants.IMAGE_FILE_TYPES,
          ].join(', ')
      );
      return;
    }
    setFileUpdate({
      file,
      filename: file.name,
      docId,
    });
  };

  const saveFileHandle = async (documentId: string) => {
    if (clientId === undefined) {
      return;
    }
    if (fileUpdate?.docId !== documentId) {
      setIsUploadIndex(-1);
      return;
    }
    setLoading(true);
    await api
      .updateAdminIdentityDocumentSubmission({
        userId: clientId,
        documentId,
        file: fileUpdate.file as Blob,
        filename: fileUpdate.filename,
      })
      .then(() => {
        return fetchData();
      })
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        throwError(new WError('Failed to upload file'));
      });
    setFileUpdate({
      file: null,
      filename: '',
      docId: '',
    });
    setIsEditingIndex(-1);
    setIsUploadIndex(-1);
  };

  const inputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const keyName = e.currentTarget.name as
      | 'documentExpiryDate'
      | 'documentSignedDate';
    const newDocFiles = [...docFiles];
    newDocFiles[index][keyName] = e.target.value;
    setDocFiles(newDocFiles);
    console.log(docFiles);
  };

  const [showDropdownIndex, setShowDropdownIndex] = useState(-1);

  const toggleDropdown = (e: React.MouseEvent, index: number) => {
    if (showDropdownIndex === index) {
      setShowDropdownIndex(-1);
    } else {
      setShowDropdownIndex(index);
    }
  };

  const updateDates = () => {
    if (clientId === undefined) {
      return;
    }
    if (isEditingIndex >= 0) {
      setLoading(true);
      const doc = docFiles[isEditingIndex];
      api
        .updateAdminIdentityDocumentSubmission({
          userId: clientId,
          documentId: doc.id!,
          documentExpiryDate: doc.documentExpiryDate,
          documentSignedDate: doc.documentSignedDate,
          status: doc.status as AdminIdentityDocumentSubmissionStatusEnum,
        })
        .then(() => {
          setLoading(false);
        })
        .catch(() => {
          throwError(new WError('Failed to update dates'));
          setLoading(false);
        });
    }
    setIsEditingIndex(-1);
  };

  const getExtension = (path?: string): string => {
    if (!path) {
      return '';
    }
    const suffix = path.split('.').pop();
    if (suffix === undefined) {
      return '';
    }
    const extension = suffix.split('?').shift();
    if (extension === undefined) {
      return '';
    }
    return '.' + extension;
  };

  const inputClasses = clsx(
    'tw-bg-formInputBackground',
    'tw-text-gray-400',
    'tw-rounded-lg',
    'tw-w-full',
    'tw-h-10',
    'tw-placeholder-textSeal',
    'tw-appearance-none',
    'tw-leading-5',
    'tw-p-3',
    'tw-cursor-pointer',
    'tw-items-center',
    'tw-align-center',
    'tw-border-2',
    'tw-border-formInputBackground',
    'focus:tw-border-white',
    'focus:tw-text-white',
    'focus:tw-outline-none'
  );

  const updateDropdown = (
    e: React.MouseEvent,
    index: number,
    state: AdminIdentityDocumentSubmissionStatusEnum
  ) => {
    // Spread operator is used to create a new array
    const newDocFiles = [...docFiles];
    newDocFiles[index].status = state;
    setDocFiles(newDocFiles);
    setShowDropdownIndex(-1);
  };

  let docFilesData = docFiles.map((doc, index) => {
    return {
      documentType: doc.documentType!.replaceAll('_', ' '),
      status: (
        <div className="tw-relative">
          <span
            className={clsx(
              'tw-cursor-pointer',
              'tw-text-black',
              'tw-px-2',
              'tw-py-1',
              'tw-text-xs',
              'tw-font-semibold',
              { 'tw-bg-[#6EE038]': doc.status! === 'accepted' },
              {
                'tw-bg-[#F2C94C]': doc.status! === 'pending_decision',
              },
              { 'tw-bg-[#EB5757]': doc.status! === 'invalid' },
              'tw-rounded'
            )}
            onClick={(e) => {
              if (isEditingIndex === index) {
                toggleDropdown(e, index);
              }
            }}
          >
            {stStatus[doc.status!]}
          </span>

          <div
            className={`tw-absolute tw-z-50 ${
              showDropdownIndex === index ? 'tw-block' : 'tw-hidden'
            }`}
          >
            <ul className="tw-bg-surfaceBlack90 tw-p-2 tw-rounded tw-mt-2">
              {Object.entries(stStatus).map(([state, stateValue], i) => (
                <li
                  className={clsx(
                    'tw-cursor-pointer',
                    'tw-mt-2',
                    'tw-p-2',
                    'tw-text-black',
                    {
                      'tw-bg-[#6EE038]': stateValue === 'Accepted',
                    },
                    {
                      'tw-bg-[#F2C94C]': stateValue === 'Pending',
                    },
                    { 'tw-bg-[#EB5757]': stateValue === 'Invalid' },
                    'tw-rounded',
                    {
                      'tw-hidden': stStatus[doc.status!] === stateValue,
                      'tw-block': stStatus[doc.status!] !== stateValue,
                    }
                  )}
                  onClick={(e) =>
                    updateDropdown(
                      e,
                      index,
                      state as AdminIdentityDocumentSubmissionStatusEnum
                    )
                  }
                  key={`${i}`}
                >
                  {stateValue}
                </li>
              ))}
            </ul>
          </div>
        </div>
      ),
      file: (
        <div className="file">
          {isUploadIndex === index ? (
            <div className="fileUpload">
              <input
                hidden={true}
                type="file"
                name={`document_${doc.id}`}
                id={`document_${doc.id}`}
                onChange={(e) =>
                  uploadFileHandle(
                    e as React.ChangeEvent<HTMLInputElement>,
                    doc.id!
                  )
                }
              />
              {fileUpdate.docId === doc.id ? (
                <label
                  style={{ lineHeight: '16px' }}
                  htmlFor={`document_${doc.id}`}
                  className={inputClasses}
                >
                  {fileUpdate.filename}
                </label>
              ) : (
                <label
                  style={{ lineHeight: '16px' }}
                  htmlFor={`document_${doc.id}`}
                  className={inputClasses}
                >
                  {doc.file ? (
                    <>
                      {doc.filename ? (
                        <>{doc.filename}</>
                      ) : (
                        <>
                          {doc.documentType}
                          {getExtension(doc.fileUrl)}
                        </>
                      )}
                    </>
                  ) : (
                    <>Upload your File</>
                  )}
                </label>
              )}
            </div>
          ) : (
            <div className="tw-grid tw-grid-cols-5 tw-w-full tw-justify-between tw-gap-4">
              <div className="tw-flex tw-flex-col tw-col-span-4">
                <a
                  className="tw-underline tw-truncate"
                  href={doc.fileUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="">
                    {doc.filename ? (
                      <>{doc.filename}</>
                    ) : (
                      <>
                        {doc.documentType}
                        {getExtension(doc.fileUrl)}
                      </>
                    )}
                  </span>
                </a>
              </div>
            </div>
          )}
        </div>
      ),
      documentExpiryDate: (
        <>
          {/* If is edit index, allow date input edit, otherwise display date */}

          {isEditingIndex === index ? (
            <input
              type="date"
              value={moment(doc.documentExpiryDate).format('YYYY-MM-DD')}
              name="documentExpiryDate"
              className={clsx(
                'tw-bg-formInputBackground',
                'tw-text-gray-400',
                'tw-rounded-lg',
                'tw-w-full',
                'tw-h-10',
                'tw-placeholder-textSeal',
                'tw-appearance-none',
                'tw-leading-5',
                'tw-p-3',
                'tw-border-2',
                'tw-border-formInputBackground',
                'focus:tw-border-white',
                'focus:tw-text-white',
                'focus:tw-outline-none'
              )}
              onChange={(e) => inputChange(e, index)}
            />
          ) : (
            <>
              {doc.documentExpiryDate
                ? moment(doc.documentExpiryDate).format('YYYY-MM-DD')
                : '-'}
            </>
          )}
        </>
      ),
      documentSignedDate: (
        <>
          {isEditingIndex === index ? (
            <>
              <input
                type="date"
                name="documentSignedDate"
                value={moment(doc.documentSignedDate).format('YYYY-MM-DD')}
                onChange={(e) => inputChange(e, index)}
                className={inputClasses}
              />
            </>
          ) : (
            <>
              {doc.documentSignedDate
                ? moment(doc.documentSignedDate).format('YYYY-MM-DD')
                : '-'}
            </>
          )}
        </>
      ),
      editAction: (
        <>
          {isEditingIndex != index && (
            <i
              className="wi wi-20px wi-edit tw-mr-4 tw-cursor-pointer"
              onClick={() => {
                setIsUploadIndex(index);
                setIsEditingIndex(index);
              }}
            ></i>
          )}

          {isEditingIndex === index && (
            <WButton
              label={loading ? 'Saving...' : 'Save'}
              variant="link-secondary"
              disabled={loading}
              onClick={() => {
                saveFileHandle(doc.id!);
                updateDates();
              }}
            />
          )}
        </>
      ),
    };
  });
  const columnStyle = {
    fontSize: '14px',
    fontWeight: 400,
    borderBottom: '1px solid #535D66',
  };
  const rowStyle = {
    fontSize: '14px',
    fontWeight: 300,
    borderBottom: '1px solid #535D66',
  };

  return (
    <div className="tw-flex tw-ml-3">
      <WTable
        columns={[
          { title: 'Document', width: '1fr', dataKey: 'documentType' },
          { title: 'Status', width: '1fr', dataKey: 'status' },
          { title: 'File uploaded', width: '2fr', dataKey: 'file' },
          { title: 'Expiry date', width: '1fr', dataKey: 'documentExpiryDate' },
          { title: 'Date signed', width: '1fr', dataKey: 'documentSignedDate' },
          { title: 'Action', width: '1fr', dataKey: 'editAction' },
        ]}
        data={docFilesData}
        columnStyle={columnStyle}
        rowStyle={docFilesData.map(() => rowStyle)}
      ></WTable>
    </div>
  );
}

export default DocumentsUpdateForm;
