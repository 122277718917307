/* tslint:disable */
/* eslint-disable */
/**
 * Westbury Music API Admin
 * API for Westbury Music admin users
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SocietyLoginsData } from './SocietyLoginsData';
import {
    SocietyLoginsDataFromJSON,
    SocietyLoginsDataFromJSONTyped,
    SocietyLoginsDataToJSON,
} from './SocietyLoginsData';

/**
 * 
 * @export
 * @interface SocietyLogins
 */
export interface SocietyLogins {
    /**
     * 
     * @type {string}
     * @memberof SocietyLogins
     */
    societyName: string;
    /**
     * 
     * @type {string}
     * @memberof SocietyLogins
     */
    societyId: string;
    /**
     * 
     * @type {string}
     * @memberof SocietyLogins
     */
    societyPortal: string;
    /**
     * 
     * @type {SocietyLoginsData}
     * @memberof SocietyLogins
     */
    data?: SocietyLoginsData;
}

/**
 * Check if a given object implements the SocietyLogins interface.
 */
export function instanceOfSocietyLogins(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "societyName" in value;
    isInstance = isInstance && "societyId" in value;
    isInstance = isInstance && "societyPortal" in value;

    return isInstance;
}

export function SocietyLoginsFromJSON(json: any): SocietyLogins {
    return SocietyLoginsFromJSONTyped(json, false);
}

export function SocietyLoginsFromJSONTyped(json: any, ignoreDiscriminator: boolean): SocietyLogins {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'societyName': json['society_name'],
        'societyId': json['society_id'],
        'societyPortal': json['society_portal'],
        'data': !exists(json, 'data') ? undefined : SocietyLoginsDataFromJSON(json['data']),
    };
}

export function SocietyLoginsToJSON(value?: SocietyLogins | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'society_name': value.societyName,
        'society_id': value.societyId,
        'society_portal': value.societyPortal,
        'data': SocietyLoginsDataToJSON(value.data),
    };
}

