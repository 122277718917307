/* tslint:disable */
/* eslint-disable */
/**
 * Westbury Music API Admin
 * API for Westbury Music admin users
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PrimaryDiscographyRecordingsInner } from './PrimaryDiscographyRecordingsInner';
import {
    PrimaryDiscographyRecordingsInnerFromJSON,
    PrimaryDiscographyRecordingsInnerFromJSONTyped,
    PrimaryDiscographyRecordingsInnerToJSON,
} from './PrimaryDiscographyRecordingsInner';

/**
 * 
 * @export
 * @interface PrimaryDiscography
 */
export interface PrimaryDiscography {
    /**
     * 
     * @type {Array<PrimaryDiscographyRecordingsInner>}
     * @memberof PrimaryDiscography
     */
    recordings: Array<PrimaryDiscographyRecordingsInner>;
}

/**
 * Check if a given object implements the PrimaryDiscography interface.
 */
export function instanceOfPrimaryDiscography(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "recordings" in value;

    return isInstance;
}

export function PrimaryDiscographyFromJSON(json: any): PrimaryDiscography {
    return PrimaryDiscographyFromJSONTyped(json, false);
}

export function PrimaryDiscographyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PrimaryDiscography {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'recordings': ((json['recordings'] as Array<any>).map(PrimaryDiscographyRecordingsInnerFromJSON)),
    };
}

export function PrimaryDiscographyToJSON(value?: PrimaryDiscography | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'recordings': ((value.recordings as Array<any>).map(PrimaryDiscographyRecordingsInnerToJSON)),
    };
}

