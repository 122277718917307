import React, { useState, useEffect } from "react";
import {
    Button,
    Form,
    FormGroup,
    FormControl,
    Modal
} from "react-bootstrap";
import Select from 'react-select'
import uploadedIcon from "shared_components/public/assets/uploadedFile.png";
import thumbImage from "shared_components/public/assets/ThumbsUp.svg";
import thumbDown from "shared_components/public/assets/ThumbsDown.svg";
import { WModal } from "shared_components/components/WModal/WModal";

function uploadDistribution(props) {

    const [creditOrEditDis, setcreditOrEditDis] = useState(null);
    const [fileDataUpload, setFileDataUpload] = useState(null);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [formError, setFormError] = useState("");
    const handlecreateOrEditChange = (event) => {
        setcreditOrEditDis(event.target.value);
        console.log(creditOrEditDis)
    };

    const handleSubmitDistribution =(e)=>{
        e.preventDefault();
    }

    const societyDropList = ['AARC', 'ACTRA', 'ABRAMUS', 'ADAMI','GVL', 'ITSRIGHT', 'LaIPA', 'LSG', 'NORMA', 'PlayRight', 'PPL', 'AARC', 'ACTRA', 'ABRAMUS','ADAMI',].map((societyDrop) => ({
        value: societyDrop,
        label: societyDrop
    }));

    const distributionDate = ['dd/mm/yy', 'dd/mm/yy', 'dd/mm/yy', 'dd/mm/yy'].map((societyDrop) => ({
        value: societyDrop,
        label: societyDrop
    }));
    const [uploadedDistributionFile, setuploadedDistributionFile] = useState(null);
    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        setuploadedDistributionFile(file);
    };
    const deleteUploaded = ()=>{
        setuploadedDistributionFile(null);
    }
    const handleUploadDistri = (e)=>{
        e.preventDefault();  
        setFormSubmitted(true);
    }
    const distributionData = [
        {
            name: 'Victoria Adams',
            wa_name: 'Victoria Beckham',
            amount: '892.77'
        },
        {
            name: 'Emma Bunton',
            wa_name: 'Emma Bunton',
            amount: '285.50'
        },
        {
            name: 'David Robert Jones',
            wa_name: 'David Robert Jones',
            amount: '2,657.12'
        },
        {
            name: 'Alicia Keys',
            wa_name: 'Alicia Keys',
            amount: '439.21'
        },
        {
            name: 'Melanie C',
            wa_name: 'Melanie Chisholm',
            amount: '750.00'
        },
        {
            name: 'Melanie Brown',
            wa_name: 'Melanie Brown',
            amount: '39.00'
        },
    ];
    const waArtist = ['Victoria Beckham', 'Emma Bunton', 'Alicia Keys', 'Melanie Chisholm','Melanie Brown'].map((artist) => ({
        value: artist,
        label: artist
    }));
    const [formValue, setFormValue] = useState({
        edit_create_distribuition: '',
        society: '',
        distribution_date:''
    });
    const handleInputChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) => {
        setFormValue({
            ...formValue,
            [event.target.name]: event.target.value,
        });
    };
   return( 
       <>
       {formSubmitted ? (
            <WModal
                isOpen={props.show && formSubmitted}
                onClose={() => { props.onCloseBtn(); setFormSubmitted(false) }}
            >
                    {formError ? (
                        <div className="thankyouBlock">
                            <div className="thImage">
                                <img
                                src={thumbDown}
                                alt="Thumbs Down"
                                width="180"
                                className="d-block m-auto"
                                />
                            </div>
                            <h3 className="mt-4 pt-1 mb-3 text-center">
                                Unable to extract the data.<br /> Please manually enter the details
                            </h3>
                        </div>
                    ) : (
                        <div className="thankyouBlock">
                            <div className="thImage">
                                <img
                                src={thumbImage}
                                alt="Thumbs Up"
                                width="180"
                                className="d-block m-auto"
                                />
                            </div>
                            <h3 className="mt-4 pt-1 mb-3 text-center">
                                ABRAMUS<br />
                                distribution uploaded
                            </h3>
                        </div>
                    )}
            </WModal>
         ) : (
            <WModal
                isOpen={props.show && !formSubmitted}
                onClose={props.onCloseBtn} title=""
            >
                <button type="button" className="ModalClose btn btn-primary" onClick={props.onCloseBtn}>close</button>
                <h3>Upload new distribution file</h3>
                <Form onSubmit={handleSubmitDistribution}>
                    <div className="scInputFields scCheckFields fonmGroupControl align-items-center">
                        <label htmlFor="Edit_create_distribuition">
                        Create or edit a distribution
                        </label>
                        <div className="FormRadioGroup">
                            <div
                            key="inline-radio-distribution"
                            className="mb-0 styledRadioButtons styledCheckboxButtons radioButtonsRound d-flex flex-nowrap"
                            >
                                <Form.Check
                                    inline
                                    label="Create distribuition"
                                    type="radio"
                                    value="create_distribuition"
                                    name="edit_create_distribuition"
                                    id="create_distribuition"
                                    onChange={handleInputChange}
                                    className="mb-0"
                                />
                                <Form.Check
                                    inline
                                    label="Edit distribuition"
                                    type="radio"
                                    value="edit_distribuition"
                                    name="edit_create_distribuition"
                                    id="edit_distribuition"
                                    onChange={handleInputChange}
                                    className="mb-0"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="scInputFields fonmGroupControl SocietySelect mb-3">
                    <label htmlFor="Society_select">Society</label>
                        <Select
                            options={societyDropList}
                            className="customSelect"
                            placeholder="Select Society"                            
                            name="society"
                            styles={{

                            control: (provided) => ({
                                ...provided,
                                width: '222px',
                                borderColor:'#000!important',
                                height:'30px',
                                minHeight:'30px',
                                color:'#000'
                                }),
                            placeholder: (provided) => ({
                                    ...provided,
                                    color: '#000', // Set the desired color for the placeholder text
                                }),
                            option: (provided) => ({
                                ...provided,
                                borderBottom: '1px solid #000',
                                color: 'var(--color-black)',
                                fontSize: '1rem',
                                padding:'.51rem 10px',
                                backgroundColor: 'white!important',
                                cursor:'pointer',
                                width: '222px', // Set the desired width for the dropdown

                            }),
                            menu: (provided) => ({
                                ...provided,
                                maxHeight: '256px', // Set maximum height for the dropdown container
                                overflowY: 'hidden', // Enable vertical scrolling
                                width: '222px', // Set the desired width for the dropdown

                            }),
                            }}
                        />
                    </div>
                    {creditOrEditDis === 'edit_distribuition' && (
                    <div className="scInputFields fonmGroupControl SocietySelect">
                        <label htmlFor="Society_select">Distribution Date</label>
                        <Select
                            options={distributionDate}
                            className="customSelect"
                            placeholder="Please select"
                            // defaultValue={selectedCountry}
                            styles={{

                                control: (provided) => ({
                                    ...provided,
                                    width: '222px',
                                    borderColor:'#000!important',
                                    height:'30px',
                                    minHeight:'30px',
                                    color:'#000'
                                }),
                                placeholder: (provided) => ({
                                    ...provided,
                                    color: '#000', // Set the desired color for the placeholder text
                                }),
                                option: (provided) => ({
                                ...provided,
                                borderBottom: '1px solid #000',
                                color: 'var(--color-black)',
                                fontSize: '1rem',
                                padding:'.51rem 10px',
                                backgroundColor: 'white!important',
                                cursor:'pointer',
                                width: '222px', // Set the desired width for the dropdown

                                }),
                                menu: (provided) => ({
                                ...provided,
                                maxHeight: '256px', // Set maximum height for the dropdown container
                                overflowY: 'hidden', // Enable vertical scrolling
                                width: '222px', // Set the desired width for the dropdown

                                }),
                            }}
                        />
                    </div>
                    )}
                    <div className=" w-100 my-3 scLogoUpload d-block fonmGroupControl" >
                        <div className="distributionFileUpload">
                            <h4>Upload the distribution file</h4>
                            <p className="fileTypeNote mb-0">(.xls, .csv and .pdf are supported)</p>                        
                            {uploadedDistributionFile === null && (
                                <div>                                
                                    <label htmlFor="DistributionFile" className="distributionfileUploadLabel">
                                        <span>
                                        Drag and drop or click to upload
                                        </span>
                                    </label>
                                    <Form.Control
                                        type="file"
                                        name="DistributionFile"
                                        className="formFileUpload"
                                        id="DistributionFile"
                                        accept=".xlsx, .xls, .pdf, .docx" // Specify the file types you want to allow
                                        onDragOver={(e) => {
                                            e.preventDefault();
                                            // Add any visual indications for drag and drop here
                                        }}
                                        onDrop={(e) => {
                                            e.preventDefault();
                                            const file = e.dataTransfer.files[0];
                                        }}
                                        onChange={handleFileUpload}
                                    />
                                </div>
                            )}
                        </div>
                        {uploadedDistributionFile != null && (
                            <div className="uploadedFile my-4 py-2">
                                <div className="d-flex align-items-end">
                                    <img src={uploadedIcon} alt="uploaded file icon" style={{'height':'70px'}}/>
                                    <div className="m-0 uploadedFileTilte ps-3">
                                        <p className="mb-0">
                                            {uploadedDistributionFile['name']}
                                        </p>
                                        <div className="uploadprcnt">
                                            <button type="button" className="btn btn-primary" onClick={deleteUploaded}>close</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {(uploadedDistributionFile != null && fileDataUpload) && (
                            <div className="uploadedFileDataColumns pt-1">
                                <div className="d-flex fileColumnsheader">
                                    <div className="saNameBox">Society artist name</div>
                                    <div className="waNameBox">Westbury artist name</div>
                                    <div className="amountBox">Amount (USD $)</div>
                                </div>
                                <div className="fileColumnsDataFieldsWrap">
                                {distributionData && (
                                    distributionData.map((item, index) => (
                                    <div className="d-flex fileColumnsDataFields mt-1" key={index}>
                                        <div className="saNameBox">
                                            <FormControl
                                                placeholder=""
                                                type="text"
                                                value={item.name ? item.name : ''}
                                                name={`society_name_${index}`}
                                                className="m-0"
                                            />
                                        </div>
                                        <div className="waNameBox">
                                            <Select
                                                options={waArtist}
                                                className="customSelect waSelect"
                                                placeholder="Please select"
                                                defaultValue={item.wa_name}
                                                name={`wa_name_${index}`}
                                                styles={{

                                                    control: (provided) => ({
                                                        ...provided,
                                                        width: '200px',
                                                        borderColor:'#000!important',
                                                        height:'30px',
                                                        minHeight:'30px',
                                                        color:'#000'
                                                    }),
                                                    placeholder: (provided) => ({
                                                        ...provided,
                                                        color: '#000', // Set the desired color for the placeholder text
                                                    }),
                                                    option: (provided) => ({
                                                    ...provided,
                                                    borderBottom: '1px solid #000',
                                                    color: 'var(--color-black)',
                                                    fontSize: '1rem',
                                                    padding:'.51rem 10px',
                                                    backgroundColor: 'white!important',
                                                    cursor:'pointer',
                                                    width: '200px', // Set the desired width for the dropdown

                                                    }),
                                                    menu: (provided) => ({
                                                    ...provided,
                                                    maxHeight: '256px', // Set maximum height for the dropdown container
                                                    overflowY: 'hidden', // Enable vertical scrolling
                                                    width: '200px', // Set the desired width for the dropdown

                                                    }),
                                                }}
                                            />
                                        </div>
                                        <div className="amountBox">
                                            <FormControl
                                                placeholder=""
                                                type="text"
                                                value={item.amount ? item.amount : ''}
                                                name={`amount_${index}`}
                                                className="m-0"
                                            />
                                        </div>
                                    </div>
                                )))}
                                </div>

                            </div>
                        )}
                    </div>
                    {fileDataUpload ? (
                        <button 
                            className="x-button bg-grad w-100 d-block ms-auto justify-content-center mt-1" 
                            onClick={handleUploadDistri}
                        >
                            Upload
                        </button>
                    ) : (
                        <button 
                            className="x-button bg-grad w-100 d-block ms-auto justify-content-center mt-1"                         
                            onClick={(e) => setFileDataUpload(true) }
                        >
                            Upload
                        </button>
                    )}
                </Form>
            </WModal>
         )}
    </>
   )
}

export default uploadDistribution;
