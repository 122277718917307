/* tslint:disable */
/* eslint-disable */
/**
 * Westbury Music API Admin
 * API for Westbury Music admin users
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdminInterestRegistrationRepresentative } from './AdminInterestRegistrationRepresentative';
import {
    AdminInterestRegistrationRepresentativeFromJSON,
    AdminInterestRegistrationRepresentativeFromJSONTyped,
    AdminInterestRegistrationRepresentativeToJSON,
} from './AdminInterestRegistrationRepresentative';

/**
 * 
 * @export
 * @interface AdminInterestRegistration
 */
export interface AdminInterestRegistration {
    /**
     * 
     * @type {string}
     * @memberof AdminInterestRegistration
     */
    readonly url?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminInterestRegistration
     */
    readonly user?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminInterestRegistration
     */
    role?: AdminInterestRegistrationRoleEnum;
    /**
     * 
     * @type {AdminInterestRegistrationRepresentative}
     * @memberof AdminInterestRegistration
     */
    representative?: AdminInterestRegistrationRepresentative;
    /**
     * 
     * @type {boolean}
     * @memberof AdminInterestRegistration
     */
    performerOrProducer?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AdminInterestRegistration
     */
    readonly fullName?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminInterestRegistration
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminInterestRegistration
     */
    middleName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminInterestRegistration
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminInterestRegistration
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminInterestRegistration
     */
    phoneNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminInterestRegistration
     */
    masterId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminInterestRegistration
     */
    masterName?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminInterestRegistration
     */
    masterEmail?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminInterestRegistration
     */
    masterRights?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AdminInterestRegistration
     */
    masterFullname?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminInterestRegistration
     */
    pplPerformerId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminInterestRegistration
     */
    pplPerformerName?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminInterestRegistration
     */
    discogsIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminInterestRegistration
     */
    deezerIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminInterestRegistration
     */
    spotifyIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminInterestRegistration
     */
    musicbrainzIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AdminInterestRegistration
     */
    notes?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminInterestRegistration
     */
    accountStatus?: AdminInterestRegistrationAccountStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof AdminInterestRegistration
     */
    readonly accountCreationUrl?: string;
}


/**
 * @export
 */
export const AdminInterestRegistrationRoleEnum = {
    PerformerProducer: 'Performer/Producer'
} as const;
export type AdminInterestRegistrationRoleEnum = typeof AdminInterestRegistrationRoleEnum[keyof typeof AdminInterestRegistrationRoleEnum];

/**
 * @export
 */
export const AdminInterestRegistrationAccountStatusEnum = {
    Pending: 'pending',
    Approved: 'approved',
    Active: 'active',
    Rejected: 'rejected',
    Inactive: 'inactive'
} as const;
export type AdminInterestRegistrationAccountStatusEnum = typeof AdminInterestRegistrationAccountStatusEnum[keyof typeof AdminInterestRegistrationAccountStatusEnum];


/**
 * Check if a given object implements the AdminInterestRegistration interface.
 */
export function instanceOfAdminInterestRegistration(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AdminInterestRegistrationFromJSON(json: any): AdminInterestRegistration {
    return AdminInterestRegistrationFromJSONTyped(json, false);
}

export function AdminInterestRegistrationFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdminInterestRegistration {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'url': !exists(json, 'url') ? undefined : json['url'],
        'user': !exists(json, 'user') ? undefined : json['user'],
        'role': !exists(json, 'role') ? undefined : json['role'],
        'representative': !exists(json, 'representative') ? undefined : AdminInterestRegistrationRepresentativeFromJSON(json['representative']),
        'performerOrProducer': !exists(json, 'performer_or_producer') ? undefined : json['performer_or_producer'],
        'fullName': !exists(json, 'full_name') ? undefined : json['full_name'],
        'firstName': !exists(json, 'first_name') ? undefined : json['first_name'],
        'middleName': !exists(json, 'middle_name') ? undefined : json['middle_name'],
        'lastName': !exists(json, 'last_name') ? undefined : json['last_name'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'phoneNumber': !exists(json, 'phone_number') ? undefined : json['phone_number'],
        'masterId': !exists(json, 'master_id') ? undefined : json['master_id'],
        'masterName': !exists(json, 'master_name') ? undefined : json['master_name'],
        'masterEmail': !exists(json, 'master_email') ? undefined : json['master_email'],
        'masterRights': !exists(json, 'master_rights') ? undefined : json['master_rights'],
        'masterFullname': !exists(json, 'master_fullname') ? undefined : json['master_fullname'],
        'pplPerformerId': !exists(json, 'ppl_performer_id') ? undefined : json['ppl_performer_id'],
        'pplPerformerName': !exists(json, 'ppl_performer_name') ? undefined : json['ppl_performer_name'],
        'discogsIds': !exists(json, 'discogs_ids') ? undefined : json['discogs_ids'],
        'deezerIds': !exists(json, 'deezer_ids') ? undefined : json['deezer_ids'],
        'spotifyIds': !exists(json, 'spotify_ids') ? undefined : json['spotify_ids'],
        'musicbrainzIds': !exists(json, 'musicbrainz_ids') ? undefined : json['musicbrainz_ids'],
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
        'accountStatus': !exists(json, 'account_status') ? undefined : json['account_status'],
        'accountCreationUrl': !exists(json, 'account_creation_url') ? undefined : json['account_creation_url'],
    };
}

export function AdminInterestRegistrationToJSON(value?: AdminInterestRegistration | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'role': value.role,
        'representative': AdminInterestRegistrationRepresentativeToJSON(value.representative),
        'performer_or_producer': value.performerOrProducer,
        'first_name': value.firstName,
        'middle_name': value.middleName,
        'last_name': value.lastName,
        'email': value.email,
        'phone_number': value.phoneNumber,
        'master_id': value.masterId,
        'master_name': value.masterName,
        'master_email': value.masterEmail,
        'master_rights': value.masterRights,
        'master_fullname': value.masterFullname,
        'ppl_performer_id': value.pplPerformerId,
        'ppl_performer_name': value.pplPerformerName,
        'discogs_ids': value.discogsIds,
        'deezer_ids': value.deezerIds,
        'spotify_ids': value.spotifyIds,
        'musicbrainz_ids': value.musicbrainzIds,
        'notes': value.notes,
        'account_status': value.accountStatus,
    };
}

