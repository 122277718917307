import { useFormContext } from 'shared_components/components/WForms/WFormContext';
import { WInput } from 'shared_components/components/WForms/WInput/WInput';
import React from 'react';

interface UserSettings {
  firstName: string | undefined | null;
  lastName: string | undefined | null;
  middleName: string | undefined | null;
}

interface FullNameProps {
  editedSettings: UserSettings;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

const FullName: React.FC<FullNameProps> = ({
  editedSettings,
  handleInputChange,
  disabled = false,
}) => {
  const { isEditing } = useFormContext();

  return (
    <>
      {isEditing && (
        <>
          <WInput
            type="text"
            name="firstName"
            label="First Name"
            disabled={disabled}
            value={editedSettings.firstName}
            onChange={handleInputChange}
          />
          <WInput
            type="text"
            name="middleName"
            label="Middle Name"
            disabled={disabled}
            value={editedSettings.middleName}
            onChange={handleInputChange}
          />
          <WInput
            type="text"
            name="lastName"
            label="Last Name"
            disabled={disabled}
            value={editedSettings.lastName}
            onChange={handleInputChange}
          />
        </>
      )}
      {!isEditing && (
        <>
          <WInput
            type="text"
            name="fullName"
            label="Name"
            value={
              editedSettings.firstName +
              ' ' +
              editedSettings.middleName +
              ' ' +
              editedSettings.lastName
            }
            onChange={handleInputChange}
          />
        </>
      )}
    </>
  );
};

export default FullName;
