/* tslint:disable */
/* eslint-disable */
/**
 * Westbury Music API Admin
 * API for Westbury Music admin users
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SocietyContactInformation
 */
export interface SocietyContactInformation {
    /**
     * 
     * @type {string}
     * @memberof SocietyContactInformation
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof SocietyContactInformation
     */
    society?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SocietyContactInformation
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SocietyContactInformation
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SocietyContactInformation
     */
    phone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SocietyContactInformation
     */
    role?: SocietyContactInformationRoleEnum;
}


/**
 * @export
 */
export const SocietyContactInformationRoleEnum = {
    General: 'General',
    Account: 'Account',
    Membership: 'Membership',
    Discography: 'Discography',
    Distributions: 'Distributions',
    Legal: 'Legal',
    Estate: 'Estate'
} as const;
export type SocietyContactInformationRoleEnum = typeof SocietyContactInformationRoleEnum[keyof typeof SocietyContactInformationRoleEnum];


/**
 * Check if a given object implements the SocietyContactInformation interface.
 */
export function instanceOfSocietyContactInformation(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SocietyContactInformationFromJSON(json: any): SocietyContactInformation {
    return SocietyContactInformationFromJSONTyped(json, false);
}

export function SocietyContactInformationFromJSONTyped(json: any, ignoreDiscriminator: boolean): SocietyContactInformation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'society': !exists(json, 'society') ? undefined : json['society'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'role': !exists(json, 'role') ? undefined : json['role'],
    };
}

export function SocietyContactInformationToJSON(value?: SocietyContactInformation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'society': value.society,
        'name': value.name,
        'email': value.email,
        'phone': value.phone,
        'role': value.role,
    };
}

