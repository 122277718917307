import React from 'react';
import { Form, FormControl } from 'react-bootstrap';
import { handleInputChange } from '../../SocietyDetails';
import { useFormContext } from 'shared_components/components/WForms/WFormContext';
import clsx from 'clsx';
interface FormMethodSegmentProps {
  notifyByEmailCheck: boolean;
  notifyByPostCheck: boolean;
  notifyByPortalCheck: boolean;
  notifyEmail: string;
  notifyPost: string;
  notifyPortal: string;
  setNotifyByEmailCheck: (notifyByEmailCheck: boolean) => void;
  setNotifyByPostCheck: (notifyByPostCheck: boolean) => void;
  setNotifyByPortalCheck: (notifyByPortalCheck: boolean) => void;
  setNotifyEmail: (notifyEmail: string) => void;
  setNotifyPost: (notifyPost: string) => void;
  setNotifyPortal: (notifyPortal: string) => void;
}

export const FormMethodSegment = ({
  notifyByEmailCheck,
  notifyByPostCheck,
  notifyByPortalCheck,
  notifyEmail,
  notifyPost,
  notifyPortal,
  setNotifyByEmailCheck,
  setNotifyByPostCheck,
  setNotifyByPortalCheck,
  setNotifyEmail,
  setNotifyPost,
  setNotifyPortal,
}: FormMethodSegmentProps) => {
  const { isEditing } = useFormContext();
  return (
    <div className="tw-flex tw-flex-col tw-mb-4">
      <label htmlFor=" " className="tw-text-white tw-mb-4">
        How Are Forms Sent To This Society?
      </label>

      <CustomInput
        label={`Email`}
        placeholder={`Email address`}
        checked={notifyByEmailCheck}
        inputValue={notifyEmail}
        isEnabled={isEditing}
        onCheckboxChange={setNotifyByEmailCheck}
        onInputChange={setNotifyEmail}
      />
      <CustomInput
        label={`Post`}
        placeholder={`Address`}
        checked={notifyByPostCheck}
        inputValue={notifyPost}
        isEnabled={isEditing}
        onCheckboxChange={setNotifyByPostCheck}
        onInputChange={setNotifyPost}
      />
      <CustomInput
        label={`Portal`}
        placeholder={`Portal`}
        checked={notifyByPortalCheck}
        inputValue={notifyPortal}
        isEnabled={isEditing}
        onCheckboxChange={setNotifyByPortalCheck}
        onInputChange={setNotifyPortal}
      />
    </div>
  );
};

interface Props {
  label: string;
  placeholder: string;
  checked: boolean;
  inputValue: string;
  isEnabled: boolean;
  onCheckboxChange: (checked: boolean) => void;
  onInputChange: (value: string) => void;
}

const CustomInput: React.FC<Props> = ({
  label,
  placeholder,
  checked,
  inputValue,
  isEnabled,
  onCheckboxChange,
  onInputChange,
}) => {
  return (
    <div
      className={`tw-flex tw-items-center tw-w-full ${
        isEnabled ? '' : 'tw-opacity-50'
      }`}
    >
      <label className="tw-flex tw-items-center tw-cursor-pointer tw-w-1/4">
        <div className="tw-relative tw-inline-block tw-w-6 tw-h-6 tw-mr-2">
          <input
            type="checkbox"
            // id={option.id}
            disabled={!isEnabled}
            checked={checked}
            onClick={(e) => {
              if (!isEnabled) e.preventDefault();
            }}
            onChange={(e) => onCheckboxChange(e.target.checked)}
            className="tw-absolute tw-w-full tw-h-full tw-opacity-0"
          />
          {/* Checkbox Visual */}
          <div
            className={`tw-w-full tw-h-full tw-border-2 tw-rounded ${
              checked ? 'tw-bg-surfaceBlack90' : 'tw-bg-surfaceBlack80'
            } tw-flex tw-items-center tw-justify-center tw-text-textWhite`}
          >
            {checked && (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="check">
                  <path
                    id="Vector"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20.6187 6.38128C20.9604 6.72299 20.9604 7.27701 20.6187 7.61872L10.6187 17.6187C10.277 17.9604 9.72299 17.9604 9.38128 17.6187L4.38128 12.6187C4.03957 12.277 4.03957 11.723 4.38128 11.3813C4.72299 11.0396 5.27701 11.0396 5.61872 11.3813L10 15.7626L19.3813 6.38128C19.723 6.03957 20.277 6.03957 20.6187 6.38128Z"
                    fill="white"
                  />
                </g>
              </svg>
            )}
          </div>
        </div>

        <span>{label}</span>
      </label>{' '}
      <div className={`tw-w-3/4`}>
        <input
          type="text"
          value={inputValue}
          onChange={(e) => onInputChange(e.target.value)}
          disabled={!checked || !isEnabled}
          className={clsx(
            'tw-bg-formInputBackground',
            'tw-text-gray-400',
            'tw-rounded-lg',
            'tw-w-full',
            'tw-h-12',
            'tw-placeholder-textSeal',
            'tw-text-sm',
            'tw-leading-5',
            'tw-p-3',
            'tw-mb-2',
            'tw-border-2',
            'tw-border-formInputBackground',
            'focus:tw-border-white',
            'focus:tw-text-white',
            'focus:tw-outline-none'
          )}
          placeholder={placeholder}
        />
      </div>
    </div>
  );
};

export default CustomInput;
