/* tslint:disable */
/* eslint-disable */
/**
 * Westbury Music API
 * API for Westbury Music
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StatementsJsonPaymentsInner
 */
export interface StatementsJsonPaymentsInner {
    /**
     * 
     * @type {string}
     * @memberof StatementsJsonPaymentsInner
     */
    readonly id?: string;
    /**
     * 
     * @type {Date}
     * @memberof StatementsJsonPaymentsInner
     */
    paymentDate: Date;
    /**
     * 
     * @type {number}
     * @memberof StatementsJsonPaymentsInner
     */
    amountPaidToClient: number;
    /**
     * 
     * @type {number}
     * @memberof StatementsJsonPaymentsInner
     */
    totalAmountReceived: number;
    /**
     * 
     * @type {number}
     * @memberof StatementsJsonPaymentsInner
     */
    comission: number;
    /**
     * 
     * @type {string}
     * @memberof StatementsJsonPaymentsInner
     */
    status: StatementsJsonPaymentsInnerStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof StatementsJsonPaymentsInner
     */
    readonly fromSociety?: string;
    /**
     * 
     * @type {Date}
     * @memberof StatementsJsonPaymentsInner
     */
    dateCreated: Date;
}


/**
 * @export
 */
export const StatementsJsonPaymentsInnerStatusEnum = {
    Held: 'held',
    ToBeTransferred: 'to_be_transferred',
    WaitingChequeClear: 'waiting_cheque_clear',
    Cleared: 'cleared'
} as const;
export type StatementsJsonPaymentsInnerStatusEnum = typeof StatementsJsonPaymentsInnerStatusEnum[keyof typeof StatementsJsonPaymentsInnerStatusEnum];


/**
 * Check if a given object implements the StatementsJsonPaymentsInner interface.
 */
export function instanceOfStatementsJsonPaymentsInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "paymentDate" in value;
    isInstance = isInstance && "amountPaidToClient" in value;
    isInstance = isInstance && "totalAmountReceived" in value;
    isInstance = isInstance && "comission" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "dateCreated" in value;

    return isInstance;
}

export function StatementsJsonPaymentsInnerFromJSON(json: any): StatementsJsonPaymentsInner {
    return StatementsJsonPaymentsInnerFromJSONTyped(json, false);
}

export function StatementsJsonPaymentsInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatementsJsonPaymentsInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'paymentDate': (new Date(json['payment_date'])),
        'amountPaidToClient': json['amount_paid_to_client'],
        'totalAmountReceived': json['total_amount_received'],
        'comission': json['comission'],
        'status': json['status'],
        'fromSociety': !exists(json, 'from_society') ? undefined : json['from_society'],
        'dateCreated': (new Date(json['date_created'])),
    };
}

export function StatementsJsonPaymentsInnerToJSON(value?: StatementsJsonPaymentsInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'payment_date': (value.paymentDate.toISOString()),
        'amount_paid_to_client': value.amountPaidToClient,
        'total_amount_received': value.totalAmountReceived,
        'comission': value.comission,
        'status': value.status,
        'date_created': (value.dateCreated.toISOString()),
    };
}

