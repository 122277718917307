/* tslint:disable */
/* eslint-disable */
/**
 * Westbury Music API Admin
 * API for Westbury Music admin users
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AdminIdentityDocumentSubmission
 */
export interface AdminIdentityDocumentSubmission {
    /**
     * 
     * @type {string}
     * @memberof AdminIdentityDocumentSubmission
     */
    readonly id?: string;
    /**
     * 
     * @type {Date}
     * @memberof AdminIdentityDocumentSubmission
     */
    readonly dateCreated?: Date;
    /**
     * 
     * @type {Blob}
     * @memberof AdminIdentityDocumentSubmission
     */
    file?: Blob;
    /**
     * 
     * @type {string}
     * @memberof AdminIdentityDocumentSubmission
     */
    filename?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminIdentityDocumentSubmission
     */
    status?: AdminIdentityDocumentSubmissionStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof AdminIdentityDocumentSubmission
     */
    documentExpiryDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminIdentityDocumentSubmission
     */
    documentSignedDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminIdentityDocumentSubmission
     */
    documentType?: AdminIdentityDocumentSubmissionDocumentTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AdminIdentityDocumentSubmission
     */
    readonly fileUrl?: string;
}


/**
 * @export
 */
export const AdminIdentityDocumentSubmissionStatusEnum = {
    PendingDecision: 'pending_decision',
    Invalid: 'invalid',
    Accepted: 'accepted'
} as const;
export type AdminIdentityDocumentSubmissionStatusEnum = typeof AdminIdentityDocumentSubmissionStatusEnum[keyof typeof AdminIdentityDocumentSubmissionStatusEnum];

/**
 * @export
 */
export const AdminIdentityDocumentSubmissionDocumentTypeEnum = {
    Passport: 'Passport',
    CurrentAgreement: 'Current_Agreement',
    TerminationConfirmation: 'Termination_Confirmation',
    ProofOfAddress: 'Proof_Of_Address',
    PowerOfAttorney: 'Power_Of_Attorney',
    TermsOfAgreement: 'Terms_Of_Agreement'
} as const;
export type AdminIdentityDocumentSubmissionDocumentTypeEnum = typeof AdminIdentityDocumentSubmissionDocumentTypeEnum[keyof typeof AdminIdentityDocumentSubmissionDocumentTypeEnum];


/**
 * Check if a given object implements the AdminIdentityDocumentSubmission interface.
 */
export function instanceOfAdminIdentityDocumentSubmission(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AdminIdentityDocumentSubmissionFromJSON(json: any): AdminIdentityDocumentSubmission {
    return AdminIdentityDocumentSubmissionFromJSONTyped(json, false);
}

export function AdminIdentityDocumentSubmissionFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdminIdentityDocumentSubmission {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'dateCreated': !exists(json, 'date_created') ? undefined : (new Date(json['date_created'])),
        'file': !exists(json, 'file') ? undefined : json['file'],
        'filename': !exists(json, 'filename') ? undefined : json['filename'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'documentExpiryDate': !exists(json, 'document_expiry_date') ? undefined : json['document_expiry_date'],
        'documentSignedDate': !exists(json, 'document_signed_date') ? undefined : json['document_signed_date'],
        'documentType': !exists(json, 'document_type') ? undefined : json['document_type'],
        'fileUrl': !exists(json, 'file_url') ? undefined : json['file_url'],
    };
}

export function AdminIdentityDocumentSubmissionToJSON(value?: AdminIdentityDocumentSubmission | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'file': value.file,
        'filename': value.filename,
        'status': value.status,
        'document_expiry_date': value.documentExpiryDate,
        'document_signed_date': value.documentSignedDate,
        'document_type': value.documentType,
    };
}

