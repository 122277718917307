/* tslint:disable */
/* eslint-disable */
/**
 * Westbury Music API
 * API for Westbury Music
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ClientBankDetails
 */
export interface ClientBankDetails {
    /**
     * 
     * @type {string}
     * @memberof ClientBankDetails
     */
    readonly url?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientBankDetails
     */
    readonly user?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientBankDetails
     */
    password?: string;
    /**
     * 
     * @type {Date}
     * @memberof ClientBankDetails
     */
    readonly dateCreated?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ClientBankDetails
     */
    readonly dateUpdated?: Date;
    /**
     * 
     * @type {string}
     * @memberof ClientBankDetails
     */
    bankAccountName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientBankDetails
     */
    bankAccountNumber: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientBankDetails
     */
    bankSortCode: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientBankDetails
     */
    bankName: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientBankDetails
     */
    bankAddress: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientBankDetails
     */
    bankPostcode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientBankDetails
     */
    bankIban: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientBankDetails
     */
    bankRoutingCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientBankDetails
     */
    bankAccountHoldingBranch?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientBankDetails
     */
    bankBicSwiftCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientBankDetails
     */
    intermediaryBankName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientBankDetails
     */
    intermediaryBankAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientBankDetails
     */
    intermediaryBankBicSwiftCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientBankDetails
     */
    intermediaryBankCountry?: string | null;
}

/**
 * Check if a given object implements the ClientBankDetails interface.
 */
export function instanceOfClientBankDetails(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "bankAccountNumber" in value;
    isInstance = isInstance && "bankSortCode" in value;
    isInstance = isInstance && "bankName" in value;
    isInstance = isInstance && "bankAddress" in value;
    isInstance = isInstance && "bankIban" in value;

    return isInstance;
}

export function ClientBankDetailsFromJSON(json: any): ClientBankDetails {
    return ClientBankDetailsFromJSONTyped(json, false);
}

export function ClientBankDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClientBankDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'url': !exists(json, 'url') ? undefined : json['url'],
        'user': !exists(json, 'user') ? undefined : json['user'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'dateCreated': !exists(json, 'date_created') ? undefined : (new Date(json['date_created'])),
        'dateUpdated': !exists(json, 'date_updated') ? undefined : (new Date(json['date_updated'])),
        'bankAccountName': !exists(json, 'bank_account_name') ? undefined : json['bank_account_name'],
        'bankAccountNumber': json['bank_account_number'],
        'bankSortCode': json['bank_sort_code'],
        'bankName': json['bank_name'],
        'bankAddress': json['bank_address'],
        'bankPostcode': !exists(json, 'bank_postcode') ? undefined : json['bank_postcode'],
        'bankIban': json['bank_iban'],
        'bankRoutingCode': !exists(json, 'bank_routing_code') ? undefined : json['bank_routing_code'],
        'bankAccountHoldingBranch': !exists(json, 'bank_account_holding_branch') ? undefined : json['bank_account_holding_branch'],
        'bankBicSwiftCode': !exists(json, 'bank_bic_swift_code') ? undefined : json['bank_bic_swift_code'],
        'intermediaryBankName': !exists(json, 'intermediary_bank_name') ? undefined : json['intermediary_bank_name'],
        'intermediaryBankAddress': !exists(json, 'intermediary_bank_address') ? undefined : json['intermediary_bank_address'],
        'intermediaryBankBicSwiftCode': !exists(json, 'intermediary_bank_bic_swift_code') ? undefined : json['intermediary_bank_bic_swift_code'],
        'intermediaryBankCountry': !exists(json, 'intermediary_bank_country') ? undefined : json['intermediary_bank_country'],
    };
}

export function ClientBankDetailsToJSON(value?: ClientBankDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'password': value.password,
        'bank_account_name': value.bankAccountName,
        'bank_account_number': value.bankAccountNumber,
        'bank_sort_code': value.bankSortCode,
        'bank_name': value.bankName,
        'bank_address': value.bankAddress,
        'bank_postcode': value.bankPostcode,
        'bank_iban': value.bankIban,
        'bank_routing_code': value.bankRoutingCode,
        'bank_account_holding_branch': value.bankAccountHoldingBranch,
        'bank_bic_swift_code': value.bankBicSwiftCode,
        'intermediary_bank_name': value.intermediaryBankName,
        'intermediary_bank_address': value.intermediaryBankAddress,
        'intermediary_bank_bic_swift_code': value.intermediaryBankBicSwiftCode,
        'intermediary_bank_country': value.intermediaryBankCountry,
    };
}

