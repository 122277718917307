import React, { useEffect } from 'react';

// ModalProps interface
interface ModalProps {
  isOpen: boolean;
  cardEvent: () => void;
  children: React.ReactNode | React.ReactNode[] | string;
  title: string;
}

export const WSidebarModal: React.FC<ModalProps> = ({
  isOpen,
  children,
  cardEvent,
  title,
}) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  // Force re-render of internal components
  if (!isOpen) return null;

  const overlayClass = isOpen
    ? 'tw-opacity-100 tw-visible'
    : 'tw-opacity-0 tw-invisible tw-pointer-events-none';
  const modalClass = isOpen ? 'tw-translate-x-0' : 'tw-translate-x-full';

  return (
    <div className={`tw-text-white ${overlayClass}`}>
      {/* Background overlay */}
      <div
        className={`tw-fixed tw-inset-0 tw-z-30 tw-transition-opacity tw-duration-300`}
        style={{
          background: 'rgba(255, 255, 255, 0.30)',
          backdropFilter: 'blur(3px)',
        }}
      ></div>

      <div className="tw-fixed tw-inset-0 tw-flex tw-justify-end tw-z-50">
        {/* Modal content */}
        <div
          className={`tw-min-w-[550px] tw-p-8 tw-pb-0 tw-bg-surfaceBlack80 tw-h-full tw-transform ${modalClass} tw-transition-transform tw-duration-400 tw-overflow-y-scroll`}
        >
          <header className="tw-flex tw-justify-between tw-items-center">
            <h2 className="tw-text-white tw-text-xl">{title}</h2>
            <button
              className="tw-text-[32px] tw-text-white"
              onClick={cardEvent}
            >
              ×
            </button>
          </header>
          {children}
        </div>
      </div>
    </div>
  );
};

export const WSidebarModalFooter = ({ children }) => {
  return (
    <div className="tw-sticky tw-bottom-0 tw-p-4 tw-left-0 tw-bg-surfaceBlack80 tw-flex tw-space-x-4">
      {children}
    </div>
  );
};
