import React, { useState, useEffect, ChangeEvent } from 'react';
import { CompanyDetails } from './CompanyDetails';
import { BankDetails } from './BankDetails';
import { CompanyFormRoles, emptyFormRole } from './CompanyFormRoles';
import { useApi } from 'shared_components/context';
import { WFormProvider } from 'shared_components/components/WForms/WFormProvider';
import z from 'zod';
import { useFormContext } from 'shared_components/components/WForms/WFormContext';
import { WFormFooter } from 'shared_components/components/WForms/WFormFooter';
import {
  CompanyFormRole,
  AdminSocietySocietyFormsInner,
} from 'shared_components/generated/admin';
import { sendToast } from 'shared_components/utils/Toast';

// large form, so we use zod to validate the form and interface to type the form data
export interface CompanyInfoFormData {
  name?: string;
  emailAddress?: string;
  streetAddress1?: string;
  streetAddress2?: string;
  county?: string;
  country?: string;
  postcode?: string;
  phoneNumber?: string;
  logoImageUrl?: string;
  bankAccountName?: string;
  bankSortCode?: string;
  bankAccountNumber?: string;
  bankName?: string;
  bankAddress?: string;
  bankPostcode?: string;
  bankBicSwiftCode?: string;
  bankIban?: string;
  marketingUrl?: string;
}

export const CompanyInfo = () => {
  const { adminApi: api } = useApi();

  const [formData, setFormData] = useState<CompanyInfoFormData>({
    name: '',
    emailAddress: '',
    streetAddress1: '',
    streetAddress2: '',
    county: '',
    country: '',
    postcode: '',
    phoneNumber: '',
    logoImageUrl: '',
    bankAccountName: '',
    bankSortCode: '',
    bankAccountNumber: '',
    bankName: '',
    bankAddress: '',
    bankPostcode: '',
    bankBicSwiftCode: '',
    bankIban: '',
    marketingUrl: '',
  });
  const [companyFormRoles, setCompanyFormRoles] = React.useState<
    Array<CompanyFormRole>
  >([emptyFormRole]);
  const [societyForms, setSocietyForms] = React.useState<
    Array<AdminSocietySocietyFormsInner>
  >([]);
  const [companyLogo, setCompanyLogo] = React.useState<File | null>(null);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const schema = z.object({
    name: z.string().min(1),
    emailAddress: z.string().email(),
    streetAddress1: z.string().min(1),
    streetAddress2: z.string().min(1),
    county: z.string().min(1),
    country: z.string().min(1),
    postcode: z.string().min(1),
    phoneNumber: z.string().min(1),

    bankAccountName: z.string().min(1),
    bankSortCode: z.string().min(1),
    bankAccountNumber: z.string().min(1),
    bankName: z.string().min(1),
    bankAddress: z.string().min(1),
    bankPostcode: z.string().min(1),
    bankBicSwiftCode: z.string().min(1),
    bankIban: z.string().min(1),
    marketingUrl: z.string().min(1),
  });

  const fetchData = () => {
    api
      .retrieveCompany()
      .then(({ companyInformation, roleForms, societyForms }) => {
        setCompanyFormRoles(roleForms ?? [defaultCompanyFormRole()]);
        setSocietyForms(societyForms ?? []);
        const formData = {
          name: companyInformation.name ?? '',
          emailAddress: companyInformation.emailAddress ?? '',
          streetAddress1: companyInformation.streetAddress1 ?? '',
          streetAddress2: companyInformation.streetAddress2 ?? '',
          county: companyInformation.county ?? '',
          country: companyInformation.country ?? '',
          postcode: companyInformation.postcode ?? '',
          phoneNumber: companyInformation.phoneNumber ?? '',
          logoImageUrl: companyInformation.logoImageUrl,
          bankAccountName:
            companyInformation.bankDetails?.bankAccountName ?? '',
          bankSortCode: companyInformation.bankDetails?.bankSortCode ?? '',
          bankAccountNumber:
            companyInformation.bankDetails?.bankAccountNumber ?? '',
          bankName: companyInformation.bankDetails?.bankName ?? '',
          bankAddress: companyInformation.bankDetails?.bankAddress ?? '',
          bankPostcode: companyInformation.bankDetails?.bankPostcode ?? '',
          bankBicSwiftCode:
            companyInformation.bankDetails?.bankBicSwiftCode ?? '',
          bankIban: companyInformation.bankDetails?.bankIban ?? '',
          marketingUrl: companyInformation.marketingUrl ?? '',
        };
        setFormData(formData);
      })
      .catch(() => {});
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSubmit = () => {
    if (companyLogo != null) {
      api
        .updateLogoImageCompanyInformationLogoImage({
          logoImage: companyLogo,
        })
        .then(() => {
          setFormData({
            ...formData,
            logoImageUrl: URL.createObjectURL(companyLogo),
          });
        })
        .catch(() => {});
    }
    return api.updateCompany({
      company: {
        roleForms: companyFormRoles,
        companyInformation: {
          name: formData.name,
          emailAddress: formData.emailAddress,
          streetAddress1: formData.streetAddress1,
          streetAddress2: formData.streetAddress2,
          county: formData.county,
          country: formData.country,
          postcode: formData.postcode,
          phoneNumber: formData.phoneNumber,
          marketingUrl: formData.marketingUrl,
          bankDetails: {
            bankAccountName: formData.bankAccountName,
            bankSortCode: formData.bankSortCode,
            bankAccountNumber: formData.bankAccountNumber,
            bankName: formData.bankName,
            bankAddress: formData.bankAddress,
            bankPostcode: formData.bankPostcode,
            bankBicSwiftCode: formData.bankBicSwiftCode,
            bankIban: formData.bankIban,
          },
        },
      },
    });
  };

  const handleLogoSubmit = (e: React.ChangeEvent) => {
    if (e.target.files === null) {
      return;
    }

    const companyLogo = e.target.files![0];

    if (companyLogo === undefined) {
      return;
    }

    setCompanyLogo(companyLogo);
  };

  // Create a props object
  const companyInfoProps = {
    formData,
    handleChange,
    handleLogoSubmit,
  };

  const companyFormRolesProps = {
    companyFormRoles,
    setCompanyFormRoles,
    societyForms,
  };

  return (
    <div className="tw-flex tw-flex-col">
      <WFormProvider
        schema={schema}
        handleSubmit={handleSubmit}
        onSuccessMessage={null}
        onSuccess={() => {
          sendToast({
            text: 'Company information saved.',
            variant: 'success',
            duration: 10000,
          });
        }}
        formData={formData}
      >
        <CompanyInfoContent>
          <CompanyDetails {...companyInfoProps} />
          <BankDetails {...companyInfoProps} />
          <CompanyFormRoles {...companyFormRolesProps} />
        </CompanyInfoContent>
        <WFormFooter />
      </WFormProvider>
    </div>
  );
};

const CompanyInfoContent: React.FC<any> = ({ children }) => {
  const { isEditing } = useFormContext();
  const containerClasses = isEditing
    ? `tw-w-3/7 tw-min-w-[600px]`
    : `tw-bg-surfaceBlack90
    tw-p-6
    tw-mt-6
    tw-text-white
    tw-rounded-2xl
    tw-max-w-full tw-w-3/7 tw-min-w-[600px]`;

  return <div className={containerClasses}>{children}</div>;
};
