import React, { useState, useEffect, useMemo } from 'react';
import z from 'zod';
import WContainer from 'shared_components/components/WContainer';
import { WFormProvider } from 'shared_components/components/WForms/WFormProvider';
import { WInput } from 'shared_components/components/WForms/WInput/WInput';
import { WSidebarModal } from 'shared_components/components/WModal/WSidebarModal';
import { useApi } from 'shared_components/context';
import { useClientId } from 'shared_components/context/client';
import { PipelineRunStatusStatusEnum } from 'shared_components/generated/admin';
import { useFormContext } from 'shared_components/components/WForms/WFormContext';
import WButton from 'shared_components/components/WForms/WButton/WButton';
import { WFormFooterSecondary } from 'shared_components/components/WForms/WFormFooterSecondary';
import FullName from 'shared_components/components/FullName';
import WMultiTextInput from 'shared_components/components/WForms/WMultiTextInput';

interface ArtistDiscographyIDCardProps {
  isOpen: boolean;
  onClose: () => void;
  onComplete?: () => void;
}

function ArtistDiscographyIDCard({
  isOpen,
  onClose,
  onComplete,
}: ArtistDiscographyIDCardProps) {
  const { adminApi: api } = useApi();

  const [isEditing, setIsEditing] = useState(false);
  const [isEditingIds, setIsEditingIds] = useState(false);

  const hideEmail = function (email: string) {
    return email.replace(/(.{2})(.*)(?=@)/, function (gp1, gp2, gp3) {
      for (let i = 0; i < gp3.length; i++) {
        gp2 += '*';
      }
      return gp2;
    });
  };
  const { clientId, setClientEmail } = useClientId();
  const [runStatus, setRunStatus] =
    useState<PipelineRunStatusStatusEnum | null>(null);

  type RunStatusMap = {
    [STATUS in PipelineRunStatusStatusEnum]: string;
  };

  const getDiscographyPipelineRunStatus = async () => {
    if (clientId === undefined) {
      return;
    }
    if (!isOpen) {
      return;
    }
    await api
      .retrievePipelineRunStatus({ userId: clientId })
      .then(({ status }) => setRunStatus(status))
      .catch(() => setRunStatus(null));
  };

  const runDiscography = async () => {
    if (clientId === undefined) {
      return;
    }
    setRunStatus(null);
    await api
      .createPipelineRun({ pipelineRunRequest: { userId: clientId } })
      .then(() => getDiscographyPipelineRunStatus())
      .catch(() => {});
  };

  const runStatusMap: RunStatusMap = {
    IN_PROGRESS: 'In progress',
    CACHING: 'Caching data',
    SUCCEEDED: 'Succeeded',
    FAILED: 'Failed',
    UNKNOWN: 'Unknown',
  };

  interface FormValue {
    hiddenEmail?: string;
    pplAccountName?: string;
    pplPerformerName?: string;
    discogsIds: string[];
    deezerIds: string[];
    spotifyIds: string[];
    musicbrainzIds: string[];
  }

  const [formValue, setFormValue] = useState<FormValue>({
    discogsIds: [],
    deezerIds: [],
    spotifyIds: [],
    musicbrainzIds: [],
  });

  const [credentialsFormData, setCredentialsFormData] = useState({
    firstName: '',
    lastName: '',
    middleName: '',
    email: '',
  });

  useEffect(() => {
    setIsEditing(false);
    setIsEditingIds(false);
    fetchData();
  }, [clientId]);

  useEffect(() => {
    getDiscographyPipelineRunStatus();
  }, [isOpen, clientId]);

  const isFormDisabled = useMemo<boolean>(() => {
    if (runStatus === PipelineRunStatusStatusEnum.InProgress) {
      return true;
    }

    if (runStatus === PipelineRunStatusStatusEnum.Caching) {
      return true;
    }

    if (runStatus === PipelineRunStatusStatusEnum.Succeeded) {
      return true;
    }

    return false;
  }, [runStatus]);

  const handleClose = () => {
    if (runStatus === PipelineRunStatusStatusEnum.Succeeded) {
      onComplete && onComplete();
      onClose();
      return;
    }
    onClose();
  };

  const fetchData = () => {
    if (clientId === undefined) {
      return;
    }
    api
      .retrieveUserSettings({ userId: clientId })
      .then((userSettings) => {
        setCredentialsFormData({
          firstName: userSettings.firstName ?? '',
          lastName: userSettings.lastName ?? '',
          middleName: userSettings.middleName ?? '',
          email: userSettings.emailAddress!,
        });
        setFormValue({
          hiddenEmail: hideEmail(userSettings.emailAddress!),
          pplAccountName: userSettings.pplAccountName ?? '',
          pplPerformerName: userSettings.pplPerformerName ?? '',
          discogsIds: userSettings.discogsIds ?? [],
          deezerIds: userSettings.deezerIds ?? [],
          spotifyIds: userSettings.spotifyIds ?? [],
          musicbrainzIds: userSettings.musicbrainzIds ?? [],
        });
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const inputChange = async (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormValue({
      ...formValue,
      [event.target.name]: event.target.value,
    });
  };

  type Source = 'discogs' | 'deezer' | 'spotify' | 'musicbrainz';

  const handleSourceIdsChange = (source: Source, ids: string[]) => {
    setFormValue({
      ...formValue,
      [`${source}Ids`]: ids,
    });
  };

  const inputChangeCredentials = async (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setCredentialsFormData({
      ...credentialsFormData,
      [event.target.name]: event.target.value,
    });
  };

  const saveDetails = async () => {
    if (clientId === undefined) {
      return;
    }
    await api
      .partialUpdateUserSettings({
        userId: clientId,
        userSettings: {
          discogsIds: formValue.discogsIds.filter((alias) => alias !== ''),
          musicbrainzIds: formValue.musicbrainzIds.filter(
            (alias) => alias !== ''
          ),
          deezerIds: formValue.deezerIds.filter((alias) => alias !== ''),
          spotifyIds: formValue.spotifyIds.filter((alias) => alias !== ''),
          pplAccountName: formValue.pplAccountName ?? '',
          pplPerformerName: formValue.pplPerformerName ?? '',
          firstName: credentialsFormData.firstName,
          lastName: credentialsFormData.lastName,
          middleName: credentialsFormData.middleName,
          emailAddress: credentialsFormData.email!,
        },
      })
      .then((response) => {
        setCredentialsFormData({
          firstName: response.firstName ?? '',
          lastName: response.lastName ?? '',
          middleName: response.middleName ?? '',
          email: response.emailAddress!,
        });
        setFormValue({
          hiddenEmail: hideEmail(response.emailAddress!),
          pplAccountName: response.pplAccountName ?? '',
          pplPerformerName: response.pplPerformerName ?? '',
          discogsIds: response.discogsIds ?? [],
          deezerIds: response.deezerIds ?? [],
          spotifyIds: response.spotifyIds ?? [],
          musicbrainzIds: response.musicbrainzIds ?? [],
        });
        if (response.emailAddress) {
          setClientEmail(response.emailAddress);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const validateArrayElementsUnique = (source: Source) => {
    return (ids: string[]) => {
      const seen = new Set();

      ids.forEach((id, i) => {
        if (id === '') {
          return;
        }

        if (seen.has(id)) {
          throw new z.ZodError([
            {
              path: [`${source}Ids`, i],
              message: 'This ID has been inputted already',
              code: 'custom',
            },
          ]);
        }
        seen.add(id);
      });

      return true;
    };
  };

  const schema = z
    .object({
      pplAccountName: z.string(),
      pplPerformerName: z.string(),
      discogsIds: z
        .array(
          z.union([
            z
              .string()
              .regex(/^\d{1,8}$/, 'Must be 1–8 digit Discogs artist ID'),
            // Permit empty string to allow fields to be left blank
            // Empty strings are filtered out post-validation
            z.string().length(0),
          ])
        )
        .refine(validateArrayElementsUnique('discogs')),
      musicbrainzIds: z
        .array(
          z.union([
            z.string().uuid('Must be MusicBrainz artist UUID'),
            z.string().length(0),
          ])
        )
        .refine(validateArrayElementsUnique('musicbrainz')),
      deezerIds: z
        .array(
          z.union([
            z.string().regex(/^\d{1,9}$/, 'Must be 1–9 digit Deezer artist ID'),
            z.string().length(0),
          ])
        )
        .refine(validateArrayElementsUnique('deezer')),
      spotifyIds: z
        .array(
          z.union([
            z
              .string()
              .regex(/^[0-9A-Za-z_-]{22}$/, 'Must be Spotify artist ID'),
            z.string().length(0),
          ])
        )
        .refine(validateArrayElementsUnique('spotify')),
    })
    .refine(
      ({
        pplPerformerName,
        discogsIds,
        deezerIds,
        spotifyIds,
        musicbrainzIds,
      }) => {
        return (
          pplPerformerName ||
          discogsIds ||
          deezerIds ||
          spotifyIds ||
          musicbrainzIds
        );
      },
      {
        path: [
          'pplPerformerName',
          'discogsIds',
          'deezerIds',
          'spotifyIds',
          'musicbrainzIds',
        ],
        message: 'At least one field must be filled',
      }
    );

  const artistDetailsSchema = z.object({
    firstName: z.string().min(1),
    middleName: z.string(),
    lastName: z.string().min(1),
    email: z.string().min(1),
  });

  return (
    <WSidebarModal title="" isOpen={isOpen} cardEvent={handleClose}>
      <WContainer>
        <div className="detailsBoxWrap">
          <WFormProvider
            schema={artistDetailsSchema}
            handleSubmit={saveDetails}
            onSuccess={() => setIsEditing(false)}
            formData={credentialsFormData}
          >
            <div className="tw-flex tw-justify-between">
              <span className={`tw-mb-6`}>Artist details</span>

              <FormEditSection setIsEditingPage={setIsEditing} />
            </div>

            <FullName
              editedSettings={{
                firstName: credentialsFormData.firstName,
                lastName: credentialsFormData.lastName,
                middleName: credentialsFormData.middleName,
              }}
              handleInputChange={inputChangeCredentials}
            />

            <WInput
              type="text"
              name="email"
              label="Email"
              onBlur={() => {}}
              onChange={inputChangeCredentials}
              value={credentialsFormData.email}
            />

            <WFormFooterSecondary />
          </WFormProvider>
        </div>
      </WContainer>
      <WContainer>
        <div className="">
          <WFormProvider
            schema={schema}
            handleSubmit={saveDetails}
            onSuccess={() => setIsEditingIds(false)}
            formData={formValue}
          >
            <div className="tw-flex tw-justify-between">
              <span className={`tw-mb-6`}>Discography IDs</span>

              <FormEditSection setIsEditingPage={setIsEditingIds} />
            </div>

            <WInput
              type="text"
              name="pplAccountName"
              label="PPL Account Name"
              onBlur={() => {}}
              onChange={inputChange}
              value={formValue.pplAccountName}
            />
            <WInput
              type="text"
              name="pplPerformerName"
              label="PPL Performer Name"
              onBlur={() => {}}
              onChange={inputChange}
              value={formValue.pplPerformerName}
            />

            <div className="tw-space-y-6">
              <WMultiTextInput
                name="discogsIds"
                label="Discogs"
                addCaption="Add ID"
                placeholder="Discogs ID"
                values={formValue.discogsIds}
                onChange={(v) => handleSourceIdsChange('discogs', v)}
              />
              <WMultiTextInput
                name="musicbrainzIds"
                label="MusicBrainz"
                addCaption="Add ID"
                placeholder="MusicBrainz ID"
                values={formValue.musicbrainzIds}
                onChange={(v) => handleSourceIdsChange('musicbrainz', v)}
              />
              <WMultiTextInput
                name="deezerIds"
                label="Deezer"
                addCaption="Add ID"
                placeholder="Deezer ID"
                values={formValue.deezerIds}
                onChange={(v) => handleSourceIdsChange('deezer', v)}
              />
              <WMultiTextInput
                name="spotifyIds"
                label="Spotify"
                addCaption="Add ID"
                placeholder="Spotify ID"
                values={formValue.spotifyIds}
                onChange={(v) => handleSourceIdsChange('spotify', v)}
              />
            </div>

            <WFormFooterSecondary disabled={isFormDisabled} />
          </WFormProvider>
        </div>
      </WContainer>
      <div className="tw-my-6">
        <WButton
          label="Run Discography"
          variant={
            isEditingIds || isEditing || isFormDisabled ? 'dark' : 'primary'
          }
          disabled={isEditingIds || isEditing || isFormDisabled}
          onClick={() => {
            runDiscography();
          }}
        />
      </div>
      {runStatus !== null && (
        <div className="tw-my-4">
          <p className="">
            {runStatusMap[runStatus ?? PipelineRunStatusStatusEnum.Unknown]}
          </p>
        </div>
      )}
    </WSidebarModal>
  );
}

type FormEditSectionProps = {
  setIsEditingPage: (isEditing: boolean) => void;
};

function FormEditSection({ setIsEditingPage }: FormEditSectionProps) {
  const { isEditing, setIsEditing } = useFormContext(); // Fetch errors from context
  return (
    <>
      {!isEditing && (
        <WButton
          label="Edit"
          icon="edit"
          variant="secondary"
          onClick={() => {
            setIsEditing(true);
            setIsEditingPage(true);
          }}
        />
      )}
    </>
  );
}
export default ArtistDiscographyIDCard;
