import React, { useEffect, useRef } from 'react';
import { useFormContext } from 'shared_components/components/WForms/WFormContext';
import clsx from 'clsx';

// a union between the two states: when a user uploads a file
// or when a user has already uploaded a file

type InnerFile = {
  file: File | null | undefined;
  fileName: string | undefined;
  fileUrl: string;
};

interface WFileUploadProps {
  onFileChange: (file: React.ChangeEvent<HTMLInputElement> | null) => void;
  label: string;
  name: string;
  subTitle?: string;
  value: InnerFile | undefined;
}

const WFileUpload: React.FC<WFileUploadProps> = ({
  name,
  label,
  subTitle = 'Drag & drop or click to upload',
  onFileChange,
  value,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files === null) {
      return;
    }

    const fileName = event.target.files[0].name;
    const fileUrl = URL.createObjectURL(event.target.files?.[0]);
    const file = event.target.files?.[0];
    setInnerValue({ file: file, fileName, fileUrl });
    onFileChange(event);
  };

  const handleClick = () => {
    fileInputRef.current?.click();
  };

  useEffect(() => {
    if (value != undefined && innerValue == undefined) {
      if (
        typeof value === 'object' &&
        typeof value?.name === 'string' &&
        typeof value?.size === 'number'
      ) {
        const file = value;
        const fileName = value.name;
        const fileUrl = URL.createObjectURL(file);
        setInnerValue({ file, fileName, fileUrl });
      } else if (
        value.hasOwnProperty('fileName') &&
        value.hasOwnProperty('fileUrl')
      ) {
        setInnerValue({
          fileName: value.fileName,
          fileUrl: value.fileUrl,
          file: undefined,
        });
      }
    }
  }, [value]);

  const [innerValue, setInnerValue] = React.useState<InnerFile | undefined>();
  const { isEditing, errors } = useFormContext(); // Fetch errors from context
  return (
    <div className="tw-flex tw-mb-4 tw-flex-col">
      <label
        className={clsx('tw-block tw-leading-4 tw-mb-2', {
          'tw-text-[14px]': !isEditing,
          'tw-text-white': !errors[name],
          'tw-text-formOutlineErrorRed': errors[name],
        })}
        htmlFor=""
      >
        {label}
      </label>
      {!isEditing && (
        <div className="tw-text-textDove tw-text-sm">
          {innerValue != undefined && (
            <a target="_blank" href={innerValue?.fileUrl}>
              {innerValue?.fileName || '-'}
            </a>
          )}
          {innerValue == undefined && '-'}
        </div>
      )}
      {isEditing && innerValue != undefined && (
        <span className="tw-underline tw-flex tw-space-x-2 tw-items-center">
          <a target="_blank" href={innerValue.fileUrl}>
            {innerValue.fileName}
          </a>
          {/* A cross to remove the file */}
          <i
            className="wi wi-xs wi-close tw-cursor-pointer"
            onClick={() => {
              setInnerValue(undefined);
            }}
          />
        </span>
      )}
      {isEditing && innerValue == undefined && (
        <>
          <div
            className={clsx(
              'tw-relative tw-border-dashed tw-border-2  tw-rounded tw-p-6 tw-cursor-pointer tw-mb-2',
              {
                'tw-border-textGraphite': !errors[name],
              },
              {
                'tw-border-formOutlineErrorRed': errors[name],
              }
            )}
            onClick={handleClick}
          >
            <div className="tw-text-center tw-font-light">
              <p className="">{subTitle}</p>
            </div>
            <div className="tw-mb-4 tw-flex tw-justify-center">
              <i className="wi wi-lg wi-export" />
            </div>

            <input
              ref={fileInputRef}
              type="file"
              name={name}
              className="tw-hidden"
              onChange={(e) => {
                handleFileChange(e);
              }}
            />
          </div>
        </>
      )}
      {isEditing && errors[name] && (
        <div className="tw-text-formOutlineErrorRed tw-text-xs">
          {errors[name]}
        </div>
      )}
    </div>
  );
};

export default WFileUpload;
