import React, { useEffect } from 'react';
import { useClientId } from 'shared_components/context/client';
import { useApi } from 'shared_components/context';
import { useWError } from 'shared_components/components/WError/WErrorProvider';
import { WError } from 'shared_components/components/WError/WError';
import WModal from 'shared_components/components/WModal';
import ThankyouBlock from 'shared_components/components/ThankYouBlock/ThankyouBlock';
import { MembershipForms } from 'shared_components/generated/admin';
import { ClientSocietyFormTask } from 'shared_components/generated/client';
import PassportAndForms from './PassportAndForms';
interface Props {
  passportFileLocation: string;
  taskId: string;
  setApproveMembershipForms: React.Dispatch<React.SetStateAction<boolean>>;
  fetchTasks: () => void;
}

interface pandadocsDocumentIds {
  sessionId: string | null | undefined;
  documentName: string | null | undefined;
  documentId: string | null | undefined;
}

function ApproveMembershipForms(props: Props) {
  const [pandadocsDocumentIds, setPandadocsDocumentIds] = React.useState<
    pandadocsDocumentIds[]
  >([]);

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [formsApproved, setFormsApproved] = React.useState<boolean>(false);
  const { adminApi: api } = useApi();
  const { throwError } = useWError();
  const passportFileLocationFileExtension = props.passportFileLocation
    .split('?')[0]
    .split('.')
    .pop();

  const { clientId } = useClientId();
  const [documentInViewId, setDocumentInViewId] = React.useState<
    string | null | undefined
  >(null);
  const [membershipForms, setMembershipForms] = React.useState<
    MembershipForms[]
  >([]);
  const [clientSocietyFormTasks, setClientSocietyFormTasks] = React.useState<
    ClientSocietyFormTask[]
  >([]);

  const fetchData = async () => {
    if (!clientId) {
      throwError(new WError('no client id'));
      return;
    }
    try {
      setIsLoading(true);
      let clientFormTasks = await api.listClientSocietyFormTasks({
        userId: clientId,
      });
      clientFormTasks = clientFormTasks.filter(
        (item) => item.status === 'admin_to_approve'
      );
      let membershipForms = await api.listMembershipForms({
        userId: clientId,
      });
      setClientSocietyFormTasks(clientFormTasks);
      setMembershipForms(membershipForms);
    } catch (error) {
      throwError(new WError('error fetching pandadocs document ids'));
    }
  };

  useEffect(() => {
    fetchData();
  }, [clientId]);

  useEffect(() => {
    setPandadocsDocumentIds(
      clientSocietyFormTasks.map((item) => {
        return {
          sessionId: item.pandadocsSessionId,
          documentName: item.societyFormName,
          documentId: item.pandadocsDocumentId,
        };
      })
    );
    setDocumentInViewId(clientSocietyFormTasks[0]?.pandadocsDocumentId);
  }, [clientSocietyFormTasks]);

  const acceptForm = async () => {
    setIsLoading(true);
    let membershipForm = membershipForms.find(
      (item) => item.pandadocsDocumentId === documentInViewId
    );
    try {
      api.adminApproveMembershipForms({
        formId: membershipForm?.id ?? '',
        membershipForms: {
          user: clientId as unknown as number,
          societyForm: membershipForm?.societyForm ?? '',
        },
      });
      // remove client task from ClientSocietyFormTasks
      let clientSocietyFormTasksCopy = [...clientSocietyFormTasks];
      clientSocietyFormTasksCopy = clientSocietyFormTasksCopy.filter(
        (item) => item.pandadocsDocumentId !== documentInViewId
      );
      if (clientSocietyFormTasksCopy.length === 0) {
        // forms complete - delete task and show modal
        setFormsApproved(true);
        api.destroyAdminTaskClient({
          taskId: props.taskId,
        });
      }
      setClientSocietyFormTasks(clientSocietyFormTasksCopy);
    } catch (error) {
      throwError(new WError('error approving form'));
    }
  };
  const rejectForm = async () => {
    // TODO: add reject functionality
  };
  window.addEventListener('message', (event: MessageEvent<any>) => {
    const type = event.data && event.data.type;
    switch (type) {
      case 'session_view.document.loaded':
        if (isLoading) setIsLoading(false);
    }
  });

  return (
    <>
      <WModal
        isOpen={formsApproved}
        onClose={() => props.setApproveMembershipForms(false)}
        title=""
        disableBackdropClick={true}
        hasCloseButton={false}
      >
        <ThankyouBlock
          title="Forms Approved"
          description="You have successfully approved the forms."
          cta={{
            ctaText: 'Return to Dashboard',
            onClick: () => {
              props.setApproveMembershipForms(false);
              props.fetchTasks();
            },
          }}
        />
      </WModal>
      <h2 className="tw-mb-4">Verify Signature</h2>
      <PassportAndForms
        passportFileLocation={props.passportFileLocation}
        onApprove={acceptForm}
        onDecline={rejectForm}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        pandadocsDocumentIds={pandadocsDocumentIds}
        setDocumentInViewId={setDocumentInViewId}
      />
    </>
  );
}

export default ApproveMembershipForms;
