import React, { useState, useRef, useEffect } from 'react';
import WDropdownContent from './WDropdownContent';

interface DropdownProps {
  trigger: React.ReactNode;
  content: React.ReactNode;
}

const WDropdown: React.FC<DropdownProps> = ({ trigger, content }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLElement | null>(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    // important to clean up event listener when component unmounts
    if (isOpen) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div className="tw-relative" ref={dropdownRef}>
      <div
        role="button"
        tabIndex={0}
        onClick={toggleDropdown}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === 'Space') {
            toggleDropdown();
          }
        }}
        className="tw-cursor-pointer"
      >
        {trigger}
      </div>

      {isOpen && (
        <div className="tw-absolute tw-top-full tw-pt-4 tw-z-50 tw-right-0">
          <WDropdownContent content={content} />
        </div>
      )}
    </div>
  );
};

export default WDropdown;
