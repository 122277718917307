import React, { useState } from 'react';

interface RowData {
  [key: string]: any;
  childComponent?: React.ReactNode;
}

interface Column {
  dataKey: string;
  title: string | React.ReactNode;
}

interface Props {
  rows: RowData[];
  columns: Column[];
  children?: React.ReactNode;
}

const StatementsTable: React.FC<Props> = ({ rows, columns }) => {
  // Generate grid columns dynamically based on the number of columns
  const gridTemplateColumns = `repeat(${columns.length}, 1fr)`;

  // State to track which rows are expanded
  const [expandedRows, setExpandedRows] = useState<number[]>([]);

  // Function to handle row click (expand/collapse)
  const handleRowClick = (id: number) => {
    setExpandedRows((prevState) =>
      prevState.includes(id)
        ? prevState.filter((e) => e !== id)
        : [...prevState, id]
    );
  };

  return (
    <div className="tw-bg-surfaceBlack90">
      {/* Table body */}
      <div
        className="tw-grid tw-gap-2 tw-w-full tw-pt-2 tw-pb-2"
        style={{ gridTemplateColumns, borderBottom: '1px solid #fff' }}
      >
        {columns.map((column, idx) => (
          <div key={idx} className={`tw-pl-0`}>
            {column.title}
          </div>
        ))}
      </div>

      {rows.map((row, rowIndex) => (
        <React.Fragment key={rowIndex}>
          {/* Main row */}
          <div
            className="tw-grid tw-gap-2 tw-w-full tw-pt-2 tw-pb-2"
            style={{ gridTemplateColumns, borderBottom: '1px solid #fff' }}
          >
            {columns.map((column, colIndex) => (
              <div key={colIndex} className="tw-pl-0 tw-flex tw-items-center">
                {row[column.dataKey]}

                {/* Show/hide column as last item */}
                {colIndex === columns.length - 1 && (
                  <span
                    className="tw-text-white tw-pl-2 tw-cursor-pointer"
                    onClick={() => handleRowClick(row.id)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handleRowClick(row.id);
                      }
                    }}
                    tabIndex={0}
                    role="button"
                  >
                    {expandedRows.includes(row.id) ? <>-</> : <>+</>}
                  </span>
                )}
              </div>
            ))}
          </div>

          {/* Expanded row */}
          {expandedRows.includes(row.id) && <>{row.childComponent}</>}
        </React.Fragment>
      ))}
    </div>
  );
};

export default StatementsTable;
