/* tslint:disable */
/* eslint-disable */
/**
 * Westbury Music API Admin
 * API for Westbury Music admin users
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CompanyInformationLogoImage
 */
export interface CompanyInformationLogoImage {
    /**
     * 
     * @type {Blob}
     * @memberof CompanyInformationLogoImage
     */
    logoImage: Blob;
    /**
     * 
     * @type {string}
     * @memberof CompanyInformationLogoImage
     */
    readonly logoImageUrl?: string;
}

/**
 * Check if a given object implements the CompanyInformationLogoImage interface.
 */
export function instanceOfCompanyInformationLogoImage(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "logoImage" in value;

    return isInstance;
}

export function CompanyInformationLogoImageFromJSON(json: any): CompanyInformationLogoImage {
    return CompanyInformationLogoImageFromJSONTyped(json, false);
}

export function CompanyInformationLogoImageFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyInformationLogoImage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'logoImage': json['logo_image'],
        'logoImageUrl': !exists(json, 'logo_image_url') ? undefined : json['logo_image_url'],
    };
}

export function CompanyInformationLogoImageToJSON(value?: CompanyInformationLogoImage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'logo_image': value.logoImage,
    };
}

