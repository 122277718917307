/* tslint:disable */
/* eslint-disable */
/**
 * Westbury Music API Admin
 * API for Westbury Music admin users
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TermsOfAgreementRepresentativeFee
 */
export interface TermsOfAgreementRepresentativeFee {
    /**
     * 
     * @type {number}
     * @memberof TermsOfAgreementRepresentativeFee
     */
    readonly id?: number;
    /**
     * 
     * @type {string}
     * @memberof TermsOfAgreementRepresentativeFee
     */
    termsOfAgreement?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TermsOfAgreementRepresentativeFee
     */
    percentage: string;
    /**
     * 
     * @type {string}
     * @memberof TermsOfAgreementRepresentativeFee
     */
    takenFrom?: TermsOfAgreementRepresentativeFeeTakenFromEnum;
    /**
     * 
     * @type {Date}
     * @memberof TermsOfAgreementRepresentativeFee
     */
    expirationDate: Date;
}


/**
 * @export
 */
export const TermsOfAgreementRepresentativeFeeTakenFromEnum = {
    WestburyIncome: 'westbury_income',
    ClientIncome: 'client_income'
} as const;
export type TermsOfAgreementRepresentativeFeeTakenFromEnum = typeof TermsOfAgreementRepresentativeFeeTakenFromEnum[keyof typeof TermsOfAgreementRepresentativeFeeTakenFromEnum];


/**
 * Check if a given object implements the TermsOfAgreementRepresentativeFee interface.
 */
export function instanceOfTermsOfAgreementRepresentativeFee(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "percentage" in value;
    isInstance = isInstance && "expirationDate" in value;

    return isInstance;
}

export function TermsOfAgreementRepresentativeFeeFromJSON(json: any): TermsOfAgreementRepresentativeFee {
    return TermsOfAgreementRepresentativeFeeFromJSONTyped(json, false);
}

export function TermsOfAgreementRepresentativeFeeFromJSONTyped(json: any, ignoreDiscriminator: boolean): TermsOfAgreementRepresentativeFee {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'termsOfAgreement': !exists(json, 'terms_of_agreement') ? undefined : json['terms_of_agreement'],
        'percentage': json['percentage'],
        'takenFrom': !exists(json, 'taken_from') ? undefined : json['taken_from'],
        'expirationDate': (new Date(json['expiration_date'])),
    };
}

export function TermsOfAgreementRepresentativeFeeToJSON(value?: TermsOfAgreementRepresentativeFee | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'terms_of_agreement': value.termsOfAgreement,
        'percentage': value.percentage,
        'taken_from': value.takenFrom,
        'expiration_date': (value.expirationDate.toISOString().substr(0,10)),
    };
}

