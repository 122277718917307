/* tslint:disable */
/* eslint-disable */
/**
 * Westbury Music API Admin
 * API for Westbury Music admin users
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CompanyFormRoleFormsInner } from './CompanyFormRoleFormsInner';
import {
    CompanyFormRoleFormsInnerFromJSON,
    CompanyFormRoleFormsInnerFromJSONTyped,
    CompanyFormRoleFormsInnerToJSON,
} from './CompanyFormRoleFormsInner';

/**
 * 
 * @export
 * @interface CompanyFormRole
 */
export interface CompanyFormRole {
    /**
     * 
     * @type {string}
     * @memberof CompanyFormRole
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyFormRole
     */
    role: CompanyFormRoleRoleEnum;
    /**
     * 
     * @type {Array<CompanyFormRoleFormsInner>}
     * @memberof CompanyFormRole
     */
    forms?: Array<CompanyFormRoleFormsInner>;
}


/**
 * @export
 */
export const CompanyFormRoleRoleEnum = {
    PerformerProducer: 'Performer/Producer'
} as const;
export type CompanyFormRoleRoleEnum = typeof CompanyFormRoleRoleEnum[keyof typeof CompanyFormRoleRoleEnum];


/**
 * Check if a given object implements the CompanyFormRole interface.
 */
export function instanceOfCompanyFormRole(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "role" in value;

    return isInstance;
}

export function CompanyFormRoleFromJSON(json: any): CompanyFormRole {
    return CompanyFormRoleFromJSONTyped(json, false);
}

export function CompanyFormRoleFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyFormRole {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'role': json['role'],
        'forms': !exists(json, 'forms') ? undefined : ((json['forms'] as Array<any>).map(CompanyFormRoleFormsInnerFromJSON)),
    };
}

export function CompanyFormRoleToJSON(value?: CompanyFormRole | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'role': value.role,
        'forms': value.forms === undefined ? undefined : ((value.forms as Array<any>).map(CompanyFormRoleFormsInnerToJSON)),
    };
}

