/* tslint:disable */
/* eslint-disable */
/**
 * Westbury Music API
 * API for Westbury Music
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { StatementsJsonMetadata } from './StatementsJsonMetadata';
import {
    StatementsJsonMetadataFromJSON,
    StatementsJsonMetadataFromJSONTyped,
    StatementsJsonMetadataToJSON,
} from './StatementsJsonMetadata';
import type { StatementsJsonPaymentsInner } from './StatementsJsonPaymentsInner';
import {
    StatementsJsonPaymentsInnerFromJSON,
    StatementsJsonPaymentsInnerFromJSONTyped,
    StatementsJsonPaymentsInnerToJSON,
} from './StatementsJsonPaymentsInner';

/**
 * 
 * @export
 * @interface StatementsJson
 */
export interface StatementsJson {
    /**
     * 
     * @type {StatementsJsonMetadata}
     * @memberof StatementsJson
     */
    metadata: StatementsJsonMetadata;
    /**
     * 
     * @type {Array<StatementsJsonPaymentsInner>}
     * @memberof StatementsJson
     */
    payments: Array<StatementsJsonPaymentsInner>;
}

/**
 * Check if a given object implements the StatementsJson interface.
 */
export function instanceOfStatementsJson(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "metadata" in value;
    isInstance = isInstance && "payments" in value;

    return isInstance;
}

export function StatementsJsonFromJSON(json: any): StatementsJson {
    return StatementsJsonFromJSONTyped(json, false);
}

export function StatementsJsonFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatementsJson {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'metadata': StatementsJsonMetadataFromJSON(json['metadata']),
        'payments': ((json['payments'] as Array<any>).map(StatementsJsonPaymentsInnerFromJSON)),
    };
}

export function StatementsJsonToJSON(value?: StatementsJson | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'metadata': StatementsJsonMetadataToJSON(value.metadata),
        'payments': ((value.payments as Array<any>).map(StatementsJsonPaymentsInnerToJSON)),
    };
}

