import React, { useState, useEffect } from 'react';
import { Col, Form, Button, FormControl, Modal } from 'react-bootstrap';
import thumbImage from 'shared_components/public/assets/ThumbsUp.svg';
import thumbDown from 'shared_components/public/assets/ThumbsDown.svg';
import { useApi } from 'shared_components/context';
import { useClientId } from 'shared_components/context/client';
import { AdminInterestRegistrationRepresentativeRoleEnum } from 'shared_components/generated/admin/models';

import { WModal } from 'shared_components/components/WModal/WModal';
import WButton from 'shared_components/components/WForms/WButton/WButton';
import { WFormProvider } from 'shared_components/components/WForms/WFormProvider';
import { WInput } from 'shared_components/components/WForms/WInput/WInput';
import { WSelect } from 'shared_components/components/WForms/WSelect/WSelect';

import z from 'zod';
import { useFormContext } from 'shared_components/components/WForms/WFormContext';
import { WFormFooter } from 'shared_components/components/WForms/WFormFooter';
function ArtistAdditionalDetails() {
  const { adminApi: api } = useApi();
  const { clientId } = useClientId();
  const [show, setShow] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [formValue, setFormValue] = useState({
    name: '',
    role: '',
    email: '',
  });
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formError, setFormError] = useState('');

  const inputChange = async (
    event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    setFormValue({
      ...formValue,
      [event.target.name]: event.target.value,
    });
  };

  const blurHandle = async (
    event: React.FocusEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    if (event.target.value) {
      setFormValue({
        ...formValue,
        [event.target.name]: event.target.value,
      });
    }
  };

  useEffect(() => {
    setFormValue({
      name: '',
      role: '',
      email: '',
    });
    fetchData();
  }, [clientId]);

  const fetchData = () => {
    if (clientId === undefined) {
      return;
    }
    api
      .retrieveAdditionalContactDetails({ userId: clientId })
      .then((additionalContactDetails) => {
        setFormValue({
          name: additionalContactDetails.representative?.name ?? '',
          role: additionalContactDetails.representative?.role ?? '',
          email: additionalContactDetails.representative?.email ?? '',
        });
      })
      .catch(() => {});
  };

  const saveDetails = async () => {
    if (clientId === undefined) {
      return;
    }
    await api
      .updateAdditionalContactDetails({
        userId: clientId,
        additionalContactDetails: {
          representative: {
            name: formValue.name,
            role: formValue.role as AdminInterestRegistrationRepresentativeRoleEnum,
            email: formValue.email,
          },
        },
      })
      .then((response) => {
        setFormSubmitted(true);
        setShow(true);
        setFormError('');
      })
      .catch((error) => {
        setFormSubmitted(true);
        setFormError('Your request has failed.');
        setShow(true);
      });
  };

  const resetData = () => {
    setFormSubmitted(false);
    setFormError('');
    setShow(false);
    setIsEditing(false);
  };

  const schema = z.object({
    name: z.string().nonempty(),
    role: z.string().nonempty(),
    email: z.string().nonempty(),
  });

  return (
    <>
      <WFormProvider
        schema={schema}
        handleSubmit={saveDetails}
        formData={formValue}
      >
        <div className="tw-flex tw-justify-end">
          <FormEditSection />
        </div>
        <div className="tw-max-w-[350px]">
          <WInput
            type="text"
            name="name"
            label="Name"
            onBlur={() => {}}
            onChange={inputChange}
            value={formValue.name}
          />
          <WSelect
            name="role"
            label="Role"
            onBlur={() => {}}
            onChange={inputChange}
            defaultValue={formValue.role}
            options={[
              { value: 'MANAGER', label: 'Manager' },
              { value: 'AGENT', label: 'Agent' },
              { value: 'PUBLICIST', label: 'Publicist' },
              { value: 'LAWYER', label: 'Lawyer' },
              { value: 'OTHER', label: 'Other' },
            ]}
          />
          <WInput
            type="email"
            name="email"
            label="Email"
            onBlur={() => {}}
            onChange={inputChange}
            value={formValue.email}
          />

          <WFormFooter />
        </div>
      </WFormProvider>

      {/* MODALS */}
      <WModal isOpen={formSubmitted} title="" onClose={resetData}>
        {formError ? (
          <div className="thankyouBlock">
            <div className="thImage">
              <img
                src={thumbDown}
                alt="Thumbs Down"
                width="180"
                className="d-block m-auto"
              />
            </div>
            <h3 className="mt-4 pt-1 mb-3 text-center">Sorry!</h3>
            <p className="text-center">
              There is an error while submitting the details! <br /> Please try
              again and make sure all the fields are filled.
            </p>
            <Col xs={12} className="d-flex justify-content-center">
              <Button
                variant="primary"
                type="button"
                className="x-button px-4 mt-3 ml-auto"
                onClick={resetData}
              >
                Ok
              </Button>
            </Col>
          </div>
        ) : (
          <div className="thankyouBlock">
            <div className="thImage">
              <img
                src={thumbImage}
                alt="Thumbs Up"
                width="180"
                className="d-block m-auto"
              />
            </div>
            <h3 className="mt-4 pt-1 mb-3 text-center">Thank you</h3>
            <p className="text-center">
              Artist&apos;s information has been updated!
            </p>
          </div>
        )}
      </WModal>
    </>
  );
}

function FormEditSection() {
  const { isEditing, setIsEditing } = useFormContext();
  return (
    <>
      {!isEditing && (
        <WButton
          label="Edit"
          variant="secondary"
          icon="edit"
          onClick={() => setIsEditing(true)}
        />
      )}
    </>
  );
}

export default ArtistAdditionalDetails;
