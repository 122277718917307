import React, { useState, useEffect } from 'react';

import { AdminSociety } from 'shared_components/generated/admin';
import { handleInputChange } from '../SocietyDetails';

import { SocietyContactInformationRoleEnum } from 'shared_components/generated/admin';
import { WInput } from 'shared_components/components/WForms/WInput/WInput';
import { WSelect } from 'shared_components/components/WForms/WSelect/WSelect';
import WButton from 'shared_components/components/WForms/WButton/WButton';
import { useFormContext } from 'shared_components/components/WForms/WFormContext';
const contactRolesOptions = [
  ...Object.keys(SocietyContactInformationRoleEnum).map((key) => {
    return {
      label: key,
      value: key,
    };
  }),
];

const defaultContactDetails = [{ name: '', email: '', phone: '', role: '' }];

interface ContactDetailsProps {
  societySelected?: AdminSociety;

  setContactDetails: (ContactDetails: any) => void;
}

interface ContactDetailsRowProps {
  index: number;
  name?: string;
  email?: string;
  phone?: string;
  role?: string;
  addNewContactDetails: () => void;
  updateContactDetails: (
    index: number,
    newContactDetail: ContactDetails
  ) => void;
  deleteContactDetails: (index: number) => void;
}

interface ContactDetails {
  name?: string;
  email?: string;
  phone?: string;
  role?: string;
}

export const ContactDetails = ({
  societySelected,
  setContactDetails,
}: ContactDetailsProps) => {
  const [contactInformation, setContactInformation] = useState<
    Array<ContactDetails>
  >(defaultContactDetails);

  const addNewContactDetails = () => {
    const newContactDetails = [...contactInformation];
    newContactDetails.push(defaultContactDetails[0]);
    setContactInformation(newContactDetails);
  };

  const deleteContactDetails = (index: number) => {
    const newContactDetails = [...contactInformation];
    newContactDetails.splice(index, 1);
    setContactInformation(newContactDetails);
  };

  const updateContactDetails = (
    index: number,
    newContactDetail: ContactDetails
  ) => {
    const newContactDetails = [...contactInformation];
    const oldContactDetail = newContactDetails[index];
    newContactDetails[index] = {
      ...oldContactDetail,
      ...newContactDetail,
    };
    setContactInformation(newContactDetails);
  };

  useEffect(() => {
    setContactInformation(
      // @ts-ignore - CLI tool thinks .contactInformation is a string, not an Array<string>
      societySelected &&
        societySelected.contactInformation &&
        societySelected.contactInformation.length > 0
        ? societySelected.contactInformation
        : defaultContactDetails
    );
  }, [societySelected]);

  useEffect(() => {
    setContactDetails({
      contactInformation: JSON.stringify(contactInformation),
    });
  }, [contactInformation]);

  const { isEditing } = useFormContext();

  return (
    <>
      <div className="tw-grid tw-grid-cols-2 tw-gap-2">
        {contactInformation.map(({ name, email, phone, role }, index) => (
          <ContactDetailsRow
            key={index}
            index={index}
            name={name}
            email={email}
            phone={phone}
            role={role}
            addNewContactDetails={addNewContactDetails}
            updateContactDetails={updateContactDetails}
            deleteContactDetails={deleteContactDetails}
          />
        ))}
      </div>
      <div>
        {isEditing && (
          <WButton
            variant="link-secondary"
            icon="plus"
            label="Add a new contact"
            onClick={() => {
              addNewContactDetails();
            }}
          />
        )}
      </div>
    </>
  );
};

const ContactDetailsRow = ({
  index,
  name,
  email,
  phone,
  role,
  addNewContactDetails,
  updateContactDetails,
  deleteContactDetails,
}: ContactDetailsRowProps) => {
  const updateName = (name: string) => {
    updateContactDetails(index, { name: name, email, phone, role });
  };

  const updateEmail = (email: string) => {
    updateContactDetails(index, { name, email: email, phone, role });
  };

  const updatePhone = (phone: string) => {
    updateContactDetails(index, { name, email, phone: phone, role });
  };

  const updateRole = (role: string) => {
    updateContactDetails(index, { name, email, phone, role: role });
  };

  return (
    <div>
      <WInput
        label="Name"
        type="text"
        onBlur={() => {}}
        value={name ? name : ''}
        onChange={(e) => handleInputChange(e, updateName)}
        name="contact_name"
      />
      <WInput
        label="Email"
        type="text"
        onBlur={() => {}}
        value={email ? email : ''}
        onChange={(e) => handleInputChange(e, updateEmail)}
        name="contact_email"
      />
      <WInput
        label="Phone"
        type="text"
        onBlur={() => {}}
        value={phone ? phone : ''}
        onChange={(e) => handleInputChange(e, updatePhone)}
        name="contact_phone"
      />
      <WSelect
        name="role"
        label="Contact Role"
        options={contactRolesOptions}
        onBlur={() => {}}
        defaultValue={role ? role : undefined}
        onChange={(e) => updateRole(e.target.value)}
      />
    </div>
  );
};
