import React, { useState } from "react";
import PageLayout from "./PageLayout";

function Distribution() {
  const [sidebarSmall, setSidebarSmall] = useState(false);

  return (
    <PageLayout>

    </PageLayout>
    );
}
export default Distribution;
