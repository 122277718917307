import {
  Configuration as ClientConfiguration,
  PortalApi as ClientPortalApi,
} from '../generated/client';
import {
  Configuration as AdminConfiguration,
  PortalApi as AdminPortalApi,
} from '../generated/admin';
import { AuthMiddleware } from './auth';
import dotenv from 'dotenv';
dotenv.config();
const clientConfiguration = new ClientConfiguration({
  basePath: process.env.WB_API_URL,
  middleware: [new AuthMiddleware()],
});

const adminConfiguration = new AdminConfiguration({
  basePath: process.env.WB_API_URL,
  middleware: [new AuthMiddleware()],
});

export const clientApi = new ClientPortalApi(clientConfiguration);
export const adminApi = new AdminPortalApi(adminConfiguration);
