import React, { ChangeEvent, useEffect, useState } from 'react';
import { useFormContext } from '../WFormContext';
import clsx from 'clsx';

export type CheckBoxOption = {
  id: string;
  label: string;
  checked: boolean;
};

type Props = {
  label: string;
  options: CheckBoxOption[];
  onBlur: () => void;
  onChange: (innerOptions: any) => void;
};

// Handle checkbox change

const WSelectBoxWithCheckboxes: React.FC<Props> = ({
  label,
  options,
  onChange,
}) => {
  const handleCheckboxChange = (id: string) => {
    const prevOptions = [...innerOptions];
    const newOptions = prevOptions.map((option) =>
      option.id === id ? { ...option, checked: !option.checked } : option
    );
    setInnerOptions(newOptions);

    // tell the parent component that the options have changed
    onChange(newOptions);
  };

  // store inner options and propagate the whole array when onChange is called
  const [innerOptions, setInnerOptions] = useState<CheckBoxOption[]>(options);
  const { isEditing, isFormSubmitted, errors } = useFormContext(); // Fetch errors from context
  const [territoriesSearch, setTerritoriesSearch] = useState<string>(''); // Fetch errors from context
  const searchRegex = new RegExp(territoriesSearch, 'i');
  const [isOpen, setIsOpen] = useState(false);
  const [isInputFocused, setInputFocus] = useState(false);

  const wrapperRef = React.useRef<HTMLDivElement>(null);
  useEffect(() => {
    // set the inner options to the parent options
    setInnerOptions(options);
  }, [options]);
  // close the dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Cleanup the event listener on component unmount
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // debugger;
  return (
    <div className="tw-relative tw-mb-4" ref={wrapperRef}>
      {/* Dropdown Header */}
      <label
        htmlFor=" "
        className="tw-block tw-leading-4 tw-font-medium tw-text-white tw-mb-2"
      >
        {label}
      </label>
      {isEditing && (
        <div
          onClick={() => setIsOpen(!isOpen)}
          className={clsx(
            'tw-block tw-w-full tw-border-2  tw-bg-surfaceBlack80 tw-text-textWhite tw-p-1 tw-pl-2 tw-rounded-lg tw-cursor-pointer',
            isInputFocused ? 'tw-border-white' : 'tw-border-surfaceBlack80'
          )}
        >
          <div className="tw-relative">
            <input
              type="text"
              autoComplete="off"
              placeholder="Select territories"
              className="tw-bg-transparent tw-text-textWhite tw-w-full tw-py-2 tw-rounded-lg tw-border-0 tw-outline-none"
              name="search_key"
              id="search_key"
              value={territoriesSearch}
              onChange={(event) => setTerritoriesSearch(event.target.value)}
              onFocus={() => setInputFocus(true)}
              onBlur={() => setInputFocus(false)}
            />
            <span className="tw-absolute tw-right-0 tw-top-0 tw-h-10 tw-w-10 tw-flex tw-justify-center tw-items-center tw-pointer-events-none">
              <i className="wi wi-sm wi-chevron-down" />
            </span>
          </div>
        </div>
      )}
      {!isEditing && (
        <div className="tw-flex tw-flex-wrap">
          {innerOptions
            .filter((option) => option.checked)
            .map((option, index, filteredOptions) => (
              <div key={index} className="tw-text-sm tw-text-textDove">
                {option.label}
                {index < filteredOptions.length - 1 && <>,</>}&nbsp;
              </div>
            ))}
        </div>
      )}

      {/* Checkboxes */}
      {isOpen && (
        <div className="tw-absolute tw-w-full tw-bg-surfaceBlack80 tw-rounded-lg tw-rounded tw-max-w-[345px] tw-mt-2 tw-p-4 tw-shadow-lg tw-z-50 tw-max-h-[300px] tw-overflow-y-scroll">
          {innerOptions
            .filter((option) => searchRegex.test(option.label))
            .map((option) => (
              <div key={option.id} className="tw-flex tw-items-center tw-mb-2">
                {/* Custom Checkbox */}
                <div className="tw-relative tw-inline-block tw-w-6 tw-h-6 tw-mr-2">
                  <input
                    type="checkbox"
                    id={option.id}
                    checked={option.checked}
                    onChange={() => handleCheckboxChange(option.id)}
                    className="tw-absolute tw-w-full tw-h-full tw-opacity-0"
                  />
                  {/* Checkbox Visual */}
                  <div
                    className={`tw-w-full tw-h-full tw-border-2 tw-rounded ${
                      option.checked
                        ? 'tw-bg-surfaceBlack90'
                        : 'tw-bg-surfaceBlack80'
                    } tw-flex tw-items-center tw-justify-center tw-text-textWhite`}
                  >
                    {option.checked && (
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g id="check">
                          <path
                            id="Vector"
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M20.6187 6.38128C20.9604 6.72299 20.9604 7.27701 20.6187 7.61872L10.6187 17.6187C10.277 17.9604 9.72299 17.9604 9.38128 17.6187L4.38128 12.6187C4.03957 12.277 4.03957 11.723 4.38128 11.3813C4.72299 11.0396 5.27701 11.0396 5.61872 11.3813L10 15.7626L19.3813 6.38128C19.723 6.03957 20.277 6.03957 20.6187 6.38128Z"
                            fill="white"
                          />
                        </g>
                      </svg>
                    )}
                  </div>
                </div>
                <label htmlFor={option.id} className="tw-text-textWhite">
                  {option.label}
                </label>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default WSelectBoxWithCheckboxes;
