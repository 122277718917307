/* tslint:disable */
/* eslint-disable */
/**
 * Westbury Music API Admin
 * API for Westbury Music admin users
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdminTaskClientClientAdminTasksInner } from './AdminTaskClientClientAdminTasksInner';
import {
    AdminTaskClientClientAdminTasksInnerFromJSON,
    AdminTaskClientClientAdminTasksInnerFromJSONTyped,
    AdminTaskClientClientAdminTasksInnerToJSON,
} from './AdminTaskClientClientAdminTasksInner';
import type { AdminTaskClientPrimaryDiscographyExportsInner } from './AdminTaskClientPrimaryDiscographyExportsInner';
import {
    AdminTaskClientPrimaryDiscographyExportsInnerFromJSON,
    AdminTaskClientPrimaryDiscographyExportsInnerFromJSONTyped,
    AdminTaskClientPrimaryDiscographyExportsInnerToJSON,
} from './AdminTaskClientPrimaryDiscographyExportsInner';

/**
 * 
 * @export
 * @interface AdminTaskClient
 */
export interface AdminTaskClient {
    /**
     * 
     * @type {number}
     * @memberof AdminTaskClient
     */
    readonly id?: number;
    /**
     * Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only.
     * @type {string}
     * @memberof AdminTaskClient
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof AdminTaskClient
     */
    readonly fullName?: string;
    /**
     * 
     * @type {Array<AdminTaskClientClientAdminTasksInner>}
     * @memberof AdminTaskClient
     */
    clientAdminTasks: Array<AdminTaskClientClientAdminTasksInner>;
    /**
     * 
     * @type {Array<AdminTaskClientPrimaryDiscographyExportsInner>}
     * @memberof AdminTaskClient
     */
    primaryDiscographyExports: Array<AdminTaskClientPrimaryDiscographyExportsInner>;
    /**
     * 
     * @type {Array<AdminTaskClientPrimaryDiscographyExportsInner>}
     * @memberof AdminTaskClient
     */
    pplReviewExports: Array<AdminTaskClientPrimaryDiscographyExportsInner>;
}

/**
 * Check if a given object implements the AdminTaskClient interface.
 */
export function instanceOfAdminTaskClient(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "username" in value;
    isInstance = isInstance && "clientAdminTasks" in value;
    isInstance = isInstance && "primaryDiscographyExports" in value;
    isInstance = isInstance && "pplReviewExports" in value;

    return isInstance;
}

export function AdminTaskClientFromJSON(json: any): AdminTaskClient {
    return AdminTaskClientFromJSONTyped(json, false);
}

export function AdminTaskClientFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdminTaskClient {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'username': json['username'],
        'fullName': !exists(json, 'full_name') ? undefined : json['full_name'],
        'clientAdminTasks': ((json['client_admin_tasks'] as Array<any>).map(AdminTaskClientClientAdminTasksInnerFromJSON)),
        'primaryDiscographyExports': ((json['primary_discography_exports'] as Array<any>).map(AdminTaskClientPrimaryDiscographyExportsInnerFromJSON)),
        'pplReviewExports': ((json['ppl_review_exports'] as Array<any>).map(AdminTaskClientPrimaryDiscographyExportsInnerFromJSON)),
    };
}

export function AdminTaskClientToJSON(value?: AdminTaskClient | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'username': value.username,
        'client_admin_tasks': ((value.clientAdminTasks as Array<any>).map(AdminTaskClientClientAdminTasksInnerToJSON)),
        'primary_discography_exports': ((value.primaryDiscographyExports as Array<any>).map(AdminTaskClientPrimaryDiscographyExportsInnerToJSON)),
        'ppl_review_exports': ((value.pplReviewExports as Array<any>).map(AdminTaskClientPrimaryDiscographyExportsInnerToJSON)),
    };
}

