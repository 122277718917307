/* tslint:disable */
/* eslint-disable */
/**
 * Westbury Music API Admin
 * API for Westbury Music admin users
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SocietyFormRoleFormsInner
 */
export interface SocietyFormRoleFormsInner {
    /**
     * 
     * @type {string}
     * @memberof SocietyFormRoleFormsInner
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof SocietyFormRoleFormsInner
     */
    form?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SocietyFormRoleFormsInner
     */
    signature: SocietyFormRoleFormsInnerSignatureEnum;
    /**
     * 
     * @type {string}
     * @memberof SocietyFormRoleFormsInner
     */
    sendVia: SocietyFormRoleFormsInnerSendViaEnum;
    /**
     * 
     * @type {string}
     * @memberof SocietyFormRoleFormsInner
     */
    signedBy: SocietyFormRoleFormsInnerSignedByEnum;
}


/**
 * @export
 */
export const SocietyFormRoleFormsInnerSignatureEnum = {
    Digital: 'Digital',
    Physical: 'Physical'
} as const;
export type SocietyFormRoleFormsInnerSignatureEnum = typeof SocietyFormRoleFormsInnerSignatureEnum[keyof typeof SocietyFormRoleFormsInnerSignatureEnum];

/**
 * @export
 */
export const SocietyFormRoleFormsInnerSendViaEnum = {
    Email: 'Email',
    Post: 'Post',
    Portal: 'Portal'
} as const;
export type SocietyFormRoleFormsInnerSendViaEnum = typeof SocietyFormRoleFormsInnerSendViaEnum[keyof typeof SocietyFormRoleFormsInnerSendViaEnum];

/**
 * @export
 */
export const SocietyFormRoleFormsInnerSignedByEnum = {
    Admin: 'Admin',
    Client: 'Client',
    Both: 'Both'
} as const;
export type SocietyFormRoleFormsInnerSignedByEnum = typeof SocietyFormRoleFormsInnerSignedByEnum[keyof typeof SocietyFormRoleFormsInnerSignedByEnum];


/**
 * Check if a given object implements the SocietyFormRoleFormsInner interface.
 */
export function instanceOfSocietyFormRoleFormsInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "signature" in value;
    isInstance = isInstance && "sendVia" in value;
    isInstance = isInstance && "signedBy" in value;

    return isInstance;
}

export function SocietyFormRoleFormsInnerFromJSON(json: any): SocietyFormRoleFormsInner {
    return SocietyFormRoleFormsInnerFromJSONTyped(json, false);
}

export function SocietyFormRoleFormsInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): SocietyFormRoleFormsInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'form': !exists(json, 'form') ? undefined : json['form'],
        'signature': json['signature'],
        'sendVia': json['send_via'],
        'signedBy': json['signed_by'],
    };
}

export function SocietyFormRoleFormsInnerToJSON(value?: SocietyFormRoleFormsInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'form': value.form,
        'signature': value.signature,
        'send_via': value.sendVia,
        'signed_by': value.signedBy,
    };
}

