/* tslint:disable */
/* eslint-disable */
/**
 * Westbury Music API
 * API for Westbury Music
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ClientSocietyFormTask
 */
export interface ClientSocietyFormTask {
    /**
     * 
     * @type {string}
     * @memberof ClientSocietyFormTask
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientSocietyFormTask
     */
    societyFormId: string;
    /**
     * 
     * @type {string}
     * @memberof ClientSocietyFormTask
     */
    societyFormName: string;
    /**
     * 
     * @type {string}
     * @memberof ClientSocietyFormTask
     */
    status?: ClientSocietyFormTaskStatusEnum;
    /**
     * When the form was approved
     * @type {Date}
     * @memberof ClientSocietyFormTask
     */
    dateCompleted?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ClientSocietyFormTask
     */
    pandadocsDocumentId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientSocietyFormTask
     */
    pandadocsSessionId?: string | null;
}


/**
 * @export
 */
export const ClientSocietyFormTaskStatusEnum = {
    ClientToSign: 'client_to_sign',
    WitnessToSign: 'witness_to_sign',
    AdminToApprove: 'admin_to_approve',
    Approved: 'approved'
} as const;
export type ClientSocietyFormTaskStatusEnum = typeof ClientSocietyFormTaskStatusEnum[keyof typeof ClientSocietyFormTaskStatusEnum];


/**
 * Check if a given object implements the ClientSocietyFormTask interface.
 */
export function instanceOfClientSocietyFormTask(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "societyFormId" in value;
    isInstance = isInstance && "societyFormName" in value;

    return isInstance;
}

export function ClientSocietyFormTaskFromJSON(json: any): ClientSocietyFormTask {
    return ClientSocietyFormTaskFromJSONTyped(json, false);
}

export function ClientSocietyFormTaskFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClientSocietyFormTask {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'societyFormId': json['society_form_id'],
        'societyFormName': json['society_form_name'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'dateCompleted': !exists(json, 'date_completed') ? undefined : (json['date_completed'] === null ? null : new Date(json['date_completed'])),
        'pandadocsDocumentId': !exists(json, 'pandadocs_document_id') ? undefined : json['pandadocs_document_id'],
        'pandadocsSessionId': !exists(json, 'pandadocs_session_id') ? undefined : json['pandadocs_session_id'],
    };
}

export function ClientSocietyFormTaskToJSON(value?: ClientSocietyFormTask | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'society_form_id': value.societyFormId,
        'society_form_name': value.societyFormName,
        'status': value.status,
        'date_completed': value.dateCompleted === undefined ? undefined : (value.dateCompleted === null ? null : value.dateCompleted.toISOString()),
        'pandadocs_document_id': value.pandadocsDocumentId,
        'pandadocs_session_id': value.pandadocsSessionId,
    };
}

