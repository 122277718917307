/* tslint:disable */
/* eslint-disable */
/**
 * Westbury Music API Admin
 * API for Westbury Music admin users
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AdminTaskClientPrimaryDiscographyExportsInner
 */
export interface AdminTaskClientPrimaryDiscographyExportsInner {
    /**
     * 
     * @type {string}
     * @memberof AdminTaskClientPrimaryDiscographyExportsInner
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminTaskClientPrimaryDiscographyExportsInner
     */
    readonly s3Url?: string;
    /**
     * 
     * @type {Date}
     * @memberof AdminTaskClientPrimaryDiscographyExportsInner
     */
    readonly dateCreated?: Date;
    /**
     * 
     * @type {Date}
     * @memberof AdminTaskClientPrimaryDiscographyExportsInner
     */
    readonly dateUpdated?: Date;
    /**
     * 
     * @type {string}
     * @memberof AdminTaskClientPrimaryDiscographyExportsInner
     */
    s3Key: string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminTaskClientPrimaryDiscographyExportsInner
     */
    ready: boolean;
    /**
     * 
     * @type {number}
     * @memberof AdminTaskClientPrimaryDiscographyExportsInner
     */
    user: number;
    /**
     * 
     * @type {string}
     * @memberof AdminTaskClientPrimaryDiscographyExportsInner
     */
    task?: string | null;
}

/**
 * Check if a given object implements the AdminTaskClientPrimaryDiscographyExportsInner interface.
 */
export function instanceOfAdminTaskClientPrimaryDiscographyExportsInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "s3Key" in value;
    isInstance = isInstance && "ready" in value;
    isInstance = isInstance && "user" in value;

    return isInstance;
}

export function AdminTaskClientPrimaryDiscographyExportsInnerFromJSON(json: any): AdminTaskClientPrimaryDiscographyExportsInner {
    return AdminTaskClientPrimaryDiscographyExportsInnerFromJSONTyped(json, false);
}

export function AdminTaskClientPrimaryDiscographyExportsInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdminTaskClientPrimaryDiscographyExportsInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        's3Url': !exists(json, 's3_url') ? undefined : json['s3_url'],
        'dateCreated': !exists(json, 'date_created') ? undefined : (new Date(json['date_created'])),
        'dateUpdated': !exists(json, 'date_updated') ? undefined : (new Date(json['date_updated'])),
        's3Key': json['s3_key'],
        'ready': json['ready'],
        'user': json['user'],
        'task': !exists(json, 'task') ? undefined : json['task'],
    };
}

export function AdminTaskClientPrimaryDiscographyExportsInnerToJSON(value?: AdminTaskClientPrimaryDiscographyExportsInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        's3_key': value.s3Key,
        'ready': value.ready,
        'user': value.user,
        'task': value.task,
    };
}

