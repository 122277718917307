import React from 'react';
import { useApi } from 'shared_components/context';
import { WLink } from 'shared_components/components/WLink/WLink';
import { getTokenFromSessionStorage } from 'shared_components/context/auth';
import { useNavigate } from 'react-router-dom';
import { useUser } from 'shared_components/context/user';
import WButton from 'shared_components/components/WForms/WButton/WButton';
import WDropdown from 'shared_components/components/WDropdownContent/WDropdown';
import SquarePlaceholder100 from 'shared_components/public/assets/SquarePlaceholder100.svg';

export const Header = () => {
  const user = useUser();
  const navigate = useNavigate();
  const api = useApi().adminApi;
  const handleSignOut = async () => {
    try {
      // const refreshToken = getTokenFromSessionStorage()?.refresh;
      // if (!refreshToken) {
      //   return;
      // }
      // await api.createTokenBlacklist({
      //   tokenBlacklist: { refresh: refreshToken },
      // });

      localStorage.removeItem('token');
      sessionStorage.removeItem('token');

      navigate('/');
    } catch (error) {
      console.error('Error during logout', error);
    }
  };

  return (
    <div className="tw-flex tw-flex-row tw-justify-end tw-max-w-[1704px] tw-p-6">
      <WDropdown
        trigger={
          <div className="tw-flex tw-flex-row tw-items-center tw-text-white tw-justify-start tw-space-x-2">
            <span>Welcome back {user.firstName}</span>{' '}
            <img
              className="tw-rounded-full tw-ml-6 tw-w-[25px]"
              src={user.imageUrl || SquarePlaceholder100}
              alt="profile img"
            />
            <i className="wi wi-20px wi-chevron-down"></i>
          </div>
        }
        content={
          <div className="tw-flex tw-flex-col tw-space-y-3 tw-p-6">
            <p className="tw-text-sm tw-font-light tw-text-xs">
              Switch dashboard
            </p>
            <hr className="tw-h-px tw-my-8 tw-bg-gray-200 tw-border-0 tw-dark:bg-gray-700" />

            <WLink label="Memberships" to="/dashboard" icon="users"></WLink>
            <WLink
              label="Discography"
              variant="disabled"
              to="/discography-dashboard"
              icon="speakers"
            ></WLink>

            {/* <Link to="/distribution-dashboard">
              <div className="tw-flex tw-items-center tw-flex tw-space-x-2 tw-pb-4">
                <i className="wi wi-20px wi-gbp" /> <span>Distribution</span>
              </div>
            </Link> */}
            <WLink
              variant="disabled"
              label="Distribution"
              to="/distribution-dashboard"
              icon="gbp"
            ></WLink>
            <WButton
              variant="primary"
              label="Sign out"
              onClick={handleSignOut}
            />
          </div>
        }
      ></WDropdown>
    </div>
  );
};

export default Header;
