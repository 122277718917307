import React from 'react';
import { FormControl } from 'react-bootstrap';
import SelectInput from 'shared_components/components/SelectInput';
import { handleInputChange } from '../../SocietyDetails';
import { AdminSocietyFormReminderFrequencyEnum } from 'shared_components/generated/admin';
import { WSelect } from 'shared_components/components/WForms/WSelect/WSelect';
import { WInput } from 'shared_components/components/WForms/WInput/WInput';

const frequencyOptions = Object.values(AdminSocietyFormReminderFrequencyEnum);

interface FormReminderFrequencySegmentProps {
  reminderSociety: string;
  reminderEmail: string;
  reminderFrequency: string;
  stopReminder: string;
  setReminderFrequency: (newState: string) => void;
  setReminderEmail: (newState: string) => void;
  setStopReminder: (newState: string) => void;
}

export const FormReminderFrequencySegment = ({
  reminderSociety,
  reminderEmail,
  reminderFrequency,
  stopReminder,
  setReminderFrequency,
  setReminderEmail,
  setStopReminder,
}: FormReminderFrequencySegmentProps) => {
  // transform options
  let reminderOptions = frequencyOptions.map((option) => {
    return { value: option, label: option };
  });
  return (
    <>
      {reminderSociety === 'reminder_society_Yes' && (
        <div className="tw-flex tw-space-x-4">
          <div className="tw-w-1/2">
            <WSelect
              label="Reminder frequency"
              name="reminder_frequency"
              defaultValue={reminderFrequency}
              options={reminderOptions}
              onBlur={() => {}}
              onChange={(e) => setReminderFrequency(e.target.value)}
            />
            <WSelect
              label="Stop reminders after"
              name="stop_reminder"
              defaultValue={stopReminder}
              options={reminderOptions}
              onBlur={() => {}}
              onChange={(e) => setStopReminder(e.target.value)}
            />
          </div>
          <div className="tw-w-1/2">
            <WInput
              label="Send to"
              type="email"
              name="reminder_email"
              onBlur={() => {}}
              onChange={(e) => setReminderEmail(e.target.value)}
              value={reminderEmail}
            />
          </div>
        </div>
      )}
    </>
  );
};
