import React, { useState, useEffect } from 'react';
import { Form, Button, FormControl } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useClientId } from 'shared_components/context/client';
import { useApi } from 'shared_components/context';
import Select from 'react-select';
import { WSidebarModal } from 'shared_components/components/WModal/WSidebarModal';
interface CashChequeProps {
  cashChequeId: string
  onComplete?: () => void
  cardEvent: () => void
  isOpen: boolean
}

function CashChequeCard({
  cashChequeId,
  onComplete,
  cardEvent,
  isOpen
}: CashChequeProps) {
  const { adminApi: api } = useApi();
  const [showDetails, setShowDetails] = useState(false);
  const [isSelected, setIsSelected] = useState<false | number>(false);
  const [isArtistToggle, setArtistToggle] = useState(false);
  const [isAmountToggle, setAmountToggle] = useState(false);
  const [isPercentageToggle, setPercentageToggle] = useState(false);
  const [formStatus, setFormStatus] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formError, setFormError] = useState<false | string>(false);
  const { clientId, clientEmail } = useClientId();
  const statusDropList = [
    'Cash Cheque 08/06/22',
    'Waiting to receive money',
    'Credit advice received',
    'Xero money in',
    'Internal Transfer',
  ].map((statusDrop) => ({
    value: statusDrop,
    label: statusDrop,
  }));
  const associationDetails = [
    {
      name: 'David Bowie',
      amount: '10,844',
      percentage: '57%',
    },
    {
      name: 'Emma Bowie',
      amount: '2,844',
      percentage: '17%',
    },
    {
      name: 'Geri Bowie',
      amount: '3,844',
      percentage: '37%',
    },
  ];
  useEffect(() => {
    setFormSubmitted(false);
    setFormError(false);
    fetchData();
  }, [clientEmail]);

  const fetchData = () => {
    if (clientId === undefined) {
      return;
    }
  };

  return (
    <WSidebarModal isOpen={isOpen} cardEvent={cardEvent}>
      <div className="artistDetailsCard tw-bg-surfaceBlack80">
        <div className="detailsBoxWrap">
          <h4 className="d-flex align-items-center mb-3">
            <span className="pr-14">Distribution details</span>
          </h4>
          <div className="d-flex mb-2 fonmGroupControl edt-form-field">
            <label className="dstcard-label">Society: </label>
            <span className="dstcard-fieldValue ">SoundExchange</span>
          </div>
          <div className="d-flex mb-2 fonmGroupControl edt-form-field">
            <label className="dstcard-label">Amount: </label>
            <span className="dstcard-fieldValue ">$18,475</span>
          </div>
          <div className="d-flex mb-2 fonmGroupControl edt-form-field">
            <label className="dstcard-label">Date: </label>
            <span className="dstcard-fieldValue "></span>
          </div>
          <div className="d-flex mb-2 fonmGroupControl edt-form-field">
            <label className="dstcard-label">Email: </label>
            <span className="dstcard-fieldValue ">
              distribution@soundexchange.com
            </span>
          </div>
          <div className="d-flex mb-2 fonmGroupControl edt-form-field">
            <label className="dstcard-label">Phone: </label>
            <span className="dstcard-fieldValue ">+1 202-604-5858</span>
          </div>
          <div className="d-flex mb-2 fonmGroupControl edt-form-field">
            <label className="dstcard-label">Status: </label>
            <span className="dstcard-fieldValue ">
              <Select
                options={statusDropList}
                className="statusSelect"
                // defaultValue={selectedCountry}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    width: '222px',
                    borderColor: '#000!important',
                    height: '30px',
                    minHeight: '30px',
                    color: '#191C1F',
                  }),
                  placeholder: (provided) => ({
                    ...provided,
                    color: '#000', // Set the desired color for the placeholder text
                  }),
                  option: (provided) => ({
                    ...provided,
                    borderBottom: '1px solid #000',
                    color: 'var(--color-black)',
                    fontSize: '1rem',
                    padding: '.51rem 10px',
                    backgroundColor: 'white!important',
                    cursor: 'pointer',
                    width: '222px', // Set the desired width for the dropdown
                  }),
                  menu: (provided) => ({
                    ...provided,
                    maxHeight: '256px', // Set maximum height for the dropdown container
                    overflowY: 'hidden', // Enable vertical scrolling
                    width: '222px', // Set the desired width for the dropdown
                  }),
                }}
              />
            </span>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-end my-4 py-2">
        <button
          className="x-button px-3 py-2 gradient-bg"
          onClick={(e) => setFormSubmitted(true)}
        >
          Complete
        </button>
      </div>

      <div className="artistDetailsCard tw-bg-surfaceBlack80">
        <div className="detailsBoxWrap">
          <div className="associationDetails">
            <div className="listingTableWrap">
              <div className="d-flex ardHeadingsRow ardRow justify-content-between">
                <div className="ard-name ardHeading">
                  Artist
                  {isArtistToggle ? (
                    <button
                      className="dropArrowButton arrowSort arrowSortUp"
                      onClick={() => setArtistToggle(false)}
                    >
                      <span className="sr-only">close</span>
                    </button>
                  ) : (
                    <button
                      className="dropArrowButton arrowSort arrowSortDown"
                      onClick={() => setArtistToggle(true)}
                    >
                      <span className="sr-only">Open</span>
                    </button>
                  )}
                  <div
                    className={`ard-inner ${
                      isArtistToggle ? '' : 'd-none'
                    }`}
                  >
                    <a href="javascript:void(0);">
                      <span className="arrow-asc"></span>A to Z
                    </a>
                    <a href="javascript:void(0);">
                      <span className="arrow-dsc"></span>Z to A
                    </a>
                  </div>
                </div>

                <div className="amounts ardHeading d-flex">
                  Amount (USD $)
                  {isAmountToggle ? (
                    <button
                      className="dropArrowButton arrowSort arrowSortUp"
                      onClick={() => setAmountToggle(!isAmountToggle)}
                    >
                      <span className="sr-only">close</span>
                    </button>
                  ) : (
                    <button
                      className="dropArrowButton arrowSort arrowSortDown"
                      onClick={() => setAmountToggle(!isAmountToggle)}
                    >
                      <span className="sr-only">Open</span>
                    </button>
                  )}
                  <div
                    className={`ard-inner ${
                      isAmountToggle ? '' : 'd-none'
                    }`}
                  >
                    <a href="javascript:void(0);">
                      <span className="arrow-asc"></span>A to Z
                    </a>
                    <a href="javascript:void(0);">
                      <span className="arrow-dsc"></span>Z to A
                    </a>
                  </div>
                </div>

                <div className="percentage ardHeading d-flex">
                  % of distribution
                  {isPercentageToggle ? (
                    <button
                      className="dropArrowButton arrowSort arrowSortUp"
                      onClick={() =>
                        setPercentageToggle(!isPercentageToggle)
                      }
                    >
                      <span className="sr-only">close</span>
                    </button>
                  ) : (
                    <button
                      className="dropArrowButton arrowSort arrowSortDown"
                      onClick={() =>
                        setPercentageToggle(!isPercentageToggle)
                      }
                    >
                      <span className="sr-only">Open</span>
                    </button>
                  )}
                  <div
                    className={`ard-inner ${
                      isPercentageToggle ? '' : 'd-none'
                    }`}
                  >
                    <a href="javascript:void(0);">
                      <span className="arrow-asc"></span>A to Z
                    </a>
                    <a href="javascript:void(0);">
                      <span className="arrow-dsc"></span>Z to A
                    </a>
                  </div>
                </div>

                <div className="viewRecords d-flex"></div>
              </div>
              {associationDetails.length > 0 ? (
                <div className="ardRowWrap">
                  {associationDetails.map((artist, playIndex) => (
                    <div className="ardRowInner" key={playIndex.toString()}>
                      <div className="ardRow b-0 d-flex justify-content-between">
                        <div className="ard-name">{artist.name}</div>
                        <div className="amounts">{artist.amount}</div>
                        <div className="percentage">
                          {artist.percentage}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </div>
    </WSidebarModal>
  );
}

export default CashChequeCard;
